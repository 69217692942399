/**
 * @author 小熊
 * @description 多语言配置表
 **/

/**
 * 使用说明：
 *   使用：
 *        1）、this.$translate('文本')
 *        2）、this.$translate({
 *             text: '',           // 文本
 *             hasUpper: false,    // 返回值是否均大写
 *             hasLower: false,    // 返回值是否均小写
 *             langCode: '',       // 指定返回的语言，默认取缓存
 *        })
 *
 * */
class Fn {
    translate(options, lang, ctx) {
        let obj = {}
        if (Object.prototype.toString.call(options) !== '[object Object]') {
            obj = {
                text: options
            }
        }
        let { text, langCode, hasUpper, hasLower } = obj;
        const storeLang = ctx.store.state.national.language || {};
        let language = ctx.$cookies.get('language') || storeLang;
        this.symbol = `🍦`
        this.text = text
        this.hasUpper = hasUpper
        this.hasLower = hasLower
        this.langCode = lang || langCode || language.identify || ''
        this.textLangCode = `${this.symbol}${this.text}${this.symbol}${this.langCode}`
        this.setLangList()
        this.setLangStr()
        this.findLang()
        return this.result
    }
    findLang() {
        this.result = ''
        this.langStr.some(item => {
            if ((item.toLowerCase()).includes(this.textLangCode.toLowerCase())) {
                this.result = item.split(this.symbol)[3]
                return true
            }
        })
        !this.result && (this.result = this.text)
        this.hasUpper && (this.result = this.result.toUpperCase())
        this.hasLower && (this.result = this.result.toLowerCase())
    }
    setLangStr() {
        this.langStr = []
        this.identify = ['en_US', 'fr_FR', 'de_DE', 'es_ES', 'ru_RU', 'pt_PT', 'ja_JP', `ar_SA`] // [英语、法语、德语、西班牙语、俄罗斯、葡萄牙语、日语、阿拉伯语]
        this.langList.forEach(item => {
            item.list.unshift(this.text)
            item.list.forEach((list, c) => {
                this.langStr.push(`${this.symbol}${item.text}${this.symbol}${this.identify[c]}${this.symbol}${list}`)
            })
        })
    }
    setLangList() {
        this.langList = [{
            text: 'YFN',
            list: ['YFN', 'YFN', 'YFN',
                ``]
        }, {
            text: 'Category',
            list: ['Catégorie', 'Kategorie', 'Categoría', 'Категория', 'Categoria', 'カテコリ', 'فئة']
        }, {
            text: 'Only',
            list: ['Seulement', 'Nur', 'Solamente',
                'Только', 'Apenas', 'おすすめ', 'فقط']
        }, {
            text: 'Clear',
            list: [`C'est clair.`, 'Löschen', 'Claro',
                'Прозрачный', 'Claro', 'クリア', `واضح`]
        }, {
            text: 'Bag',
            list: ['Panier', 'Tasche', 'Bolsa',
                'Сумка', 'Bolsa', 'バッグ', `شنطة`]
        }, {
            text: 'Me',
            list: ['Moi', 'Mein', 'Yo',
                'Мне', 'Meu', '自分', `أنا`]
        }, {
            text: 'and',
            list: ['et', 'Und', 'y', 'и', 'e', 'と', `و`]
        }, {
            text: 'Fee',
            list: ['Frais', 'Gebühr', 'Tarifa', 'Платеж', 'Taxa', '手数料', `مصاريف`]
        }, {
            text: 'Successful',
            list: ['Réussi', 'Erfolgreich', 'Exitoso', 'Успешный', 'Bem-sucedido', '成功', `ناجح`]
        }, {
            text: 'Pending',
            list: ['En attente', 'Ausstehend', 'Pendiente', 'В ожидании', 'Pendente', '保留中', `قيد الانتظار`]
        }, {
            text: 'Failed',
            list: ['Échoué', 'Fehlgeschlagen', 'Fallido', 'Неуспешный', 'Fracassado', '失敗した', `فشل`]
        }, {
            text: 'Cancel',
            list: ['Annuler', 'Stornieren', 'Cancelar', 'Отмена', 'Cancelar', 'キャンセル', `يلغي`]
        }, {
            text: 'Canceled',
            list: ['Annulé', 'Abgesagt', 'Cancelado', 'Отменено', 'Cancelado', 'キャンセル', `ألغيت`]
        }, {
            text: 'street',
            list: ['rue', 'Straße', 'calle', 'улица', 'rua', '街', `شارع`]
        }, {
            text: 'Delivered',
            list: ['Livré', 'Geliefert', 'Entregado', 'Доставленный', 'Entregue', '配達済み', `تم التوصيل`]
        }, {
            text: 'Jan',
            list: ['Jan', 'Jan', 'Ene',
                'Ян', 'janeiro', '1月', `يناير`]
        }, {
            text: 'Feb',
            list: ['Fév', 'Feb', 'Feb',
                'февраль', 'fevereiro', '2月', `فبراير`]
        }, {
            text: 'Mar',
            list: ['Mar', 'Beschädigen', 'Mar',
                'март', 'março', '3月', `مارس`]
        }, {
            text: 'Apr',
            list: ['Avr', 'Apr', 'Abr',
                'апр', 'abril', '4月', `أبريل`]
        }, {
            text: 'May',
            list: ['Peut', 'Dürfen', 'Puede',
                'Может', 'Poderia', '5月', `يمكن`]
        }, {
            text: 'Jun',
            list: ['Juin', 'Jun', 'Jun',
                'июнь', 'junho', '6月', `يونيو`]
        }, {
            text: 'Jul',
            list: ['Juil', 'Juli', 'Jul',
                'июль', 'julho', '7月', `يوليو`]
        }, {
            text: 'Aug',
            list: ['Août', 'Aug', 'Ago',
                'авг', 'agosto', '8月', `أغسطس`]
        }, {
            text: 'Sept',
            list: ['Septembre', 'Sept', 'septiembre',
                'Сентябрь', 'setembro', '9月', `سبتمبر`]
        }, {
            text: 'Oct',
            list: ['Oct', 'Okt', 'Oct',
                'октябрь', 'outubro', '10月', `أكتوبر`]
        }, {
            text: 'Nov',
            list: ['Nov', 'Nov', 'Nov',
                'ноябрь', 'novembro', '11月', `نوفمبر`]
        }, {
            text: 'Dec',
            list: ['Déc', 'Dez', 'Dic',
                'декабрь', 'dezembro', '12月', `ديسمبر`]
        }, {
            text: 'Change',
            list: ['Changement', 'Ändern', 'Cambiar', 'Изменять', 'Mudar', '変化', `يتغير`]
        }, {
            text: 'REGISTER',
            list: [`S'inscrire`, 'Registrieren', 'Registrar',
                'РЕГИСТР', 'REGISTRO', '登録', `يسجل`]
        }, {
            text: 'Friends',
            list: [
                `Amis`,
                `Freunde`,
                `Amigos`,
                `Друзья`,
                `Amigos`,
                `友達`,
                `أصدقاء`]
        }, {
            text: 'You',
            list: [
                `Toi`,
                `Du`,
                `Tú`,
                `Ты`,
                `Você`,
                `あなた`,
                `أنت`]
        }, {
            text: 'Reviewed',
            list: [
                `Révisé`,
                `Bewertet`,
                `Revisado`,
                `Рассмотрено`,
                `Revisado`,
                `審査`,
                `تمت المراجعة`]
        }, {
            text: 'Size',
            list: [
                `Taille`,
                `Größe`,
                `Tamaño`,
                `Размер`,
                `Tamanho`,
                `サイズ`,
                `حجم`]
        }, {
            text: 'Email',
            list: ['l’e-mail', 'E-Mail', 'correo electrónico',
                'Электронная почта', 'E-mail', 'Eメール',
                `بريد إلكتروني`]
        }, {
            text: 'SIGN IN',
            list: ['Connexion', 'Anmelden', 'Ingresar',
                'ВОЙТИ', 'ENTRAR', 'ログイン', `تسجيل الدخول`]
        }, {
            text: 'EMAIL ADDRESS',
            list: ['Adresse courriel', 'E-Mail-Adresse', 'Email/Correo',
                'АДРЕС ЭЛЕКТРОННОЙ ПОЧТЫ', 'ENDEREÇO ​​DE EMAIL', '電子メールアドレス', `عنوان البريد الإلكتروني`]
        }, {
            text: 'PASSWORD',
            list: ['Mot de passe', 'Passwort', 'Contraseña',
                'ПАРОЛЬ', 'SENHA', 'パスワード', `كلمة المرور`]
        }, {
            text: 'Total',
            list: ['Tout le', 'Insgesamt', 'Todo',
                'Общий', 'Total', '合計', `المجموع`]
        }, {
            text: 'Satisfied',
            list: ['Satisfait', 'Befriedigt', 'Satisfecho',
                'Удовлетворен', 'Satisfeito', '満足', `راضي`]
        }, {
            text: 'Unsatisfied',
            list: ['Insatisfait', 'Nicht zufrieden', 'Insatisfecho',
                'Неудовлетворенный', 'Insatisfeito', '不満', `غير راضي`]
        }, {
            text: 'Rating',
            list: ['Notation', 'Bewertung', 'Clasificación',
                'Рейтинг', 'Avaliação', '評価', `تقييم`]
        }, {
            text: 'Please enter',
            list: [`Entrez s'il vous plait`, 'Bitte eingeben', 'Por favor escribe',
                'Пожалуйста входите', 'Por favor, insira', '入ってください', `تفضل`]
        }, {
            text: 'Very Dissatisfied',
            list: ['Très insatisfait', 'Sehr unzufrieden', 'Muy insatisfecho', 'Очень Недовольный', 'Muito Insatisfeito', '非常に不満', `مستاء جدا`]
        }, {
            text: 'Dissatisfied',
            list: ['Mécontent', 'Unzufrieden', 'Insatisfecho', 'Неудовлетворенный', 'Insatisfeito', '不満', `غير راض`]
        }, {
            text: 'Fair',
            list: ['Équitable', 'Gerecht', 'Justo', 'Справедливый', 'Justo', '公平', `عدل`]
        }, {
            text: 'Satisfied',
            list: ['Satisfait', 'Befriedigt', 'Satisfecho', 'Удовлетворен', 'Satisfeito', '満足', `راضي`]
        }, {
            text: 'items',
            list: ['Articles', 'Artikel', 'Productos',
                'предметы', 'Unid', 'アイテム',
                `أغراض`]
        }, {
            text: 'Unpaid',
            list: [
                `Non payé`,
                `Unbezahlt`,
                `No pagado`,
                `Неоплачиваемый`,
                `não pago`,
                `未払い`,
                `غير مدفوعة`]
        }, {
            text: 'Processing',
            list: [
                `En cours`,
                `Verarbeitung`,
                `Procesando`,
                `Обработка`,
                `Em processamento`,
                `処理`,
                `يعالج`]
        }, {
            text: 'Shipped',
            list: [
                `Expédié`,
                `Ausgeliefert`,
                `Enviado`,
                `Отправленный`,
                `Enviado`,
                `出荷済み`,
                `شحنها`]
        }, {
            text: 'Delivered',
            list: [
                `Livré`,
                `Geliefert`,
                `Entregado`,
                `Доставленный`,
                `Entregue`,
                `納品済み`,
                `تم التوصيل`]
        }, {
            text: 'Cancelled',
            list: [
                `Annulé`,
                `Abgesagt`,
                `Cancelado`,
                `Отменено`,
                `Cancelado`,
                `キャンセル`,
                `ألغيت`]
        }, {
            text: 'Refunded',
            list: [
                `Remboursé`,
                `Erstattet`,
                `Reintegrado`,
                `Возмещено`,
                `Devolveu`,
                `返金されました`,
                `معاد`]
        }, {
            text: 'Reviews',
            list: [
                `Commentaires`,
                `Rezensionen`,
                `Reseñas`,
                `Отзывы`,
                `Avaliações`,
                `レビュー`,
                `المراجعات`]
        }, {
            text: 'Pending',
            list: [
                `En attente`,
                `Ausstehend`,
                `Pendiente`,
                `В ожидании`,
                `Pendente`,
                `保留中`,
                `قيد الانتظار`]
        }, {
            text: 'days',
            list: [
                `jours`,
                `Tage`,
                `días`,
                `дни`,
                `dias`,
                `日々`,
                `أيام`]
        }, {
            text: 'coupon',
            list: [
                `coupon`,
                `Coupon`,
                `cupón`,
                `купон`,
                `cupom`,
                `クーポン`,
                `قسيمة`]
        }, {
            text: 'reward',
            list: [
                `récompense`,
                `belohnen`,
                `premio`,
                `награда`,
                `recompensa`,
                `褒美`,
                `جائزة`]
        }, {
            text: 'Order ID',
            list: [
                `numéro de commande`,
                `Auftragsnummer`,
                `Solicitar ID`,
                `номер заказа`,
                `ID do pedido`,
                `注文ID`,
                `رقم التعريف الخاص بالطلب`]
        }, {
            text: 'OFF',
            list: [
                `DÉSACTIVÉ`,
                `AUS`,
                `APAGADO`,
                `ВЫКЛЮЧЕННЫЙ`,
                `DESLIGADO`,
                `オフ`,
                `عن`]
        }, {
            text: 'Translate',
            list: [
                `Traduire`,
                `Übersetzen`,
                `Traducir`,
                `Переводить`,
                `Traduzir`,
                `翻訳する`,
                `يترجم`]
        }, {
            text: 'Translating',
            list: [
                `Traduction en cours`,
                `Übersetzen`,
                `Traductorio`,
                `Идет перевод`,
                `Traduzindo`,
                `翻訳中`,
                `جار الترجمة`]
        }, {
            text: 'See Original',
            list: [
                `Voir l'original`,
                `Siehe Original`,
                `Ver original`,
                `См. оригинал`,
                `Ver Original`,
                `原文を見る`,
                `انظر الأصل`]
        }, {
            text: 'Recommended',
            list: [
                `Recommandé`,
                `Empfohlen`,
                `Recomendado`,
                `рекомендуемые`,
                `Recomendado`,
                `推奨`,
                `مُستَحسَن`]
        }, {
            text: 'Sites',
            list: [
                `Des sites`,
                `Websites`,
                `Sitios`,
                `Места`,
                `Locais`,
                `サイト`,
                `مواقع`]
        }, {
            text: 'Countries',
            list: [
                `Des pays`,
                `Länder`,
                `Países`,
                `Страны`,
                `Países`,
                `国`,
                `بلدان`]
        }, {
            text: 'PC',
            list: [
                `PC`,
                `PC`,
                `ordenador personal`,
                `ПК`,
                `PC`,
                `パソコン`,
                `الكمبيوتر`]
        }, {
            text: 'Mobile',
            list: [
                `Mobile`,
                `Handy, Mobiltelefon`,
                `Móvil`,
                `мобильный`,
                `Móvel`,
                `携帯`,
                `متحرك`]
        }, {
            text: 'North America',
            list: [
                `Amérique du Nord`,
                `Nordamerika`,
                `América del norte`,
                `Северная Америка`,
                `América do Norte`,
                `北米`,
                `أمريكا الشمالية`]
        }, {
            text: 'Canada',
            list: [
                `Canada`,
                `Kanada`,
                `Canadá`,
                `Канада`,
                `Canadá`,
                `カナダ`,
                `كندا`]
        }, {
            text: 'Europe',
            list: [
                `L'Europe `,
                `Europa`,
                `Europa`,
                `Европа`,
                `Europa`,
                `ヨーロッパ`,
                `أوروبا`]
        }, {
            text: 'United Kingdom',
            list: [
                `Royaume-Uni`,
                `Großbritannien`,
                `Reino Unido`,
                `Великобритания`,
                `Reino Unido`,
                `イギリス`,
                `المملكة المتحدة`]
        }, {
            text: 'France',
            list: [
                `France`,
                `Frankreich`,
                `Francia`,
                `Франция`,
                `França`,
                `フランス`,
                `فرنسا`]
        }, {
            text: 'Germany',
            list: [
                `Allemagne`,
                `Deutschland`,
                `Alemania`,
                `Германия`,
                `Alemanha`,
                `ドイツ`,
                `ألمانيا`]
        }, {
            text: 'Spain',
            list: [
                `Espagne`,
                `Spanien`,
                `España`,
                `Испания`,
                `Espanha`,
                `スペイン`,
                `إسبانيا`]
        }, {
            text: 'Italy',
            list: [
                `Italie`,
                `Italien`,
                `Italia`,
                `Италия`,
                `Itália`,
                `イタリア`,
                `إيطاليا`]
        }, {
            text: 'Portugal',
            list: [
                `le Portugal`,
                `Portugal`,
                `Portugal`,
                `Португалия`,
                `Portugal`,
                `ポルトガル`,
                `البرتغال`]
        }, {
            text: 'Ireland',
            list: [
                `Irlande`,
                `Irland`,
                `Irlanda`,
                `Ирландия`,
                `Irlanda`,
                `アイルランド`,
                `أيرلندا`]
        }, {
            text: 'Netherlands',
            list: [
                `Pays-Bas`,
                `Niederlande`,
                `Países Bajos`,
                `Нидерланды`,
                `Holanda`,
                `オランダ`,
                `هولندا`]
        }, {
            text: 'Oceania',
            list: [
                `Océanie`,
                `Ozeanien`,
                `Oceanía`,
                `Океания`,
                `Oceânia`,
                `オセアニア`,
                `أوقيانوسيا`]
        }, {
            text: 'Australia',
            list: [
                `Australie`,
                `Australien`,
                `Australia`,
                `Австралия`,
                `Austrália`,
                `オーストラリア`,
                `أستراليا`]
        }, {
            text: 'The Middle East',
            list: [
                `Moyen-orient`,
                `Mittlerer Osten`,
                `El medio Oriente`,
                `Ближний Восток`,
                `O Oriente Médio`,
                `中東`,
                `الشرق الأوسط`]
        }, {
            text: 'United Arab Emirates',
            list: [
                `Emirats Arabes Unis`,
                `Vereinigte Arabische Emirate`,
                `Emiratos Árabes Unidos`,
                `Объединенные Арабские Эмираты`,
                `Emirados Árabes Unidos`,
                `アラブ首長国連邦`,
                `الإمارات العربية المتحدة`]
        }, {
            text: 'Saudi',
            list: [
                `Saoudien`,
                `Saudi-Arabien`,
                `saudí`,
                `Саудовская Аравия`,
                `saudita`,
                `サウジ`,
                `سعودي`]
        }, {
            text: 'South America',
            list: [
                `Amérique du Sud`,
                `Südamerika`,
                `Sudamerica`,
                `Южная Америка`,
                `América do Sul`,
                `南アメリカ`,
                `أمريكا الجنوبية`]
        }, {
            text: 'Mexico',
            list: [
                `Mexique`,
                `Mexiko`,
                `México`,
                `Мексика`,
                `México`,
                `メキシコ`,
                `المكسيك`]
        }, {
            text: 'Brazil',
            list: [
                `Brésil`,
                `Brasilien`,
                `Brasil`,
                `Бразилия`,
                `Brasil`,
                `ブラジル`,
                `البرازيل`]
        }, {
            text: 'Asia',
            list: [
                `Asie`,
                `Asien`,
                `Asia`,
                `Азия`,
                `Ásia`,
                `アジア`,
                `آسيا`]
        }, {
            text: 'Japan',
            list: [
                `Japon`,
                `Japan`,
                `Japón`,
                `Япония`,
                `Japão`,
                `日本`,
                `اليابان`]
        }, {
            text: `Saved`,
            list: [
                `Enregistré`,
                `Gerettet`,
                `Salvado`,
                `Сохранено`,
                `Salvou`,
                `保存されました`,
                `أنقذ`]
        }, {
            text: 'Very Satisfied',
            list: ['Très satisfait', 'Sehr zufrieden', 'Muy satisfecho', 'очень доволен', 'muito satisfeito', '非常に満足', `راضي جدا`]
        }, {
            text: 'Tap to rate',
            list: ['Appuyez pour noter', 'Zum Bewerten tippen', 'Toca para calificar', 'Нажмите, чтобы оценить', 'Toque para avaliar', 'タップして評価する', `انقر للتقييم`]
        }, {
            text: `I'd like to receive exclusive offers and news by Email or SMS`,
            list: [`Je souhaite recevoir des offres exclusives et des nouvelles par
            Courriel ou SMS`, `Ich möchte exklusive Angebote und Neuigkeiten per E-Mail oder SMS erhalten`, `Me gustaría recibir ofertas exclusivas y noticias por Email o SMS`,
                'Я хочу получать эксклюзивные предложения и новости по электронной почте или SMS', 'Desejo receber ofertas e novidades exclusivas por e-mail ou SMS', 'メールまたは SMS で限定オファーやニュースを受け取りたい',
                `أرغب في تلقي عروض وأخبار حصرية عبر البريد الإلكتروني أو الرسائل القصيرة`]
        }, {
            text: 'By sign in/register to your account, you agree to our',
            list: ['En vous connectant/vous inscrivant à votre compte, vous acceptez notre Politique', 'Indem Sie sich bei Ihrem Konto anmelden/registrieren, stimmen Sie unseren', 'Por registrar/ingresar su cuenta, estás acuerdo con nuestra Política de',
                'Войдя/зарегистрировавшись в своей учетной записи, вы соглашаетесь с нашими', 'Ao entrar/registrar-se em sua conta, você concorda com nossos', 'アカウントにサインイン/登録することにより、あなたは私たちの',
                `من خلال تسجيل الدخول / التسجيل في حسابك ، فإنك توافق على`]
        }, {
            text: `Privacy & Cookie Policy`,
            list: ['confidentialité et de cookies', 'Datenschutz- &Cookie-Richtlinien', 'Privacidad & Cookies y',
                'Политика конфиденциальности и файлов cookie', 'Política de Privacidade e Cookies', 'プライバシーとクッキーに関するポリシー',
                `سياسة الخصوصية وملفات تعريف الارتباط`]
        }, {
            text: 'Forgot your password?',
            list: ['Vous avez oublié votre mot de passe?', 'Vergessen Sie Ihr Passwort?', '¿Olvidaste tu contraseña?',
                'Забыли пароль?', 'Esqueceu sua senha?', 'パスワードをお忘れですか？',
                `نسيت كلمة السر؟`]
        }, {
            text: 'Please enter your email',
            list: ['Veuillez saisir votre e-mail', 'Bitte geben Sie Ihre E-Mail ein', ' Introduzca su correo electrónico, por favor',
                'Пожалуйста, введите адрес электронной почты', 'Por favor introduza o seu e-mail', 'あなたのメールアドレスを入力してください',
                `رجاءا أدخل بريدك الإلكتروني`]
        }, {
            text: 'Invalid email address',
            list: ['Addres de messagerie non valides', 'E-Mail-Adresse eingeben', 'Dirección de correo está no válida',
                'Неверный адрес электронной почты', 'Endereço de email invalido', '無効なメールアドレス',
                `عنوان البريد الإلكتروني غير صالح`]
        }, {
            text: 'new password',
            list: ['Nouveau mot de passe', 'Neues Passwort', 'Contraseña nueva ',
                'Новый пароль', 'Nova Senha', '新しいパスワード',
                `كلمة المرور الجديدة`]
        }, {
            text: 'CONFIRM password',
            list: ['Confirmez le mot de passe', 'Passwort bestätigen', 'Confirmar su contraseña ',
                'Подтвердите пароль', 'Confirme sua senha', 'パスワードを認証する',
                `CONFIRM password`]
        }, {
            text: 'confirm',
            list: ['Confirmez', 'bestätigen', 'Confirmar',
                'подтверждать', 'confirme', '確認',
                `يتأكد`]
        }, {
            text: 'Change Password',
            list: ['Changer le mot de passe', 'Passwort ändern', 'Cambiar contraseña',
                'Изменить пароль', 'Alterar a senha', 'パスワードを変更する',
                `تغيير كلمة المرور`]
        }, {
            text: 'Country/Region',
            list: ['Pays/Région', 'Land/Region', 'País/Región',
                'Country/Region', 'País/Região', '国/地域',
                `البلد / المنطقة`]
        }, {
            text: 'Language',
            list: ['Langue', 'Sprechen', 'Lengua',
                'Язык', 'Linguagem', '言語',
                `لغة`]
        }, {
            text: 'Shipping Policy',
            list: ['Politique d’expédition', 'Versandbedingungen', 'Politica de envios',
                'Политика доставки', 'Política de envio', '配送ポリシー',
                `سياسة الشحن`]
        }, {
            text: 'Currency',
            list: ['Monnaie', 'Währung', 'Divisa',
                'Валюта', 'Moeda', '通貨',
                `عملة`]
        }, {
            text: 'Payment Method',
            list: ['Mode de paiement', 'Zahlungsmethode', 'Método de pago',
                'Способ оплаты', 'Forma de pagamento', '支払方法',
                `طريقة الدفع او السداد`]
        }, {
            text: 'Terms & Conditions',
            list: ['Termes & Conditions', 'Allgemeine Geschäftsbedingungen', 'Términos y Condiciones',
                'Условия', 'termos e Condições', '利用規約',
                `البنود و الظروف`]
        }, {
            text: 'Privacy & Cookie Policy',
            list: ['Politique de confidentialité et de cookies', 'Datenschutz- &Cookie-Richtlinien', 'Política de Privacidad & Cookies',
                'Политика конфиденциальности и файлов cookie', 'Política de Privacidade e Cookies', 'プライバシーとクッキーに関するポリシー',
                `سياسة الخصوصية وملفات تعريف الارتباط`]
        }, {
            text: 'Sign Out',
            list: ['Se déconnecter', 'Abmelden', 'Cerrar Sesión',
                'Выход', 'Sair', 'サインアウト',
                `خروج`]
        }, {
            text: 'Are you sure to sign out?',
            list: ['Êtes-vous sûr de vous déconnecter ?', 'Sind Sie sicher, dass Sie sich abmelden', '¿Estás seguro de cerrar sesión?',
                'Вы уверены, что хотите выйти?', 'Tem certeza que deseja sair?', 'ログアウトしてもよろしいですか？',
                `هل أنت متأكد من تسجيل الخروج؟`]
        }, {
            text: 'Deactivate Account',
            list: ['Désactiver le compte', 'Konto deaktivieren', 'Desactivar cuenta',
                'Деактивировать аккаунт', 'Desativar conta', 'アカウントを無効化し',
                `تعطيل الحساب`]
        }, {
            text: 'Settings',
            list: ['Réglages', 'Einstellung', 'Ajustes',
                'Настройки', 'Configurações', '設定',
                `إعدادات`]
        }, {
            text: 'New password and confirm password do not match.',
            list: ['Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas', 'новый пароль и Подтверждение пароля не совпадают', 'Nueva contraseña y contraseña de confirmación no coinciden',
                'Новый пароль и пароль подтверждения не совпадают.', 'A nova senha e a confirmação da senha não correspondem.', '新しいパスワードと確認パスワードが一致しません。',
                `كلمة المرور الجديدة وتأكيد كلمة المرور غير متطابقين`]
        }, {
            text: 'Current Country/Region',
            list: ['Pays/Région actuels', 'Aktuelles Land/Region', 'País/región actual',
                'Текущая страна/регион', 'País/região atual', '現在の国/地域',
                `الدولة / المنطقة الحالية`]
        }, {
            text: 'Shopping',
            list: ['Achats', 'Einkaufen', 'Compras', 'Покупка', 'Compras', '買い物',
                `التسوق`]
        }, {
            text: 'Shopping Bag',
            list: ['Sac à provisions', 'Einkaufstasche', 'Bolsa de la compra',
                'Сумка для покупок', 'Bolsa de compras', 'ショッピングバッグ',
                `كيس التسوق`]
        }, {
            text: 'Edit',
            list: ['Éditer', 'Bearbeiten', 'Editar',
                'Редактировать', 'Editar', '編集',
                `يحرر`]
        }, {
            text: 'Done',
            list: ['Fait', 'Fertig', 'Hecho',
                'Сделанный', 'Feito', '終わり',
                `منتهي`]
        }, {
            text: 'Eligible for free shipping',
            list: ['Éligible à la livraison gratuite', 'Kostenloser Versand möglich', '¡Elegible para envío gratis',
                'Доступна бесплатная доставка', 'Elegível para frete grátis', '無料配送可能',
                `مؤهل للشحن المجاني`]
        }, {
            text: 'Checkout',
            list: ['vérifier', 'Einkaufen', 'Verificar',
                'Проверить', 'Confira', 'チェックアウト',
                `الدفع`]
        }, {
            text: 'Delete',
            list: ['Supprimer', 'Löschen', 'Borrar',
                'Удалить', 'Excluir', '消去',
                `يمسح`]
        }, {
            text: 'Customers Also Viewed',
            list: ['Clients également consultés', 'Kunden haben sich auch angesehen', 'Los clientes también  los vieron',
                'Клиенты также просмотрели', 'Clientes também vistos', 'お客様も閲覧',
                `العملاء شاهدوا أيضا`]
        }, {
            text: 'You May Also Like',
            list: ['Tu pourrais aussi aimer', 'Sie können auch mögen', 'También te puede interesar',
                'Вам также может понравиться', 'Você pode gostar', 'あなたはおそらくそれも好きでしょう',
                `قد يعجبك ايضا`]
        }, {
            text: 'Select all',
            list: ['Tout sélectionner', 'Wählen Sie Alle', 'Seleccionar todo',
                'Выбрать все', 'Selecionar tudo', 'すべて選択',
                `اختر الكل`]
        }, {
            text: 'Wishlist',
            list: ['Liste de souhaits', 'Wunschliste', 'Lista de deseos',
                'Список желаний', 'Lista de Desejos', 'ウィッシュリスト',
                `قائمة الرغبات`]
        }, {
            text: 'Move to wishlist',
            list: ['Passer à la liste de souhaits', 'Zur Wunschliste verschieben', 'Mover a la lista de deseos',
                'Добавить в список желаемого', 'Mover para Lista de desejos', 'ウィッシュリストに入れる',
                `الانتقال إلى قائمة الرغبات`]
        }, {
            text: 'free gift',
            list: ['cadeau gratuit', 'kostenloses geschenk', 'regalito gratis',
                'бесплатный подарок', 'presente gratuito', '無料ギフト',
                `هدية مجانية`]
        }, {
            text: 'Change Gifts',
            list: ['Changer de cadeau', 'Geschenke ändern', 'Cambiar regalos',
                'Изменить подарки', 'Troque presentes', 'ギフトを変更する',
                `تغيير الهدايا`]
        }, {
            text: 'Flash Sale',
            list: ['Vente flash', 'Blitzangebote', 'Venta express',
                'Флэш-распродажа', 'Venda rápida', 'フラッシュセール',
                `بيع مفاجئ`]
        }, {
            text: 'Ends in',
            list: ['Se termine en', 'Endet in', 'Termina en',
                'Заканчивается в', 'Termina em', 'で終わる',
                `ينتهي بـ`]
        }, {
            text: 'Invalid Product',
            list: ['Produit invalide', 'Ungültiges Produkt', 'Producto no válido',
                'Недействительный продукт', 'Produto inválido', '無効な製品',
                `منتج غير صالح`]
        }, {
            text: 'Buy',
            list: ['Acheter', 'Kaufen', 'Compre',
                'Купить', 'Comprar', '買う',
                `يشتري`]
        }, {
            text: 'Get',
            list: ['Obtenir', 'Erhalten Sie', 'Obtenga ',
                'Получать', 'Pegar', '得る',
                `يحصل`]
        }, {
            text: 'Off',
            list: ['de réduction', 'RABATT', 'de descuento',
                'Выключенный', 'Desligado', 'オフ',
                `عن`]
        }, {
            text: 'Free Shipping',
            list: ['Livraison gratuite', 'Kostenlose Lieferung', 'Envío gratis',
                'Бесплатная доставка', 'Frete grátis', '送料無料',
                `ًالشحن مجانا`]
        }, {
            text: 'CHECKOUT',
            list: ['', 'Einkaufen', 'Verificar',
                'ПРОВЕРИТЬ', 'CONFIRA', 'チェックアウト',
                `الدفع`]
        }, {
            text: 'Address Management',
            list: ['Gestion des adresses', 'Addresse Management', 'Gestión de direcciones',
                'Управление адресами', 'Gerenciamento de endereço', 'アドレス管理',
                `إدارة العناوين`]
        }, {
            text: 'Address is empty',
            list: ['L’adresse est vide', 'Adresse ist leer', 'La dirección está invalida',
                'Адрес пуст', 'O endereço está vazio', '住所が空です',
                `العنوان فارغ`]
        }, {
            text: 'Shipping Address',
            list: ['Adresse de Livraison', 'Lieferadresse', 'Dirección de Envío',
                'Адрес доставки', 'Endereço para envio', 'お届け先の住所',
                `عنوان الشحن`]
        }, , {
            text: 'Add a Shipping Address',
            list: ['Ajouter une adresse de livraison', 'Lieferadresse hinzufügen', 'Agregar una dirección de envío',
                'Добавить адрес доставки', 'Adicionar um endereço de entrega', '配送先住所を追加する',
                `أضف عنوان الشحن`]
        }, {
            text: 'Default',
            list: ['Faire défaut', 'Default', 'Defecto',
                'По умолчанию', 'Padrão', 'デフォルト',
                `تقصير`]
        }, {
            text: 'Set As Default Address',
            list: ['Définir comme adresse par défaut', 'Als Defaultadresse festlegen', 'Establecer como dirección predeterminada',
                'Установить как адрес по умолчанию', 'Definir como endereço padrão', 'デフォルトのアドレスとして設定',
                `تعيين كعنوان افتراضي`]
        }, {
            text: 'Are you sure you to delete this address?',
            list: ['Êtes-vous sûr de supprimer cette adresse ?', 'Sind Sie sicher, löschen Sie diese Adresse?', '¿Estás seguro de eliminar esta dirección?',
                'Вы уверены, что хотите удалить этот адрес?', 'Tem certeza de que deseja excluir este endereço?', 'このアドレスを削除してもよろしいですか?',
                `هل أنت متأكد من حذف هذا العنوان`]
        }, {
            text: 'First Name',
            list: ['Prénom', 'Vorname', 'Nombre',
                'Имя', 'Primeiro nome', 'ファーストネーム',
                `الاسم الأول`]
        }, {
            text: 'Last Name',
            list: ['Nom', 'Nachname', 'Apellido',
                'Фамилия', 'Sobrenome', '苗字',
                `اسم العائلة`]
        }, {
            text: 'Phone Number',
            list: ['Numéro de téléphone', 'Telenfonnummer', 'Número de teléfono',
                'Номер телефона', 'Número de telefone', '電話番号',
                `رقم التليفون`]
        }, {
            text: 'Please enter a mailbox',
            list: ['Veuillez saisir une boîte aux lettres', 'Bitte geben Sie ein Postfach ein', 'Por favor, introduzca el buzón de correo',
                'Пожалуйста, введите почтовый ящик', 'Insira uma caixa de correio', 'メールボックスを入力してください',
                `الرجاء إدخال صندوق بريد`]
        }, {
            text: 'Street,Address,Company Name,C/O',
            list: ['Streed,Adresse,Nom de l’entreprise,C/O', 'Straße, Adresse, Firmenname, C/O', 'Calle, Dirección, Nombre de la empresa, C/O',
                'Улица, адрес, название компании, C/O', 'Rua, Endereço, Nome da Empresa, C/O', '番地、住所、会社名、C/O',
                `الشارع ، العنوان ، اسم الشركة ، C / O`]
        }, {
            text: `Apt.Suite.Building(Optional)`,
            list: [`Immeuble d'appartements (facultatif)`, 'Apt.Suite.Building(optional)', 'Edificio de apartamentos (opcional)',
                'Apt.Suite.Building (необязательно)', 'Apt.Suite.Building(Opcional)', 'Apt.Suite.Building(オプション)',
                `Apt.Suite.Building (اختياري)`]
        }, {
            text: 'Create A New Password',
            list: ['Définir un nouveau mot de passe', 'ein neues Passwort festlegen', 'Establecer una nueva contraseña',
                'Создать новый пароль', 'Criar uma nova senha', '新しいパスワードを作成する',
                `أنشئ كلمة مرور جديدة`]
        }, {
            text: 'Address Line1',
            list: ['Adresse Line 1', 'Adressezeile 1', 'Dirección Línea 1',
                'Адресная строка 1', 'Endereço Linha 1', '住所1',
                `العنوان سطر 1`]
        }, {
            text: 'Address Line2',
            list: ['Adresse Line  2', 'Adressezeile 2', 'Dirección Línea 2',
                'Адресная строка 2', 'Endereço linha 2', '住所2',
                `سطر العنوان 2`]
        }, {
            text: 'Address Line',
            list: ['Adresse Line', 'Adressezeile', 'Dirección Línea',
                'Адресная строка', 'Linha de endereço', '住所欄',
                `خط عنوان`]
        }, {
            text: 'Streed,Address,Company Name,C/O',
            list: ['Streed,Adresse,Nom de l’entreprise,C/O', 'Straße, Adresse, Firmenname, C/O ', 'Calle, Dirección, Nombre de la empresa, C/O',
                'Стрид, адрес, название компании, C/O', 'Rua, Endereço, Nome da Empresa, C/O', '通り、住所、会社名、C/O',
                `Streed ، العنوان ، اسم الشركة ، C / O`]
        }, {
            text: 'Apartment,Suite ,Unit,Building,Floor,etc(Optional)',
            list: ['Appartement, Suite, Unité, Immeuble, Étage, etc. (Facultatif)', 'Hausnummer, Wohngebäude, Etage usw. (optional)', 'Apartamento, Suite, Unidad, Edificio, Piso, etc. (Opcional)',
                'Квартира, люкс, блок, здание, этаж и т. д. (опционально)', 'Apartamento, Suíte, Unidade, Prédio, Andar, etc (Opcional)', 'アパート、スイート、ユニット、建物、フロアなど（オプション）',
                `شقة ، جناح ، وحدة ، مبنى ، طابق ، إلخ (اختياري)`]
        }, {
            text: 'City/State(Province)',
            list: ['Ville/État (Province)', 'Stadt/Bundesland(Provinz)', 'Ciudad  / Provincia',
                'Город/штат (провинция)', 'Cidade Estado / província)', '市/州(州)',
                `مدينة ولاية / محافظة)`]
        }, {
            text: 'Post/Zip Code',
            list: ['Code postal', 'Postleitzahl', 'Código postal',
                'Почтовый индекс', 'Código Postal/CEP', '郵便番号',
                `الرمز البريدي`]
        }, {
            text: 'Such as 20001 or 20001-0000',
            list: ['Comme 20001 ou 20001-0000', 'z.B. 20001 oder 20001-0000', 'Como 20001 o 20001-0000',
                'Например, 20001 или 20001-0000.', 'Como 20001 ou 20001-0000', '20001 または 20001-0000 など',
                `مثل 20001 أو 20001-0000`]
        }, {
            text: 'Vat Number',
            list: ['Numéro de TVA', 'Umsatzsteuer-Identifikationsnummer', 'Número de VAT',
                'Номер НДС', 'Número de IVA', 'VAT番号',
                `ظريبه الشراء`]
        }, {
            text: 'Make Default',
            list: ['Définir par défaut', 'Defaulteinstellung', 'Hacer por defecto',
                'Использовать по умолчанию', 'Tornar padrão', 'デフォルトにする',
                `جعل الافتراضي`]
        }, {
            text: 'Save',
            list: ['Enregistrer', 'Bestätigen', 'Guardar',
                'Сохранять', 'Salvar', '保存',
                `يحفظ`]
        }, {
            text: 'First name should be 2-34 letters with no space',
            list: ['Le prénom doit être composé de 2 à 34 lettres sans espace', 'Der Vorname sollte aus 2-34 Buchstaben ohne Leerzeichen bestehen', 'El nombre debe tener de 2 a 34 letras sin espacio',
                'Имя должно состоять из 2-34 букв без пробела.', 'O primeiro nome deve ter de 2 a 34 letras sem espaço', '名はスペースを入れずに 2 ～ 34 文字にする必要があります',
                `يجب أن يتكون الاسم الأول من 2-34 حرف بدون مسافات`]
        }, {
            text: '2-30 letters or spaces',
            list: ['2-30 lettres ou espaces', '2-30 Buchstaben oder Leerzeichen', '2-30 letras o espacios',
                '2-30 букв или пробелов', '2-30 letras ou espaços', '2 ～ 30 文字またはスペース',
                `2-30 حرفًا أو مسافات`]
        }, {
            text: 'Please enter first name.',
            list: ['Veuillez saisir un nom', 'Bitte geben Sie Ihren Vornamen ein', 'Introduzca un nombre',
                'Пожалуйста, введите имя.', 'Insira o primeiro nome.', '名を入力してください。',
                `الرجاء إدخال الاسم الأول.`]
        }, {
            text: 'Please enter last name.',
            list: ['Veuillez saisir votre nom de famille', 'Bitte Nachname eingeben', 'Por favor, introduzca el apellido',
                'Пожалуйста, введите фамилию.', 'Insira o sobrenome.', '姓を入力してください。',
                `الرجاء إدخال الاسم الأخير.`]
        }, {
            text: `State/Provin...`,
            list: ['État /Province...', 'Staat/Provinz...', 'Provincia / Estado...',
                'Штат/провинция...', 'Estado/província...', '都道府県...',
                `الولاية / المقاطعة ...`]
        }, {
            text: `City/Towm/Ca...`,
            list: ['Ville / ville / Californie...', 'Stadt/Ort/Ca...', 'Ciudad / ciudad / California...',
                'Город/Город/Канада...', 'Cidade/Vila/Ca...', 'シティ/タウン/カ...',
                `المدينة / التووم / كاليفورنيا ...`]
        }, {
            text: 'City/Towm/Ca',
            list: ['Ville / ville / Californie', 'Stadt/Ort/Ca', 'Ciudad / ciudad / California',
                'Город/Город/Канада', 'Cidade/Vila/Ca', 'シティ/タウン/カ',
                `المدينة / توم / كاليفورنيا`]
        }, {
            text: 'Phone number should be a 10-digital number',
            list: ['Le numéro de téléphone doit être un numéro numérique 10', 'Die Telefonnummer sollte 10-stellig sein', 'El número de teléfono debe ser un número de 10 dígitos',
                'Номер телефона должен состоять из 10 цифр.', 'O número de telefone deve ser um número de 10 dígitos', '電話番号は 10 桁の数字である必要があります',
                `يجب أن يكون رقم الهاتف 10-رقم رقمي`]
        }, {
            text: 'Invalid email address',
            list: ['Adresse e-mail non valide', 'Ungültige E-Mail-Adresse', 'Dirección de correo electrónico no válida',
                'Неверный адрес электронной почты', 'Endereço de email invalido', '無効なメールアドレス',
                `عنوان البريد الإلكتروني غير صالح`]
        }, {
            text: 'Address line 1 should contain 5-30 letters, digits or spaces',
            list: ['La ligne d’adresse 1 doit contenir de 5 à 30 lettres, chiffres ou espaces', 'Adresszeile 1 sollte 5-30 Buchstaben, Zahl oder Leerzeichen enthalten', 'La línea de dirección 1 debe contener de 5 a 30 letras, dígitos o espacios',
                'Адресная строка 1 должна содержать от 5 до 30 букв, цифр или пробелов.', 'A linha de endereço 1 deve conter de 5 a 30 letras, dígitos ou espaços', '住所行 1 には、5 ～ 30 文字の文字、数字、またはスペースを含める必要があります',
                `يجب أن يحتوي سطر العنوان 1 على 5-30 حرفًا أو رقمًا أو مسافات`]
        }, {
            text: 'Zip/Postal Code should be 5 digits or 5 digits with a  4-digit number,e.g. 20001or 20001-0000',
            list: ['Le code postal doit comporter 5 chiffres ou 5 chiffres avec un numéro à 4 chiffres, par ex. 20001ou 20001-0000', 'Postleitzahl sollte 5-stellig oder 5-stellig plus 4-stellig sein, z.B. 20001 oder 20001-0000', 'El código postal debe tener 5 dígitos o 5 dígitos con un número de 4 dígitos, p. 20001 o 20001-0000',
                'Почтовый индекс должен состоять из 5 цифр или 5 цифр с 4-значным номером, например. 20001 или 20001-0000', 'CEP/Código Postal deve ter 5 dígitos ou 5 dígitos com um número de 4 dígitos, por exemplo 20001 ou 20001-0000', '郵便番号は 5 桁または 5 桁と 4 桁の数字である必要があります。 20001 または 20001-0000',
                `يجب أن يتكون الرمز البريدي من 5 أرقام أو 5 أرقام مع 4 أرقام ، على سبيل المثال. 20001 أو 20001-0000`]
        }, {
            text: 'in order to deliver your package more smoothly, please provide your VAT number',
            list: ['afin de livrer votre colis plus facilement, veuillez fournir votre numéro de TVA', 'Für eine reibungslose Zustellung Ihres Pakets geben Sie bitte Ihre Umsatzsteuer-Identifikationsnummer an', 'para entregar su paquete sin problemas, proporcione su número de VAT',
                'для того, чтобы доставить вашу посылку более гладко, пожалуйста, укажите свой номер плательщика НДС', 'para entregar seu pacote com mais facilidade, forneça seu número de IVA', '荷物をよりスムーズに配達するために、VAT番号を入力してください',
                `من أجل توصيل الحزمة الخاصة بك بشكل أكثر سلاسة ، يرجى تقديم رقم ضريبة القيمة المضافة الخاص بك`]
        }, {
            text: 'all',
            list: ['tout', 'Alle', 'Todos',
                'все', 'todos', '全て',
                `الجميع`]
        }, {
            text: 'Search',
            list: ['Rechercher', 'Suchen', 'Buscar',
                'Поиск', 'Procurar', '検索',
                `يبحث`]
        }, {
            text: 'Recently Searched',
            list: ['Recherche récente', 'Letzten Suchen', 'Buscado recientemente',
                'Недавно искали', 'pesquisado recentemente', '最近検索したもの',
                `بحثت مؤخرا`]
        }, {
            text: 'Hot Search',
            list: ['Recherche à chaud', 'Hot Suchen', 'Más buscado',
                'Горячий поиск', 'Hot Search', 'ホットサーチ',
                `البحث الساخن`]
        }, {
            text: 'Recommend',
            list: ['Recommander', 'Empfehlen', 'Recomendar',
                'Рекомендовать', 'recomendar', 'お勧め',
                `يوصي`]
        }, {
            text: 'New Arrivals',
            list: ['Nouveaux arrivants', 'Neu eingetroffen', 'Los recién llegados',
                'Новые поступления', 'Novas chegadas', '新着',
                `القادمون الجدد`]
        }, {
            text: 'Price Low to High',
            list: ['Prix bas à élevé', 'Preis: aufsteigend', 'Precios de  bajo a alto ',
                'Цена от низкой до высокой', 'Preço Baixo a Alto', '価格の低い順',
                `السعر من الارخص للاعلى`]
        }, {
            text: 'Price High to Low',
            list: ['Prix élevé à bas', 'Preis: absteigend', 'Precio alto a bajo',
                'Цена от высокой к низкой', 'Preço alto a baixo', '価格の高い順',
                `السعر الاعلى الى الادنى`]
        }, {
            text: 'Customer Reviews',
            list: ['Avis des clients', 'Kundenrezensionen', 'Opiniones de clientes',
                'Отзывы клиентов', 'Avaliações de Clientes', 'カスタマーレビュー',
                `آراء العملاء`]
        }, {
            text: 'Sales',
            list: ['Ventes', 'Angeboten', 'Ventas',
                'Продажи', 'Vendas', '販売',
                `مبيعات`]
        }, {
            text: 'Filter',
            list: ['Filtre', 'Filtern', 'Filtrar',
                'Фильтр', 'Filtro', 'フィルター',
                `منقي`]
        }, {
            text: 'Products',
            list: ['Produits', 'Produckte', 'Productos',
                'Продукты', 'Produtos', '製品',
                `منتجات`]
        }, {
            text: 'sold',
            list: ['Vendu', 'Verkaufen', 'vendido',
                'продал', 'vendido', '売った',
                `مُباع`]
        }, {
            text: 'Following',
            list: ['Suivant', 'Folgende', 'Siguiendo',
                'Следующий', 'Seguindo', '続く',
                `التالي`]
        }, {
            text: 'Fans',
            list: ['Fans', 'Fans', 'Aficionados',
                'Поклонники', 'fãs', 'ファン',
                `المشجعين`]
        }, {
            text: 'Likes',
            list: ['Aime', 'Mag', 'Gustos',
                'Нравится', 'Curtidos', 'いいね',
                `الإعجابات`]
        }, {
            text: 'More',
            list: ['Plus', 'Mehr', 'Más',
                'Более', 'Mais', 'もっと',
                `أكثر`]
        }, {
            text: 'My Orders',
            list: ['Mes commandes', 'Meine Bestellungen', 'Mis ordenes',
                'мои заказы', 'minhas ordens', '私の注文',
                `طلباتي`]
        }, {
            text: 'Processing',
            list: ['En cours', 'Verarbeiten', 'Procesando',
                'Обработка', 'Em processamento', '処理',
                `يعالج`]
        }, {
            text: 'Shipped',
            list: ['Expédiés', 'Verschiffte', 'Enviado',
                'Отправленный', 'Enviado', '発送済み',
                `شحنها`]
        }, {
            text: 'Review',
            list: ['Commentaires', 'Rezensionen', 'Opinión',
                'Обзор', 'Análise', 'レビュー',
                `مراجعة`]
        }, {
            text: 'Returns',
            list: ['Retourne', 'Zurücksenden', 'Devoluciones',
                'Возвращает', 'devoluções', '戻り値',
                `عائدات`]
        }, {
            text: 'My Credit Card',
            list: ['Ma carte de crédit', 'Meine Kreditkarte', 'Mi tarjeta de crédito',
                'Моя кредитная карта', 'Meu cartão de crédito', '私のクレジットカード',
                `بطاقة الائتمان الخاصة بي`]
        }, {
            text: 'Feedback',
            list: ['Rétroaction', 'Feedback', 'Comentarios',
                'Обратная связь', 'Opinião', 'フィードバック',
                `تعليق`]
        }, {
            text: 'Contact Us',
            list: ['Contactez Nous', 'Kontaktieren Sie uns', 'Contacta con nosotros',
                'Связаться с нами', 'Contate-nos', 'お問い合わせ',
                `اتصل بنا`]
        }, {
            text: 'About',
            list: ['À propos', 'Über', 'Sobre',
                'О', 'Sobre', 'だいたい',
                `عن`]
        }, {
            text: 'About YFN',
            list: ['À propos de YFN', 'Über YFN', 'Sobre YFN',
                'О YFN', 'Sobre YFN', 'YFNについて',
                `حول YFN`]
        }, {
            text: 'Recently Viewed',
            list: ['Récemment consulté', 'Zulezten Angesehen', 'Visto recientemente',
                'Recently Viewed', 'Visualizado recentemente', '最近見たもの',
                `شوهدت مؤخرا`]
        }, {
            text: 'Recommend For You',
            list: ['Recommander pour vous', 'Für Sie empfehlen', 'Recomendar para usted',
                'Рекомендую для вас', 'Recomendo para você', 'あなたにおすすめ',
                `يوصي لك`]
        }, {
            text: 'Feedback Type',
            list: ['Type de rétroaction', 'Feedback Art', 'Tipo de Comentarios',
                'Тип обратной связи', 'Tipo de feedback', 'フィードバックの種類',
                `نوع الأنطباع`]
        }, {
            text: 'App issues',
            list: ['Problèmes d’application', 'App Ausgaben', 'Problemas de la aplicación',
                'Проблемы с приложением', 'Problemas de aplicativos', 'アプリの問題',
                `مشاكل التطبيق`]
        }, {
            text: 'Suggestion',
            list: ['Suggestion', 'Vorschlag', 'Sugerencia',
                'Предположение', 'Sugestão', '提案',
                `اقتراح`]
        }, {
            text: 'Others',
            list: ['Les autres', 'Andere', 'Otros',
                'Другие', 'Outros', 'その他',
                `آحرون`]
        }, {
            text: 'Please Input The Feedback',
            list: ['S’il vous plaît entrer les commentaires', 'Bitte geben Sie Feedback ein', 'Ingrese los comentarios, por favor ',
                'Пожалуйста, введите отзыв', 'Insira o feedback', 'フィードバックを入力してください',
                `الرجاء إدخال الملاحظات`]
        }, {
            text: 'Please describe your problems or opinions in detail.',
            list: ['Veuillez ajouter une photo', 'Bitte beschreiben Sie Ihre Probleme oder Meinungen im Detail.', 'describa sus problemas u opiniones en detalle, por favor ',
                'Подробно опишите свои проблемы или мнения.', 'Por favor, descreva seus problemas ou opiniões em detalhes.', 'お悩みやご意見を具体的にご記入ください',
                `يرجى وصف مشاكلك أو آرائك بالتفصيل.`]
        }, {
            text: 'Please Add A Picture',
            list: ['Veuillez ajouter une image', 'Bitte fügen Sie ein Bild hinzu', 'Agregue una imagen, por favor ',
                'Пожалуйста, добавьте изображение', 'Adicione uma imagem', '写真を追加してください',
                `الرجاء إضافة صورة`]
        }, {
            text: 'Submit',
            list: ['Soumettre', 'Bestätigen', 'Enviar',
                'Представлять на рассмотрение', 'Enviar', '送信',
                `يُقدِّم`]
        }, {
            text: 'Customer Service',
            list: ['Service Clients', 'Kundendienst', 'Servicio al Cliente',
                'Обслуживание клиентов', 'Atendimento ao Cliente', '顧客サービス',
                `خدمة الزبائن`]
        }, {
            text: 'Other Question? Customer Service',
            list: ['Autre question? Service Clients', 'Andere Frage? Kundendienst', '¿Otra pregunta? Servicio al Cliente',
                'Другой вопрос? Обслуживание клиентов', 'Outra questão? Atendimento ao Cliente', 'その他の質問？顧客サービス',
                `سؤال آخر؟ خدمة الزبائن`]
        }, {
            text: 'Submitted Successfully',
            list: ['Soumis avec succès', 'Erfolgreich vorlegen', 'Enviado exitosamente',
                'Отправлено успешно', 'Submetido com sucesso', '正常に送信されました',
                `تم الإرسال بنجاح`]
        }, {
            text: 'Thanks for your feedback . We will try our best to create abetter shopping experience',
            list: ['Thanks for your feedback . We will try our best to create abetter shopping experience', 'Vielen Dank für Ihre Feedback. Wir werden unser Bestes geben, um Ihnen das beste Einkaufserlebnis zu bieten', 'Gracias por tus comentarios. Haremos todo lo posible para crear una mejor experiencia de compra.',
                'Спасибо за ваш отзыв . Мы постараемся сделать все возможное, чтобы создать лучший опыт покупок',
                'Obrigado pelo seu feedback . Faremos o nosso melhor para criar uma melhor experiência de compra',
                'ご意見ありがとうございます 。私たちはより良いショッピング体験を作成するために最善を尽くします',
                `شكرا لملاحظاتك . سنبذل قصارى جهدنا لخلق تجربة تسوق أفضل`]
        }, {
            text: 'Close',
            list: ['Fermer', 'Schließen', '',
                'Закрывать', 'Fechar', '近い',
                `يغلق`]
        }, {
            text: 'Coupons',
            list: ['Coupons', 'Gutscheine', 'Cerrar',
                'купоны', 'cupons', 'クーポン',
                `كوبونات`]
        }, {
            text: 'Available',
            list: ['Disponible', 'Verfügbar', 'Cupones',
                'Доступный', 'Disponível', '利用可能',
                `متاح`]
        }, {
            text: 'Expired',
            list: ['Périmé', 'Verfallen', 'Disponible',
                'Истекший', 'Expirado', '期限切れ',
                `منتهي الصلاحية`]
        }, {
            text: 'Coupon is empty',
            list: ['Le coupon est vide', 'Coupon ist leer', 'Caducado',
                'Купон пуст', 'Use-o', 'クーポンが空です',
                `القسيمة فارغة`]
        }, {
            text: 'Use It',
            list: ['Utilisez-le', 'Verwenden Sie es', 'El cupón está vacío',
                'Используй это', 'Use-o', 'これを使って',
                `استخدمه`]
        }, {
            text: 'Code',
            list: ['Code', 'Code', 'Código',
                'Код', 'Código', 'コード',
                `شفرة`]
        }, {
            text: 'new',
            list: ['Nouveau', 'Neu', 'Nuevo',
                'новый', 'novo', '新しい',
                `جديد`]
        }, {
            text: 'expires',
            list: ['Périmer', 'Verfällt', 'Caduca',
                'истекает', 'expira', '期限切れ',
                `تنتهي`]
        }, {
            text: 'For orders over',
            list: ['Pour les commandes supérieures à', 'Für Bestellungen über', 'Para pedidos a',
                'Для заказов свыше', 'Para pedidos acima', '以上のご注文について',
                `لأوامر أكثر`]
        }, {
            text: 'For all products',
            list: ['Pour tous les produits', 'Für alle Produkte -', 'Para todos productos',
                'На все товары -', 'Para todos os produtos -', 'すべての製品について',
                `لجميع المنتجات`]
        }, {
            text: 'For all products -',
            list: ['Pour tous les produits -', 'Für alle Produkte -', 'Para todos productos - ',
                'На все товары -', 'Para todos os produtos -', 'すべての製品について -',
                `لجميع المنتجات -`]
        }, {
            text: 'Do not meet the minimum amount limit',
            list: ['Ne pas respecter la limite de montant minimum', 'Mindestbetragsgrenze nicht erreicht', 'No cumple con el límite de monto mínimo',
                'Не уложиться в лимит минимальной суммы', 'Não cumpra o limite de valor mínimo', '最低金額を満たしていない',
                `لا تستوفي الحد الأدنى للمبلغ`]
        }, {
            text: 'My Order',
            list: ['Ma commande', 'Meine Bestellung', 'Mi pedido',
                'Мой заказ', 'Meu pedido', '私の注文',
                `طلبي`]
        }, {
            text: 'All orders',
            list: ['Toutes les commandes', 'Alle Bestellungen', 'Todos pedidos',
                'Все заказы', 'Todos os pedidos', 'すべての注文',
                `جميع الطلبات`]
        }, {
            text: 'Reviews',
            list: ['Avis', 'Rezensionen', 'Comentarios',
                'Отзывы', 'Avaliações', 'レビュー',
                `المراجعات`]
        }, {
            text: 'Order Number',
            list: ['Numéro de commande', 'Bestellnummer', 'Número de pedidos',
                'Номер заказа', 'Número do pedido', '注文番号',
                `رقم الأمر`]
        }, {
            text: 'Refund',
            list: ['Remboursement', 'Erstattung', 'Reembolso',
                'Возвращать деньги', 'Reembolso', '返金',
                `استرداد`]
        }, {
            text: 'Edit Address',
            list: ['Modifier l’adresse	Adresse bearbeiten', 'Adresse bearbeiten', 'Editar dirección',
                'Изменить адрес', 'Editar Endereço', '住所を編集',
                `تعديل العنوان`]
        }, {
            text: 'Track',
            list: ['Piste', 'Logistik ', 'Pista',
                'Отслеживать', 'Acompanhar', '追跡',
                `مسار`]
        }, {
            text: 'Received',
            list: ['Reçu', 'Erhalten', 'Recibió',
                'Полученный', 'Recebido', '受け取った',
                `تلقى`]
        }, {
            text: 'Repurchase',
            list: ['Rachat', 'Wiederkaufen', 'Recompra',
                'Выкуп', 'recompra', '買い戻し',
                `إعادة الشراء`]
        }, {
            text: 'Detail',
            list: ['Détail', 'Detail', 'Detalle',
                'Деталь', 'Detalhe', '詳細',
                `التفاصيل`]
        }, {
            text: 'item',
            list: ['articles', 'Artikel', 'productos',
                'элемент', 'item', 'アイテム',
                `غرض`]
        }, {
            text: 'items total',
            list: ['total des articles', 'Artikel Total', 'Todos productos',
                'всего предметов', 'total de itens', '項目合計',
                `مجموع العناصر`]
        }, {
            text: 'Cancel',
            list: ['Annuler', 'Abbrechen', 'Cancelar',
                'Отмена', 'Cancelar', 'キャンセル',
                `يلغي`]
        }, {
            text: 'Refunded',
            list: ['Remboursé', 'Erstattet', 'Reintegrado',
                'Возврат', 'Devolveu', '返金済み',
                `معاد`]
        }, {
            text: 'Evaluated',
            list: ['Évalué', 'Ausgewertet', 'Evaluado',
                'Оценено', 'Avaliado', '評価済み',
                `مقيمة`]
        }, {
            text: 'Completed',
            list: ['Terminé', 'Fertigen', 'Completo',
                'Завершенный', 'Concluído', '完了',
                `مكتمل`]
        }, {
            text: 'No data',
            list: ['Pas de données', 'Keine Daten', 'Sin datos',
                'Нет данных', 'sem dados', 'データなし',
                `لايوجد بيانات`]
        }, {
            text: 'Order Number/Product Name',
            list: ['Numéro de commande/nom du produit', 'Bestellnummer/ Producktname', 'Número de pedido/Nombre del producto',
                'Номер заказа/название продукта', 'Número do pedido/Nome do produto', '注文番号/製品名',
                `رقم الطلب / اسم المنتج`]
        }, {
            text: 'Order Detail',
            list: ['Détail de la commande', 'Bestellung Detail', 'Detalle de los pedido',
                'Детали заказа', 'Detalhes do pedido', '注文詳細',
                `تفاصيل الطلب`]
        }, {
            text: 'Your package is being packed',
            list: ['Votre colis est en cours d’emballage', 'Ihr Paket wird gepackt', 'Su paquete está embalado',
                'Ваша посылка упаковывается', 'Seu pacote está sendo embalado', 'あなたのパッケージは梱包されています',
                `يتم حزم الحزمة الخاصة بك`]
        }, {
            text: 'Order Product',
            list: ['Commander un produit', 'Bestellung Produckt', 'Peidir producto',
                'Заказать продукт', 'Encomendar produto', '注文商品',
                `طلب المنتج`]
        }, {
            text: 'Order Information',
            list: ['Informations sur la commande', 'Bestellung Information', 'Información del pedido',
                'запросить информацию', 'Informações do pedido', '注文情報',
                `معلومات الطلب`]
        }, {
            text: 'Shipping Method',
            list: ['Méthode d’expédition', 'Liefermethode', 'Método de envío',
                'способ доставки', 'método de envio', '配送方法',
                `طريقة الشحن`]
        }, {
            text: 'Payment time',
            list: ['Délai de paiement', 'Zahlungszeit', 'Tiempo de pago',
                'Время оплаты', 'tempo de pagamento', 'お支払い時期',
                `وقت الدفع`]
        }, {
            text: 'Time of delivery',
            list: ['Délai de livraison', 'Lieferzeit', 'Tiempo de entrega',
                'Время доставки', 'Tempo de entrega', '配達の時間',
                `وقت التوصيل`]
        }, {
            text: 'Subtotal',
            list: ['Sous-total', 'Zwischensumme', 'Total parcial',
                'Промежуточный итог', 'Subtotal', '小計',
                `المجموع الفرعي`]
        }, {
            text: 'Shipping fee',
            list: ['Frais d’expédition', 'Versandgebühr', 'Gastos de envío',
                'Стоимость доставки', 'Taxa de envio', '送料',
                `رسوم الشحن`]
        }, {
            text: 'Coupon Discount',
            list: ['Coupon De réduction', 'Coupon-Rabatt', 'Cupón de descuento',
                'Скидка по купону', 'cupom de desconto', 'クーポン割引',
                `خصم القسيمة`]
        }, {
            text: 'Discount',
            list: ['Rabais', 'Rabatt', 'Descuento',
                'Скидка', 'Desconto', '割引',
                `تخفيض`]
        }, {
            text: 'Your package is waiting for collection',
            list: ['Votre colis est en attente de collecte', 'Ihr Paket wartet auf die Abholung', 'Tu paquete está esperando a recoger',
                'Ваша посылка ожидает получения', 'Seu pacote está aguardando coleta', 'あなたの荷物は集荷待ちです',
                `الحزمة الخاصة بك في انتظار التجميع`]
        }, {
            text: 'Order completion',
            list: ['Achèvement de la commande', 'Bestellung abgeschlossen', 'Completo del pedido',
                'Выполнение заказа', 'Conclusão do pedido', '注文完了',
                `إكمال الطلب`]
        }, {
            text: 'Thank you for your trust and support',
            list: ['Merci de votre confiance et de votre soutien', 'Vielen Dank für Ihr Vertrauen und Ihre Unterstützung', 'Gracias por su confianza y apoyo',
                'Спасибо за доверие и поддержку', 'Obrigado por sua confiança e apoio', 'あなたの信頼とサポートに感謝します',
                `شكرا لك على ثقتك ودعمك`]
        }, {
            text: 'Check My Review',
            list: ['Vérifier mon avis', 'Überprüfen meine Rezensionen', 'Revisar mi revisión',
                'Проверьте мой обзор', 'Confira minha avaliação', '私のレビューをチェック',
                `تحقق تقييمي`]
        }, {
            text: 'Share your thoughts on this shopping',
            list: ['Partagez vos réflexions sur ce shopping', 'Teilen Sie uns Ihre Gedenken zu diesem Einkauf mit', 'Comparta sus pensamientos sobre esta compra',
                'Поделитесь своим мнением об этой покупке', 'Compartilhe seus pensamentos sobre esta compra', 'この買い物についてあなたの考えを共有してください',
                `شارك بأفكارك حول هذا التسوق`]
        }, {
            text: 'Thank you for your comments',
            list: ['Merci pour vos commentaires', 'Vielen Dank für Ihre Komments', 'Gracias por tus comentarios',
                'Спасибо за ваши Коментарии', 'Obrigado por seus comentários', 'コメントしてくださってありがとうございます',
                `أشكركم على تعليقاتكم`]
        }, {
            text: 'The funds have returned the same way',
            list: ['Les fonds ont été retournés de la même manière', 'Die Erstattung hat auf das ursprüngliche Zahlungskonto zurückgekehrt', 'Los fondos han regresado de la misma manera.',
                'Средства вернулись тем же путем', 'Os fundos retornaram da mesma forma', '資金は同じように戻ってきました',
                `عادت الأموال بنفس الطريقة`]
        }, {
            text: 'Star evaluation',
            list: ['Évaluation des étoiles', 'Sternebewertungen', 'Evaluación de estrellas',
                'Звездная оценка', 'Avaliação de estrelas', '星評価',
                `تقييم النجوم`]
        }, {
            text: 'Satisfied',
            list: ['Satisfait', 'Zufrieden', 'Satisfecho',
                'Удовлетворен', 'Satisfeito', '満足',
                `راضي`]
        }, {
            text: 'Order Confirmation',
            list: ['Confirmation de commande', 'Bestellbestätigung', 'Confirmación del pedido',
                'Подтверждение заказа', 'Confirmação do pedido', '注文の確認',
                `تأكيد الطلب`]
        }, {
            text: 'Please enter the shipping address',
            list: ['Veuillez saisir l’adresse de livraison', 'Bitte geben Lieferadresse ein', 'Introduzca la dirección de envío,por favor',
                'Пожалуйста, введите адрес доставки', 'Por favor, insira o endereço de entrega', '配送先住所を入力してください',
                `الرجاء إدخال عنوان الشحن`]
        }, {
            text: 'Standard Shipping',
            list: ['Expédition standard', 'Standard Lieferung', 'Envío estándar',
                'Стандартная Доставка', 'Envio padrão', '通常輸送',
                `شحن قياسي`]
        }, {
            text: 'Order placed now are expected to arive before',
            list: ['Les commandes passées maintenant devraient être passées avant', 'Jetzt aufgegebene Bestellungen werden bevor', 'Se espera que el pedido realizado ahora llegue antes',
                'Ожидается, что заказ, размещенный сейчас, будет доставлен до', 'Espera-se que o pedido feito agora chegue antes', '現在発注された注文は、それより前に到着する予定です',
                `من المتوقع أن يتم تقديم الطلب الآن من قبل`]
        }, {
            text: 'Express Shipping',
            list: ['Livraison express', 'Express-Versand', 'Envío express',
                'Экспресс-доставка', 'Encomenda expressa', '特急運送',
                `الشحن السريع`]
        }, {
            text: 'Credit / Debit Card',
            list: ['Carte de crédit / débit', 'Kredit-/ Debitkarte', 'Tarjeta de crédito / débito',
                'Кредитная / дебетовая карта', 'Cartão de Crédito / Débito', 'クレジット/デビットカード',
                `بطاقة الائتمان / الخصم`]
        }, {
            text: 'PayPal',
            list: ['PayPal', 'PayPal', 'PayPal',
                `باي بال`]
        }, {
            text: 'Promo Code',
            list: ['Code promotionnel', 'Promo-Code', 'Promo Code',
                'Промо-код', 'Código promocional', 'プロモーションコード',
                `رمز ترويجي`]
        }, {
            text: 'My Coupon',
            list: ['Mon coupon', 'Mein Coupon', 'Mi cupón',
                'Мой купон', 'Meu cupom', 'マイクーポン',
                `قسيمتي`]
        }, {
            text: 'Enter Promo Code',
            list: ['Entrez le code promotionnel', 'Promo-Code eingeben', 'Introduce el Promo Code',
                'Введите промо-код', 'Insira o código promocional', 'プロモーションコードを入力',
                `إدخال الرمز الترويجي`]
        }, {
            text: 'You’ve got a US',
            list: ['Vous bénéficiez d’une réduction US', 'Sie haben einen Rabatt von EUR€', 'Tienes un  EUR€ ',
                'У вас есть США', 'Você tem um EUA', 'あなたは米国を持っています',
                `لقد حصلت على الولايات المتحدة`]
        }, {
            text: 'discount',
            list: ['rabais', 'erhalten', 'descuento',
                'скидка', 'desconto', '割引',
                `تخفيض`]
        }, {
            text: 'please input coupon code！',
            list: ['s’il vous plaît entrer le code de coupon!', 'Bitte geben Coupon Code ein!', 'Ingrese el código de cupón, por favor ',
                'пожалуйста, введите код купона！', 'por favor insira o código do cupom！', 'クーポンコードを入力してください！',
                `الرجاء إدخال رمز القسيمة！`]
        }, {
            text: 'Shipping List',
            list: ['Liste d’expédition', 'Versandliste', 'Lista de embarque',
                'Список доставки', 'Lista de envio', '出荷リスト',
                `قائمة الشحن`]
        }, {
            text: 'Items',
            list: ['Articles', 'Artikel', 'Productos',
                'Предметы', 'Unid', 'アイテム',
                `Items`]
        }, {
            text: 'Note to seller',
            list: ['Note au vendeur', 'Verkäuferhinweis', 'Nota al vendedor',
                'заметка продавцу', 'Nota ao vendedor', '販売者への注意',
                `مذكرة إلى البائع`]
        }, {
            text: 'Retail Price',
            list: ['Prix de détail', 'Einzelhandelspreis', 'Precio por menor',
                'Розничная цена', 'Preço de varejo', '小売価格',
                `سعر التجزئة`]
        }, {
            text: 'Subtotal',
            list: ['Sous-total', 'Zwischensumme', 'Total parcial',
                'Промежуточный итог', 'Subtotal', '小計',
                `المجموع الفرعي`]
        }, {
            text: 'Shipping fee',
            list: ['Frais d’expédition', 'Versandgebühr', 'Gastos de envío',
                'Стоимость доставки', 'Taxa de envio', '送料',
                `رسوم الشحن`]
        }, {
            text: 'Discount',
            list: ['Rabais', 'Rabatt', 'Descuento',
                'Скидка', 'Desconto', '割引',
                `تخفيض`]
        }, {
            text: 'Total Price',
            list: ['Prix total', 'Total Preis', 'Precio total',
                'Итоговая цена', 'Preço total', '合計金額',
                `السعر الكلي`]
        }, {
            text: 'Place Order',
            list: ['Passer commande', 'Bestellung aufgeben', 'Realizar pedido',
                'Разместить заказ', 'Faça a encomenda', '注文する',
                `مكان الامر`]
        }, {
            text: 'This payment method is only available with orders total between',
            list: ['Ce mode de paiement n’est disponible qu’avec le total des commandes entre', 'Diese Zahlungsmethode ist nur für Bestellungen zwischen', 'Este método de pago solo está disponible con pedidos totales entre',
                'Этот способ оплаты доступен только при общей сумме заказов между',
                'Este método de pagamento está disponível apenas para pedidos totais entre',
                'この支払い方法は、注文合計が',
                `طريقة الدفع هذه متاحة فقط مع إجمالي الطلبات بين`]
        }, {
            text: 'Refund Detail',
            list: ['Détails du remboursement', 'Erstattung Detail', 'Detalle del reembolso',
                'Детали возврата', 'Detalhe do reembolso', '払い戻しの詳細',
                `تفاصيل رد الأموال`]
        }, {
            text: 'Refund Item price',
            list: ['Remboursement Prix de l’objet', 'Erstattung Preis', 'Reembolso del precio del artículo',
                'Возврат стоимости товара', 'Reembolsar preço do item', '商品代金の返金',
                `استرداد سعر العنصر`]
        }, {
            text: 'Shipping fee',
            list: ['Frais d’expédition', 'Versandgebühr', 'Gastos de envío',
                'Стоимость доставки', 'Taxa de envio', '送料',
                `رسوم الشحن`]
        }, {
            text: 'no eligible for free shipping',
            list: ['non éligible à la livraison gratuite', 'Kein Anspruch auf kostenlosen Versand', 'no elegible para envío gratis',
                'нет права на бесплатную доставку', 'não é elegível para frete grátis', '送料無料対象外',
                `غير مؤهل للشحن المجاني`]
        }, {
            text: 'Actual refund amount',
            list: ['Montant réel du remboursement', 'Tatsächlicher Erstattungsbetrag', 'Reembolso real',
                'Фактическая сумма возврата', 'Valor real do reembolso', '実際の返金額',
                `مبلغ الاسترداد الفعلي`]
        }, {
            text: 'Refund amount',
            list: ['Montant du remboursement', 'Erstattungsbetrag', 'Monto devuelta',
                'Сумма возврата', 'Quantia de reembolso', '返金額',
                `المبلغ المسترد`]
        }, {
            text: 'Next',
            list: ['Prochain', 'Nächste', 'Próximo',
                'Следующий', 'Próximo', '次',
                `التالي`]
        }, {
            text: 'cancel reason',
            list: ['raison d’annulation', 'Kündigungsgrund', 'Razón motivo de cancelación',
                'отменить причину', 'Esqueceu de usar um código de cupom', 'キャンセル理由',
                `إلغاء السبب`]
        }, {
            text: 'Forgot to use a coupon code',
            list: ['J’ai oublié d’utiliser un code promo', 'Vergessen Sie einen Promo-Code zu verwenden', 'Olvidé usar un código de cupón',
                'Забыли использовать код купона', 'Esqueceu de usar um código de cupom', 'クーポンコードの使用を忘れた',
                `نسيت استخدام رمز القسيمة`]
        }, {
            text: 'Reached an agreement with the customer service team',
            list: ['Conclusion d’une entente avec l’équipe du service à la clientèle', 'Einigung mit dem Kundendienstteam erzielt', 'Llegó a un acuerdo con el equipo de atención al cliente',
                'Достигнуто соглашение с командой обслуживания клиентов',
                'Chegou a um acordo com a equipe de atendimento ao cliente',
                'カスタマーサービスチームと合意に達した',
                `تم التوصل إلى اتفاق مع فريق خدمة العملاء`]
        }, {
            text: 'Alter the personalized content',
            list: ['Modifier le contenu personnalisé', 'Ändern des personalisierten Inhalts', 'Alterar el contenido personalizado',
                'Изменить персонализированный контент', 'Alterar o conteúdo personalizado', 'パーソナライズされたコンテンツを変更する',
                `قم بتعديل المحتوى المخصص`]
        }, {
            text: 'Change shipping method',
            list: ['Modifier la méthode d’expédition', 'Versandart ändern', 'Cambiar método de envío',
                'Изменить способ доставки', 'Alterar método de envio', '配送方法の変更',
                `تغيير طريقة الشحن`]
        }, {
            text: 'Refund Item',
            list: ['Objet de remboursement', 'Erstattungsartikel', 'Artículo de reembolso',
                'Возврат товара', 'Item de reembolso', '払い戻しアイテム',
                `عنصر الاسترداد`]
        }, {
            text: 'Order',
            list: ['Order', 'Bestellung', 'Pedido',
                'Заказ', 'Ordem', '注文',
                `طلب`]
        }, {
            text: 'Refund',
            list: ['Remboursement', 'Erstattung', 'Reembolso',
                'Возвращать деньги', 'Reembolso', '返金',
                ``]
        }, {
            text: 'Refund amount',
            list: ['Montant du remboursement', 'Erstattungsbetrag', 'Monto devuelta',
                'Сумма возврата', 'Quantia de reembolso', '返金額',
                `المبلغ المسترد`]
        }, {
            text: 'Refund Method',
            list: ['Méthode de remboursement', 'Erstattungsmethode', 'Método de reembolso',
                'Метод возврата', 'Método de reembolso', '払い戻し方法',
                `طريقة الاسترداد`]
        }, {
            text: 'Application time',
            list: ['Temps d’application', 'Bewerbungszeit', 'Tiempo de aplicación',
                'Время применения', 'tempo de aplicação', '申請時間',
                `وقت التطبيق`]
        }, {
            text: 'Refund ID',
            list: ['ID de remboursement', 'Erstattungs-ID', 'ID de reembolso',
                'Идентификатор возврата', 'ID de reembolso', '返金ID',
                `معرف المبلغ المسترد`]
        }, {
            text: 'If you request for this order to be refunded,we will be unable to recover it.Would you still like to cancel?',
            list: ['Si vous demandez le remboursement de cette commande, nous ne pourrons pas la récupérer. Souhaitez-vous toujours annuler?', 'Wenn Sie die Erstattung dieser Bestellung beantragen, können wir sie nicht wiederherstellen. Möchten Sie trotzdem stornieren?', 'Si solicita el reembolso de este pedido, no podremos recuperarlo. ¿Aún desea cancelarlo?',
                'Если вы запросите возврат средств за этот заказ, мы не сможем его восстановить. Вы все еще хотите отменить?',
                'Se você solicitar o reembolso deste pedido, não poderemos recuperá-lo. Ainda assim deseja cancelar?',
                'この注文の払い戻しをリクエストした場合、返品することはできません。キャンセルしますか?',
                `إذا طلبت استرداد هذا الطلب ، فلن نتمكن من استعادته. هل ما زلت ترغب في الإلغاء؟`]
        }, {
            text: 'yes',
            list: ['Oui', 'Ja', 'sí',
                'да', 'sim', 'はい',
                `نعم`]
        }, {
            text: 'no',
            list: ['Non', 'Nein', 'no',
                'нет', 'não', 'いいえ',
                `لا`]
        }, {
            text: 'Apply for return',
            list: ['Demande de retour', 'Rücksendung beantragen', 'Solicitar devolución',
                'Подать заявку на возврат', 'Solicitar devolução', '返品を申し込む',
                `تقدم بطلب الإرجاع`]
        }, {
            text: 'Please contact customer service on “my” page to apply for refund. Thank you for your cooperation.',
            list: ['Veuillez contacter le service client sur « ma » page pour demander un remboursement. Merci de votre coopération.', 'Bitte wenden Sie sich an den Kundendienst auf "Mein" Seite, um eine Erstattung zu beantragen. Vielen Dank für Ihre Mitarbeit.', 'Comuníquese con el servicio al cliente en la página "mi" para solicitar un reembolso. Gracias por su cooperación.',
                'Пожалуйста, свяжитесь со службой поддержки на «моей» странице, чтобы подать заявку на возврат. Спасибо за ваше сотрудничество.',
                'Entre em contato com o atendimento ao cliente na página "minha" para solicitar o reembolso. Obrigado por sua cooperação.',
                'マイページよりカスタマーサービスへご連絡いただき、返金をお申し込みください。ご協力ありがとうございます',
                `يرجى الاتصال بخدمة العملاء على صفحة "my" لتقديم طلب استرداد. شكرا لتعاونكم.`]
        }, {
            text: 'Later',
            list: ['Plus tard', 'Weiter', 'Luego',
                'Позже', 'Mais tarde', '後で',
                `لاحقاً`]
        }, {
            text: 'Order Amount',
            list: ['Montant de la commande', 'Bestellbetrag', 'Monto de pedido',
                'Сумма заказа', 'Valor do pedido', '注文金額',
                `كمية الطلب`]
        }, {
            text: 'Use New / debit card',
            list: ['Utiliser une carte de débit / neuve', 'Neue-/Debitkarte verwenden', 'Usar tarjeta nueva/débito',
                'Использовать новую/дебетовую карту', 'Usar Novo / cartão de débito', '新・デビットカードを利用する',
                `استخدم بطاقة جديدة / خصم`]
        }, {
            text: 'Confirm Payment',
            list: ['Confirmer le paiement', 'Zahlung bestätigen', 'Confirmar pago',
                'Подтвердить платеж', 'Confirme o pagamento', 'お支払いの確認',
                `تأكيد الدفع`]
        }, {
            text: 'Please Add A Card',
            list: ['Veuillez ajouter une carte', 'Bitte fügen Sie eine Karte hinzu', 'Por favor agregue una tarjeta',
                'Пожалуйста, добавьте карту', 'Adicione um cartão', 'カードを追加してください',
                `الرجاء إضافة بطاقة`]
        }, {
            text: 'Select credit / debit card',
            list: ['Sélectionnez une carte de crédit / débit', 'Kredit- / Debitkarte auswählen', 'Seleccionar tarjeta de crédito/débito',
                'Выберите кредитную/дебетовую карту', 'Selecione o cartão de crédito/débito', 'クレジットカード・デビットカードを選択',
                `حدد بطاقة الائتمان / الخصم`]
        }, {
            text: 'security verification',
            list: ['vérification de la sécurité', 'Sicherheitsüberprüfung', 'Verificación de seguridad',
                'проверка безопасности', 'Verificação de Segurança', 'セキュリティ検証',
                `تصريح امني`]
        }, {
            text: 'What is CVV',
            list: ['Qu’est-ce que CVV', 'Was ist CVV', '¿Qué es CVV?',
                'Что такое ЦВВ', 'o que é cvv', 'CVVとは',
                `ما هو CVV`]
        }, {
            text: 'The 3 or 4 digit security code on the.front or back of your credit card.',
            list: ['Le code de sécurité à 3 ou 4 chiffres sur le recto ou le verso de votre carte de crédit.', 'Der 3- oder 4-stellige Sicherheitscode auf der Vorder- oder Rückseite der Kreditkarte.', 'El código de seguridad de 3 o 4 dígitos en el anverso o reverso de su tarjeta de crédito.',
                '3- или 4-значный код безопасности на лицевой или оборотной стороне вашей кредитной карты.',
                'O código de segurança de 3 ou 4 dígitos na frente ou no verso do seu cartão de crédito.',
                'クレジット カードの表面または裏面に記載されている 3 桁または 4 桁のセキュリティ コード',
                `رمز الحماية المكون من 3 أو 4 أرقام الموجود على الجهة الأمامية أو الخلفية لبطاقتك الائتمانية.`]
        }, {
            text: 'Continue to Pay',
            list: ['Continuer à payer', 'Weiter bezahlen', 'Continuar pagando',
                'Продолжать платить', 'Continuar a pagar', '支払いを続ける',
                `استمر في الدفع`]
        }, {
            text: 'Are you sure to cancel this payment? This item will be sold out soon',
            list: ['Êtes-vous sûr d’annuler ce paiement? Cet article sera bientôt épuisé.', 'Möchten Sie diese Zahlung wirklich stornieren? Dieser Artikel wird bald ausverkauft sein.', '¿Estás seguro de cancelar este pago? Este artículo se agotará pronto.',
                'Вы уверены, что хотите отменить этот платеж? Этот товар скоро будет распродан',
                'Tem certeza que deseja cancelar este pagamento? Este item será vendido em breve',
                'この支払いをキャンセルしてもよろしいですか？このアイテムはすぐに売り切れます',
                `هل أنت متأكد من إلغاء هذه الدفعة؟ سيتم بيع هذا العنصر قريبًا`]
        }, {
            text: 'Payment Successful',
            list: ['Paiement réussi', 'Bezahlung erfolgreich', 'Pago exitoso',
                'Оплата прошла успешно', 'Pagamento bem sucedido', '支払い完了',
                `تم الدفع بنجاح`]
        }, {
            text: 'Thank you for shopping with us. An Order Confirmation email has been sent to',
            list: ['Merci d’avoir magasiné avec nous. Un e-mail de confirmation de commande a été envoyé à', 'Vielen Dank für Ihren Einkauf. Eine Bestellbestätigungs-E-Mail wurde an', 'Gracias por comprar con nosotros. Se ha enviado un correo electrónico de confirmación de pedido a',
                'Спасибо за покупку. Письмо с подтверждением заказа отправлено на',
                'Obrigado por comprar conosco. Um e-mail de confirmação do pedido foi enviado para',
                'ありがとうございます。注文確認メールが送信されました',
                `شكرا للتسوق معنا. تم إرسال بريد إلكتروني لتأكيد الطلب إلى`]
        }, {
            text: 'Continue Shopping',
            list: ['Continuer vos achats', 'Weiter einkaufen', 'Seguir comprando',
                'Продолжить покупки', 'Continue comprando', 'ショッピングを続ける',
                `مواصلة التسوق`]
        }, {
            text: 'Check My Order',
            list: ['Vérifier ma commande', 'Meine Bestellung überprüfen', 'Check My Order',
                'Проверить мой заказ', 'Verifique meu pedido', '注文を確認する',
                `تحقق من طلبي`]
        }, {
            text: 'Card Number',
            list: ['Numéro de carte', 'Kartennummer', 'Número de tarjeta',
                'Номер карты', 'Número do cartão', 'カード番号',
                `رقم البطاقة`]
        }, {
            text: 'Expiration Date',
            list: ['Date d’expiration', 'Verfallsdatum', 'Fecha de caducidad',
                'Дата окончания срока', 'Data de validade', '有効期限',
                `تاريخ انتهاء الصلاحية`]
        }, {
            text: 'CVV',
            list: ['CVV', 'CVV', 'CVV',
                ``]
        }, {
            text: 'Remember this card for future use',
            list: ['Mémoriser cette carte pour une utilisation ultérieure', 'Notieren diese Karte zur späteren Verwendung', 'Recuerde esta tarjeta para uso futuro',
                'Запомните эту карту для использования в будущем', 'Lembre-se deste cartão para uso futuro', '将来の使用のためにこのカードを覚えておいてください',
                `تذكر هذه البطاقة للاستخدام في المستقبل`]
        }, {
            text: 'Incorrect credit card input',
            list: ['Saisie incorrecte de la carte de crédit', 'Falsche Kreditkarteneingabe', 'Entrada de tarjeta de crédito incorrecta',
                'Неправильный ввод кредитной карты', 'Entrada de cartão de crédito incorreta', 'クレジットカードの入力間違い',
                `إدخال بطاقة ائتمان غير صحيح`]
        }, {
            text: 'Posts',
            list: ['Messages', 'Posts', 'Posts',
                'Сообщения', 'Postagens', '投稿',
                `دعامات`]
        }, {
            text: 'Collections',
            list: ['Collections', 'Sammlungen', 'Colecciones',
                'Коллекции', 'Coleções', 'コレクション',
                `المجموعات`]
        }, {
            text: 'Likes',
            list: ['Aime', 'Mag', 'Gustos',
                'Нравится', 'Curtidos', 'いいね',
                `الإعجابات`]
        }, {
            text: 'Have no post yet',
            list: ['N’avez pas encore de poste', 'Habe noch keinen Posts', 'Aún no tengo ninguna post ',
                'У меня еще нет поста', 'ainda não tem postagem', 'まだ投稿がありません',
                `ليس لديك وظيفة بعد`]
        }, {
            text: 'Follow',
            list: ['Suivre', 'Folgende', 'Seguir',
                'Следовать', 'Seguir', '従う',
                `يتبع`]
        }, {
            text: 'Change Cover',
            list: ['Changer de couverture', 'Abdeckung wechseln', 'Cubierta de cambio',
                'Изменение обложки', 'Mudar capa', '表紙を変える',
                `تبديل الغطاء`]
        }, {
            text: 'Monthly New',
            list: ['Nouveau mensuel', 'Monatlich Neu', 'Nuevo mensual',
                'Ежемесячно Новый', 'Novo Mensal', '毎月の新しい',
                `شهري جديد`]
        }, {
            text: 'January',
            list: ['Janvier', 'Januar', 'Enero',
                'январь', 'Janeiro', '1月',
                `يناير`]
        }, {
            text: 'February',
            list: ['Février', 'Februar', 'Febrero',
                'февраль', 'Fevereiro', '2月',
                `شهر فبراير`]
        }, {
            text: 'March',
            list: ['Mars', 'März', 'Marzo',
                'Маршировать', 'Marchar', '行進',
                `يمشي`]
        }, {
            text: 'April',
            list: ['Avril', 'April', 'Abril',
                'апрель', 'abril', '4月',
                `أبريل`]
        }, {
            text: 'May',
            list: ['Mai', 'Mai', 'Mayo',
                'Может', 'Poderia', '5月',
                `يمكن`]
        }, {
            text: 'June',
            list: ['Juin', 'Juni', 'Junio',
                'Июнь', 'Junho', '六月',
                `يونيو`]
        }, {
            text: 'July',
            list: ['Juillet', 'Juli', 'Julio',
                'Июль', 'Julho', '7月',
                `يوليو`]
        }, {
            text: 'August',
            list: ['Août', 'August', 'Agosto',
                'Август', 'Agosto', '8月',
                `أغسطس`]
        }, {
            text: 'September',
            list: ['Septembre', 'September', 'Septiembre',
                'Сентябрь', 'Setembro', '9月',
                `سبتمبر`]
        }, {
            text: 'October',
            list: ['Octobre', 'Oktober', 'Octubre',
                'Октябрь', 'Outubro', '10月',
                `اكتوبر`]
        }, {
            text: 'November',
            list: ['Novembre', 'November', 'Noviembre',
                'ноябрь', 'novembro', '11月',
                `شهر نوفمبر`]
        }, {
            text: 'December',
            list: ['Décembre', 'Dezember', 'Diciembre',
                'Декабрь', 'dezembro', '12月',
                `ديسمبر`]
        }, {
            text: 'New',
            list: ['Nouveau', 'Neu', 'Nuevo',
                'Новый', 'Novo', '新しい',
                `جديد`]
        }, {
            text: 'This Week',
            list: ['Cette semaine', 'Diese Woche', 'Esta semana',
                'На этой неделе', 'Essa semana', '今週',
                `هذا الاسبوع`]
        }, {
            text: 'Week',
            list: ['Semaine', 'Woche', 'Semana',
                'Неделя', 'Semana', '週',
                `أسبوع`]
        }, {
            text: 'Yesterday',
            list: ['Hier', 'Gestern', 'Ayer',
                'Вчера', 'Ontem', '昨日',
                `أمس`]
        }, {
            text: 'Most Popular in',
            list: ['Le plus populaire dans', 'Am beliebtesten in', 'Más Popular en',
                'Самый популярный в', 'Mais populares em', '最も人気のある',
                `الأكثر شهرة في`]
        }, {
            text: 'Most Popular',
            list: ['Le plus populaire', 'Am beliebtesten', 'Más Popular',
                'Самый популярный', 'Mais populares', '最も人気のあ',
                `الأكثر شهرة في`]
        }, {
            text: 'Top Seller',
            list: ['Meilleure vente', 'Bestseller', 'Lo más vendidos',
                'Лучший продавец', 'Mais vendido', 'トップセラー',
                `أعلى بائع`]
        }, {
            text: 'bought it',
            list: ['l’a acheté', 'kaufte es', 'Lo comprado',
                'купил это', 'comprei', '買った',
                `اشتريته`]
        }, {
            text: 'sold',
            list: ['vendu', 'verkaufen', 'vendido',
                'продал', 'vendido', '売った',
                `مُباع`]
        }, {
            text: 'P.S. Please make sure you’re putting your picture in correct position, we’ll make it exactly the same as shown in the preview above',
            list: ['P.S. S’il vous plaît assurez-vous que vous mettez votre photo dans la bonne position, nous le ferons exactement comme indiqué dans l’aperçu ci-dessus', 'Tipp: Bitte platzieren Sie das Bild an der richtigen Position, wir werden genau so vorgehen, wie in der Vorschau gezeigt.', 'PD Por favor, asegúrese de colocar su imagen en la posición correcta, la haremos exactamente igual a como se muestra en la vista previa anterior.',
                'P.S. Пожалуйста, убедитесь, что вы помещаете изображение в правильное положение, мы сделаем его точно таким же, как показано на предварительном просмотре выше.',
                'PS Por favor, certifique-se de colocar sua foto na posição correta, vamos torná-la exatamente igual à mostrada na visualização acima',
                '追記写真を正しい位置に配置していることを確認してください。上のプレビューに表示されているものとまったく同じになります。',
                `ملاحظة. يرجى التأكد من أنك تضع صورتك في الموضع الصحيح ، فسنجعلها مماثلة تمامًا كما هو موضح في المعاينة أعلاه`]
        }, {
            text: 'CONFIRM YOUR DESIGN',
            list: ['CONFIRMEZ VOTRE CONCEPTION', 'BESTÄTIGEN SIE IHR DESIGN', 'CONFIRME SU DISEÑO',
                'ПОДТВЕРДИТЕ ВАШ ДИЗАЙН', 'CONFIRME SEU DESENHO', 'デザインを確認する',
                `قم بتأكيد التصميم الخاص بك`]
        }, {
            text: 'On Sale',
            list: ['En vente', 'Angeboten', 'En venta',
                'В продаже', 'À venda', '発売中',
                `للبيع`]
        }, {
            text: 'Coming Soon',
            list: ['À venir', 'Kommt bald', 'Viniendo，pronto',
                'Вскоре', 'Em breve', '近日公開',
                `قريباً`]
        }, {
            text: 'Purchase limited to',
            list: ['Achat limité aux produits promotionnels', 'Kaufen Sie nur', 'Compra limitada a',
                'Покупка ограничена', 'Compra limitada a', '購入限定',
                `الشراء يقتصر على`]
        }, {
            text: 'promotional products',
            list: ['produits promotionnels', 'Promotion-Produkte', 'productos promocionales',
                'рекламная продукция', 'produtos promocionais', 'プロモーション製品',
                `منتجات دعائية`]
        }, {
            text: 'pcs left',
            list: ['piezas', 'übrig', 'pcs',
                'шт осталось', 'unidades restantes', '個残り',
                `جهاز كمبيوتر شخصى اليسار`]
        }, {
            text: 'pcs sold out',
            list: ['pcs épuisés', 'verkauft', 'piezas agotadas',
                'шт продано', 'peças esgotadas', '個売り切れ',
                `تم بيع أجهزة الكمبيوتر`]
        }, {
            text: 'Sold Out',
            list: ['Épuisé', 'Ausverkauft', 'Vendido',
                'Распроданный', 'Vendido', '完売',
                `نفذ`]
        }, {
            text: 'View More',
            list: ['Voir plus', 'Mehr anzeigen', 'Ver más',
                'Посмотреть больше', 'Veja mais', 'もっと見る',
                `عرض المزيد`]
        }, {
            text: 'Quantity',
            list: ['Quantité', 'Quantität', 'Cantidad',
                'Количество', 'Quantidade', '量',
                `كمية`]
        }, {
            text: 'Recommend',
            list: ['Recommander', 'Empfehlen', 'Recomendar',
                'Рекомендовать', 'recomendar', 'お勧め',
                `يوصي`]
        }, {
            text: 'Product',
            list: ['Produit', 'Produkt', 'Producto',
                'Продукт', 'produtos', '製品',
                `منتج`]
        }, {
            text: 'Details',
            list: ['Détails', 'Details', 'Detalles',
                'Подробности', 'Detalhes', '詳細',
                `تفاصيل`]
        }, {
            text: 'Add To Bag',
            list: ['Ajouter au panier', 'In den Warenkorb legen', 'Agregar a la bolsa',
                'Добавить в корзину', 'Adicionar ao saco', 'かごに入れます',
                `أضف الى الحقيبة`]
        }, {
            text: 'Buy Now',
            list: ['Acheter maintenant', 'Jetzt kaufen', 'Comprar ahora',
                'Купить сейчас', 'Comprar agora', '今すぐ購入',
                `اشتري الآن`]
        }, {
            text: 'Product Details',
            list: ['Détails du produit', 'Produktdetails', 'Detalles de producto',
                'информация о продукте', 'Detalhes do produto', '製品詳細',
                `تفاصيل المنتج`]
        }, {
            text: 'In Stock',
            list: ['En stock', 'Auf Lager', 'En stock',
                'В наличии', 'Em estoque', '在庫あり',
                `في الأوراق المالية`]
        }, {
            text: 'Added to Bag successfully!',
            list: ['Ajouter au panier avec succès!', 'Erfolgreich in den Warenkorb legen!', 'Añadido a la bolsa con éxito!',
                'Добавлено в корзину успешно!', 'Adicionado ao Saco com sucesso!', 'バッグに追加されました',
                `تمت الإضافة إلى الحقيبة بنجاح!`]
        }, {
            text: 'Home',
            list: ['Page d’accueil', 'Homepage', 'Casa',
                'Дом', 'Lar', '家',
                `بيت`]
        }, {
            text: 'Support',
            list: ['Soutien', 'Unterstützen', 'apoyar',
                'Поддерживать', 'Apoiar', 'サポート',
                `يدعم`]
        }, {
            text: 'ALL',
            list: ['TOUS', 'ALLE', 'Todos',
                'ВСЕ', 'TODOS', '全て',
                `الجميع`]
        }, {
            text: 'With Photo',
            list: ['Avec photo', 'Mit Bild', 'Con foto',
                'С фото', 'com foto', '写真付き',
                `مع الصور`]
        }, {
            text: 'Helpful',
            list: ['Utile', 'Nützlich', 'Útil',
                'Полезный', 'Útil', '役に立った',
                `متعاون`]
        }, {
            text: 'Message',
            list: ['Message', 'Nachricht', 'Mensaje',
                'Сообщение', 'Mensagem', 'メッセージ',
                `رسالة`]
        }, {
            text: 'Copy Link',
            list: ['Copier le lien', 'Link kopieren', 'Enlace de copiar ',
                'Копировать ссылку', 'Link de cópia', 'リンクをコピーする',
                `نسخ الوصلة`]
        }, {
            text: 'Share To',
            list: ['Partager vers', 'Teilen mit', 'Compartir a',
                'Поделиться с', 'Compartilhar com', '共有先',
                `شارك على`]
        }, {
            text: 'Notify Me, Never Miss it!',
            list: ['Prévenez-moi, ne le manquez jamais!', 'Benachrichtigen Sie mich und verpassen Sie nichts!', '¡Avísame, nunca te lo pierdas!',
                'Сообщите мне, никогда не пропустите!', 'Notifique-me, nunca perca!', '通知して、見逃すことはありません',
                `أعلمني ، لا تفوتها أبدًا!`]
        }, {
            text: 'Exclusive Coupons',
            list: ['Coupons exclusifs', 'Exklusive Coupons', 'Cupones exclusivos',
                'Эксклюзивные купоны', 'Cupons Exclusivos', '限定クーポン',
                `كوبونات حصرية`]
        }, {
            text: 'Lastest Promotions',
            list: ['Dernières promotions', 'Neueste Angebote', 'Últimas promociones',
                'Последние акции', 'últimas promoções', '最新のプロモーション',
                `أحدث العروض الترويجية`]
        }, {
            text: 'Your Order Status',
            list: ['Statut de votre commande', 'Ihr Bestellstatus', 'Estado de su pedido',
                'Статус вашего заказа', 'Status do seu pedido', 'ご注文状況',
                `حالة طلبك`]
        }, {
            text: 'Daily Check-in',
            list: ['Enregistrement quotidien', 'Tägliches Einchecken', 'Registro diario',
                'Ежедневная регистрация', 'Check-in diário', '毎日のチェックイン',
                `تسجيل الوصول اليومي`]
        }, {
            text: 'YEP!',
            list: ['OUI!', 'JA!', '¡SÍ!',
                'АГА!', 'SIM!', 'はい',
                `نعم!`]
        }, {
            text: 'NOPE',
            list: ['NON', 'NEIN', 'NO',
                'НЕА', 'NÃO', 'いいえ',
                `لا`]
        }, {
            text: 'Allow Notifications regarding your Order Status & Trackong Information.',
            list: ['Autoriser les notifications concernant l’état de votre commande et les informations trackong.', 'Lassen Sie Benachrichtigungen über Ihren Bestellstatus und Tracking-Informationen zu.', 'Permita notificaciones sobre el estado de su pedido y la información de seguimiento.',
                'Разрешить уведомления о статусе вашего заказа и информации об отслеживании.',
                'Permitir notificações sobre o status do seu pedido e informações de rastreamento.',
                '注文状況と追跡情報に関する通知を許可する',
                `السماح بالإشعارات المتعلقة بحالة طلبك ومعلومات التعقب.`]
        }, {
            text: 'New Version is Coming',
            list: ['Nouvelle version à venir', 'Neue Version kommt', 'Viene una nueva versión',
                'Новая версия идет', 'Nova versão está chegando', '新しいバージョンが来ています',
                `الإصدار الجديد قادم`]
        }, {
            text: 'Better user experience',
            list: ['Meilleure expérience utilisateur', 'Bessere Benutzererfahrung', 'Mejor experiencia de usuario',
                'Улучшенный пользовательский опыт', 'Melhor experiência do usuário', 'ユーザーエクスペリエンスの向上',
                `تجربة مستخدم أفضل`]
        }, {
            text: 'Some bugs fix',
            list: ['Correction de quelques bugs', 'Einige Fehler behoben', 'Corrección de algunos errores',
                'Исправлены некоторые ошибки', 'Alguns bugs corrigidos', 'いくつかのバグ修正',
                `إصلاح بعض الأخطاء`]
        }, {
            text: 'Later',
            list: ['Plus tard', 'Später', 'Luego',
                'Позже', 'Mais tarde', '後で',
                `لاحقاً`]
        }, {
            text: 'Update Now',
            list: ['Mettre à jour', 'Jetzt aktualisieren', 'Actualizar ahora',
                'Обновить сейчас', 'Atualizar agora', '今すぐアップデート',
                `تحديث الان`]
        }, {
            text: 'No Message',
            list: ['Aucun message', 'Kein Nachricht', 'No mensaje',
                'Нет сообщений', 'Nenhuma mensagem', 'メッセージなし',
                `لا توجد رسالة`]
        }, {
            text: 'Wallet',
            list: ['Portefeuille', 'Geldtasche', 'Cartera',
                'Кошелек', 'Carteira', '財布',
                `محفظة`]
        }, {
            text: 'YFN Wallet',
            list: ['Portefeuille YFN', 'YFN Geldtasche', 'Cartera YFN',
                'Кошелек YFN', 'Carteira YFN', 'YFNウォレット',
                `محفظة YFN`]
        }, {
            text: 'Total amount',
            list: ['Montant total', 'Gesamtbetrag', 'Monto total',
                'Общая сумма', 'Montante total', '合計金額',
                `المبلغ الإجمالي`]
        }, {
            text: 'Available Balance',
            list: ['Solde disponible', 'Verfügbares Guthaben', 'Saldo disponible',
                'доступные средства', 'Saldo disponível', '利用可能残高',
                `الرصيد المتوفر`]
        }, {
            text: 'No details',
            list: ['Pas de détails', 'Keine Details', 'Sin detalles',
                'Нет подробностей', 'Sem detalhes', '詳細なし',
                `بدون تفاصيل`]
        }, {
            text: 'History',
            list: ['Histoire', 'Geschichte', 'Historia',
                'История', 'História', '歴史',
                `تاريخ`]
        }, {
            text: 'Free standard shipping on order over',
            list: ['Livraison standard gratuite pour toute commande supérieure à', 'Kostenloser Standardvesand für Bestellung über', 'Envío estándar gratuito en pedidos superiores al Euro',
                'Бесплатная стандартная доставка при заказе свыше',
                'Envio padrão gratuito para pedidos acima de',
                '以上のご注文で通常配送無料',
                `شحن قياسي مجاني عند الطلب`]
        }, {
            text: 'Skip',
            list: ['Sauter', 'Überspringen', 'Saltar',
                'Пропускать', 'Pular', 'スキップ',
                `يتخطى`]
        }, {
            text: 'Color',
            list: ['Couleur', 'Farbe', 'Color',
                'Цвет', 'Cor', '色',
                `لون`]
        }, {
            text: 'Video',
            list: ['Vidéo', 'Video', 'Video',
                'видео', 'Vídeo', 'ビデオ',
                `فيديو`]
        }, {
            text: 'Free',
            list: ['Gratuite', 'Frei', 'Gratis',
                'Бесплатно', 'Livre', '無料',
                `حر`]
        }, {
            text: 'You have not got coupon yet',
            list: ['Vous n’avez pas encore de coupon', 'Sie haben noch kein Coupon erhalten', 'Aún no tienes cupón',
                'У вас еще нет купона', 'Você ainda não tem cupom', 'まだクーポンを取得していません',
                `لم تحصل على قسيمة بعد`]
        }, {
            text: 'How to get a coupon?',
            list: ['Comment obtenir un coupon?', 'Wie erhalte ich ein Coupon?', '¿Cómo obtener un cupón?',
                'Как получить купон?', 'Como obter um cupom?', 'クーポンの入手方法',
                `كيف تحصل على قسيمة؟`]
        }, {
            text: 'Photo',
            list: ['Photo', 'Bild', 'Foto',
                'Фото', 'foto', '写真',
                `صورة`]
        }, {
            text: 'Tags',
            list: ['Balises', 'Tags', 'Etiquetas',
                'Теги', 'Tag', 'タグ',
                `صورة`]
        }, {
            text: 'no more data',
            list: ['Pas de plus de données', 'Kein Daten', 'sin más datos',
                'больше нет данных', 'sem mais dados', 'もうデータはありません',
                `لا مزيد من البيانات`]
        }, {
            text: 'Wishlist is empty',
            list: ['La liste de souhaits est vide', 'Wunschliste ist leer', 'La lista de deseos está vacía',
                'Список желаний пуст', 'A lista de desejos está vazia', 'ウィッシュリストは空です',
                `قائمة الرغبات فارغة`]
        }, {
            text: 'Shopping List',
            list: ['Liste de courses', 'Einkaufsliste', 'Lista de la compra',
                'Список покупок', 'Lista de compras', '買い物リスト',
                `قائمة التسوق`]
        }, {
            text: 'Please enter your password',
            list: ['Veuillez entrer votre mot de passe', 'Bitte geben Sie Ihr Passwort ein', ' Introduzca su contraseña, por favor',
                'Пожалуйста введите ваш пароль', 'Por favor, insira sua senha', 'パスワードを入力してください',
                `من فضلك أدخل رقمك السري`]
        }, {
            text: 'Your account needs to be linked to an email address',
            list: ['Votre compte doit être lié à une adresse e-mail', 'Ihr Konto muss mit einer E-Mail-Adresse verknüpft sein', 'Su cuenta debe estar vinculada a una dirección de correo electrónico',
                'Ваша учетная запись должна быть связана с адресом электронной почты',
                'Sua conta precisa estar vinculada a um endereço de e-mail',
                'アカウントをメールアドレスにリンクする必要があります',
                `يجب ربط حسابك بعنوان بريد إلكتروني`]
        }, {
            text: '2-20 letters or spaces',
            list: ['2-20 lettres ou espaces', '2-20 Buchstaben oder Leerzeichen', '2-20 letras o espacios',
                '2-20 букв или пробелов', '2-20 letras ou espaços', '2 ～ 20 文字またはスペース',
                `2-20 حرفًا أو مسافات`]
        }, {
            text: 'email',
            list: ['e-mail', 'E-Mail', 'correo',
                'электронная почта', 'e-mail', 'Eメール',
                `بريد إلكتروني`]
        }, {
            text: 'Please enter your email',
            list: ['Veuillez saisir votre e-mail', 'Bitte geben Sie ihre E-Mail-Adresse ein', 'Por favor introduzca su correo electrónico',
                'Пожалуйста, введите адрес электронной почты', 'Por favor introduza o seu e-mail', 'Please enter your email',
                `رجاءا أدخل بريدك الإلكتروني`]
        }, {
            text: 'Building/Apartment/Suite no, Unit, Floor, etc(optional).',
            list: ['Numéro de bâtiment/appartement/suite, unité, étage, etc. (facultatif).', 'Gebäude/Apartment/Suite Nr., Einheit, Etage usw. (optional).', 'Número de edificio/apartamento/suite, unidad, piso, etc. (opcional).',
                '№ здания/квартиры/люкса, единица измерения, этаж и т. д. (по желанию).',
                'Prédio/Apartamento/Suíte nº, Unidade, Andar, etc(opcional).',
                '建物/アパート/スイート番号、ユニット、フロアなど(オプション)',
                `رقم المبنى / الشقة / الجناح ، الوحدة ، الطابق ، إلخ (اختياري).`]
        }, {
            text: 'Address line 2 should contain 5-50 letters, digits or spaces.',
            list: [`La ligne d'adresse 2 doit contenir 5 à 50 lettres, chiffres ou espaces.`, 'Adresszeile 2 sollte 5-50 Buchstaben, Ziffern oder Leerzeichen enthalten.', 'La línea de dirección 2 debe contener de 5 a 50 letras, dígitos o espacios.',
                'Адресная строка 2 должна содержать от 5 до 50 букв, цифр или пробелов.',
                'A linha de endereço 2 deve conter de 5 a 50 letras, dígitos ou espaços.',
                '住所行 2 には、5 ～ 50 文字の文字、数字、またはスペースを含める必要があります',
                `يجب أن يحتوي سطر العنوان 2 على 5-50 حرفًا أو رقمًا أو مسافات.`]
        }, {
            text: 'Please enter your State/Province',
            list: ['Veuillez entrer votre état/province', 'Bitte geben Sie Ihr Bundesland/Provinz ein', 'Ingrese su estado/provincia',
                'Пожалуйста, введите свой штат/область', 'Por favor, insira seu estado/província', '都道府県を入力してください',
                `الرجاء إدخال الولاية / المقاطعة الخاصة بك`]
        }, {
            text: 'Please enter your City',
            list: ['Veuillez saisir votre ville', 'Bitte geben Sie Ihre Stadt ein', 'Ingrese su Ciudad, por favor',
                'Пожалуйста, введите свой город', 'Por favor, insira sua cidade', 'あなたの都市を入力してください',
                `الرجاء إدخال مدينتك`]
        }, {
            text: 'Once the order created,the country Do Not Support to Change',
            list: ['Une fois la commande créée, le pays ne prend pas en charge le changement', 'Wenn Sie die Bestellung bestätigen, lassen Sie das Land nicht zu ändern', 'Una vez creado el pedido, el país no admite cambios',
                'После создания заказа страна не поддерживает изменения',
                'Uma vez que o pedido foi criado, o país não suporta a mudança',
                '注文が作成されたら、国は変更をサポートしていません',
                `بمجرد إنشاء الأمر ، لا تدعم الدولة التغيير`]
        }, {
            text: 'Please Select',
            list: ['Veuillez sélectionner', 'Bitte auswählen', 'Seleccionar',
                'Пожалуйста выберите', 'Por favor selecione', '選んでください',
                `الرجاء التحديد`]
        }, {
            text: 'Used',
            list: ['Utilisé', 'Verwendet', 'Usó',
                'Использовал', 'Usado', '使用済み',
                `مستخدم`]
        }, {
            text: 'How to get the a coupon?',
            list: ['Comment obtenir le bon de réduction ?', 'Wie erhalte ich Coupons?', '¿Cómo obtener el cupón?',
                'Как получить купон?', 'Como obter o cupom?', 'クーポンの入手方法',
                `كيف تحصل على القسيمة؟`]
        }, {
            text: 'Ok',
            list: [`D'accord`, 'OK', 'Ok',
                'Хорошо', 'OK', 'Ok',
                `نعم`]
        }, {
            text: 'Are you Really sure?',
            list: ['Êtes-vous vraiment sûr?', 'Sind Sie sicher?', '¿ Estas seguro?',
                'Вы действительно уверены?', 'Você realmente tem certeza?', '本当によろしいですか',
                `هل انت حقا متاكد؟`]
        }, {
            text: 'No Thanks',
            list: ['Non Merci', 'Nein Danke', 'No, gracias',
                'Нет, спасибо', 'Não, obrigado', '結構です',
                `ًلا شكرا`]
        }, {
            text: 'Shipping address can only be changed once . If you want to change again , you can contact service',
            list: ['L’adresse de livraison ne peut être modifiée qu’une seule fois. Si vous souhaitez changer à nouveau, vous pouvez contacter le service', 'Die Lieferadresse kann nur einmal geändert werden. Wenn Sie erneut wechseln möchten, können Sie sich an den Service wenden', 'La dirección de envío solo se puede cambiar una vez. Si desea cambiar de nuevo, puede ponerse en contacto con el servicio',
                'Адрес доставки можно изменить только один раз. Если вы хотите изменить снова, вы можете связаться со службой',
                'O endereço de entrega só pode ser alterado uma vez. Se você quiser mudar novamente, você pode entrar em contato com o serviço',
                '配送先住所は 1 回のみ変更できます。もう一度変更したい場合は、サービスに連絡できます',
                `يمكن تغيير عنوان الشحن مرة واحدة فقط. إذا كنت تريد التغيير مرة أخرى ، يمكنك الاتصال بالخدمة`]
        }, {
            text: 'Are you sure you want to cancel the order?',
            list: ['Êtes-vous sûr de vouloir annuler la commande?', 'Möchten Sie die Bestellung wirklich stornieren?', '¿Estás seguro de que deseas cancelar el pedido?',
                'Вы уверены, что хотите отменить заказ?',
                'Tem certeza que deseja cancelar o pedido?',
                '注文をキャンセルしてもよろしいですか',
                `هل أنت متأكد أنك تريد إلغاء الطلب؟`]
        }, {
            text: 'Give up cancel',
            list: ['Renoncer à annuler', 'Aufgeben abbrechen', 'Renunciar cancelar',
                'Отменить отменить', 'desistir cancelar', 'あきらめてキャンセル',
                `استسلم إلغاء`]
        }, {
            text: 'Are you sure the received goods have been received?',
            list: ['Êtes-vous sûr que les marchandises reçues ont été reçues?', 'Sind Sie sicher, dass die Ware eingegangen ist?', '¿Está seguro de que se han recibido los productos recibidos?',
                'Вы уверены, что полученные товары были получены?', 'Tem certeza de que as mercadorias recebidas foram recebidas?', '受け取った商品は確実に受け取っていますか',
                `هل أنت متأكد من استلام البضائع المستلمة؟`]
        }, {
            text: 'Copy Email',
            list: ['Copier l’e-mail', 'E-Mail kopieren', 'Copiar correo electrónico',
                'Копировать электронную почту', 'Copiar e-mail', 'メールをコピー',
                `نسخ البريد الإلكتروني`]
        }, {
            text: 'Shipping address can only be changed once . If you want to change again , you can contact our service',
            list: ['L’adresse de livraison ne peut être modifiée qu’une seule fois. Si vous souhaitez changer à nouveau, vous pouvez contacter notre service', 'Die Lieferadresse kann nur einmal geändert werden. Wenn Sie wechseln möchten, können Sie sich an unseren Service wenden', 'La dirección de envío solo se puede cambiar una vez. Si desea cambiar de nuevo, puede ponerse en contacto con nuestro servicio',
                'Адрес доставки можно изменить только один раз. Если вы хотите изменить снова, вы можете связаться с нашей службой',
                'O endereço de entrega só pode ser alterado uma vez. Caso pretenda alterar novamente, pode contactar o nosso serviço',
                '配送先住所は 1 回のみ変更できます。再度変更したい場合は、当社のサービスにご連絡ください',
                `يمكن تغيير عنوان الشحن مرة واحدة فقط. إذا كنت تريد التغيير مرة أخرى ، يمكنك الاتصال بخدمتنا`]
        }, {
            text: 'Secure payment',
            list: ['Paiement sécurisé', 'Sichere Bezahlung', 'Pago seguro',
                'Безопасная оплата', 'pagamento seguro', '安全なお支払い',
                `دفع امن`]
        }, {
            text: 'Remaining payment time',
            list: ['Temps de paiement restant', 'Verbleibende Zahlungszeit', 'Tiempo de pago restante',
                'Оставшееся время оплаты', 'Tempo restante de pagamento', '支払いの残り時間',
                `الوقت المتبقي للدفع`]
        }, {
            text: 'You cancelled your order on',
            list: ['Vous avez annulé votre commande le', 'Sie haben Ihre Bestellung storniert', 'Usted canceló su pedido en',
                'Вы отменили свой заказ на', 'Você cancelou seu pedido em', '注文をキャンセルしました',
                `لقد ألغيت طلبك في`]
        }, {
            text: 'Pay Now',
            list: ['Payer maintenant', 'Zahlen Sie jetzt', 'Pagar ahora',
                'Заплатить сейчас', 'Pague agora', '今払う',
                `ادفع الآن`]
        }, {
            text: 'Cancel Order',
            list: ['Annuler la commande', 'Bestellung stornieren', 'Cancelar pedido',
                'Отменить заказ', 'Cancelar pedido', '注文をキャンセルする',
                `الغاء الطلب`]
        }, {
            text: 'Order Canceled',
            list: ['Commande annulée', 'Bestellung storniert', 'Pedido cancelado',
                'Заказ отменен', 'Pedido cancelado', '注文がキャンセルされました',
                `تم إلغاء الطلب`]
        }, {
            text: 'Closed',
            list: ['Fermé', 'Abgeschlossen', 'Cerrado',
                'Закрыто', 'Fechado', '閉まっている',
                `مغلق`]
        }, {
            text: 'Unshipped',
            list: ['Non expédié', 'Unverpackt', 'Sin enviar',
                'Неотгружено', 'Não enviado', '未発送',
                `غير مشحونة`]
        }, {
            text: 'Please select a refund item',
            list: ['Veuillez sélectionner un article de remboursement', 'Bitte wählen Sie einen Erstattungsartikel aus', 'Seleccione un artículo de reembolso',
                'Пожалуйста, выберите пункт возврата', 'Selecione um item de reembolso', '払い戻し項目を選択してください',
                `الرجاء تحديد عنصر استرداد`]
        }, {
            text: 'Submited successful',
            list: ['Envoyé avec succès', 'Erfolgreich eingereicht', 'Enviado satisfactoriamente',
                'Отправлено успешно', 'Enviado com sucesso', '提出しました',
                `تم الإرسال بنجاح`]
        }, {
            text: 'Submited. You may check order status in My Order later.',
            list: [`Soumis. Vous pouvez vérifier l'état de la commande dans Ma commande plus tard.`, 'Eingereicht. Sie können den Bestellstatus später unter Meine Bestellung überprüfen.', 'Enviado. Puede verificar el estado del pedido en Mi pedido más tarde.',
                'Отправлено. Вы можете проверить статус заказа в разделе «Мой заказ» позже.',
                'Enviado. Você pode verificar o status do pedido em Meu pedido mais tarde.',
                '提出しました。注文ステータスは、後で [マイ オーダー] で確認できます',
                `تم الإرسال. يمكنك التحقق من حالة الطلب في طلبي لاحقًا.`]
        }, {
            text: 'Enter',
            list: ['Entrer', 'Weiter', 'Ingresar',
                'Входить', 'Digitar', '入力',
                `يدخل`]
        }, {
            text: 'My Review',
            list: ['Mon avis', 'Meine Rezensionen', 'Mi comentario',
                'Мой отзыв', 'minha opinião', '私のレビュー',
                `تقييمي`]
        }, {
            text: 'Invalid Product',
            list: ['Produit invalide', 'Ungültiges Produkt', 'Producto no válido',
                'Недействительный продукт', 'Produto inválido', '無効な製品',
                `منتج غير صالح`]
        }, {
            text: 'Apply Coupon code,YFN points on the next step',
            list: [`Appliquer le code promo, les points YFN à l'étape suivante`, 'Wenden Sie im nächsten Schritt den Coupon-Code und die YFN-Punkte an', 'Aplicar código de cupón, puntos YFN en el siguiente paso',
                'Примените код купона, баллы YFN на следующем шаге', 'Aplique o código do cupom, pontos YFN na próxima etapa', '次のステップでクーポンコード、YFNポイントを適用',
                `قم بتطبيق رمز القسيمة ، نقاط YFN في الخطوة التالية`]
        }, {
            text: 'Are you sure to delete this item(s)',
            list: ['Êtes-vous sûr de vouloir supprimer ce(s) élément(s)', 'Möchten Sie diese(n) Artikel löschen', '¿Estás seguro de eliminar este artículo(s)',
                'Вы уверены, что хотите удалить этот элемент?', 'Tem certeza que deseja excluir este(s) item(ns)', 'このアイテムを削除してもよろしいですか',
                `هل أنت متأكد من حذف هذا العنصر (العناصر)`]
        }, {
            text: 'free gift activity',
            list: ['activité cadeau gratuite', 'kostenlose Geschenkaktivität', 'actividad de regalo gratis',
                'бесплатная подарочная деятельность', 'atividade de presente grátis', '無料プレゼント活動',
                `نشاط هدية مجانية`]
        }, {
            text: 'Are you sure to moving the item(s) to wishlist from shopping bag',
            list: ['Êtes-vous sûr de vouloir supprimer ce message', 'Möchten Sie diesen Beitrag wirklich löschen', '¿Estás seguro de eliminar esta publicación',
                'Вы уверены, что хотите переместить товар(ы) в список желаний из корзины?', 'Tem certeza de que deseja mover o(s) item(ns) para a lista de desejos da sacola de compras',
                'アイテムをショッピング バッグからウィッシュリストに移動してもよろしいですか',
                `¿هل لديك مشكلة في التنقل؟`]
        }, {
            text: 'Are you sure to moving the item (s) to wishlist from shopping bag ?',
            list: ['Êtes-vous sûr de déplacer le ou les articles vers la liste de souhaits du panier ?', 'Möchten Sie die Artikel aus dem Warenkorb in die Wunschliste verschieben?', '¿Está seguro de mover los artículos a la lista de deseos de la bolsa de compras?',
                'Вы уверены, что переместите товар(ы) в список желаний из корзины?', 'Tem certeza de que deseja mover o(s) item(ns) para a lista de desejos da sacola de compras?',
                'アイテムをショッピングバッグからウィッシュリストに移動しますか',
                `هل أنت متأكد من نقل العنصر (العناصر) إلى قائمة الرغبات من حقيبة التسوق؟`]
        }, {
            text: 'This item is Sold out',
            list: ['Cet article est en rupture de stock', 'Dieser Artikel ist ausverkauft', 'Este articulo esta agotado',
                'Этот товар распродан', 'Este item está esgotado', 'この商品は売り切れです',
                `ويباع هذا العنصر`]
        }, {
            text: 'Estimated To Be Delivered On',
            list: ['Estimation à livrer le', 'Voraussichtlich Lieferzeit', 'Entrega estimada el',
                'Ожидается к сдаче', 'Estimado para ser entregue em', '配達予定日',
                `من المتوقع أن يتم التسليم في`]
        }, {
            text: 'SHIPPING',
            list: ['EXPÉDITION', 'VERSENDET', 'ENVIANDO',
                'ПЕРЕВОЗКИ', 'ENVIO', '運送',
                `شحن`]
        }, {
            text: 'Guarantee',
            list: ['Garantie', 'Garantie', 'Garantizar',
                'Гарантия', 'Garantia', '保証',
                `يضمن`]
        }, {
            text: 'CMA Certified genuine goods',
            list: ['Produits authentiques certifiés CMA', 'CMA-zertifiziertes Original', 'Productos genuinos certificados por CMA',
                'Сертифицированный CMA подлинный товар', 'Produtos genuínos certificados pela CMA', 'CMA認定の正規品',
                `سلع أصلية معتمدة من هيئة أسواق المال`]
        }, {
            text: 'Platform certification genuine good goods',
            list: ['Plate-forme de certification des biens authentiques', 'Plattformzertifizierte echte gute Ware', 'Certificación de plataforma de bienes genuinos buenos',
                'Сертификация платформы подлинных хороших товаров', 'Certificação de plataforma produtos bons genuínos', 'プラットフォーム認証 正真正銘の良品',
                `شهادة منصة سلع جيدة حقيقية`]
        }, {
            text: 'Return and exchange',
            list: ['Retour et échange', 'Rückgabe und Umtausch', 'Devolución y cambio',
                'Возврат и обмен', 'Devolução e troca', '返品・交換',
                `العودة والتبادل`]
        }, {
            text: 'Free Shipping',
            list: ['Livraison gratuite', 'Kostenlose Lieferung', 'Envío gratis',
                'Бесплатная доставка', 'Frete grátis', '送料無料',
                `ًالشحن مجانا`]
        }, {
            text: 'Buy More Save More',
            list: ['Achetez plus, économisez plus', 'Kaufen Sie mehr, sparen Sie mehr', 'Compre más ahorre más',
                'Покупайте больше, экономьте больше', 'Compre mais economize mais', 'さらに購入してさらに節約',
                `اشتر أكثر ووفر أكثر`]
        }, {
            text: 'AR Watch',
            list: ['AR', 'AR', 'AR',
                'Часы с дополненной реальностью', 'Relógio RA', 'ARウォッチ',
                `ساعة AR`]
        }, {
            text: 'Goods',
            list: ['Des biens', 'Waren', 'Artículos',
                'Товары', 'Bens', '品',
                `بضائع`]
        }, {
            text: 'Customer Says',
            list: ['Le client dit', 'Stimme aus Kunden', 'El cliente dice',
                'Клиент говорит', 'O cliente diz', 'お客様の声',
                `يقول الزبون`]
        }, {
            text: 'Review Detail',
            list: [`Détail de l'examen`, 'Rezensionen Detail', 'Detalles de commentarios',
                'Подробности обзора', 'Detalhe da avaliação', 'レビューの詳細',
                `مراجعة التفاصيل`]
        }, {
            text: 'View Bag',
            list: ['Voir le panier', 'Tasche ansehen', 'Ver bolsa',
                'Посмотреть сумку', 'Ver bolsa', 'バッグを見る',
                `حقيبة عرض`]
        }, {
            text: 'Keep Shopping',
            list: ['Continuer vos achats', 'Shoppen Sie weiter', 'Seguir comprando',
                'Продолжайте делать покупки', 'Continue comprando', '買い物を続ける',
                `استمر في التسوق`]
        }, {
            text: 'You can also try with 2 or 3 friends',
            list: ['Vous pouvez aussi essayer avec 2 ou 3 amis', 'Sie können es auch mit 2 oder 3 Freunden versuchen', 'También puedes probar con 2 o 3 amigos.',
                'Вы также можете попробовать с 2 или 3 друзьями', 'Você também pode tentar com 2 ou 3 amigos', '2～3人で挑戦することもできます',
                `يمكنك أيضًا المحاولة مع 2 أو 3 من الأصدقاء`]
        }, {
            text: 'Share',
            list: ['Partager', 'Teilen', 'Compartir',
                'Делиться', 'Compartilhar', '共有',
                `يشارك`]
        }, {
            text: 'Share with your friends',
            list: ['Partage avec tes amis', 'Teile mit deinen Freunden', 'Comparte con tus amigos',
                'Поделись с друзьями', 'Compartilhe com os seus amigos', '友達と共有する',
                `شارك الموضوع مع أصدقائك`]
        }, {
            text: 'Save Successfully',
            list: ['Enregistrer avec succès', 'Erfolgreich gespeichert', 'Guardar con éxito',
                'Сохранить успешно', 'Salvo com sucesso', 'セーブに成功',
                `حفظ بنجاح`]
        }, {
            text: 'Use New card',
            list: ['Utiliser une nouvelle carte', 'Neue Karte verwenden', 'Usar nueva tarjeta',
                'Использовать новую карту', 'Usar novo cartão', '新しいカードを使う',
                `استخدم بطاقة جديدة`]
        }, {
            text: 'Please input CVV',
            list: ['Veuillez saisir CVV', 'Bitte CVV eingeben', 'Por favor ingrese CVV',
                'Пожалуйста, введите CVV', 'Insira CVV', 'CVVを入力してください',
                `الرجاء إدخال CVV`]
        }, {
            text: 'The 3 or 4 digit security code on the front or back of your credit card.',
            list: ['Le code de sécurité à 3 ou 4 chiffres au recto ou au verso de votre carte de crédit.', 'Der 3- oder 4-stellige Sicherheitscode auf der Vorder- oder Rückseite Ihrer Kreditkarte.', 'El código de seguridad de 3 o 4 dígitos en el anverso o reverso de su tarjeta de crédito.',
                '3- или 4-значный код безопасности на лицевой или оборотной стороне вашей кредитной карты.',
                'O código de segurança de 3 ou 4 dígitos na frente ou no verso do seu cartão de crédito.',
                'クレジット カードの表面または裏面に記載されている 3 桁または 4 桁のセキュリティ コード。',
                `رمز الحماية المكون من 3 أو 4 أرقام الموجود على الجزء الأمامي أو الخلفي من بطاقة الائتمان الخاصة بك.`]
        }, {
            text: 'Expiration date is invalid!',
            list: ['La date d’expiration n’est pas valide!', 'Ablaufdatum ist ungültig!', '¡La fecha de caducidad no es válida!',
                'Срок годности недействителен!', 'A data de validade é inválida!', '有効期限が無効です。',
                `تاريخ انتهاء الصلاحية غير صالح!`]
        }, {
            text: 'Popular',
            list: ['Populaire', 'Beliebt', 'Popular',
                'Популярный', 'Popular', '人気',
                `شائع`]
        }, {
            text: 'No Post Now',
            list: ['Pas de publication maintenant', 'Keine Beiträge jetzt', 'No publicar ahora',
                'Нет публикации сейчас', 'Nenhuma postagem agora', '現在投稿なし',
                `لا وظيفة الآن`]
        }, {
            text: `You haven't followed anyone yet`,
            list: [`Vous n'avez encore suivi personne`, 'Sie haben noch niemandem gefolgt', 'Aún no has seguido a nadie',
                'Вы еще ни на кого не подписаны', 'Você ainda não seguiu ninguém', 'まだ誰もフォローしていません',
                `لم تتبع أي شخص حتى الآن`]
        }, {
            text: 'Latest',
            list: ['Dernier', 'Neueste', 'Más reciente',
                'Последний', 'Mais recente', '最新',
                `أحدث`]
        }, {
            text: 'No more video',
            list: ['Plus de vidéo', 'Kein Video', 'No más vídeo',
                'Нет больше видео', 'Sem mais vídeo', 'もうビデオはありません',
                `لا مزيد من الفيديو`]
        }, {
            text: 'Swipe up to view more',
            list: ['Balayez vers le haut pour en voir plus', 'Wischen Sie nach oben, um mehr zu sehen', 'Desliza hacia arriba para ver más',
                'Проведите вверх, чтобы увидеть больше', 'Deslize para cima para ver mais', '上にスワイプして詳細を表示',
                `اسحب لأعلى لعرض المزيد`]
        }, {
            text: 'People you may also interested in',
            list: ['Personnes susceptibles de vous intéresser', 'Sie auch interessieren könnten', 'Personas que también te pueden interesar',
                'Люди, которых вы также можете заинтересовать', 'Pessoas que você também pode se interessar', 'あなたも興味があるかもしれない人々',
                `الأشخاص الذين قد تهتم بهم أيضًا`]
        }, {
            text: 'Publish Your first Post Now',
            list: ['Publiez votre premier message maintenant', 'Veröffentlichen Sie jetzt Ihren ersten Beitrag', 'Publica tu primera publicación ahora',
                'Опубликуйте свой первый пост сейчас', 'Publique sua primeira postagem agora', '最初の投稿を今すぐ公開する',
                `انشر رسالتك الأولى الآن`]
        }, {
            text: 'Have no likes yet',
            list: [`Je n'ai pas encore de likes`, 'Noch keine Likes', 'Aún no tiene GUSTA',
                'Еще нет лайков', 'ainda não tem curtidas', 'まだいいねがありません',
                `ليس لديك إعجابات حتى الآن`]
        }, {
            text: 'Have no collection yet',
            list: ['Pas encore de collection', 'Noch keine Sammlung', 'Aún no tienes colección',
                'У меня пока нет коллекции', 'Ainda não tem coleção', 'まだコレクションを持っていません',
                `ليس لديها مجموعة حتى الان`]
        }, {
            text: 'You have no fans yet',
            list: [`Vous n'avez pas encore de fans`, 'Sie haben noch keine Fans', 'Aún no tienes fans',
                'У вас еще нет поклонников', 'Você ainda não tem fãs', 'まだファンがいません',
                `ليس لديك معجبين بعد`]
        }, {
            text: 'Show',
            list: ['Afficher', 'Zeigen', 'Demostrar',
                'Показывать', 'Mostrar', '見せる',
                `يعرض`]
        }, {
            text: 'Say something',
            list: ['Dis quelque chose', 'Sag etwas', 'Di algo',
                'Скажите что-то', 'Say something', '何か言って',
                `Say something`]
        }, {
            text: 'Add Products',
            list: ['Ajouter des produits', 'Produkte hinzufügen', 'Añadir productos',
                'Добавить продукты', 'Adicionar produtos', '製品を追加',
                `أضف المنتجات`]
        }, {
            text: 'Are you sure to leave',
            list: ['Êtes-vous sûr de partir', 'Sind Sie sicher auszutreten', 'Estas seguro de irte',
                'Ты уверен, что уйдешь?', 'Tem certeza que vai embora?', '本当に出発しますか',
                `هل أنت متأكد من المغادرة`]
        }, {
            text: 'Exiting will empty all contests',
            list: ['Quitter videra tous les concours', 'Beim Austreten werden alle Contests gelöscht', 'Salir vaciará todos los concursos.',
                'Выход очистит все конкурсы', 'A saída esvaziará todos os concursos', '終了すると、すべてのコンテストが空になります',
                `سيؤدي الخروج إلى إفراغ جميع المسابقات`]
        }, {
            text: 'At least one picture',
            list: ['Au moins une image', 'Mindestens ein Bild', 'Al menos una foto',
                'Хотя бы одна картинка', 'Pelo menos uma foto', '少なくとも 1 枚の写真',
                `صورة واحدة على الأقل`]
        }, {
            text: 'Video is required',
            list: ['La vidéo est requise', 'Videos sind erforderlich', 'Se requiere video',
                'Требуется видео', 'O vídeo é obrigatório', 'ビデオが必要です',
                `الفيديو مطلوب`]
        }, {
            text: 'Uploading',
            list: ['Téléchargement', 'Hochladen', 'Cargando',
                'Загрузка', 'Enviando', 'アップロード中',
                `تحميل`]
        }, {
            text: 'Upload Failed',
            list: ['Échec du téléchargement', 'Upload fehlgeschlagen', 'Carga fallida',
                'Загрузка не удалась', 'Falha no envio', 'アップロードに失敗しました',
                `التحميل فشل`]
        }, {
            text: 'Contest Tags',
            list: ['Balises du concours', 'Contest-Tags', 'Etiquetas del concurso',
                'Теги конкурса', 'Etiquetas do Concurso', 'コンテストタグ',
                `علامات المسابقة`]
        }, {
            text: 'No More than 9 Tags',
            list: ['Pas plus de 9 balises', 'Nicht mehr als 9 Tags', 'No más de 9 etiquetas',
                'Не более 9 тегов', 'Não mais que 9 tags', 'タグは 9 つまで',
                `لا يزيد عن 9 بطاقات`]
        }, {
            text: 'Add Products',
            list: ['Ajouter des produits', 'Produkte hinzufügen', 'Añadir productos',
                'Добавить продукты', 'Adicionar produtos', '製品を追加',
                `أضف المنتجات`]
        }, {
            text: 'No More than 9 Products',
            list: ['Pas plus de 9 produits', 'Nicht mehr als 9 Produkte', 'No más de 9 productos',
                'Не более 9 продуктов', 'Não mais que 9 produtos', '9 個以下の製品',
                `لا يزيد عن 9 منتجات`]
        }, {
            text: 'Are you sure to delete this Post?',
            list: ['Êtes-vous sûr de vouloir supprimer ce message ?', 'Möchten Sie diesen Beitrag wirklich löschen?', '¿Estás seguro de eliminar esta publicación?',
                'Вы уверены, что хотите удалить это сообщение?', 'Tem certeza de que deseja excluir esta postagem?', 'この投稿を削除してもよろしいですか?',
                `هل أنت متأكد من حذف هذا المنشور؟`]
        }, {
            text: `it can't be retrieved after deleted`,
            list: ['Il ne peut pas être récupéré après avoir été supprimé', 'Es kann nach dem Löschen nicht abgerufen werden', 'no se puede recuperar después de eliminar',
                'его нельзя восстановить после удаления', 'não pode ser recuperado após excluído', '削除後は復元できません',
                `لا يمكن استرجاعها بعد حذفها`]
        }, {
            text: 'Are you sure to unfollow?',
            list: ['Êtes-vous sûr de ne plus suivre ?', 'Sind Sie sicher, dass Sie nicht mehr folgen?', '¿Estás seguro de dejar de seguir?',
                'Вы уверены, что хотите отписаться?', 'Tem certeza que deseja deixar de seguir?', 'フォローを外してもよろしいですか？',
                `هل أنت متأكد من إلغاء المتابعة؟`]
        }, {
            text: 'Comments',
            list: ['commentaires', 'Kommentar', 'Comentarios',
                'Комментарии', 'Comentários', 'コメント',
                `تعليقات`]
        }, {
            text: 'View replies',
            list: ['Afficher les réponses', 'Antworten anzeigen', 'Ver respuestas',
                'Посмотреть ответы', 'Ver respostas', '返信を表示',
                `عرض الردود`]
        }, {
            text: 'Put more',
            list: ['Mettre plus', 'Legen Sie mehr', 'Pon mas',
                'Положите больше', 'Coloque mais', 'もっと入れて',
                `اضف المزيد`]
        }, {
            text: 'Reply Comment',
            list: ['Répondre Commentaire', 'Kommentar antworten', 'Responder comentario',
                'Ответить Комментарий', 'Responder comentário', '返信コメント',
                `رد التعليق`]
        }, {
            text: 'Copy Comment',
            list: ['Copier le commentaire', 'Kommentar kopieren', 'Copiar comentario',
                'Копировать комментарий', 'Copiar comentário', 'コメントをコピー',
                `انسخ التعليق`]
        }, {
            text: 'Report Comment',
            list: ['Signaler un commentaire', 'Kommentar melden', 'Reportar Comentario',
                'Сообщить о комментарии', 'Comentário do relatório', 'コメントを報告',
                `الإبلاغ عن التعليق`]
        }, {
            text: 'Report',
            list: ['Signaler', 'Bericht', 'Reporte',
                'Отчет', 'Relatório', '報告',
                `تقرير`]
        }, {
            text: 'Select the reason for reporting',
            list: ['Sélectionnez la raison du signalement', 'Wählen Sie den Grund für die Meldung aus', 'Seleccione el motivo de la denuncia',
                'Выберите причину сообщения', 'Selecione o motivo da denúncia', '報告の理由を選択してください',
                `حدد سبب الإبلاغ`]
        }, {
            text: 'The report was successful. Thank you for your feedback. We will verify as soon as possible.',
            list: ['Le rapport a été un succès. Merci pour votre avis. Nous vérifierons dès que possible.', 'Erfolg melden. Danke für deine Rückmeldung. Wir werden dies so schnell wie möglich überprüfen.', 'El informe fue exitoso. Gracias por tus comentarios. Lo verificaremos lo antes posible.',
                'Доклад прошел успешно. Спасибо за ваш отзыв. Мы проверим как можно скорее.',
                'O relatório foi bem sucedido. Obrigado pelo seu feedback. Iremos verificar o mais breve possível.',
                'レポートは成功しました。ご意見ありがとうございます。できるだけ早く確認いたします',
                `كان التقرير ناجحًا. شكرا لك على ملاحظاتك. سوف نتحقق في أقرب وقت ممكن.`]
        }, {
            text: 'Contains nudity or erotic messages',
            list: ['Contient de la nudité ou des messages érotiques', 'Enthält Nacktheit oder erotische Botschaften', 'Contiene desnudos o mensajes eróticos.',
                'Содержит наготу или эротические сообщения', 'Contém nudez ou mensagens eróticas', 'ヌードまたはエロティックなメッセージが含まれています',
                `يحتوي على عري أو رسائل جنسية`]
        }, {
            text: 'Contains advertisement',
            list: ['Contient de la publicité', 'Enthält Werbung', 'Contiene publicidad',
                'Содержит рекламу', 'Contém anúncio', '広告が含まれています',
                `يحتوي على إعلان`]
        }, {
            text: 'Contains personal information',
            list: ['Contient des informations personnelles', 'Enthält persönliche Informationen', 'Contiene información personal',
                'Содержит личную информацию', 'Contém informações pessoais', '個人情報が含まれています',
                `يحتوي على معلومات شخصية`]
        }, {
            text: 'Harmful information involving minors',
            list: ['Informations préjudiciables impliquant des mineurs', 'Schädliche Informationen, die Minderjährige betreffen', 'Información nociva que involucre a menores',
                'Вредоносная информация о несовершеннолетних', 'Informações prejudiciais envolvendo menores', '未成年に有害な情報',
                `معلومات ضارة تتعلق بالقصر`]
        }, {
            text: 'Other violations',
            list: ['Autres infractions', 'Andere Verstöße', 'Otras violaciones',
                'Другие нарушения', 'Outras violações', 'その他の違反',
                `انتهاكات أخرى`]
        }, {
            text: 'Have no bio yet',
            list: [`Je n'ai pas encore de biographie`, 'Habe noch keine Profil', 'Aún no tengo biografía',
                'У меня пока нет биографии', 'Ainda não tem biografia', 'バイオはまだありません',
                `ليس لديك السيرة الذاتية حتى الآن`]
        }, {
            text: 'Trending Looks',
            list: ['Looks tendance', 'Trendige Aussehen', 'Looks de tendencia',
                'Трендовые образы', 'Looks em alta', 'トレンドのルックス',
                `تبدو رائجة`]
        }, {
            text: 'Pull down to refresh',
            list: ['Déroulez pour rafraîchir', 'Zum Auffrischen nach unten ziehen', 'Tire hacia abajo para refrescar',
                'Потяните вниз, чтобы обновить', 'Puxe para baixo para atualizar', 'プルダウンして更新',
                `للتحديث اسحب للأسفل`]
        }, {
            text: 'Refreshing',
            list: ['Rafraîchissant', 'Erneuern', 'Refrescante',
                'Освежающий', 'Refrescante', 'さわやか',
                `منعش`]
        }, {
            text: 'Loading',
            list: ['Chargement', 'Wird geladen', 'Cargando',
                'Загрузка', 'Carregando', '読み込み中',
                `تحميل`]
        }, {
            text: 'Release Refresh now',
            list: ['Publier Actualiser maintenant', 'Refresh jetzt freigeben', 'Liberar Actualizar ahora',
                'Выпустить Обновить сейчас', 'Liberar atualização agora', '今すぐリフレッシュをリリース',
                `حرر التحديث الآن`]
        }, {
            text: 'Refresh to complete',
            list: ['Actualiser pour terminer', 'Aktualisierung fertig', 'Actualizar para completar',
                'Обновить, чтобы завершить', 'Atualizar para concluir', '更新して完了 ',
                `قم بالتحديث للإكمال`]
        }, {
            text: 'Refresh the failure',
            list: [`Rafraîchir l'échec`, 'Aktualisierung falsch', 'Actualizar el fracaso',
                'Обновить ошибку', 'Atualize a falha', '失敗をリフレッシュ',
                `قم بتحديث الفشل`]
        }, {
            text: 'Last update',
            list: ['Dernière mise à jour', 'Letztes Update', 'Última actualización',
                'Последнее обновление', 'Última atualização', '最後の更新',
                `اخر تحديث`]
        }, {
            text: 'Release into the second floor',
            list: ['Relâchez au deuxième étage', 'Geben Sie in den zweiten Stock frei', 'Suelte en el segundo piso',
                'Выход на второй этаж', 'Solte no segundo andar', '二階に放つ',
                `أطلق سراحه في الطابق الثاني`]
        }, {
            text: 'Load Failed',
            list: ['Chargement raté', 'Laden fehlgeschlagen', 'Carga fallida',
                'Загрузка не удалась', 'Erro de carregamento', '読み込みに失敗しました',
                `فشل التحميل`]
        }, {
            text: 'Load Success',
            list: ['Chargement réussi', 'Laden erfolgreich', 'Carga exitosa',
                'Загрузить успешно', 'Carregar com sucesso', '読み込み成功',
                `نجاح التحميل`]
        }, {
            text: 'No more commodity',
            list: ['Plus de marchandise', 'Keine Ware mehr', 'No más mercancía',
                'Нет больше товара', 'Não há mais mercadoria', 'もう商品はありません',
                `لا مزيد من السلع`]
        }, {
            text: 'Pull Up To Load More',
            list: ['Tirez vers le haut pour charger plus', 'Nach oben ziehen, um mehr zu laden', 'Tire hacia arriba para cargar más',
                'Потяните вверх, чтобы загрузить больше', 'Puxe para cima para carregar mais', 'プルアップしてさらにロード',
                `اسحب لتحميل المزيد`]
        }, {
            text: 'Wait For Refreshing',
            list: ['Attendez le rafraîchissement', 'Warten Sie auf die Aktualisierung', 'Espere a que se actualice',
                'Подождите обновления', 'Aguarde a atualização', '更新を待つ',
                `انتظر الانتعاش`]
        }, {
            text: 'Release To Load More',
            list: ['Relâcher pour charger plus', 'Lassen Sie los, um mehr zu laden', 'Suelte para cargar más',
                'Отпустите, чтобы загрузить больше', 'Solte para carregar mais', 'リリースしてさらに読み込む',
                `حرر لتحميل المزيد`]
        }, {
            text: 'load more failed',
            list: ['charger plus a échoué', 'mehr laden fehlgeschlagen', 'Cargar más fallido',
                'загрузить больше не удалось', 'carregar mais falhou', 'さらに読み込みに失敗しました',
                `تحميل المزيد فشل`]
        }, {
            text: 'Click to load more',
            list: ['Cliquez pour charger plus', 'Klicken Sie hier, um mehr zu laden', 'Haga clic para cargar más',
                'Нажмите, чтобы загрузить больше', 'Clique para carregar mais', 'クリックしてさらに読み込む',
                `انقر لتحميل المزيد`]
        }, {
            text: 'Sure',
            list: ['Bien sûr', 'Sicher', 'Por supuesto',
                'Конечно', 'Claro', 'もちろん',
                `بالتأكيد`]
        }, {
            text: 'Back',
            list: ['Retour', 'Zurück', 'atrás',
                'Назад', 'Voltar', '戻る',
                `خلف`]
        }, {
            text: 'Open',
            list: ['Ouvert', 'Offen', 'Abrir',
                'Открыть', 'Abrir', '開ける',
                `يفتح`]
        }, {
            text: 'Install',
            list: ['Installer', 'Installieren', 'Instalar',
                'Установить', 'Instalar', 'インストール',
                `ثَبَّتَ`]
        }, {
            text: 'ReferenceError',
            list: ['Erreur de référence', 'Referenzfehler', 'Error de referencia',
                'ReferenceError', 'ReferenceError', '参照エラー',
                `المرجع خطأ`]
        }, {
            text: 'No network',
            list: ['Pas de réseau', 'Kein Netzwerk', 'Sin red',
                'Нет сети', 'sem rede', '圏外',
                `لا توجد شبكة`]
        }, {
            text: 'CLICK RETRY',
            list: ['CLIQUEZ SUR RÉESSAYER', 'Klicken Sie, um es erneut zu versuchen', 'HAGA CLIC EN REINTENTAR',
                'НАЖМИТЕ ПОВТОРИТЬ', 'CLIQUE EM TENTAR NOVAMENTE', '再試行をクリック',
                `انقر فوق إعادة المحاولة`]
        }, {
            text: 'View All',
            list: ['Voir tout', 'Alle ansehen', 'Ver todo',
                'Посмотреть все', 'Ver tudo', 'すべて見る',
                `مشاهدة الكل`]
        }, {
            text: 'Move to wishlist successful',
            list: ['Déplacement vers la liste de souhaits réussi', 'Zur Wunschliste verschieben erfolgreich', 'Mover a la lista de deseos exitoso',
                'Переместить в список желаний успешно', 'Mover para a lista de desejos com sucesso', 'ウィッシュリストに移動しました',
                `انتقل إلى قائمة الرغبات بنجاح`]
        }, {
            text: 'Start in',
            list: ['Commencez dans', 'Beginnen mit', 'Empezar en',
                'Начинать', 'Começar', 'で開始',
                `تبدأ في`]
        }, {
            text: 'Copied',
            list: ['Copié', 'Kopiert', 'Copiado',
                'Скопировано', 'Copiado', 'コピーしました',
                `نسخ`]
        }, {
            text: 'Copied to clipboard',
            list: ['Copié dans le presse-papier', 'In die Zwischenablage kopiert', 'Copiado al portapapeles',
                'Скопировано в буфер обмена', 'Copiado para a área de transferência', 'クリップボードにコピーしました',
                `نسخ إلى الحافظة`]
        }, {
            text: 'Zip',
            list: ['Zipper', 'Kompression', 'Cremallera',
                'Почтовый индекс', 'Fecho eclair', 'ジップ',
                `أَزِيز`]
        }, {
            text: 'Payment',
            list: ['Paiement', 'Zahlung', 'Pago',
                'Оплата', 'Pagamento', '支払い',
                `قسط`]
        }, {
            text: 'Apply',
            list: ['Appliquer', 'Bewerben', 'Aplicar',
                'Применять', 'Aplicar', '申し込み',
                `يتقدم`]
        }, {
            text: 'Remove',
            list: ['Retirer', 'Löschen', 'Remover',
                'Удалять', 'Remover', '削除',
                `يزيل`]
        }, {
            text: 'view less',
            list: ['voir moins', 'Weniger sehen', 'ver menos',
                'смотреть меньше', 'ver menos', '表示を減らす',
                `عرض أقل`]
        }, {
            text: 'No Thanks',
            list: ['Non Merci', 'Nein Danke', 'No,gracias',
                'Нет, спасибо', 'Não, obrigado', '結構です',
                `ًلا شكرا`]
        }, {
            text: 'Share the failure',
            list: ['Partager l’échec', 'Teilen fehlgeschlagen', 'Comparte el fracaso',
                'Поделитесь неудачей', 'Compartilhe o fracasso', '失敗を共有する',
                `شارك الفشل`]
        }, {
            text: 'Be the first one to Say something',
            list: ['Soyez le premier à dire quelque chose', 'Sei der Erste, der etwas sagt', 'Sé el primero en decir algo',
                'Будьте первым, кто скажет что-нибудь', 'Seja o primeiro a dizer alguma coisa', '最初に何かを言う人になりましょう',
                `كن أول من يقول شيئا`]
        }, {
            text: 'Failed, please try again',
            list: ['Echec, veuillez réessayer', 'Fehlgeschlagen, bitte versuchen Sie es erneut', 'Error, por favor inténtalo de nuevo',
                'Не удалось, попробуйте еще раз', 'Falha, tente novamente', '失敗しました。もう一度お試しください',
                `فشل ، يرجى المحاولة مرة أخرى`]
        }, {
            text: 'Retry again',
            list: ['Réessayez', 'Versuchen Sie es nochmal', 'Reintentar de nuevo',
                'Повторите попытку', 'Tente novamente', '再試行',
                `أعد المحاولة`]
        }, {
            text: 'Payment',
            list: ['Paiement', 'Zahlung', 'Pago',
                'Оплата', 'Pagamento', '支払い',
                `قسط`]
        }, {
            text: 'View Order Detail',
            list: ['Afficher le détail de la commande', 'Bestelldetails anzeigen', 'Ver detalles del pedido',
                'Посмотреть детали заказа', 'Ver detalhes do pedido', '注文の詳細を表示',
                `عرض تفاصيل الطلب`]
        }, {
            text: 'out of stock',
            list: ['En rupture de stock', 'ausverkauft', 'Agotado',
                'распродано', 'fora de estoque', '在庫切れ',
                `إنتهى من المخزن`]
        }, {
            text: 'Please select product properties',
            list: ['Veuillez sélectionner les propriétés du produit', 'Bitte Produkteigenschaften auswählen', 'Por favor seleccione las propiedades del producto',
                'Пожалуйста, выберите свойства продукта', 'Selecione as propriedades do produto', '製品特性を選択してください',
                `الرجاء تحديد خصائص المنتج`]
        }, {
            text: 'Please check the product quantity',
            list: ['Veuillez vérifier la quantité de produit', 'Bitte überprüfen Sie die Produktmenge', 'Por favor, compruebe la cantidad del producto.',
                'Пожалуйста, проверьте количество товара',
                'Por favor, verifique a quantidade do produto',
                '商品の数量をご確認ください',
                `يرجى التحقق من كمية المنتج`]
        }, {
            text: 'Unfollow',
            list: ['Ne plus suivre', 'Entfolgen', 'Unfollow',
                'Отписаться', 'Deixar de seguir', 'フォローをやめる',
                `الغاء المتابعة`]
        }, {
            text: 'Report',
            list: ['Signaler', 'Bericht', 'Reporte',
                'Отчет', 'Relatório', '報告',
                `تقرير`]
        }, {
            text: 'Shipping to',
            list: ['Livrer à', 'Versand nach', 'Envío a',
                'Доставка', 'Enviando para', 'への配送',
                `يشحن إلى`]
        }, {
            text: '60-Day Return & Refund',
            list: ['Retour & remboursement sous 60 Jours', '60-tägige Rückgabe&Rückerstattung', 'Devolución y Reembolso de 60 Días',
                '60-дневный возврат и возмещение', 'Devolução e reembolso de 60 dias', '60日間の返品と返金',
                `60 يومًا للإرجاع والاسترداد`]
        }, {
            text: 'on order over',
            list: ['commande terminée', 'von Bestellung über', 'en pedidos de',
                'под заказ', 'sob encomenda', '注文中',
                `عند الطلب`]
        }, {
            text: 'Description',
            list: ['Description', 'Beschreibung', 'Descripción',
                'Описание', 'Descrição', '説明',
                `وصف`]
        }, {
            text: 'FAQ',
            list: ['FAQ', 'FAQ', 'FAQ', '', '', '',
                `التعليمات`]
        }, {
            text: 'How to order on YFN.COM?',
            list: ['Comment commander sur yfn.com', 'So bestellen Sie auf YFN.COM', 'Cómo pedir en yfn.com',
                'Как сделать заказ на YFN.COM?', 'Como encomendar em YFN.COM?', 'YFN.COMで注文するには？',
                `كيف تطلب على YFN.COM؟`]
        }, {
            text: `It\'s easy and simple. Kindly follow the steps below to place an order: Select and add items into[Shopping bag]→Check out and add your shipping address→Confirm the order details→Pay order successfully`,
            list: ['', '', '',
                '', '', '',
                ``]
        }, {
            text: 'How can l track the shipping information?',
            list: [`Comment puis - je suivre les informations d'expédition?`, 'Wie kann ich die Versandinformationen verfolgen?', `¿Cómo puedo rastrear la información de envío?`]
        }, {
            text: `Just go to your Account->Shipped to find your order. After clicking"Order Track", you will see the tracking No., shipping company and the progress. And also We will inform you as soon as there is progress on your order by email.`,
            list: ['', '', '',
                '', '', '',
                ``]
        }, {
            text: 'What is the Return Policy?',
            list: ['Quelle est la politique de retour?', 'Was ist die Rückkehrpolitik?', '¿Cuál es la política de devolución?',
                '', '', '',
                ``]
        }, {
            text: 'You have 60 days to make a return request after your order has been received. For more details, please click',
            list: ['', '', '',
                '', '', '',
                ``]
        }, {
            text: 'Will your jewelry be rusted or faded?',
            list: [`Vos bijoux rouillent - ils ou s'estompent - ils?`, 'Wird Ihr Schmuck verrostet oder verblasst sein?', 'Suas jóias estarão enferrujadas ou desbotadas?',
                '', '', '',
                ``]
        }, {
            text: `We made the items with 925 sterling silver or real gold, from the pendants to the chains and other accessories. All of them will have 18K yellow gold, rose gold, or white gold plated on the surface. As a result, they won’t be fade or tarnish easily and they won’t let your skin be green. But you’d better keep them from soaking in water or sweat for a long time, as well as any cosmetics that contain chemicals. `,
            list: ['', '', '',
                '', '', '',
                ``]
        }, {
            text: `What’s 925 silver?`,
            list: [`Combien coûte 925 pièces d'argent?`, 'Was ist 925 Silber?', '¿Cuál es 925 monedas de plata?',
                'Что такое серебро 925 пробы?', 'O que é prata 925?', 'シルバー925とは？',
                `ما هو 925 الفضة؟`]
        }, {
            text: `In simple terms, 925 silver is a kind of alloy which have 92.5% purity of silver in it. Pure silver, which contains 99.9% elemental silver (Ag), is naturally very soft, and would easily bend or break if used for jewelry without being blended with a stronger metal. So sterling silver (925)，Which contains one or more additional metals that add strength to the otherwise delicate material is more widely used in functional silver jewelry. In YFN, we fortify pure silver with copper into 92.5% silver in making our jewels. Unlike adding durability with alloys of zinc or nickel, sterling silver fortified with copper is more friendly for jewelry lovers with sensitive skin. It is one of the important factors that we can capture so many followers and keep on ploughing in this industry for more than 10 years.`,
            list: ['', '', '',
                '', '', '',
                ``]
        }, {
            text: 'Copy',
            list: ['Copier', 'kopieren', 'Copiar',
                'Копировать', 'cópia de', 'コピー',
                `ينسخ`]
        }, {
            text: 'You May Also Like',
            list: ['Vous aimerez aussi', 'Sie können auch mögen', 'También te puede interesar',
                'Вам также может понравиться', 'Você pode gostar', 'あなたも好きかも',
                `ربما يعجبك أيضا`]
        }, {
            text: 'Safe and Secure Payment',
            list: ['Paiement Sûr et Sécurisé', 'sichere Zahlung', 'Pago seguro y protegido',
                'Безопасная и надежная оплата', 'Pagamento Seguro e Seguro', '安心・安全なお支払い',
                `دفع آمن ومضمون`]
        }, {
            text: '60-Day Return Guarantee',
            list: ['Garantie de Retour de 60 Jours', '60-tägige Rückgabegarantie', 'Garantía de devolución de 60 días',
                '60-дневная гарантия возврата', 'Garantia de devolução de 60 dias', '60日間の返品保証',
                `ضمان الإرجاع لمدة 60 يومًا`]
        }, {
            text: 'Worldwide Free Standard Shipping',
            list: ['Livraison Standard Gratuite dans le Monde Entier', 'Weltweiter Kostenloser Standardversand', 'Envío estándar gratuito en todo el mundo',
                'Бесплатная стандартная доставка по всему миру', 'Envio padrão gratuito para todo o mundo', '世界中の無料標準配送',
                `الشحن القياسي المجاني في جميع أنحاء العالم`]
        }, {
            text: '24Hrs Online Service',
            list: ['Service en ligne 24h', '24-Stunde Online-Service', 'Servicio en línea las 24 horas',
                '24-часовой онлайн-сервис', 'Atendimento on-line 24 horas', '24時間オンラインサービス',
                `خدمة عبر الإنترنت على مدار 24 ساعة`]
        }, {
            text: 'FIND US ON',
            list: ['NOUS TROUVEZ DANS', 'FINDEN SIE UNS AUF', 'ENCUÉNTRANOS EN',
                'НАЙТИ НАС НА', 'ENCONTRAR-NOS NO', 'で私たちをみつけて',
                `تجدنا على`]
        }, {
            text: 'Download YFN app now',
            list: [`Téléchargez l'application YFN maintenant`, 'YFN-App jetzt herunterladen', 'Descarga la aplicación YFN ahora',
                'Загрузите приложение YFN прямо сейчас', 'Baixe o aplicativo YFN agora', 'YFNアプリを今すぐダウンロード',
                `قم بتنزيل تطبيق YFN الآن`]
        }, {
            text: 'Shipping Info',
            list: [`information d'expédition`, 'Versandinformationen', 'Datos de envío',
                'Информация о доставке', 'Informação de Envio', '配送情報',
                `معلومات الشحن`]
        }, {
            text: 'Privacy Policy',
            list: ['Politique de confidentialité', 'Datenschutzerklärung', 'Política de privacidad',
                'политика конфиденциальности', 'política de Privacidade', 'プライバシーポリシー',
                `سياسة الخصوصية`]
        }, {
            text: 'YFN Blog',
            list: ['YFN Blog', 'YFN-Blog', 'Blog de YFN',
                'Блог YFN', 'Blog da YFN', 'YFN ブログ',
                `مدونة YFN`]
        }, {
            text: 'About Us',
            list: ['À propos de nous', 'Über uns', 'Sobre Nosotros',
                'О нас', 'Sobre nós', '私たちに関しては',
                `معلومات عنا`]
        }, {
            text: 'All Rights Reserved',
            list: ['Tous droits réservés', 'Alle Rechte vorbehalten', 'Todos los derechos reservados',
                'Все права защищены', 'Todos os direitos reservados', '全著作権所有',
                `كل الحقوق محفوظة`]
        }, {
            text: 'No result found',
            list: ['Aucun résultat trouvé', 'Keine Suchergebnisse', 'No se han encontrado resultados',
                'Результатов не найдено', 'nenhum resultado encontrado', '結果が見つかりません',
                `لم يتم العثور على نتائج`]
        }, {
            text: 'Track Orders',
            list: ['Suivre les Commandes', 'Bestellungen verfolgen', 'Seguimiento de pedidos',
                'Отслеживание заказов', 'Rastreie Pedidos', '注文を追跡する',
                `تتبع الطلبات`]
        }, {
            text: 'Track Order',
            list: ['Suivre les Commande', 'Bestellungen verfolgen', 'Seguimiento de pedido',
                'Отследить заказ', 'Acompanhar Pedido', '注文の追跡',
                `Track Order`]
        }, {
            text: 'Ship to',
            list: ['Livrer à', 'Versenden nach', 'Enviar a',
                'Корабль', 'Enviar para', '送り先',
                `سافر على متن سفينة لِـ`]
        }, {
            text: 'English',
            list: ['Anglais', 'Englisch', 'Inglés',
                'Английский', 'Inglês', '英語',
                `إنجليزي`]
        }, {
            text: 'Manage your addresses',
            list: ['Gérez vos adresses', 'Verwalten Sie Ihre Adressen', 'Administra tus direcciones',
                'Управляйте своими адресами', 'Gerencie seus endereços', '住所を管理する',
                `إدارة عناوينك`]
        }, {
            text: 'Help Center',
            list: [`Centre d'Aide`, 'Hilfe-Center', 'Centro de ayuda',
                'Центр помощи', 'Centro de ajuda', 'ヘルプセンター',
                `مركز المساعدة`]
        }, {
            text: 'Influencer Program',
            list: [`Programme d'Influence`, 'Influencer-Programm', 'Programa de Influenciadores',
                'Программа влияния', 'Programa de influenciadores', 'インフルエンサー プログラム',
                `برنامج المؤثر`]
        }, {
            text: 'Use Now',
            list: ['Utiliser Maintenant', 'Jetzt verwenden', 'Usar ahora',
                'Использовать сейчас', 'Use agora', '今すぐ使用',
                `استخدم الان`]
        }, {
            text: 'No Data',
            list: ['Pas de données', 'Keine Daten', 'Sin datos',
                'Нет данных', 'sem dados', 'データなし',
                `لايوجد بيانات`]
        }, {
            text: 'How to get a coupon ?',
            list: ['Comment obtenir un coupon ?', 'Wie bekomme ich einen Coupon?', '¿Cómo obtener un cupón?',
                'Как получить купон?', 'Como obter um cupom?', 'クーポンを取得するには？',
                `كيف تحصل على قسيمة؟`]
        }, {
            text: 'Successfully Added',
            list: ['Ajouté avec succès', 'Erfolgreich hinzugefügt', 'Añadida exitosamente',
                'Успешно добавлено', 'Adicionado com sucesso', '正常に追加されました',
                `أضيف بنجاح`]
        }, {
            text: 'Dear customer, if you want to cancel this order(s), please email to: service@yfn.com',
            list: [`Cher client, si vous voulez annuler cette commande (ces commandes), veuillez envoyer un e-mail à : service@yfn.com`, 'Sehr geehrter Kunde, wenn Sie diese Bestellung(en) stornieren möchten, senden Sie bitte eine E-Mail an: service@yfn.com', 'Estimado cliente, si desea cancelar este(s) pedido(s), envíe un correo electrónico a:service@yfn.com',
                'Уважаемый клиент, если вы хотите отменить этот заказ (заказы), пожалуйста, напишите по адресу: service@yfn.com',
                'Prezado cliente, se desejar cancelar este(s) pedido(s), envie um e-mail para: service@yfn.com',
                '親愛なるお客様、この注文をキャンセルしたい場合は、service@yfn.com にメールしてください。',
                `عزيزي العميل ، إذا كنت ترغب في إلغاء هذا الطلب (الطلبات) ، يرجى إرسال بريد إلكتروني إلى: service@yfn.com`]
        }, {
            text: 'Reordered by mistake',
            list: ['Recommandé par erreur', 'Kauf wiederholen', 'Reordenado por error',
                'أعيد ترتيبها عن طريق الخطأПереупорядочено по ошибке', 'Reordenado por engano', '間違えて再注文',
                ``]
        }, {
            text: 'The shipping fee is NOT acceptable',
            list: [`Le frais de livraison n'est pas  acceptable`, 'Die Versandkosten sind NICHT akzeptabel', 'La tarifa de envío NO es aceptable',
                'Стоимость доставки НЕ приемлема', 'A taxa de envio NÃO é aceitável', '配送料は受け入れられません',
                `رسوم الشحن غير مقبولة`]
        }, {
            text: `Wrongly ordered.  I'm going to place a new order`,
            list: ['Mal commandé. je vais passer une nouvelle commande', 'Falsch bestellt. Ich werde eine neue Bestellung aufgeben', 'Pedido equivocado. Voy a hacer un nuevo pedido',
                'Неправильно заказал. Я собираюсь разместить новый заказ', 'Ordenado errado. vou fazer um novo pedido', '注文間違い。新しい注文をするつもりです',
                `أمر خاطئ. سأقوم بتقديم طلب جديد`]
        }, {
            text: 'Payment was rejected',
            list: ['Le paiement a été refusé', 'Zahlung abgelehnt', 'El pago fue rechazado',
                'Платеж был отклонен', 'O pagamento foi rejeitado', '支払いが拒否されました',
                `تم رفض الدفع`]
        }, {
            text: 'I forgot my debit/credit card information',
            list: [`J'ai oublié mes informations de carte de débit/crédit`, 'Ich habe meine Debit-/Kreditkartendaten vergessen', 'Olvidé la información de mi tarjeta de débito/crédito',
                'Я забыл информацию о своей дебетовой/кредитной карте', 'Esqueci as informações do meu cartão de débito/crédito', 'デビット/クレジットカード情報を忘れた',
                `لقد نسيت معلومات بطاقة الخصم / الائتمان الخاصة بي`]
        }, {
            text: 'Bad reviews',
            list: ['Mauvaises critiques', 'Schlechte Rezensionen', 'Malos comentarios',
                'Плохие отзывы', 'Comentários ruins', '悪いレビュー',
                `مراجعات سيئة`]
        }, {
            text: `Don't want it`,
            list: ['Je ne le veux pas', 'Ich möchte es nicht', 'No quiero',
                'Не хочу этого', 'não quero isso', 'いらない',
                `لا تريد ذلك`]
        }, {
            text: 'Cheaper alternative available',
            list: ['Alternative moins chère disponible', 'billigere Alternativen verfügbar', 'Alternativa más barata disponible',
                'Доступна более дешевая альтернатива', 'Alternativa mais barata disponível', 'より安価な代替品が利用可能',
                `بديل أرخص متاح`]
        }, {
            text: 'Confirm receipt',
            list: ['Confirmer la réception', 'Rechnung bestätigen', 'Confirmar recibo',
                'Подтвердить получение', 'Confirme o recebimento', '領収書の確認',
                `تأكيد استلام`]
        }, {
            text: 'Tracking No',
            list: ['Numéro de Suivi', 'Tracking-Nr.', 'Número de seguimiento',
                'Номер отслеживания', 'Número de Rastreamento', '追跡番号',
                `تتبع أي`]
        }, {
            text: 'Shipping company',
            list: ['Transporteur', 'Versandunternehmen', 'Compañía de envios',
                'Транспортная компания', 'Transportadora', '運送会社',
                `شركة شحن`]
        }, {
            text: 'Tracking information',
            list: ['Information de suivi', 'Tracking-Informationen', 'Tracking information',
                'Информация об отслеживании', 'informações de rastreamento', '追跡情報',
                `معلومات التتبع`]
        }, {
            text: 'City',
            list: ['Ville', 'Stadt', 'Ciudad',
                'Город', 'Cidade', '街',
                `مدينة`]
        }, {
            text: 'City/Town',
            list: ['Ville', 'Stadt/Gemeinde', 'Ciudad/Pueblo',
                'Город/Город', 'Cidade', '市区町村',
                `المدينة / البلدة`]
        }, {
            text: 'In case we need to contact you about your order',
            list: ['Si nous avons besoin de contacter votre commande', 'Falls wir Sie bezüglich Ihrer Bestellung kontaktieren müssen', 'Si necesitamos contactar con su pedido',
                'В случае, если нам нужно связаться с вами по поводу вашего заказа', 'Caso precisemos entrar em contato com você sobre seu pedido', 'ご注文内容についてご連絡が必要な場合',
                `في حال احتجنا للتواصل معك بخصوص طلبك`]
        }, {
            text: 'If you want to switch to another country, please go to your personal certer and make the change there',
            list: ['Si vous voulez changer de pays, veuillez vous rendre sur votre certificat personnel et y effectuer le changement.', 'Wenn Sie ein anderes Land wechseln möchten, gehen Sie zu Ihrem persönliches Center und ändern Sie es dort.', 'Si desea cambiar a otro país, vaya a su certificado personal y realice el cambio allí.',
                'Если вы хотите переключиться на другую страну, перейдите в свой личный центр и внесите изменения там.',
                'Se você deseja mudar para outro país, acesse seu certificado pessoal e faça a alteração lá.',
                '別の国に切り替えたい場合は、個人証明書にアクセスして、そこで変更してください。',
                `إذا كنت ترغب في التحول إلى بلد آخر ، فالرجاء الانتقال إلى رسالتك الشخصية وإجراء التغيير هناك.`]
        }, {
            text: 'Use this Address',
            list: ['Utiliser  cette adresse', 'Verwenden diese Adresse', 'Usa esta dirección',
                'Использовать этот адрес', 'Use esse endereço', 'この住所を使う',
                `استخدم هذا العنوان`]
        }, {
            text: 'Please add your first name',
            list: ['Veuillez Ajoutez votre prénom', 'Bitte geben Sie Ihren Vornamen an', 'Agregue su primer nombre, por favor ',
                'Пожалуйста, добавьте свое имя', 'Por favor, adicione seu primeiro nome', 'お名前を追加してください',
                `الرجاء إضافة اسمك الأول`]
        }, {
            text: 'First Name Should be 2-20 letters (Spaces count).',
            list: ['prénom doit être composé de 2 à 34 lettres(espace est compté)', 'Vorname sollte aus 2-20 Buchstaben bestehen (Leerzeichen zählen).', 'Nombre Debe tener de 2 a 20 letras (los espacios cuentan).',
                'Имя Должно состоять из 2-20 букв (с учетом пробелов).', 'O primeiro nome deve ter de 2 a 20 letras (contagem de espaços).', '名は 2 ～ 20 文字にする必要があります (スペースは数えます)。',
                `يجب أن يتكون الاسم الأول من حرفين إلى 20 حرفًا (عدد المسافات).`]
        }, {
            text: 'Please add your phone number',
            list: ['Veuillez ajouter votre numéro de téléphone', 'Bitte fügen Sie Ihre Telefonnummer hinzu', 'Añade tu número de teléfono, por favor ',
                'Пожалуйста, добавьте свой номер телефона', 'Por favor, adicione seu número de telefone', '電話番号を追加してください',
                `الرجاء إضافة رقم هاتفك`]
        }, {
            text: 'Please add your email address',
            list: ['Veuillez ajouter votre adresse  e-mail', 'Bitte fügen Sie Ihre E-Mail-Adresse hinzu', 'Añade tu dirección de correo electrónico, por favor ',
                'Пожалуйста, добавьте свой адрес электронной почты', 'Por favor, adicione seu endereço de e-mail', 'メールアドレスを追加してください',
                `الرجاء إضافة عنوان البريد الإلكتروني الخاص بك`]
        }, {
            text: 'Address line 1 should contain 5-30 charactors. Letters, digits and spaces will be all counted.',
            list: [`La ligne d'adresse 1 doit contenir entre 5 et 30 caractères. Les lettres, les chiffres et les espaces seront tous comptés.`, 'Adresszeile 1 sollte 5-30 Zeichen enthalten. Es werden alle Buchstaben, Ziffern und Leerzeichen gezählt.', 'La línea de dirección 1 debe contener entre 5 y 30 caracteres. Se contarán todas las letras, dígitos y espacios.',
                'Адресная строка 1 должна содержать от 5 до 30 символов. Буквы, цифры и пробелы будут подсчитаны.',
                'A linha de endereço 1 deve conter de 5 a 30 caracteres. Letras, dígitos e espaços serão todos contados.',
                '住所 1 行目には 5 ～ 30 文字を含める必要があります。文字、数字、スペースはすべてカウントされます。',
                `يجب أن يحتوي سطر العنوان 1 على 5-30 حرفًا. سيتم احتساب الحروف والأرقام والمسافات.`]
        }, {
            text: 'Please Select A State/Province',
            list: ['Veuillez sélectionner un état/ une province', 'Bitte wählen Sie ein Bundesland/eine Provinz aus', 'Seleccione un estado/provincia',
                'Пожалуйста, выберите штат/область', 'Selecione um estado/província', '都道府県を選択してください',
                `يرجى تحديد ولاية / مقاطعة`]
        }, {
            text: 'Please Select A City',
            list: ['Veuillez sélectionner une ville', 'Bitte wählen Sie eine Stadt aus', 'Seleccione una ciudad',
                'Пожалуйста, выберите город', 'Selecione uma cidade', '都市を選択してください',
                `الرجاء تحديد مدينة`]
        }, {
            text: 'Please enter a province/state',
            list: ['Veuillez entrer une province/état', 'Bitte geben Sie eine Provinz/ein Bundesland ein', 'Ingrese una provincia/estado, por favor ',
                'Пожалуйста, укажите провинцию/штат', 'Insira uma província/estado', '都道府県を入力してください',
                `الرجاء إدخال مقاطعة / ولاية`]
        }, {
            text: 'Please enter a city',
            list: ['Veuillez entrer une ville', 'Bitte geben Sie eine Stadt ein', 'Ingrese ciudad, por favor',
                'Пожалуйста, введите город', 'Por favor, insira uma cidade', '都市を入力してください',
                `الرجاء إدخال مدينة`]
        }, {
            text: 'No Post/Zip Code',
            list: ['Sans Code Postal', 'Keine Postleitzahl', 'Sin código postal',
                'Нет почтового индекса', 'Sem CEP/código postal', '郵便番号なし',
                `لا يوجد رمز بريدي / بريدي`]
        }, {
            text: 'Expected Delivery Date',
            list: ['Date de livraison Prévue', 'voraussichtliches Lieferdatum', 'Fecha de Entrega Estimada',
                'Ожидаемая дата доставки', 'data prevista de entrega', '配達予定日',
                `تاريخ التسليم المتوقع`]
        }, {
            text: 'Credit/Debit Card',
            list: ['carte de débit/crédit', 'Kredit-/Debitkarte', 'Tarjeta de crédito / débito',
                'Кредитная / дебетовая карта', 'Cartão de Crédito / Débito', 'クレジット/デビットカード',
                `بطاقة الائتمان / الخصم`]
        }, {
            text: 'Use Your Current Credit Card',
            list: ['Utilisez votre carte de crédit actuelle', 'vorherige Karte verwenden', 'Usar tarjeta anterior',
                'Используйте свою текущую кредитную карту', 'Use seu cartão de crédito atual', '現在のクレジット カードを使用する',
                `استخدم بطاقتك الائتمانية الحالية`]
        }, {
            text: 'Expire/Date',
            list: [`Date d'expiration`, 'Ablaufdatum', 'Fecha de caducidad',
                'Годен до', 'Data de validade', '有効期限',
                `تاريخ انتهاء الصلاحية`]
        }, {
            text: 'Billing Address',
            list: ['Adresse de facturation', 'Rechnungsadresse', 'Dirección de Envio',
                'Адрес для выставления счета', 'Endereço de Cobrança', '請求先住所',
                `عنوان وصول الفواتير`]
        }, {
            text: 'Please enter your card number',
            list: ['Veuillez entrer votre numéro de carte', 'Bitte geben Sie Ihre Kartennummer ein', 'Ingrese su número de tarjeta, por favor.',
                'Пожалуйста, введите номер карты', 'Por favor, insira o número do seu cartão', 'カード番号を入力してください',
                `الرجاء إدخال رقم بطاقتك`]
        }, {
            text: 'Please input the card Expiration Date',
            list: [`Veuillez saisir la date d'expiration de la carte`, 'Bitte geben Sie das Ablaufdatum der Karte ein', 'ingrese la fecha de vencimiento de la tarjeta, por favor.',
                'Пожалуйста, введите срок действия карты', 'Insira a data de validade do cartão', 'カードの有効期限を入力してください',
                `الرجاء إدخال تاريخ انتهاء البطاقة`]
        }, {
            text: 'BACK TO HOME',
            list: [`REVENIR À L'ACCUEIL`, 'ZURÜCK NACH HAUSEPAGE', 'VOLVER A CASA',
                'ВЕРНУТЬСЯ ДОМОЙ', 'VOLTAR PARA CASA', '家に帰る',
                `العودة إلى المنزل`]
        }, {
            text: 'Thank you for shopping from us. You will receive an order confirmation email at',
            list: [`Merci d'avoir fait vos achats chez nous. Vous recevrez un e-mail de confirmation de commande à`, 'Vielen Dank für Ihren Einkauf bei uns. Sie werden eine Bestellbestätigungs-E-Mail an', 'Gracias por comprar con nosotros. Recibirá un correo electrónico de confirmación del pedido a',
                'Спасибо за покупку у нас. Вы получите электронное письмо с подтверждением заказа на',
                'Obrigado por comprar conosco. Você receberá um e-mail de confirmação do pedido em',
                '私たちから買い物をしていただきありがとうございます。に注文確認メールが届きます。',
                `شكرا للتسوق منا. سوف تتلقى رسالة بريد إلكتروني لتأكيد الطلب على`]
        }, {
            text: 'Enter Email Address',
            list: [`Entrer l'adresse e-mail`, 'E-Mail-Adresse eingeben', 'Introduzca la dirección de correo ',
                'Введите адрес электронной почты', 'Insira o endereço de e-mail', 'メールアドレスを入力してください',
                `أدخل عنوان البريد الالكتروني`]
        }, {
            text: 'Address Line',
            list: ['La ligne d’adresse', 'Adresszeile', 'Dirección',
                'Адресная строка', 'Linha de endereço', '住所欄',
                `خط عنوان`]
        }, {
            text: 'State/Province',
            list: ['État /Province', 'Staat/Provinz', 'Provincia / Estado',
                'Штат/провинция', 'Estado/província', '都道府県',
                `الولاية / المقاطعة`]
        }, {
            text: 'Set a new password',
            list: ['Définir un nouveau mot de passe', 'ein neues Passwort festlegen', 'Establecer una nueva contraseña',
                'Установить новый пароль', 'Definir uma nova senha', '新しいパスワードを設定する',
                `قم بتعيين كلمة مرور جديدة`]
        }, {
            text: 'Thank for coming back! Please enter your new password below.',
            list: [`Merci d'être revenu! Veuillez entrez votre nouveau mot de passe ci-dessous`, 'Vielen Dank für Ihre zurückkommen! Geben Sie bitte Ihr neues Passwort hier ein.', 'Gracias por volver.  Introduzca su nueva contraseña abajo, por favor.',
                'Спасибо, что вернулись! Пожалуйста, введите новый пароль ниже.',
                'Obrigado por voltar! Por favor digite sua nova senha abaixo.',
                '戻ってきてくれてありがとう！以下に新しいパスワードを入力してください。',
                `شكرا على العودة! الرجاء إدخال كلمة المرور الجديدة أدناه.`]
        }, {
            text: 'Order number/Tracking number',
            list: ['Numéro de commande / numéro de suivi', 'Bestellnummer/Tracking-Nummer', 'Número de orden / número de seguimiento',
                'Номер заказа/номер отслеживания', 'Número do pedido/número de rastreamento', '注文番号/追跡番号',
                `رقم الطلب / رقم التتبع`]
        }, {
            text: 'You can get your order number or tracking number shown in the email we sent to you',
            list: ['Vous pouvez obtenir le numéro de commande ou de suivi indiqué dans le courriel que nous vous avons envoyé', 'Sie können Ihre Bestellnummer oder Tracking-Nummer in der E-Mail sehen, die wir Ihnen geschickt haben.', 'Puede obtener el número de orden o el número de seguimiento que aparece en el correo electrónico que le enviamos',
                'Вы можете получить номер заказа или номер для отслеживания, указанные в электронном письме, которое мы отправили вам.',
                'Você pode obter o número do seu pedido ou número de rastreamento mostrado no e-mail que enviamos para você',
                '注文番号または追跡番号は、当社から送信された電子メールに記載されています。',
                `يمكنك الحصول على رقم طلبك أو رقم التتبع الموضح في البريد الإلكتروني الذي أرسلناه إليك`]
        }, {
            text: 'TRACK',
            list: ['Piste', 'Logistik', 'Pista',
                'ОТСЛЕЖИВАТЬ', 'ACOMPANHAR', '追跡',
                `مسار`]
        }, {
            text: 'Track Result',
            list: ['Suivi des résultats', 'Spurergebnis', 'Resultados de seguimiento',
                'Отследить результат', 'Rastrear resultado', '追跡結果',
                `نتيجة المسار`]
        }, {
            text: 'State/Provin',
            list: ['État / Province', 'State/Provin', 'Estado / provincia',
                'Штат/провинция', 'Estado/província', '都道府県',
                `الولاية / المقاطعة`]
        }, {
            text: 'currency',
            list: ['Monnaie', 'Währung', 'Divisa',
                'валюта', 'moeda', '通貨',
                `عملة`]
        }, {
            text: 'Style',
            list: ['Style', 'Stil', 'Estilo',
                'Стиль', 'Estilo', 'スタイル',
                `أسلوب`]
        }, {
            text: `Keep your login status and you needn't log out after the password was changed. The new password needs to be put in the next time you log in`,
            list: [`Après avoir changé votre passward, vous n’avez pas besoin de vous déconnecter de votre compte. Conservez votre statut de connexion. Vous devez vous connecter avec un nouveau mot de passe la prochaine fois`, `Nachdem Sie Ihren Passwort geändert haben, müssen Sie sich nicht mehr von Ihrem Konto abmelden. Behalten Sie Ihren Anmeldestatus bei. Sie müssen sich das nächste Mal mit einem neuen Passwort anmelden`, `Después de cambiar su contraseña, no necesita cerrar sesión en su cuenta. Mantenga su estado de inicio de sesión. Necesita iniciar sesión con una nueva contraseña la próxima vez`,
                'Сохраните свой статус входа в систему, и вам не нужно выходить из системы после смены пароля. Новый пароль необходимо ввести при следующем входе в систему.',
                'Mantenha seu status de login e você não precisará sair depois que a senha for alterada. A nova senha precisa ser inserida na próxima vez que você fizer login',
                'ログイン状態を保持し、パスワード変更後にログアウトする必要はありません。次回のログイン時に新しいパスワードを入力する必要があります',
                `احتفظ بحالة تسجيل الدخول الخاصة بك ولن تحتاج إلى تسجيل الخروج بعد تغيير كلمة المرور. يجب إدخال كلمة المرور الجديدة في المرة القادمة التي تقوم فيها بتسجيل الدخول`]
        }, {
            text: 'Password has been successfully changed',
            list: ['Le mot de passe a été changé avec succès', 'Passwort wurde erfolgreich geändert', 'La contraseña ha sido cambiada con éxito',
                'Пароль успешно изменен', 'A senha foi alterada com sucesso', 'パスワードが正常に変更されました',
                `تم تغيير كلمة المرور بنجاح`]
        }, {
            text: 'Most Popular Questions',
            list: ['Questions les plus populaires', 'Die beliebtesten Fragen', 'Preguntas más populares',
                'Самые популярные вопросы', 'Perguntas mais populares', 'よくある質問',
                `الأسئلة الأكثر شيوعًا`]
        }, {
            text: 'Track Your Order',
            list: ['Suivre votre commande', 'Verfolgen Sie Ihre Bestellung', 'Rastrea tu orden',
                'Отследить ваш заказ', 'Acompanhe seu pedido', 'ご注文の追跡',
                `Track Your Order`]
        }, {
            text: 'Find the latest shipping update on your order',
            list: [`Trouvez la dernière mise à jour d'expédition sur votre commande`, 'Finden Sie das neueste Versandupdate für Ihre Bestellung', 'Encuentre la última actualización de envío de su pedido',
                'Найдите последнее обновление доставки для вашего заказа', 'Encontre a atualização de remessa mais recente em seu pedido', 'ご注文の最新の配送状況を確認する',
                `ابحث عن آخر تحديث للشحن على طلبك`]
        }, {
            text: 'GET MY ORDER STATUS',
            list: ['OBTENIR LE STATUT DE MA COMMANDE', 'MEINEN BESTELLSTATUS ERHALTEN', 'OBTENER EL ESTADO DE MI PEDIDO',
                'ПОЛУЧИТЬ СТАТУС ЗАКАЗА', 'OBTER MEU STATUS DE PEDIDO', '注文状況を取得する',
                `الحصول على حالة طلبي`]
        }, {
            text: 'Still Need Help',
            list: [`Vous avez encore besoin d'aide ?`, 'Benötigen Sie noch Hilfe', `¿Aún necesitas ayuda?`,
                'Все еще нужна помощь', 'Ainda precisa de ajuda', 'まだ助けが必要',
                `لا تزال بحاجة الى مساعدة`]
        }, {
            text: `We're here for you 24/7. Each of our non-commissioned experts offer diamond jewelry guidance and possess a passion for customer service`,
            list: [`Nous sommes là à votre service 24 heures sur 24. Chacun de nos experts nos experts non-commissionnés offre des conseils sur les bijoux en diamants et possède une passion pour le service à la clientèle`, `Wir sind 24/7 für Sie da. Jeder unserer non-Experten bietet Beratung zu Diamantschmuck und engagiert sich leidenschaftlich für den Kundenservice`, `Estamos a su disposición 24/7. Cada uno de nuestros expertos no comisionados ofrece orientación sobre joyas de diamantes y posee una pasión por el servicio al cliente`,
                'Мы здесь для вас 24/7. Каждый из наших внештатных экспертов предлагает рекомендации по ювелирным изделиям с бриллиантами и проявляет страсть к обслуживанию клиентов.',
                'Estamos aqui para você 24/7. Cada um de nossos especialistas não comissionados oferece orientação sobre joias com diamantes e possui paixão pelo atendimento ao cliente',
                '24時間年中無休でお待ちしています。私たちの委託されていない専門家のそれぞれがダイヤモンドジュエリーのガイダンスを提供し、顧客サービスへの情熱を持っています',
                `نحن هنا من أجلك 24/7. يقدم كل من خبرائنا غير المفوضين إرشادات حول المجوهرات الماسية ولديهم شغف بخدمة العملاء`]
        }, {
            text: 'Chat with us Get an instant response',
            list: ['Chattez avec nous Obtenez une réponse instantanée', 'Kontaktieren Sie uns und erhalten sofort eine Antwort', 'Chatea con nosotros Obtén una respuesta instantánea',
                'Свяжитесь с нами Получите мгновенный ответ', 'Converse conosco Obtenha uma resposta instantânea', '私たちとチャットしてください すぐに返事をもらいましょう',
                `تحدث معنا احصل على رد فوري`]
        }, {
            text: 'Email us anytime Let us know how we can help',
            list: ['Envoyez-nous un e-mail à tout moment Faites-nous savoir comment nous pouvons vous aider', 'Senden Sie uns jederzeit eine E-Mail Lassen Sie uns wissen, wie wir helfen können', 'Envíenos un correo electrónico en cualquier momento Háganos saber cómo podemos ayudarle',
                'Напишите нам в любое время Дайте нам знать, как мы можем помочь',
                'Envie-nos um e-mail a qualquer momento Deixe-nos saber como podemos ajudar',
                'いつでもメールでお問い合わせください',
                ``]
        }, {
            text: 'Connect with us on WhatsApp',
            list: ['Connectez-vous avec nous sur WhatsApp', 'Kontaktieren Sie uns auf WhatsApp', 'Conéctanos por WhatsApp',
                'Свяжитесь с нами в WhatsApp', 'Conecte-se conosco no WhatsApp', 'WhatsAppで私たちとつながりましょう',
                `تواصل معنا على WhatsApp`]
        }, {
            text: 'Email us about influencers',
            list: ['Envoyez-nous un e-mail à propos des influenceurs', 'Senden Sie uns eine E-Mail über Influencer', 'Envíenos un correo electrónico sobre influencers',
                'Пишите нам о влиятельных лицах', 'Envie-nos um e-mail sobre influenciadores', 'インフルエンサーについてメールでお問い合わせください',
                `راسلنا عن المؤثرين`]
        }, {
            text: 'In These Social Medias Can Find Us',
            list: ['Dans ces médias sociaux pouvez-vous nous trouver', 'In diesen sozialen Medien finden Sie uns', `¿En qué medios sociales puede encontrarnos`,
                'В этих социальных сетях вы можете найти нас', 'Nessas Redes Sociais Podem Nos Encontrar', 'これらのソーシャルメディアで私たちを見つけることができます',
                `في هذه الوسائط الاجتماعية يمكن أن تجدنا`]
        }, {
            text: 'The service may need some waitings',
            list: ['Le service peut nécessiter une certaine attente', 'Der Dienst benötigt möglicherweise einige Wartezeiten', 'El servicio puede necesitar algunas esperas',
                'Службе может потребоваться некоторое ожидание', 'O serviço pode precisar de algumas esperas', 'サービスには多少の待ち時間が必要な場合があります',
                `قد تحتاج الخدمة إلى بعض فترات الانتظار`]
        }, {
            text: 'Chat Online',
            list: ['Chat en ligne', 'Online chatten', 'Chatear en línea',
                'Онлайн чат', 'Bate-papo on-line', 'オンラインチャット',
                `الدردشة على الانترنت`]
        }, {
            text: 'You have suggestions for',
            list: ['Vous avez des suggestions pour', 'Sie haben folgende Vorschläge', 'Tienes sugerencias para',
                'У вас есть предложения для', 'Você tem sugestões para', '提案があります',
                `لديك اقتراحات ل`]
        }, {
            text: 'Describe your suggestion in detail',
            list: ['Décrivez votre suggestion en détail', 'Beschreiben Sie Ihren Vorschlag im Detail', 'Describa detalladamente su sugerencia',
                'Подробно опишите ваше предложение', 'Descreva detalhadamente sua sugestão', 'あなたの提案を詳しく説明してください',
                `صِف اقتراحك بالتفصيل`]
        }, {
            text: 'Upload an image will help with your description (Up to 5)',
            list: [`Téléchargez une image qui aidera à votre description (jusqu'à 5)`, `Laden Sie Bilder hoch, die Ihrer Beschreibung helfen (bis zu 5)`, `Sube una imagen ayudará con su descripción (Hasta 5)`,
                'Загрузить изображение поможет с описанием (до 5)',
                'Carregar uma imagem ajudará com sua descrição (até 5)',
                '画像をアップロードすると、説明に役立ちます (最大 5 つまで)',
                `سيساعدك تحميل صورة في وصفك (ما يصل إلى 5)`]
        }, {
            text: 'Please describe your suggestion so we can work it as fast as we can',
            list: ['Veuillez décrire votre suggestion afin que nous puissions y travailler le plus rapidement possible', 'Bitte beschreiben Sie Ihren Vorschlag, damit wir ihn so schnell wie möglich bearbeiten können', 'Por favor, describa su sugerencia para que podamos trabajar en ella lo más rápido posible',
                'Пожалуйста, опишите ваше предложение, чтобы мы могли работать над ним как можно быстрее',
                'Por favor, descreva sua sugestão para que possamos trabalhá-la o mais rápido possível',
                'できる限り迅速に対応できるよう、ご提案について説明してください',
                `يرجى وصف اقتراحك حتى نتمكن من العمل به بأسرع ما يمكن`]
        }, {
            text: 'Finding products',
            list: ['Trouver des produits', 'Produkt finden', 'Encontrar productos',
                'Поиск продуктов', 'Encontrar produtos', '製品を探す',
                `البحث عن المنتجات`]
        }, {
            text: 'Not Available',
            list: ['Impossible à utiliser', 'Nicht verfügbar', 'Inutilizable',
                'Нет в наличии', 'Não disponível', '利用不可',
                `غير متاح`]
        }, {
            text: 'Upload Image',
            list: ['Télécharger une image', 'Bild hochladen', 'Cargar imágenes',
                'Загрузить изображение', 'Enviar Imagem', '画像をアップロード',
                `تحميل الصور`]
        }, {
            text: 'Product ID',
            list: ['ID du Produit', 'Produkt-ID', 'ID del producto',
                'Код товара', 'ID do produto', '製品番号',
                `معرف المنتج`]
        }, {
            text: 'shipping over',
            list: ['expédition partir de', 'Kauf über', 'envío terminado',
                'доставка закончилась', 'envio sobre', '発送',
                `الشحن أكثر`]
        }, {
            text: 'Estimated to be delivered on',
            list: ['Estimation à livrer le', 'Voraussichtlich Lieferzeit', 'Entrega estimada el',
                'Ориентировочно будет доставлено', 'Previsto para entrega em', '配達予定日',
                `يقدر أن يتم تسليمها في`]
        }, {
            text: 'workdays',
            list: ['jours ouvrables', 'Arbeitstag', 'dias laborables',
                'рабочие дни', 'dias úteis', '平日',
                `أيام العمل`]
        }, {
            text: 'Free on orders over',
            list: ['Gratuit pour les commandes supérieures à', 'Kostenlos bei Bestellungen über', 'Envío gratis en pedidos superiores a',
                'Бесплатно при заказе свыше', 'Grátis em pedidos acima', '以上のご注文で無料',
                `مجانا على أوامر أكثر`]
        }, {
            text: 'Costs',
            list: ['frais', 'Kosten', 'costos',
                'Расходы', 'custos', '費用',
                `التكاليف`]
        }, {
            text: 'Notice',
            list: ['Attention', 'Bekanntmachung', 'Atención',
                'Уведомление', 'Perceber', '知らせ',
                `يلاحظ`]
        }, {
            text: 'Shopping Security',
            list: ['Sécurité des achats', 'Sicherheit beim Einkaufen', 'Seguridad de las compras',
                'Безопасность покупок', 'segurança de compras', 'ショッピング セキュリティ',
                `أمن التسوق`]
        }, {
            text: 'Safe Payments',
            list: ['Paiements sécurisés', 'Sichere Zahlungen', 'Pago Seguro',
                'Безопасные платежи', 'Pagamentos seguros', '安全な支払い',
                `مدفوعات آمنة`]
        }, {
            text: 'Your payment at YFN is secured with global payment security cetifications',
            list: ['Votre paiement chez YFN est certifié global payment Security', 'Ihre Zahlung bei YFN ist mit globalen Zahlungssicherheitszertifikaten gesichert', 'Su pago en yfn tiene una certificación global de Seguridad de pago',
                'Ваш платеж в YFN защищен глобальными сертификатами безопасности платежей.',
                'Seu pagamento na YFN é garantido com certificações globais de segurança de pagamento',
                'YFNでの支払いは、グローバルな支払いセキュリティ認証によって保護されています',
                `يتم تأمين مدفوعاتك في YFN من خلال شهادات أمان الدفع العالمية`]
        }, {
            text: 'Secure Logistics',
            list: ['Logistique sécurisée', 'Sichere Logistik', 'Logística segura',
                'Безопасная логистика', 'Logística segura', '安全な物流',
                `تأمين الخدمات اللوجستية`]
        }, {
            text: 'Order tracking',
            list: ['Suivi de commande', 'Bestellung verfolgen', 'Rastreo de orden',
                'Отслеживание заказа', 'Rastreamento de pedido', '注文追跡',
                `تتبع الطلب`]
        }, {
            text: 'View My Orders',
            list: ['Vérifier ma commande', 'Überprüfen meine Bestellung', 'Consultar mi pedido',
                'Посмотреть мои заказы', 'Ver Meus Pedidos', '私の注文を見る',
                `عرض طلباتي`]
        }, {
            text: 'View My Order',
            list: ['Vérifier ma commande', 'Überprüfen meine Bestellung', 'Consultar mi pedido',
                'Посмотреть мои заказы', 'Ver Meus Pedido', '私の注文を見る',
                `عرض طلباتي`]
        }, {
            text: 'About COVID-19',
            list: ['À propos de la COVID-19', 'Über COVID-19', 'Sobre COVID-19',
                'О COVID-19', 'Sobre o COVID-19', 'COVID-19について',
                `حول COVID-19`]
        }, {
            text: 'Learn more',
            list: ['En savoir plus', 'Lern mehr', 'Aprende más',
                'Узнать больше', 'Saber mais', 'もっと詳しく知る',
                `يتعلم أكثر`]
        }, {
            text: 'YFN follows strict protocols to sterilize warehouse and packages prior to shipment',
            list: [`YFN suit un protocole strict pour désinfecter l'entrepôt et l'emballage avant l'expédition`, 'YFN folgt strengen Protokollen, um Lager und Pakete vor dem Versand zu sterilisieren', 'YFN sigue protocolos estrictos para desinfectar almacenes y envases antes del envío',
                'YFN следует строгим протоколам стерилизации склада и упаковки перед отправкой.', 'A YFN segue protocolos rígidos para esterilizar armazéns e embalagens antes do envio',
                'YFNは厳格なプロトコルに従って、出荷前に倉庫とパッケージを滅菌します',
                `تتبع YFN بروتوكولات صارمة لتعقيم المستودعات والحزم قبل الشحن`]
        }, {
            text: 'Contact us',
            list: ['Contactez nous', 'Kontaktieren Sie uns', 'Contáctanos',
                'Связаться с нами', 'Contate-nos', 'お問い合わせ',
                `اتصل بنا`]
        }, {
            text: 'Privacy Protection',
            list: ['Protection de la vie privée', 'Datenschutz', 'Protección de la privacidad',
                'Защита конфиденциальности', 'Proteção de privacidade', 'プライバシー保護',
                `حماية الخصوصية`]
        }, {
            text: 'YFN never shares your personal information with nonaffiliated third parties. Be assure that your information and privacy will be kept secure and uncompromised',
            list: ['YFN ne partage jamais vos informations personnelles avec des tiers non affiliés. Soyez assuré que vos informations et votre vie privée seront gardées en sécurité et sans compromis.', 'YFN wird Ihre persönliche Daten niemals an nicht verbundene Dritte weitergeben. Keine Sorge bitte, dass Ihre Informationen und Privatsphäre sicher und kompromisslos aufbewahrt werden.', 'YFN nunca comparte su información personal con terceros no afiliados. Asegúrese de que su información y privacidad se mantendrán seguras y sin compromisos.',
                'YFN никогда не передает вашу личную информацию неаффилированным третьим лицам. Будьте уверены, что ваша информация и конфиденциальность будут храниться в безопасности и бескомпромиссной',
                'YFN nunca compartilha suas informações pessoais com terceiros não afiliados. Certifique-se de que suas informações e privacidade serão mantidas seguras e descomprometidas',
                'YFNは、あなたの個人情報を関連のない第三者と共有することはありません。あなたの情報とプライバシーが安全に保護され、妥協されないことを保証してください',
                `لا تشارك YFN أبدًا معلوماتك الشخصية مع أطراف ثالثة غير منتسبة. تأكد من أن معلوماتك وخصوصيتك ستبقى آمنة وغير منقوصة`]
        }, {
            text: 'Most Helpful',
            list: ['Le plus utile', 'Am hilfreichsten', 'El más útil',
                'Самый полезный', 'Mais útil', '最も役に立った',
                `مفيد جدا`]
        }, {
            text: 'Return & Refund',
            list: ['Retour & Remboursement', 'Rückgabe & Rückerstattung', 'Devolución y reembolso',
                'Возврат и возврат', 'Devolução e Reembolso', '返品と返金',
                `العودة واسترداد`]
        }, {
            text: 'Customize Now',
            list: ['Personnaliser maintenant', 'Jetzt anpassen', 'Ahora personalizado',
                'Настроить сейчас', 'Personalize agora', '今すぐカスタマイズ',
                `تخصيص الآن`]
        }, {
            text: 'Yes',
            list: ['Oui', 'Ja', 'sí',
                'Да', 'Sim', 'はい',
                `نعم`]
        }, {
            text: 'No',
            list: ['Non', 'Nein', 'no',
                'Нет', 'Não', 'いいえ',
                `لا`]
        }, {
            text: 'Add',
            list: ['Ajouter', 'hinzufügen', 'Agregar',
                'Добавлять', 'Adicionar', '追加',
                `يضيف`]
        }, {
            text: 'Purchase',
            list: ['Rachat', 'Wiederkaufen', 'Recompra',
                'Покупка', 'Comprar', '購入',
                `شراء`]
        }, {
            text: 'PLACE ORDER',
            list: ['Passer commande', 'Bestellung aufgeben', 'Realizar pedido',
                'РАЗМЕСТИТЬ ЗАКАЗ', 'FAÇA A ENCOMENDA', '注文する',
                `مكان الامر`]
        }, {
            text: 'interest-free payments of',
            list: ['paiements sans intérêt de', 'zinslose Zahlungen von', 'pagos sin intereses de',
                'беспроцентные платежи по', 'pagamentos sem juros de', 'の無利子支払い',
                `مدفوعات بدون فوائد`]
        }, {
            text: 'Make 4 payments of',
            list: ['4 paiements de', 'Machen Sie 4-Zahlungen von', 'PPagos 4 de',
                'Совершить 4 платежа', 'Faça 4 pagamentos de', 'を4回支払う',
                `إجراء 4 دفعات من`]
        }, {
            text: 'Make 4 payments on orders over',
            list: ['4 paiements pour les commandes de plus de', 'Machen Sie 4-Zahlungen auf Bestellungen über', 'Cuatro pagos por pedidos de más de',
                'Совершите 4 платежа при заказе свыше', 'Faça 4 pagamentos em pedidos acima de', '以上の注文で 4 回の支払いを行う',
                `إجراء 4 مدفوعات على أوامر أكثر`]
        }, {
            text: 'You’ve got a',
            list: ['Vous bénéficiez d’une réduction', 'Sie haben einen', 'Tienes un',
                'У вас есть', 'você tem um', 'あなたは持っています',
                `لقد حصلت على`]
        }, {
            text: 'discount',
            list: ['rabais', 'erhalten', 'descuento',
                'скидка', 'desconto', '割引',
                `تخفيض`]
        }, {
            text: 'You order currency will be converted to USD, which is the only currency this payment methods supports',
            list: ['La devise que vous commandez sera convertie en USD, la seule devise prise en charge par ce mode de paiement', 'Ihre Bestellwährung wird in USD umgerechnet, die einzige Währung, die diese Zahlungsmethode unterstützt', 'La moneda que ordene se convertirá en dólares estadounidenses, que es la única moneda apoyada por este método de pago.',
                'Валюта вашего заказа будет конвертирована в доллары США, которые являются единственной валютой, поддерживаемой этим способом оплаты.',
                'A moeda do seu pedido será convertida em USD, que é a única moeda aceita por este método de pagamento',
                '注文通貨は、この支払い方法がサポートする唯一の通貨である米ドルに換算されます',
                `سيتم تحويل عملة الطلب إلى الدولار الأمريكي ، وهي العملة الوحيدة التي تدعمها طرق الدفع هذه`]
        }, {
            text: 'Please select the item to be ordered',
            list: [`Veuillez sélectionner l'élément que vous souhaitez utiliser`, 'Bitte wählen Sie die Artikel aus, mit denen Sie zur Kasse gehen möchten', 'Por favor, elija el proyecto que desea usar',
                'Пожалуйста, выберите товар для заказа', 'Por favor, selecione o item a ser pedido', '注文する商品を選択してください',
                `يرجى تحديد العنصر المطلوب طلبه`]
        }, {
            text: 'Please select a shipping method',
            list: [`Veuillez choisir une méthode d'expédition`, 'Bitte wählen Sie eine Versandart', 'Por favor, elija el método de envío.',
                'Пожалуйста, выберите способ доставки', 'Selecione um método de envio', '配送方法を選択してください',
                `الرجاء تحديد طريقة الشحن`]
        }, {
            text: 'Please select a payment method',
            list: ['Veuillez sélectionner un mode de paiement', 'Bitte wählen Sie eine Zahlungsart', 'Por favor, elija el método de pago.',
                'Пожалуйста, выберите способ оплаты', 'Selecione um método de pagamento', 'お支払い方法を選択してください',
                `الرجاء اختيار طريقة الدفع`]
        }, {
            text: 'Order placed now are expected to arrive before',
            list: ['Les commandes passées maintenant devraient arriver avant', 'Jetzt aufgegebene Bestellungen werden vor ', 'Se espera que el pedido realizado ahora llegue antes',
                'Ожидается, что заказ, размещенный сейчас, будет доставлен раньше', 'Espera-se que o pedido feito agora chegue antes', '今注文すると、それより前に到着する予定です',
                `من المتوقع أن يصل الطلب الذي تم وضعه الآن من قبل`]
        }, {
            text: 'Address Management',
            list: ['Gestion des adresses', 'Addresse Management', 'Gestión de direcciones',
                'Управление адресами', 'Gerenciamento de endereço', 'アドレス管理',
                `إدارة العناوين`]
        }, {
            text: 'Expire date',
            list: ['Date d’expiration', 'Verfallsdatum', 'Fecha de caducidad',
                'Годен до', 'Data de validade', '有効期限',
                `تاريخ انتهاء الصلاحية`]
        }, {
            text: 'Please input card number',
            list: ['Veuillez entrer le numéro de carte', 'Bitte geben Sie die Kartennummer ein', 'Por favor, introduzca el número de la tarjeta.',
                'Пожалуйста, введите номер карты', 'Insira o número do cartão', 'カード番号を入力してください',
                `الرجاء إدخال رقم البطاقة`]
        }, {
            text: 'Please enter expire date',
            list: [`Veuillez entrer une date d'expiration`, 'Bitte geben Sie Ablaufdatum ein', 'Por favor, introduzca la fecha de caducidad',
                'Пожалуйста, введите срок действия', 'Insira a data de expiração', '有効期限を入力してください',
                `الرجاء إدخال تاريخ انتهاء الصلاحية`]
        }, {
            text: 'Please input CVV',
            list: ['Veuillez saisir CVV', 'Bitte CVV eingeben', 'Por favor ingrese CVV',
                'Пожалуйста, введите CVV', 'Insira CVV', 'CVVを入力してください',
                `الرجاء إدخال CVV`]
        }, {
            text: 'Size Guide',
            list: ['Guide des tailles', 'Größentabelle', 'Guía de tamaño',
                'Руководство по размерам', 'Guia de tamanho', 'サイズガイド',
                `دليل المقاسات`]
        }, {
            text: 'Added to bag',
            list: ['Ajouter au pack', 'Zum Warenkorb hinzugefügt', 'Añadir al paquete',
                'Добавлено в корзину', 'Adicionado ao saco', 'バッグに追加',
                `يضاف إلى الحقيبة`]
        }, {
            text: 'Customization',
            list: ['Personnalisation', 'Anpassung', 'Personalizado',
                'Настройка', 'Costumização', 'カスタマイズ',
                `التخصيص`]
        }, {
            text: 'QTY',
            list: ['Quantité', 'Menge', 'Cantidad',
                'КОЛ-ВО', 'QUANTIDADE', '数量',
                `الكمية`]
        }, {
            text: 'Free Gift Promo',
            list: ['Promotion cadeau gratuite', 'Kostenlose Geschenkaktion', 'Promoción gratuita de regalos',
                'Бесплатный подарок', 'Promoção de presente grátis', '無料ギフトプロモーション',
                `عرض هدية مجانية`]
        }, {
            text: 'Discount Details',
            list: ['Détails de réduction', 'Rabattdetails', 'Detalles de descuento',
                'Детали скидки', 'Detalhes do desconto', '割引の詳細',
                `تفاصيل الخصم`]
        }, {
            text: 'Promotions',
            list: ['promotions', 'Angebote', 'promociones',
                'Акции', 'Promoções', '割引合計',
                `الترقيات`]
        }, {
            text: 'Discount Total',
            list: ['Remise total', 'Gesamtrabatt', 'Descuento total',
                'Общая скидка', 'Desconto Total', '',
                `إجمالي الخصم`]
        }, {
            text: 'Above discounts does not include coupon deals, please review at the next page',
            list: [`Les remises ci-dessus n'incluent pas les offres de coupons, veuillez consulter à la page suivante`, 'Oben Rabatte beinhaltet keine Gutscheinangebote. Bitte überprüfen Sie auf der nächsten Seite', 'Los descuentos anteriores no incluyen ofertas de cupón, revise en la página siguiente',
                'Вышеупомянутые скидки не включают купоны, пожалуйста, ознакомьтесь с ними на следующей странице.',
                'Os descontos acima não incluem ofertas de cupom, revise na próxima página',
                '上記の割引にはクーポン取引は含まれていません。次のページで確認してください。',
                `الخصومات أعلاه لا تشمل صفقات القسيمة ، يرجى مراجعتها في الصفحة التالية`]
        }, {
            text: 'Specials',
            list: ['Spéciaux', 'Specials', 'Especiales',
                'Специальные предложения', 'Especiais', '特別',
                `العروض الخاصة`]
        }, {
            text: 'Free Gift(s) For You',
            list: ['Cadeaux gratuits pour vous', 'Kostenlose Geschenk (e) für Sie', 'Regalos gratis para ti',
                'Бесплатные подарки для вас', 'Presente(s) Gratuito(s) para Você', 'あなたへの無料ギフト',
                `هدية (هدايا) مجانية لك`]
        }, {
            text: 'Easy returns within 60 days',
            list: ['Retour facile dans les 60 jours', 'Einfache Rückgabe innerhalb von 60 Tagen', 'Devoluciones fáciles en 60 días',
                'Легкий возврат в течение 60 дней', 'Devoluções fáceis em 60 dias', '60日以内の簡単返品',
                `إرجاع سهل في غضون 60 يومًا`]
        }, {
            text: 'Most Recent',
            list: ['Récemment', 'Neueste', 'Recientemente',
                'Most Recent', 'Mais recente', '最も最近の',
                `الأحدث`]
        }, {
            text: 'Most Critical',
            list: ['Le plus crucial', 'Am kritischsten', 'El más crítico',
                'Самый важный', 'Mais crítico', '最も重要',
                `أهم`]
        }, {
            text: 'Download App',
            list: [`Télécharger l'application`, 'App herunterladen', 'Descargar la aplicación',
                'Скачать приложение', 'Baixar aplicativo', 'アプリをダウンロードする',
                `تحميل التطبيق`]
        }, {
            text: 'Help&Support',
            list: ['Aide et soutien', 'Hilfe&Support', 'Ayuda y apoyo',
                'Справка и поддержка', 'Ajuda&Suporte', 'ヘルプサポート',
                `ساعد لدعم`]
        }, {
            text: 'Company Info',
            list: ['Informations sur la société', 'Firmeninfo', 'Información de la empresa',
                'информация о компании', 'Informação da companhia', '会社情報',
                `معلومات الشركة`]
        }, {
            text: 'Subscribe for YFN',
            list: [`S'abonner à yFn`, 'ABONNEHMEN FÜR YFN', 'Suscripción a yfn',
                'Подпишитесь на YFN', 'Inscreva-se no YFN', 'YFNに申し込む',
                `اشترك في YFN`]
        }, {
            text: 'Get the latest arrivals & exclusive offer',
            list: ['Obtenez les derniers arrivages et des offres exclusives', 'Erhalten Sie die neuesten Neuheiten zum exklusiven Angebot', 'Obtener las últimas llegadas y ofertas exclusivas',
                'Получите последние поступления и эксклюзивное предложение', 'Obtenha as últimas novidades e ofertas exclusivas', '最新の到着と限定オファーを入手',
                `احصل على أحدث الوافدين والعرض الحصري`]
        }, {
            text: 'Select Country',
            list: ['Sélectionner un pays / une région', 'Land auswählen', 'Selección de países / regiones',
                'Выберите страну', 'Selecione o pais', '国を選択',
                `حدد الدولة`]
        }, {
            text: 'Select Language',
            list: ['Choisir une langue', 'Sprache auswählen', 'Elegir idioma',
                'Выберите язык', 'Selecione o idioma', '言語を選択する',
                `اختار اللغة`]
        }, {
            text: 'Added to Your bag',
            list: ['Ajouter à votre sac', 'Zum Warenkorb hinzugefügt', 'Añadir a su paquete',
                'Добавлено в Вашу корзину', 'Adicionado à sua bolsa', 'あなたのバッグに追加されました',
                `يضاف إلى حقيبتك`]
        }, {
            text: 'SUBSCRIBE FOR YFN',
            list: [`S'abonner à yFn`, 'ABONNEHMEN FÜR YFN', 'Suscripción a yfn',
                'ПОДПИСАТЬСЯ НА YFN', 'INSCREVA-SE NO YFN', 'YFNに登録する',
                `الاشتراك في YFN`]
        }, {
            text: 'Note',
            list: ['Notes', 'Anmerkung', 'Notas',
                'Примечание', 'Selecione os itens primeiro', 'ノート',
                `ملحوظة`]
        }, {
            text: 'Please select the items first',
            list: [`Veuillez d'abord sélectionner un projet`, 'Bitte wählen Sie zuerst die Artikel aus', 'Por favor, elija el proyecto primero',
                'Сначала выберите элементы', 'Selecione os itens primeiro', '最初に項目を選択してください',
                `الرجاء تحديد العناصر أولا`]
        }, {
            text: 'filter',
            list: ['filtre', 'Filter', 'filtro',
                'фильтр', 'filtro', 'フィルター',
                `منقي`]
        }, {
            text: 'All Valid Benefits',
            list: ['Tous les avantages valides', 'Alle gültigen Vorteile', 'Todos los beneficios válidos',
                'Все действительные преимущества', 'Todos os benefícios válidos', 'すべての有効な特典',
                `جميع الفوائد الصالحة`]
        }, {
            text: 'This is a free shipping item',
            list: ['Ceci est un article en livraison gratuite', 'Dies ist ein kostenloser Versandartikel', 'Este es un artículo de envío gratuito',
                'Это бесплатная доставка товара', 'Este é um item de frete grátis', '送料無料の商品です',
                `هذا عنصر شحن مجاني`]
        }, {
            text: 'Refund Policy',
            list: ['Politique de remboursement', 'Rückerstattungspolitik', 'Política de reembolso',
                'Политика возврата', 'Politica de reembolso', '代金返却方針',
                `سياسة الاسترجاع`]
        }, {
            text: 'Edit Billing Address',
            list: [`Modifier l'adresse de facturation`, 'Rechnungsadresse bearbeiten', 'Editar dirección de facturación',
                'Изменить платежный адрес', 'Editar endereço de cobrança', '請求先住所の編集',
                `تحرير عنوان الفواتير`]
        }, {
            text: 'Apartment,Suite,Unit,Building,Foor,etc(Optional)',
            list: ['Appartement, suite, unité, bâtiment, étage, etc. (facultatif)', 'Wohnung, Suite, Einheit, Gebäude, Etage usw. (optional)', 'Apartamento, Suite, Unidad, Edificio, Piso, etc. (Opcional)',
                'Квартира, люкс, блок, здание, пол и т. Д. (Необязательно)', 'Apartamento, Suíte, Unidade, Prédio, Piso, etc (Opcional)', 'アパート、スイート、ユニット、建物、フロアなど（オプション）',
                `شقة ، جناح ، وحدة ، مبنى ، طابق ، إلخ (اختياري)`]
        }, {
            text: 'Street address should contain 5-50 letters, digits or spaces',
            list: [`Adresse de la rue doit contenir 5 à 50 lettres, chiffres ou espaces`,
                'Adresse der Straße sollte 5-50 Buchstaben, Ziffern oder Leerzeichen enthalten',
                'Dirección de la calle debe contener de 5 a 50 letras, dígitos o espacios',
                'Адрес улицы должна содержать от 5 до 50 букв, цифр или пробелов.',
                'Endereço de rua deve conter de 5 a 50 letras, dígitos ou espaços',
                'ストリートアドレス には、5 ～ 50 文字の文字、数字、またはスペースを含める必要があります',
                `يجب أن يحتوي سطر عنوان الشارع على 5-50 حرفًا أو رقمًا أو مسافات`]
        }, {
            text: 'Please enter a valid email address',
            list: ['Veuillez entrer une adresse e - mail valide', 'Bitte geben Sie eine gültige E-Mail-Adresse ein', 'Introduzca una dirección de correo electrónico válida',
                'Пожалуйста, введите действительный адрес электронной почты',
                'Por favor insira um endereço de e-mail válido',
                '有効なメールアドレスを入力してください',
                `يرجى إدخال عنوان بريد إلكتروني صالح`]
        }, {
            text: 'Subscribe',
            list: [`S'abonner`, 'Abonnieren', 'Suscripción',
                'Подписаться', 'Se inscrever', '購読',
                `يشترك`]
        }, {
            text: 'Thank you for subscribing. Enjoy shopping at YFN',
            list: ['Merci pour votre abonnement. Profitez du shopping chez yFn', 'Vielen Dank für die Anmeldung. Genießen Sie das Einkaufen bei YFN', 'Gracias por su suscripción. Disfrute de comprar en yfn',
                'Спасибо за подписку. Наслаждайтесь покупками в YFN',
                'Obrigado por se inscrever. Aproveite as compras na YFN',
                '登録ありがとうございます。 YFNでショッピングを楽しむ',
                `شكرا لك على الاشتراك. استمتع بالتسوق في YFN`]
        }, {
            text: 'Email Address',
            list: ['Adresse e - mail', 'E-Mail-Adresse', 'Dirección de correo electrónico',
                'Адрес электронной почты', 'Endereço de email', '電子メールアドレス',
                `عنوان البريد الإلكتروني`]
        }, {
            text: 'Password',
            list: ['La langue sombre', 'Passwort', 'Palabras clave',
                'Пароль', 'Senha', 'パスワード',
                `كلمة المرور`]
        }, {
            text: 'The password must contain 8 to 16 characters with both alphabets and numbers',
            list: ['Le mot de passe doit contenir de 8 à 16 caractères avec à la fois des lettres et des chiffres', 'Das Passwort muss 8 bis 16 Zeichen mit Buchstaben und Zahlen enthalten', 'La contraseña debe contener de 8 a 16 caracteres con letras y números',
                'Пароль должен содержать от 8 до 16 символов, включая буквы и цифры.',
                'A senha deve conter de 8 a 16 caracteres com letras e números',
                'パスワードには、アルファベットと数字の両方を含む 8 ～ 16 文字を含める必要があります',
                `يجب أن تتكون كلمة المرور من 8 إلى 16 حرفًا مع كل من الحروف الأبجدية والأرقام`]
        }, {
            text: 'I’d like to receive exclusive offers and news by Email or SMS',
            list: ['Je souhaite recevoir des offres exclusives et des nouvelles par e - mail ou SMS', 'Ich möchte exklusive Angebote und Neuigkeiten per E-Mail oder SMS erhalten', 'Quiero recibir ofertas y noticias exclusivas por correo electrónico o SMS',
                'Я хочу получать эксклюзивные предложения и новости по электронной почте или SMS', 'Desejo receber ofertas e novidades exclusivas por e-mail ou SMS', 'メールまたはSMSで限定オファーやニュースを受け取りたい',
                `أرغب في تلقي عروض وأخبار حصرية عبر البريد الإلكتروني أو الرسائل القصيرة`]
        }, {
            text: 'Forgot password',
            list: ['Mot de passe oublié', 'Passwort vergessen', '¿Olvidar la contraseña',
                'Забыли пароль', 'Esqueceu sua senha', 'パスワードをお忘れですか',
                `هل نسيت كلمة السر`]
        }, {
            text: 'Forgot Your Password',
            list: ['Obtenez votre mot de passe', 'Ihr Passwort erhalten', 'Obtenga su contraseña',
                'Забыли пароль', 'Esqueceu sua senha', 'パスワードをお忘れですか',
                `نسيت كلمة السر`]
        }, {
            text: `Enter your email address and we'll send you a reset link for your password`,
            list: ['Entrez votre adresse e - mail et nous vous enverrons un lien de Réinitialisation de mot de passe', 'Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts.', 'Introduzca su dirección de correo electrónico y le enviaremos un enlace de restablecimiento de contraseña',
                'Введите свой адрес электронной почты, и мы вышлем вам ссылку для сброса пароля',
                'Digite seu endereço de e-mail e enviaremos um link para redefinir sua senha',
                'メールアドレスを入力すると、パスワードのリセットリンクが送信されます',
                `أدخل عنوان بريدك الإلكتروني وسنرسل لك رابط إعادة تعيين كلمة المرور الخاصة بك`]
        }, {
            text: 'SEND RESET LINK',
            list: ['Envoyer un lien de réinitialisation', 'RESET LINK SENDEN', 'Enviar enlace de reinicio',
                'ОТПРАВИТЬ ССЫЛКУ ДЛЯ СБРОСА', 'ENVIAR LINK DE REINICIALIZAÇÃO', 'リセットリンクを送信',
                `إرسال رابط إعادة التعيين`]
        }, {
            text: 'Select Currency',
            list: ['Choisir une devise', 'Währung auswählen', 'Elegir moneda',
                'Выберите валюту', 'Escolha a moeda', '通貨を選択',
                `اختر العملة`]
        }, {
            text: 'Edit Shipping Address',
            list: [`Modifier l'adresse de livraison`, 'Lieferadresse bearbeiten', 'Editar la dirección de envío',
                'Изменить адрес доставки', 'Editar endereço de entrega', '配送先住所の編集',
                `تحرير عنوان الشحن`]
        }, {
            text: '2-34 letters or space',
            list: ['2 - 34 lettres ou espaces', '2-34 Buchstaben oder Leerzeichen', '2 - 34 lettres ou espaces',
                '2-34 буквы или пробел', '2-34 letras ou espaço', '2 ～ 34 文字またはスペース',
                `2-34 حرف أو مسافة`]
        }, {
            text: 'Invalid email address',
            list: ['Adresse e - mail non valide', 'Ungültige E-Mail-Adresse', 'Adresse e - mail non valide',
                'Неверный адрес электронной почты', 'Endereço de email invalido', '無効なメールアドレス',
                `عنوان البريد الإلكتروني غير صالح`]
        }, {
            text: 'Address line 1 should contain 5-50 letters, digits or space',
            list: [`La ligne d'adresse 1 doit contenir de 5 à 50 lettres, chiffres ou espaces.`, 'Adresszeile 1 sollte 5-50 Buchstaben, Ziffern oder Leerzeichen enthalten', `La ligne d'adresse 1 doit contenir de 5 à 50 lettres, chiffres ou espaces.`,
                'Адресная строка 1 должна содержать от 5 до 50 букв, цифр или пробелов.',
                'A linha de endereço 1 deve conter de 5 a 50 letras, dígitos ou espaço',
                '住所行 1 には、5 ～ 50 文字の文字、数字、またはスペースを含める必要があります',
                `يجب أن يحتوي سطر العنوان 1 على 5-50 حرفًا أو رقمًا أو مسافة`]
        }, {
            text: 'Please select',
            list: ['Veuillez sélectionner', 'Bitte wählen', 'Veuillez sélectionner',
                'Пожалуйста выберите', 'Por favor selecione', '選んでください',
                `الرجاء التحديد`]
        }, {
            text: 'Phone number should be a 10-digit number',
            list: ['Le numéro de téléphone doit comporter 10 chiffres', 'Die Telefonnummer sollte eine 10-stellige Nummer sein', 'El número de teléfono debe ser de 10 dígitos',
                'Номер телефона должен состоять из 10 цифр.',
                'O número de telefone deve ser um número de 10 dígitos',
                '電話番号は 10 桁の数字である必要があります',
                `يجب أن يتكون رقم الهاتف من 10 أرقام`]
        }, {
            text: 'Phone number should be a 9-digit number',
            list: ['Le numéro de téléphone doit être à 9 Chiffres', 'Die Telefonnummer sollte eine 9-stellige Nummer sein', 'El número de teléfono debe ser de 9 dígitos',
                'Номер телефона должен быть 9-значным.', 'O número de telefone deve ser um número de 9 dígitos', '電話番号は 9 桁の数字である必要があります',
                `يجب أن يتكون رقم الهاتف من 9 أرقام`]
        }, {
            text: 'Please Select Sku',
            list: [`Veuillez sélectionner Sku'`, 'Bitte Artikelnummer auswählen', `Seleccione Sku'`,
                'Пожалуйста, выберите Артикул', 'Por favor, selecione SKU', 'SKUを選択してください',
                `يرجى تحديد Sku`]
        }, {
            text: 'Customize Your Own Style',
            list: ['Personnalisez votre propre style', 'Passen Sie Ihren eigenen Stil an', 'Personaliza tu propio estilo',
                'Настройте свой собственный стиль', 'Personalize seu próprio estilo', '独自のスタイルをカスタマイズ',
                `تخصيص طريقتك الخاصة`]
        }, {
            text: 'Upload photo',
            list: ['Envoyer la photo', 'Foto hochladen', 'Subir foto',
                'Загрузить фото', 'Carregar foto', '写真をアップする',
                `حمل الصورة`]
        }, {
            text: 'Your Photo',
            list: ['Ta photo', 'Dein Foto', 'Tu foto',
                'Твое фото', 'Sua foto', 'あなたの写真',
                `صورتك`]
        }, {
            text: 'Total Additional Charge',
            list: ['Frais supplémentaires totaux', 'Gesamtzuschlag', 'Cargo Adicional Total',
                'Общая дополнительная плата', 'Cobrança Adicional Total', '追加料金合計',
                `إجمالي الرسوم الإضافية`]
        }, {
            text: 'Amount',
            list: ['Montante', 'Menge', 'Cantidad',
                'Количество', 'Quantia', '額',
                `كمية`]
        }, {
            text: 'Sorry, this item has been removed',
            list: [`Désolé, cet élément a été supprimé`, 'Dieser Artikel wurde leider entfernt', 'Lo sentimos, este artículo ha sido eliminado',
                'Извините, этот элемент был удален', 'Desculpe, este item foi removido', '申し訳ありませんが、このアイテムは削除されました',
                `آسف ، هذا العنصر قد أزيل`]
        }, {
            text: 'Common Questions',
            list: ['Questions courantes', 'Häufige Fragen', 'Preguntas comunes',
                'Общие вопросы', 'Perguntas comuns', 'よくある質問',
                `الأسئلة الشائعة`]
        }, {
            text: `We're Here to Help`,
            list: ['Nous sommes là pour vous aider', 'Wir sind hier um zu helfen', '¡Estamos aquí para ayudar',
                'Мы здесь, чтобы помочь', 'Nós estamos aqui para ajudar', '私たちは助けるためにここにいます',
                `نحن هنا للمساعدة`]
        }, {
            text: 'enter your questions here',
            list: ['entrez vos questions ici', 'Geben Sie hier Ihre Fragen ein', 'ingresa tus preguntas aqui',
                'введите ваши вопросы здесь', 'coloque suas dúvidas aqui', 'ここに質問を入力してください',
                `أدخل أسئلتك هنا`]
        }, {
            text: 'Was this article helpful',
            list: ['Cet article a-t-il été utile', 'War dieser Artikel hilfreich', '¿Te resultó útil este artículo',
                'Была ли эта статья полезна', 'Esse artigo foi útil', 'この記事は役に立ちましたか',
                `هل كان المقال مساعدا`]
        }, {
            text: 'Have more questions',
            list: [`Vous avez d'autres questions`, 'Haben Sie weitere Fragen', '¿Tienes más preguntas',
                'Есть еще вопросы', 'Tem mais perguntas', 'もっと質問があります',
                `هل لديك المزيد من الأسئلة`]
        }, {
            text: 'CHAT NOW',
            list: ['DISCUTER MAINTENANT', 'JETZT CHATTEN', 'CHATEA AHORA',
                'ТЕПЕРЬ ГОВОРИТЕ', 'CONVERSAR AGORA', '今すぐチャット',
                `الدردشة الآن`]
        }, {
            text: 'Bundle',
            list: ['Empaqueter', 'Bündeln', 'Manojo',
                'Пучок', 'Pacote', 'バンドル',
                `باقة`]
        }, {
            text: 'Bundle Sale',
            list: ['Offre groupée', 'Bundle-Verkauf', 'Venta de paquetes',
                'Пакетная распродажа', 'Venda de pacote', 'バンドル販売',
                `بيع الحزم`]
        }, {
            text: 'Buy these together to save',
            list: ['Achetez-les ensemble pour économiser', 'Kaufen Sie diese zusammen, um zu sparen', 'Compre estos juntos para ahorrar',
                'Купите их вместе, чтобы сэкономить', 'Compre-os juntos para economizar', 'まとめて買うとお得',
                `قم بشرائها معًا لتوفر`]
        }, {
            text: 'Limited time offer to save 65% Code',
            list: ['Zeitlich begrenztes Angebot zum Sparen von 65 % Code', 'Offre à durée limitée pour économiser 65 % Code', 'Oferta por tiempo limitado para ahorrar un 65 % Código',
                'Предложение ограничено по времени, чтобы сэкономить 65% кода', 'Oferta por tempo limitado para economizar 65% do código', '65% コードを節約する期間限定オファー',
                `عرض لفترة محدودة لتوفير 65٪ كود`]
        }, {
            text: 'Cash On Delivery service is available only when total amount after discount is between',
            list: ['Le service de paiement à la livraison est disponible uniquement lorsque le montant total après remise est compris entre', 'Der Nachnahmeservice ist nur verfügbar, wenn der Gesamtbetrag nach Rabatt zwischen liegt', 'El servicio de pago contra reembolso está disponible solo cuando el monto total después del descuento está entre',
                'Услуга «Наложенный платеж» доступна только в том случае, если общая сумма после скидки находится в пределах', 'O serviço Pagamento na entrega está disponível somente quando o valor total após o desconto estiver entre', '代金引換サービスは割引後の合計金額が',
                `تتوفر خدمة الدفع عند الاستلام فقط عندما يكون المبلغ الإجمالي بعد الخصم بين`]
        }, {
            text: 'and COD cost is not included',
            list: [`le coût n'est pas inclus`, 'Kosten sind nicht enthalten', 'el costo no está incluido',
                'и стоимость наложенного платежа не включена', 'e o custo de COD não está incluído', '代引手数料は含まれておりません',
                `ولا يتم تضمين تكلفة COD`]
        }, {
            text: 'Enter 4-digit Verify Code',
            list: ['Entrez le code de vérification à 4 chiffres', 'Geben Sie den 4-stelligen Bestätigungscode ein', 'Ingrese el código de verificación de 4 dígitos',
                'Введите 4-значный код подтверждения', 'Digite o código de verificação de 4 dígitos', '4 桁の確認コードを入力してください',
                `أدخل رمز التحقق المكون من 4 أرقام`]
        }, {
            text: 'Please enter phone number',
            list: ['Veuillez entrer le numéro de téléphone', 'Bitte Telefonnummer eingeben', 'Por favor ingrese el número de teléfono',
                'Пожалуйста, введите номер телефона', 'Por favor, insira o número de telefone', '電話番号を入力してください',
                `الرجاء إدخال رقم الهاتف`]
        }, {
            text: 'please enter verification code',
            list: ['veuillez entrer le code de vérification', 'Bitte geben Sie den Bestätigungscode ein', 'por favor ingrese el código de verificación',
                'пожалуйста, введите проверочный код', 'por favor insira o código de verificação', '確認コードを入力してください',
                `الرجاء إدخال رمز التحقق`]
        }, {
            text: 'Resend',
            list: ['Renvoyer', 'Erneut senden', 'reenviar',
                'Отправить', 'Reenviar', '再送',
                `إعادة إرسال`]
        }, {
            text: 'For the safety of your order, Please input the code which you received with our SMS',
            list: ['Pour la sécurité de votre commande, veuillez entrer le code que vous avez reçu avec notre SMS', 'Zur Sicherheit Ihrer Bestellung geben Sie bitte den Code ein, den Sie mit unserer SMS erhalten haben', 'Para la seguridad de su pedido, ingrese el código que recibió con nuestro SMS',
                'Для сохранности вашего заказа, пожалуйста, введите код, который вы получили с нашим SMS', 'Para a segurança da sua encomenda, por favor introduza o código que recebeu com o nosso SMS', 'ご注文の安全のため、SMSで受け取ったコードを入力してください',
                `من أجل سلامة طلبك ، يرجى إدخال الرمز الذي تلقيته من خلال رسائلنا النصية القصيرة`]
        }, {
            text: 'more to use',
            list: ['plus à utiliser', 'mehr zu verwenden', 'más para usar',
                'больше использовать', 'mais para usar', 'もっと使う',
                `أكثر للاستخدام`]
        }, {
            text: 'Online Payment Discount',
            list: ['Rabais de paiement en ligne', 'Online-Zahlungsrabatt', 'Descuento por pago en línea',
                'Скидка на оплату онлайн', 'Desconto de pagamento on-line', 'オンライン決済割引',
                `خصم الدفع عبر الإنترنت`]
        }, {
            text: 'l am not willing to pay the shipping fee',
            list: [`Je ne suis pas disposé à payer les frais d'expédition`, 'Ich bin nicht bereit, die Versandkosten zu zahlen', 'No estoy dispuesta a pagar la tarifa de envío',
                'Я не хочу платить за доставку', 'não estou disposto a pagar o frete', '送料を払いたくありません',
                `أنا لست على استعداد لدفع رسوم الشحن`]
        }, {
            text: 'Wrong order, will place a new one',
            list: ['Mauvaise commande, en passera une nouvelle', 'Falsche Bestellung, werde eine neue aufgeben', 'Pedido incorrecto, haré uno nuevo',
                'Неправильный заказ, разместим новый', 'Pedido errado, farei um novo', '間違った注文、新しいものを配置します',
                `طلب خاطئ ، سيضع واحدًا جديدًا`]
        }, {
            text: 'Payment was rejected',
            list: ['Le paiement a été rejeté', 'Zahlung wurde abgelehnt', 'El pago fue rechazado',
                'Платеж был отклонен', 'O pagamento foi rejeitado', '支払いが拒否されました',
                `تم رفض الدفع`]
        }, {
            text: 'I forgot my debit/credit card information',
            list: [`J'ai oublié mes informations de carte de débit/crédit`, 'Ich habe meine Debit-/Kreditkartendaten vergessen', 'Olvidé la información de mi tarjeta de débito/crédito',
                'Я забыл информацию о своей дебетовой/кредитной карте', 'Esqueci as informações do meu cartão de débito/crédito', 'デビット/クレジットカード情報を忘れた',
                `لقد نسيت معلومات بطاقة الخصم / الائتمان الخاصة بي`]
        }, {
            text: 'Did not offer the payment method l preferred',
            list: [`N'a pas proposé le mode de paiement que j'ai préféré`, 'Ich habe die von mir bevorzugte Zahlungsmethode nicht angeboten', 'No ofrecí el método de pago que prefería',
                'Не предложил способ оплаты, который я предпочитаю', 'Não ofereceu o método de pagamento que eu preferia', '希望する支払い方法を提供しなかった',
                `لم تقدم طريقة الدفع المفضلة l`]
        }, {
            text: 'Bad reviews',
            list: ['Mauvaises critiques', 'Schlechte Bewertungen', 'Malas críticas',
                'Плохие отзывы', 'Comentários ruins', '悪いレビュー',
                `مراجعات سيئة`]
        }, {
            text: `Don't want it`,
            list: ['Je ne le veux pas', 'Ich will es nicht', 'no lo quiero',
                'Не хочу этого', 'não quero isso', 'いらない',
                `لا تريد ذلك`]
        }, {
            text: 'Cheaper alternative available for lesser price',
            list: ['Alternative moins chère disponible pour un prix moindre', 'Billigere Alternative für geringeren Preis verfügbar', 'Alternativa más barata disponible por un precio menor',
                'Более дешевая альтернатива доступна за меньшую цену', 'Alternativa mais barata disponível por um preço menor', 'より安い価格で利用できるより安価な代替品',
                `بديل أرخص متاح بسعر أقل`]
        }, {
            text: 'Order Placed Successfully',
            list: ['Commande passée avec succès', 'Bestellung erfolgreich aufgegeben', 'Pedido realizado con éxito',
                'Заказ успешно размещен', 'Pedido feito com sucesso', '注文が正常に行われました',
                `تم تقديم الطلب بنجاح`]
        }, {
            text: 'Your payment is pending now, please be patience and we will also notify you via email about the payment',
            list: ['Votre paiement est en attente maintenant, veuillez patienter et nous vous informerons également par e-mail du paiement', 'Ihre Zahlung steht jetzt aus, bitte haben Sie etwas Geduld und wir werden Sie auch per E-Mail über die Zahlung benachrichtigen', 'Su pago está pendiente ahora, tenga paciencia y también le notificaremos por correo electrónico sobre el pago.',
                'Ваш платеж находится на рассмотрении, пожалуйста, будьте терпеливы, и мы также уведомим вас по электронной почте о платеже', 'Seu pagamento está pendente agora, tenha paciência e também o notificaremos por e-mail sobre o pagamento', 'お支払いは現在保留中です。しばらくお待ちください。お支払いについてはメールでもお知らせします。',
                `دفعتك معلقة الآن ، يرجى التحلي بالصبر وسنخطرك عبر البريد الإلكتروني بشأن الدفع`]
        }, {
            text: 'Sorry, this payment failed, you can try again or change the payment method',
            list: ['Désolé, ce paiement a échoué, vous pouvez réessayer ou changer le mode de paiement', 'Entschuldigung, diese Zahlung ist fehlgeschlagen. Sie können es erneut versuchen oder die Zahlungsmethode ändern', 'Lo sentimos, este pago falló, puedes intentarlo de nuevo o cambiar el método de pago',
                'К сожалению, этот платеж не прошел, попробуйте еще раз или измените способ оплаты.', 'Desculpe, este pagamento falhou, você pode tentar novamente ou alterar o método de pagamento', '申し訳ありませんが、この支払いは失敗しました。もう一度試すか、支払い方法を変更してください',
                `عذرًا ، فشل هذا الدفع ، يمكنك المحاولة مرة أخرى أو تغيير طريقة الدفع`]
        }, {
            text: 'You have cancelled your payment',
            list: ['Vous avez annulé votre paiement', 'Sie haben Ihre Zahlung storniert', 'Has cancelado tu pago',
                'Вы отменили платеж', 'Você cancelou seu pagamento', 'お支払いをキャンセルしました',
                `لقد ألغيت الدفع الخاص بك`]
        }, {
            text: 'Change Payment',
            list: ['Modifier le paiement', 'Zahlung ändern', 'Cambiar Pago',
                'Изменить платеж', 'Alterar pagamento', 'お支払いの変更',
                `تغيير الدفع`]
        }, {
            text: 'Try Again',
            list: ['Essayer à nouveau', 'Versuchen Sie es erneut', 'Intentar otra vez',
                'Попробуйте еще раз', 'Tente novamente', '再試行する',
                `حاول ثانية`]
        }, {
            text: 'Your order is under review, and we will process it within 3 days',
            list: [`Votre commande est en cours d'examen et nous la traiterons dans les 3 jours`, 'Ihre Bestellung wird überprüft und wir werden sie innerhalb von 3 Tagen bearbeiten', 'Su pedido está bajo revisión y lo procesaremos dentro de 3 días',
                'Ваш заказ находится на рассмотрении, и мы обработаем его в течение 3 дней.', 'Seu pedido está em análise e será processado em até 3 dias', 'ご注文は現在審査中です。3 日以内に処理いたします。',
                `طلبك قيد المراجعة وسنقوم بمعالجته في غضون 3 أيام`]
        }, {
            text: 'Middle Name',
            list: ['Deuxième nom', 'Zweiter Vorname', 'Segundo nombre',
                'Второе имя', 'Nome do meio', 'ミドルネーム',
                `الاسم الأوسط`]
        }, {
            text: 'Alternate Phone Number',
            list: ['Autre numéro de téléphone', 'Alternative Telefonnummer', 'Número de teléfono alternativo',
                'Альтернативный номер телефона', 'Número de telefone alternativo', '代替電話番号',
                `رقم هاتف بديل`]
        }, {
            text: 'National ID Number',
            list: [`numéro national d'identité`, 'nationale ID-Nummer', 'Numero de identificacion nacional',
                'Национальный идентификационный номер', 'Número de Identificação Nacional', '国民 ID 番号',
                `رقم الهوية الوطنية`]
        }, {
            text: 'Street should contain 5-30 characters',
            list: ['La rue doit contenir entre 5 et 30 caractères', 'Straße sollte 5-30 Zeichen enthalten', 'La calle debe contener de 5 a 30 caracteres',
                'Улица должна содержать от 5 до 30 символов.', 'A rua deve conter de 5 a 30 caracteres', '番地には 5 ～ 30 文字を含める必要があります',
                `يجب أن يحتوي الشارع على 5-30 حرفًا`]
        }, {
            text: 'For customs clearance purposes, please provide your ID',
            list: [`Pour les besoins du dédouanement, veuillez fournir votre pièce d'identité`, 'Bitte geben Sie für die Zollabfertigung Ihren Personalausweis an', 'Para fines de despacho de aduana, proporcione su identificación',
                'Для целей таможенного оформления, пожалуйста, предоставьте свое удостоверение личности', 'Para fins de desembaraço alfandegário, forneça seu ID', '通関手続きのため、身分証明書をご提示ください',
                `لأغراض التخليص الجمركي ، يرجى تقديم بطاقة الهوية الخاصة بك`]
        }, {
            text: 'What is the National ID Number',
            list: [`Quel est le numéro d'identification national`, 'Was ist die nationale ID-Nummer', '¿Qué es el número de identificación nacional',
                'Что такое национальный идентификационный номер', 'Qual é o número de identificação nacional', '国民 ID 番号とは',
                `ما هو رقم الهوية الوطنية`]
        }, {
            text: 'WHY NEED THE ID NUMBER',
            list: [`POURQUOI AVOIR BESOIN DU NUMÉRO D'IDENTIFICATION`, 'WARUM BENÖTIGEN SIE DIE ID-NUMMER', 'POR QUÉ NECESITA EL NÚMERO DE IDENTIFICACIÓN',
                'ЗАЧЕМ НУЖЕН ИДЕНТИФИКАЦИОННЫЙ НОМЕР', 'POR QUE PRECISA DO NÚMERO DE IDENTIFICAÇÃO', 'ID番号が必要な理由',
                `لماذا تحتاج إلى رقم الهوية`]
        }, {
            text: 'According to the new custom regulations of Saudi Arabia, consignee needs to offer provide their national ID number for the case of custom clearance.Providing the ID number attached will efficiently reduce the risk of package being delayed or detained by the random inspection at the customs',
            list: [`Selon les nouvelles réglementations douanières de l'Arabie saoudite, le destinataire doit proposer de fournir son numéro d'identification national en cas de dédouanement. La fourniture du numéro d'identification joint réduira efficacement le risque que le colis soit retardé ou retenu par l'inspection aléatoire à la douane.`,
                'Gemäß den neuen Zollbestimmungen von Saudi-Arabien muss der Empfänger seine nationale ID-Nummer für den Fall der Zollabfertigung angeben. Durch die Angabe der beigefügten ID-Nummer wird das Risiko, dass das Paket verzögert oder durch die zufällige Inspektion beim Zoll zurückgehalten wird, effizient verringert',
                'De acuerdo con las nuevas regulaciones aduaneras de Arabia Saudita, el destinatario debe ofrecer su número de identificación nacional para el caso de despacho de aduana. Proporcionar el número de identificación adjunto reducirá de manera eficiente el riesgo de que el paquete se retrase o sea detenido por la inspección aleatoria en la aduana.',
                'В соответствии с новыми таможенными правилами Саудовской Аравии грузополучатель должен предоставить свой национальный идентификационный номер в случае таможенного оформления. Предоставление прикрепленного идентификационного номера эффективно снизит риск задержки или задержания посылки в результате выборочной проверки на таможне.',
                'De acordo com os novos regulamentos alfandegários da Arábia Saudita, o destinatário precisa fornecer seu número de identificação nacional para o caso de desembaraço alfandegário. Fornecer o número de identificação anexado reduzirá com eficiência o risco de atraso ou retenção do pacote pela inspeção aleatória na alfândega',
                'サウジアラビアの新しい通関規則によると、荷受人は通関の際に国民 ID 番号を提供する必要があります。添付された ID 番号を提供することで、税関での無作為検査によって荷物が遅延または留置されるリスクを効率的に軽減できます。',
                `وفقًا للوائح الجمركية الجديدة في المملكة العربية السعودية ، يتعين على المرسل إليه تقديم رقم الهوية الوطنية الخاص به في حالة التخليص الجمركي ، كما أن توفير رقم الهوية المرفق سيقلل بشكل فعال من خطر تأخير الطرد أو احتجازه من خلال التفتيش العشوائي في الجمارك`]
        }, {
            text: 'YFN promises that your personal information is confidential and will not be shared with any third party for commercial purposes',
            list: ['YFN promet que vos informations personnelles sont confidentielles et ne seront partagées avec aucun tiers à des fins commerciales', 'YFN verspricht, dass Ihre persönlichen Daten vertraulich sind und nicht zu kommerziellen Zwecken an Dritte weitergegeben werden', 'YFN promete que su información personal es confidencial y no será compartida con terceros con fines comerciales.',
                'YFN обещает, что ваша личная информация является конфиденциальной и не будет передана третьим лицам в коммерческих целях.', 'YFN promete que suas informações pessoais são confidenciais e não serão compartilhadas com terceiros para fins comerciais', 'YFN は、お客様の個人情報が機密であり、商業目的で第三者と共有されないことを約束します',
                `تتعهد YFN بأن معلوماتك الشخصية سرية ولن يتم مشاركتها مع أي طرف ثالث لأغراض تجارية`]
        }, {
            text: 'Please log in first',
            list: [`S'il vous plait Connectez-vous d'abord`, 'Bitte loggen Sie sich zuerst ein', 'Por favor ingresa primero',
                'Пожалуйста, войдите сначала', 'Por favor, faça o login primeiro', '最初にログインしてください',
                `الرجاء تسجيل الدخول أولا`]
        }, {
            text: 'Added to bag Successfully',
            list: ['Ajouté au panier avec succès', 'Erfolgreich zum Warenkorb hinzugefügt', 'Agregado a la bolsa con éxito',
                'Добавлено в корзину Успешно', 'Adicionado ao saco com sucesso', 'バッグに追加しました',
                `تمت الإضافة إلى الحقيبة بنجاح`]
        }, {
            text: 'Please Select Style',
            list: ['Veuillez sélectionner le style', 'Bitte Stil auswählen', 'Seleccione el estilo',
                'Пожалуйста выберите Стиль', 'Selecione o estilo', 'スタイルを選択してください',
                `يرجى تحديد النمط`]
        }, {
            text: 'Purchase per new user for New User Price',
            list: ['Achat par nouvel utilisateur pour le prix du nouvel utilisateur', 'Kauf pro neuem Benutzer zum Preis für neue Benutzer', 'Compra por nuevo usuario por precio de nuevo usuario',
                'Покупка на нового пользователя по цене нового пользователя', 'Compra por novo usuário pelo preço de novo usuário', '新規ユーザー価格で新規ユーザーごとに購入',
                `الشراء لكل مستخدم جديد بسعر المستخدم الجديد`]
        }, {
            text: 'Rules',
            list: ['Règles', 'Regeln', 'Normas',
                'Правила', 'Regras', 'ルール',
                `قواعد`]
        }, {
            text: 'Each new user would only get one NewUser-Price item',
            list: [`Chaque nouvel utilisateur n'obtiendrait qu'un seul article NewUser-Price`, 'Jeder neue Benutzer würde nur einen NewUser-Price-Artikel erhalten', 'Cada nuevo usuario solo obtendría un elemento NewUser-Price',
                'Каждый новый пользователь получит только один предмет NewUser-Price.', 'Cada novo usuário obteria apenas um item NewUser-Price', '各新規ユーザーは、NewUser-Price アイテムを 1 つだけ取得します。',
                `سيحصل كل مستخدم جديد على عنصر سعر مستخدم جديد واحد فقط`]
        }, {
            text: 'Click the "change" button and choose the item to enjoy the discount',
            list: [`Cliquez sur le bouton "Modifier" et choisissez l'article pour profiter de la remise`, 'Klicken Sie auf die Schaltfläche "Ändern" und wählen Sie den Artikel aus, um den Rabatt zu erhalten', `Haz clic en el botón "cambiar" y elige el artículo para disfrutar del descuento`,
                'Нажмите кнопку «Изменить» и выберите товар, чтобы воспользоваться скидкой.', 'Clique no botão "alterar" e escolha o item para aproveitar o desconto', '「変更」ボタンをクリックして、割引を楽しむアイテムを選択してください',
                `انقر فوق الزر "تغيير" واختر العنصر للاستمتاع بالخصم`]
        }, {
            text: 'New User Price',
            list: ['Nouveau prix utilisateur', 'Preis für neue Benutzer', 'Precio de nuevo usuario',
                'Цена нового пользователя', 'Preço de novo usuário', '新規ユーザー価格',
                `سعر المستخدم الجديد`]
        }, {
            text: 'Got it',
            list: [`J'ai compris`, 'Habe es', 'Entiendo',
                'Понятно', 'Entendi', 'とった',
                `فهمتها`]
        }, {
            text: 'New User Only',
            list: ['Nouvel utilisateur uniquement', 'Nur für neue Benutzer', 'Solo nuevo usuario',
                'Только новый пользователь', 'Somente novo usuário', '新規ユーザーのみ',
                `مستخدم جديد فقط`]
        }, {
            text: `Please select the items you'd like to checkout with`,
            list: ['Veuillez sélectionner les articles avec lesquels vous souhaitez payer', 'Bitte wählen Sie die Artikel aus, mit denen Sie zur Kasse gehen möchten', 'Seleccione los artículos con los que desea pagar',
                'Пожалуйста, выберите товары, с которыми вы хотите оформить заказ', 'Selecione os itens com os quais deseja finalizar a compra', 'チェックアウトするアイテムを選択してください',
                `الرجاء تحديد العناصر التي ترغب في الخروج بها`]
        }, {
            text: 'Email/Order number/Tracking number',
            list: ['E-mail/numéro de commande/numéro de suivi', 'E-Mail/Bestellnummer/Tracking-Nummer', 'Correo electrónico/Número de pedido/Número de seguimiento',
                'Электронная почта/номер заказа/номер отслеживания', 'E-mail/Número do pedido/Número de rastreamento', 'メール/注文番号/追跡番号',
                `البريد الإلكتروني / رقم الطلب / رقم التتبع`]
        }, {
            text: 'No items found',
            list: ['Aucun élément trouvé', 'Keine Elemente gefunden', 'No se encontraron artículos',
                'ничего не найдено', 'Nenhum item encontrado', '項目は見つかりませんでした',
                `لم يتم العثور على العناصر`]
        }, {
            text: 'Try something like',
            list: ['Essayez quelque chose comme', 'Versuchen Sie so etwas wie', 'Prueba algo como',
                'Попробуйте что-то вроде', 'Tente algo como', '次のようなものを試してください',
                `جرب شيئًا مثل`]
        }, {
            text: 'Use more general terms',
            list: ['Utiliser des termes plus généraux', 'Verwenden Sie allgemeinere Begriffe', 'Usar términos más generales',
                'Используйте более общие термины', 'Use termos mais gerais', 'より一般的な用語を使用する',
                `استخدم مصطلحات أكثر عمومية`]
        }, {
            text: 'Check your spelling',
            list: ['Vérifie ton orthographe', 'Überprüfe deine Rechtschreibung', 'Corrige tu ortografía',
                'Проверить написание', 'Verifique sua ortografia', 'スペルをチェックする',
                `تفقد تهجئتك`]
        }, {
            text: 'Please Enter',
            list: [`Entrez s'il vous plait`, 'Bitte eingeben', 'Por favor escribe',
                'Пожалуйста входите', 'Por favor, insira', '入ってください',
                `Please Enter`]
        }, {
            text: `To fulfill the order faster, we'll pack your items in several packages. Your understanding will be really appreciated`,
            list: ['Pour traiter la commande plus rapidement, nous emballons vos articles en plusieurs colis. Votre compréhension sera vraiment appréciée',
                'Um die Bestellung schneller auszuführen, verpacken wir Ihre Artikel in mehreren Paketen. Ihr Verständnis wird wirklich geschätzt',
                'Para completar el pedido más rápido, empaquetaremos sus artículos en varios paquetes. Su comprensión será muy apreciada.',
                'Чтобы быстрее выполнить заказ, мы упакуем ваши вещи в несколько пакетов. Ваше понимание будет действительно оценено',
                'Para atender o pedido mais rapidamente, embalaremos seus itens em vários pacotes. Sua compreensão será muito apreciada',
                'ご注文をより迅速に処理するために、商品を複数のパッケージに分けて梱包します。ご理解のほどよろしくお願いいたします',
                `لتلبية الطلب بشكل أسرع ، سنقوم بتعبئة أغراضك في عدة حزم. سيكون فهمك موضع تقدير حقًا`]
        }, {
            text: 'Dear customer, If you want to edit shipping address, please contact us',
            list: [`Cher client, Si vous souhaitez modifier l'adresse de livraison, veuillez nous contacter`,
                'Sehr geehrter Kunde, wenn Sie die Lieferadresse bearbeiten möchten, kontaktieren Sie uns bitte',
                'Estimado cliente, si desea editar la dirección de envío, contáctenos',
                'Уважаемый клиент, Если вы хотите изменить адрес доставки, пожалуйста, свяжитесь с нами',
                'Prezado cliente, Se você deseja editar o endereço de entrega, entre em contato conosco',
                '親愛なるお客様、配送先住所を編集したい場合は、お問い合わせください',
                `عزيزي العميل ، إذا كنت ترغب في تعديل عنوان الشحن ، يرجى الاتصال بنا`]
        }, {
            text: 'Dear customer, If you want to refund this order, please contact us',
            list: ['Cher client, Si vous souhaitez rembourser cette commande, veuillez nous contacter',
                'Sehr geehrter Kunde, wenn Sie diese Bestellung zurückerstatten möchten, kontaktieren Sie uns bitte',
                'Estimado cliente, si desea reembolsar este pedido, contáctenos',
                'Уважаемый клиент, если вы хотите вернуть этот заказ, пожалуйста, свяжитесь с нами',
                'Prezado cliente, Se você deseja reembolsar este pedido, entre em contato conosco',
                '親愛なるお客様、この注文を返金したい場合は、お問い合わせください',
                `عزيزي العميل ، إذا كنت ترغب في رد أموال هذا الطلب ، فيرجى الاتصال بنا`]
        }, {
            text: 'Dear customer, this order has been shipped, If you want to return this item(s), please contact us',
            list: ['Cher client, cette commande a été expédiée, si vous souhaitez retourner ce(s) article(s), veuillez nous contacter',
                'Sehr geehrter Kunde, diese Bestellung wurde versandt. Wenn Sie diese(n) Artikel zurücksenden möchten, kontaktieren Sie uns bitte',
                'Estimado cliente, este pedido ha sido enviado. Si desea devolver este (s) artículo (s), contáctenos',
                'Уважаемый клиент, этот заказ был отправлен, если вы хотите вернуть этот товар (ы), пожалуйста, свяжитесь с нами',
                'Prezado cliente, este pedido foi enviado, se você quiser devolver este(s) item(ns), entre em contato conosco',
                '親愛なるお客様、この注文は発送されました。この商品を返品したい場合は、お問い合わせください',
                `عزيزي العميل ، لقد تم شحن هذا الطلب ، إذا كنت ترغب في إرجاع هذا العنصر (العناصر) ، يرجى الاتصال بنا`]
        }, {
            text: 'Dear customer, If you want to return this order, please contact us',
            list: ['Cher client, Si vous souhaitez retourner cette commande, veuillez nous contacter',
                'Sehr geehrter Kunde, wenn Sie diese Bestellung zurücksenden möchten, kontaktieren Sie uns bitte',
                'Estimado cliente, si desea devolver este pedido, contáctenos',
                'Уважаемый клиент, если вы хотите вернуть этот заказ, пожалуйста, свяжитесь с нами',
                'Estimado cliente, Se pretender devolver esta encomenda, por favor contacte-nos',
                '親愛なるお客様、この注文を返品したい場合は、お問い合わせください',
                `عزيزي العميل ، إذا كنت ترغب في إرجاع هذا الطلب ، فيرجى الاتصال بنا`]
        }, {
            text: 'Dear customer, If you want to cancel this order, please contact us',
            list: ['Cher client, Si vous souhaitez annuler cette commande, veuillez nous contacter',
                'Sehr geehrter Kunde, wenn Sie diese Bestellung stornieren möchten, kontaktieren Sie uns bitte',
                'Estimado cliente, si desea cancelar este pedido, contáctenos',
                'Уважаемый клиент, Если вы хотите отменить этот заказ, пожалуйста, свяжитесь с нами.',
                'Prezado cliente, Se você deseja cancelar este pedido, entre em contato conosco',
                '親愛なるお客様、この注文をキャンセルしたい場合は、お問い合わせください',
                `عزيزي العميل ، إذا كنت ترغب في إلغاء هذا الطلب ، فيرجى الاتصال بنا`]
        }, {
            text: 'Your feedback or suggestions will urge us to do better',
            list: ['Vos commentaires ou suggestions nous inciteront à faire mieux',
                'Ihr Feedback oder Ihre Vorschläge werden uns anspornen, es besser zu machen',
                'Sus comentarios o sugerencias nos impulsarán a hacerlo mejor.',
                'Ваши отзывы или предложения побудят нас работать лучше',
                'Seus comentários ou sugestões nos incitarão a fazer melhor',
                'あなたのフィードバックや提案は、私たちをより良くするように促します',
                `ستحثنا ملاحظاتك أو اقتراحاتك على القيام بعمل أفضل`]
        }, {
            text: 'Are you satisfied with logistic service',
            list: ['Êtes-vous satisfait du service logistique',
                'Sind Sie mit dem Logistikservice zufrieden',
                '¿Está satisfecho con el servicio logístico',
                'Довольны ли вы логистическим сервисом',
                'Você está satisfeito com o serviço logístico',
                '物流サービスに満足していますか',
                `هل أنت راض عن الخدمة اللوجستية`]
        }, {
            text: 'Review more items',
            list: [`Examiner plus d'articles`, 'Überprüfen Sie weitere Artikel', 'Revisar más artículos',
                'Просмотрите больше элементов', 'Avalie mais itens', 'さらにアイテムをレビュー',
                `راجع المزيد من العناصر`]
        }, {
            text: 'Check My Review',
            list: ['Vérifier mon avis', 'Überprüfen Sie meine Bewertung', 'Revisar mi revisión',
                'Проверьте мой обзор', 'Confira minha avaliação', '私のレビューをチェック',
                `تحقق تقييمي`]
        }, {
            text: 'Back to Homepage',
            list: [`Retour à la page d'accueil`, 'Zurück zur Startseite', 'Volver a la página de inicio',
                'Назад на главную', 'Voltar à página inicial', 'ホームページに戻ります',
                `العودة إلى الصفحة الرئيسية`]
        }, {
            text: 'Submitted Successfully',
            list: ['Soumis avec succès', 'Erfolgreich eingereicht', 'Enviado satisfactoriamente',
                'Отправлено успешно', 'Submetido com sucesso', '正常に送信されました',
                `تم الإرسال بنجاح`]
        }, {
            text: 'This order has been reviewed, please do not repeat review',
            list: [`Cette commande a été examinée, veuillez ne pas répéter l'examen`,
                'Diese Bestellung wurde überprüft, bitte wiederholen Sie die Überprüfung nicht',
                'Este pedido ha sido revisado, por favor no repita la revisión',
                'Этот заказ был проверен, пожалуйста, не повторяйте проверку',
                'Este pedido foi revisado, por favor, não repita a revisão',
                'この注文は審査済みです。審査を繰り返さないでください',
                `تمت مراجعة هذا الطلب ، يرجى عدم تكرار المراجعة`]
        }, {
            text: 'Thank you for your review',
            list: ['Merci pour votre avis', 'Vielen Dank für Ihre Bewertung', 'gracias por tu reseña',
                'Спасибо за отзыв', 'Obrigado pela sua avaliação', 'レビューありがとうございます',
                `شكرا لك، لمراجعتك`]
        }, {
            text: 'Top Reviewed Items',
            list: ['Articles les plus évalués', 'Am besten bewertete Artikel', 'Principales artículos revisados',
                'Самые популярные товары', 'Principais itens avaliados', 'トップレビューアイテム',
                `أعلى العناصر التي تمت مراجعتها`]
        }, {
            text: 'Each new user would onily get one NewUser-Price item',
            list: [
                `Chaque nouvel utilisateur n'obtiendrait qu'un seul article NewUser-Price`,
                'Jeder neue Benutzer würde nur einen NewUser-Price-Artikel erhalten',
                'Cada nuevo usuario solo obtendría un elemento NewUser-Price',
                'Каждый новый пользователь получит только один предмет NewUser-Price',
                'Cada novo usuário obteria apenas um item NewUser-Price',
                '各新規ユーザーは、NewUser-Price アイテムを 1 つだけ取得します',
                `سيحصل كل مستخدم جديد بمفرده على عنصر NewUser-Price واحد`]
        }, {
            text: 'New User Exclusive Discount',
            list: ['Remise exclusive pour les nouveaux utilisateurs', 'Exklusiver Rabatt für neue Benutzer', 'Descuento exclusivo para nuevos usuarios',
                'Эксклюзивная скидка для новых пользователей', 'Desconto exclusivo para novos usuários', '新規ユーザー限定割引',
                `خصم حصري للمستخدم الجديد`]
        }, {
            text: 'My Review',
            list: ['Mon avis', 'Meine Bewertung', 'Mi reseña',
                'Мой отзыв', 'minha opinião', '私のレビュー',
                `تقييمي`]
        }, {
            text: 'COD Fee',
            list: ['COD Frais', 'COD Gebühr', 'COD Tarifa',
                'COD Платеж', 'COD Taxa', 'COD 手数料',
                `رسوم الدفع عند الإستلام`]
        }, {
            text: 'Estimated Delivery Time',
            list: ['temps de livraison estimé', 'Voraussichtliche Lieferzeit', 'Tiempo de entrega estimado',
                'Расчетное время доставки', 'Tempo de entrega estimado', '推定配達時間',
                `يقدر وقت التسليم`]
        }, {
            text: `To fulfill the order faster, we'll pack your items
            in several packages. Your understanding will be really appreciated`,
            list: [`Pour traiter la commande plus rapidement, nous emballons vos articles
            en plusieurs colis. Votre compréhension sera vraiment appréciée`,
                `Um die Bestellung schneller auszuführen, verpacken wir Ihre Artikel
            in mehreren Paketen. Ihr Verständnis wird wirklich geschätzt`,
                `Para completar el pedido más rápido, empaquetaremos sus artículos
            en varios paquetes. Su comprensión será muy apreciada`,
                `Чтобы быстрее выполнить заказ, мы упакуем ваши вещи
            в нескольких упаковках. Ваше понимание будет действительно оценено`,
                `Para atender o pedido mais rapidamente, embalaremos seus itens
            em vários pacotes. Sua compreensão será muito apreciada`,
                `注文をより迅速に処理するために、商品を梱包します
            いくつかのパッケージで。ご理解のほどよろしくお願いいたします`,
                `لتنفيذ الطلب بشكل أسرع ، سنقوم بتعبئة أغراضك
            في عدة مجموعات. سيكون فهمك موضع تقدير حقًا`]
        }, {
            text: 'Logistics Company',
            list: ['Entreprise de logistique', 'Logistikunternehmen', 'Compañía de logísitca',
                'Логистическая компания', 'Empresa de logística', '物流会社',
                `شركة التخطيط والتنفيذ`]
        }, {
            text: 'Some content is not filled',
            list: ['Certains contenus ne sont pas remplis',
                'Einige Inhalte sind nicht ausgefüllt',
                'Parte del contenido no está lleno',
                'Некоторый контент не заполнен',
                'Algum conteúdo não está preenchido',
                '一部のコンテンツが満たされていない',
                `لم يتم ملء بعض المحتويات`]
        }, {
            text: 'Are you sure to leave',
            list: ['Êtes-vous sûr de partir',
                'Bist du sicher zu gehen',
                'Estas seguro de irte',
                'Ты уверен, что уйдешь?',
                'Tem certeza que vai embora?',
                '本当に出発しますか',
                `هل أنت متأكد من المغادرة`]
        }, {
            text: 'Best Selling',
            list: ['Meilleure vente', 'Meistverkauft', 'Más vendido',
                'Бестселлер', 'Mais vendido', 'ベストセラー',
                `أفضل مبيعات`]
        }, {
            text: 'VIP SERVICE',
            list: ['SERVICES VIP', 'VIP-SERVICE', 'SERVICIO VIP',
                'VIP-ОБСЛУЖИВАНИЕ', 'SERVIÇO VIP', 'VIPサービス',
                `خدمة كبار الشخصيات`]
        }, {
            text: 'Please complete the logistics review',
            list: [`Veuillez compléter l'examen logistique`, 'Bitte füllen Sie die Logistikprüfung aus', 'Complete la revisión de logística',
                'Пожалуйста, заполните обзор логистики', 'Por favor, complete a revisão de logística', '物流レビューを完了してください',
                `من فضلك أكمل المراجعة اللوجستية`]
        }, {
            text: 'See more',
            list: ['Voir plus', 'Mehr sehen', 'Ver más',
                'Узнать больше', 'Ver mais', '続きを見る',
                `شاهد المزيد`]
        }, {
            text: 'See less',
            list: ['Voir moins', 'Weniger sehen', 'Ver menos',
                'Увидеть меньше', 'ver menos', 'もっと見る',
                `رؤية أقل`]
        }, {
            text: 'Please choose your satisfied/unsatisfied reason',
            list: ['Veuillez choisir votre motif satisfait/insatisfait',
                'Bitte wählen Sie den Grund für Ihre Zufriedenheit/Unzufriedenheit aus',
                'Por favor, elija su motivo satisfecho/insatisfecho',
                'Пожалуйста, выберите удовлетворенную/неудовлетворенную причину',
                'Por favor, escolha o motivo da sua satisfação/insatisfação',
                '満足/不満の理由を選択してください',
                `الرجاء اختيار سبب راضٍ / غير راضٍ`]
        }, {
            text: 'Rating is required',
            list: ['Une évaluation est requise',
                'Eine Bewertung ist erforderlich',
                'Se requiere calificación',
                'Требуется рейтинг',
                'A classificação é obrigatória',
                '評価は必須です',
                `التصنيف مطلوب`]
        }, {
            text: 'How about this jewelry? share with others(optional)',
            list: [`Que diriez-vous de ce bijou? partager avec d'autres (facultatif)`,
                'Wie wäre es mit diesem Schmuck? Mit anderen teilen (optional)',
                '¿Qué tal esta joyería? compartir con otros (opcional)',
                'Как насчет этого украшения? поделиться с другими (необязательно)',
                'Que tal essa joia? compartilhar com outras pessoas (opcional)',
                'このジュエリーはどうでしょうか？他の人と共有する(オプション)',
                `ماذا عن هذه المجوهرات؟ شارك مع الاخرين (اختياري)`]
        }, {
            text: 'You have no associated order',
            list: [`Vous n'avez pas de commande associée`,
                'Sie haben keine zugehörige Bestellung',
                'No tienes pedido asociado',
                'У вас нет связанного заказа',
                'Você não tem nenhum pedido associado',
                '関連付けられた注文はありません',
                `ليس لديك طلب مرتبط`]
        }, {
            text: 'Your suggestions will urge us to do better',
            list: ['Vos suggestions nous pousseront à faire mieux',
                'Ihre Vorschläge werden uns dazu anspornen, es besser zu machen',
                'Sus sugerencias nos impulsarán a hacerlo mejor',
                'Ваши предложения побудят нас работать лучше',
                'Suas sugestões nos incitarão a fazer melhor',
                'あなたの提案は私たちをより良くするよう促します',
                `ستحثنا اقتراحاتك على القيام بعمل أفضل`]
        }, {
            text: 'Order ID/Product Name',
            list: ['Numéro de commande/Nom du produit',
                'Bestell-ID/Produktname',
                'ID de pedido/Nombre del producto',
                'Идентификатор заказа/название продукта',
                'ID do pedido/Nome do produto',
                '注文ID/商品名',
                `معرف الطلب / اسم المنتج`]
        }, {
            text: 'No Order found',
            list: ['Aucune commande trouvée',
                'Keine Bestellung gefunden',
                'No se encontró ningún pedido',
                'Заказ не найден',
                'Nenhum pedido encontrado',
                '注文が見つかりませんでした',
                `لم يتم العثور على طلب`]
        }, {
            text: 'Thank you for shopping with us. An Order Confirmation email has been sent to',
            list: [
                `Merci d'avoir magasiné avec nous. Un e-mail de confirmation de commande a été envoyé à`,
                `Danke für ihren Einkauf. Eine Bestellbestätigungs-E-Mail wurde an gesendet`,
                `Gracias por comprar con nosotros. Se ha enviado un correo electrónico de confirmación de pedido a`,
                `Спасибо за покупку. Письмо с подтверждением заказа отправлено на`,
                `Obrigado por comprar conosco. Um e-mail de confirmação do pedido foi enviado para`,
                `ありがとうございます。注文確認メールが送信されました`,
                `شكرا للتسوق معنا. تم إرسال بريد إلكتروني لتأكيد الطلب إلى`]
        }, {
            text: 'Order Details',
            list: [
                `détails de la commande`,
                `Bestelldetails`,
                `Detalles del pedido`,
                `Информация для заказа`,
                `detalhes do pedido`,
                `注文詳細`,
                `تفاصيل الطلب`]
        }, {
            text: 'Site Map',
            list: [
                `Plan du site`,
                `Seitenverzeichnis`,
                `Mapa del sitio`,
                `Карта сайта`,
                `Mapa do site`,
                `サイトマップ`,
                `خريطة الموقع`]
        }, {
            text: 'FAQ about',
            list: [
                `FAQ sur`,
                `FAQ zu `,
                `Preguntas frecuentes sobre`,
                `Часто задаваемые вопросы `,
                `Perguntas frequentes sobre`,
                `G カラー ダイヤモンドに関するよくある質問`,
                `أسئلة وأجوبة حول `,]
        }, {
            text: 'Explore More',
            list: [
                `Explore plus`,
                `Erkunde mehr`,
                `Explora más`,
                `Узнайте больше`,
                `Explore mais`,
                `もっと詳しく見る`,
                `استكشاف المزيد`]
        }, {
            text: 'You can try some other keywords',
            list: [
                `Vous pouvez essayer d'autres mots clés`,
                `Sie können einige andere Schlüsselwörter ausprobieren`,
                `Puedes probar con otras palabras clave`,
                `Вы можете попробовать другие ключевые слова`,
                `Você pode tentar algumas outras palavras-chave`,
                `他のキーワードを試してみてください`,
                `يمكنك تجربة بعض الكلمات الرئيسية الأخرى`]
        }, {
            text: 'All prices include VAT',
            list: [
                `Tous les prix incluent la TVA`,
                `Alle Preisangaben inkl. MwSt`,
                `Todos los precios incluyen IVA`,
                `Все цены включают НДС`,
                `Todos os preços incluem IVA`,
                `すべての価格には消費税が含まれています`,
                `تشمل جميع الأسعار ضريبة القيمة المضافة`]
        }, {
            text: 'Good logistics service attitude',
            list: [
                `Bonne attitude de service logistique`,
                `Gute Logistikdienstleistungseinstellung`,
                `Buena actitud de servicio logístico.`,
                `Хорошее отношение службы логистики`,
                `Boa atitude de serviço de logística`,
                `優れた物流サービス態度`,
                `موقف الخدمة اللوجستية الجيدة`]
        }, {
            text: 'Fast delivery',
            list: [
                `Livraison rapide`,
                `Schnelle Lieferung`,
                `Entrega rápida`,
                `Быстрая доставка`,
                `Entrega rápida`,
                `迅速な配達`,
                `توصيل سريع`]
        }, {
            text: 'Good packing service',
            list: [
                `Bon service d'emballage`,
                `Guter Verpackungsservice`,
                `Buen servicio de embalaje`,
                `Хороший сервис по упаковке`,
                `Bom serviço de embalagem`,
                `良い梱包サービス`,
                `خدمة تعبئة جيدة`]
        }, {
            text: 'Delivery on time',
            list: [
                `Livraison à temps`,
                `Lieferung pünktlich`,
                `Entrega a tiempo`,
                `Доставка вовремя`,
                `Entrega no prazo`,
                `時間どおりに配達`,
                `توصيلة على الوقت`]
        }, {
            text: 'Delivery time is too long',
            list: [
                `Le délai de livraison est trop long`,
                `Die Lieferzeit ist zu lang`,
                `El tiempo de entrega es demasiado largo`,
                `Срок доставки слишком длинный`,
                `O prazo de entrega é muito longo`,
                `納期が長すぎます`,
                `وقت التسليم طويل جدا`]
        }, {
            text: 'Bad logistics service attitude',
            list: [
                `Mauvaise attitude de service logistique`,
                `Schlechte Einstellung zum Logistikdienstleister`,
                `Mala actitud de servicio de logística.`,
                `Плохое отношение службы логистики`,
                `Atitude de serviço de logística ruim`,
                `物流サービスの態度が悪い`,
                `موقف خدمة لوجستية سيئة`]
        }, {
            text: `Don't deliver till home`,
            list: [
                `Ne pas livrer avant la maison`,
                `Nicht bis nach Hause liefern`,
                `No entregues hasta casa`,
                `Не доставлять до дома`,
                `Não entregue até em casa`,
                `家まで配達しないでください`,
                `لا توصل حتى المنزل`]
        }, {
            text: `Don't deliver at scheduled time`,
            list: [
                `Ne pas livrer à l'heure prévue`,
                `Liefern Sie nicht zum vereinbarten Zeitpunkt`,
                `No entregar a la hora programada`,
                `Не доставлять в назначенное время`,
                `Não entrega no horário agendado`,
                `予定時間通りに配達しない`,
                `لا تقدم في الوقت المحدد`]
        }, {
            text: `Bad packing service`,
            list: [
                `Mauvais service d'emballage`,
                `Schlechter Verpackungsservice`,
                `mal servicio de embalaje`,
                `Плохая служба упаковки`,
                `Serviço de embalagem ruim`,
                `悪い梱包サービス`,
                `خدمة تعبئة سيئة`]
        }, {
            text: 'View Products',
            list: [
                `Voir les produits`,
                `Produkte anzeigen`,
                `Ver productos`,
                `Посмотреть продукты`,
                `Ver produtos`,
                `製品を見る`,
                `مشاهدة المنتجات`]
        }, {
            text: 'Sorry, Greeting Card can be only bought together with Jewelry Items',
            list: [
                `Désolé, la carte de vœux ne peut être achetée qu'avec des bijoux`,
                `Leider kann die Grußkarte nur zusammen mit Schmuckstücken gekauft werden`,
                `Lo sentimos, la tarjeta de felicitación solo se puede comprar junto con artículos de joyería`,
                `К сожалению, поздравительную открытку можно купить только вместе с ювелирными изделиями`,
                `Desculpe, o cartão comemorativo só pode ser comprado junto com itens de joalheria`,
                `申し訳ありませんが、グリーティングカードはジュエリーアイテムと一緒にのみ購入できます`,
                `عذرًا ، لا يمكن شراء بطاقة التهنئة إلا مع عناصر المجوهرات`]
        }, {
            text: 'Something nice We Highly Recommended',
            list: [
                `Quelque chose de bien que nous recommandons fortement`,
                `Etwas Schönes, das wir wärmstens empfehlen können`,
                `Algo agradable Muy recomendable`,
                `Что-то хорошее, что мы настоятельно рекомендуем`,
                `Algo legal Nós altamente recomendados`,
                `何か素敵なものを強くお勧めします`,
                `شيء لطيف نوصي بشدة`]
        }, {
            text: 'Add one more Greeting Card',
            list: [
                `Ajouter un autre Carte de vœux`,
                `Fügen Sie eine weitere Grußkarte hinzu`,
                `Agrega uno más Tarjetas de felicitación`,
                `Добавьте еще одну поздравительную открытку`,
                `Adicionar mais um Cartão de felicitações`,
                `グリーティングカードをもう 1 枚追加`,
                `أضف بطاقة تهنئة أخرى`]
        }, {
            text: 'Greeting Card',
            list: [
                `Carte de voeux`,
                `Grußkarte`,
                `Tarjeta de felicitación`,
                `Открытка`,
                `cartão`,
                `グリーティングカード`,
                `بطاقة تحية`]
        }, {
            text: 'Add a Greeting Card',
            list: [
                `Ajouter une carte de vœux`,
                `Fügen Sie eine Grußkarte hinzu`,
                `Agregar una tarjeta de felicitación`,
                `Добавить поздравительную открытку`,
                `Adicionar um cartão`,
                `グリーティングカードを追加する`,
                `أضف بطاقة ترحيب`]
        }, {
            text: 'Balance',
            list: [
                `Équilibre`,
                `Gleichgewicht`,
                `Balance`,
                `Баланс`,
                `Equilíbrio`,
                `バランス`,
                `توازن`]
        }, {
            text: 'Expires soon',
            list: [
                `Expire bientôt`,
                `Läuft bald ab`,
                `Caduca pronto`,
                `Истекает скоро`,
                `Expira em breve`,
                `もうすぐ期限切れになります`,
                `تنتهي قريبا`]
        }, {
            text: 'wallet',
            list: [
                `portefeuille`,
                `Geldbörse`,
                `billetera`,
                `кошелек`,
                `carteira`,
                `財布`,
                `محفظة`]
        }, {
            text: 'Frozen amount',
            list: [
                `Montant gelé`,
                `Gefrorene Menge`,
                `Cantidad congelada`,
                `Замороженная сумма`,
                `quantidade congelada`,
                `凍結量`,
                `كمية مجمدة`]
        }, {
            text: 'It is empty here',
            list: [
                `C'est vide ici`,
                `Hier ist es leer`,
                `esta vacio aqui`,
                `здесь пусто`,
                `está vazio aqui`,
                `ここは空いています`,
                `إنه فارغ هنا`]
        }, {
            text: 'Max Available',
            list: [
                `Max disponible`,
                `Maximal verfügbar`,
                `Máximo disponible`,
                `Макс. доступно`,
                `Máximo disponível`,
                `利用可能な最大値`,
                `ماكس متاح`]
        }, {
            text: 'Use Wallet',
            list: [
                `Utiliser le portefeuille`,
                `Verwenden Sie Wallet`,
                `Usar billetera`,
                `Использовать кошелек`,
                `Usar Carteira`,
                `ウォレットを使用する`,
                `استخدم Wallet`]
        }, {
            text: 'Input amount',
            list: [
                `Montant d'entrée`,
                `Eingabebetrag`,
                `Cantidad de entrada`,
                `Сумма ввода`,
                `Valor de entrada`,
                `投入量`,
                `كمية المدخلات`]
        }, {
            text: 'Customizations',
            list: [
                `Personnalisations`,
                `Anpassungen`,
                `Personalizaciones`,
                `Настройки`,
                `personalizações`,
                `カスタマイズ`,
                `التخصيصات`]
        }, {
            text: 'View',
            list: [
                `Voir`,
                `Sicht`,
                `Vista`,
                `Вид`,
                `Visualizar`,
                `意見`,
                `منظر`]
        }, {
            text: '8 to 16 characters',
            list: [
                `8 à 16 caractères`,
                `8 bis 16 Zeichen`,
                `8 a 16 caracteres`,
                `от 8 до 16 символов`,
                `8 a 16 caracteres`,
                `8～16文字`,
                `من 8 إلى 16 حرفًا`]
        }, {
            text: 'At least one letter',
            list: [
                `Au moins une lettre`,
                `Mindestens ein Buchstabe`,
                `al menos una letra`,
                `Хотя бы одно письмо`,
                `Pelo menos uma letra`,
                `少なくとも 1 文字`,
                `حرف واحد على الأقل`]
        }, {
            text: 'At least one number',
            list: [
                `Au moins un numéro`,
                `Mindestens eine Nummer`,
                `al menos un numero`,
                `Хотя бы один номер`,
                `Pelo menos um numero`,
                `少なくとも 1 つの数字`,
                `رقم واحد على الأقل`]
        }, {
            text: 'Your bag is empty',
            list: [
                `Votre sac est vide`,
                `Deine Tasche ist leer`,
                `tu bolsa esta vacia`,
                `Ваша сумка пуста`,
                `Sua bolsa está vazia`,
                `あなたのバッグは空です`,
                `حقيبتك فارغة`]
        }, {
            text: 'Shop Now',
            list: [
                `Achetez maintenant`,
                `Jetzt einkaufen`,
                `Compra ahora`,
                `Купить сейчас`,
                `Compre agora`,
                `今すぐ購入`,
                `تسوق الآن`]
        }, {
            text: 'If you don’t have a paypal account. you can also pay via paypal with your credit card or bank debit card. Payment can be submitted in any currency',
            list: [
                `Si vous n'avez pas de compte paypal. vous pouvez également payer via paypal avec votre carte de crédit ou carte de débit bancaire. Le paiement peut être effectué dans n'importe quelle devise!`,
                `Wenn Sie kein PayPal-Konto haben. Sie können auch per Paypal mit Ihrer Kreditkarte oder Bankomatkarte bezahlen. Die Zahlung kann in jeder Währung erfolgen!`,
                `Si no tienes una cuenta de paypal. También puede pagar a través de Paypal con su tarjeta de crédito o tarjeta de débito bancaria. ¡El pago puede realizarse en cualquier moneda!`,
                `Если у вас нет учетной записи PayPal. Вы также можете оплатить через PayPal с помощью кредитной карты или банковской дебетовой карты. Оплата может быть произведена в любой валюте!`,
                `Se você não tem uma conta paypal. você também pode pagar via paypal com seu cartão de crédito ou cartão de débito bancário. O pagamento pode ser feito em qualquer moeda!`,
                `PayPal アカウントをお持ちでない場合。 PayPal 経由でクレジット カードまたは銀行デビット カードでお支払いいただくこともできます。支払いはどの通貨でも行うことができます。`,
                `إذا لم يكن لديك حساب paypal. يمكنك أيضًا الدفع عبر paypal باستخدام بطاقة الائتمان أو بطاقة الخصم البنكية. يمكن تقديم الدفع بأي عملة!`]
        }, {
            text: 'Security Policy',
            list: [
                `Politique de sécurité`,
                `Sicherheitsrichtlinie`,
                `Politica de seguridad`,
                `Политика безопасности`,
                `Política de segurança`,
                `セキュリティポリシー`,
                `نهج الأمان`]
        }, {
            text: 'Size Policy',
            list: [
                `Politique de taille`,
                `Größenrichtlinie`,
                `Política de tallas`,
                `Политика размера`,
                `Política de tamanho`,
                `サイズポリシー`,
                `سياسة الحجم`]
        }, {
            text: 'Shipping Time',
            list: [
                `Temps d'expédition`,
                `Lieferdauer`,
                `Tiempo de envío`,
                `Tiempo de envío`,
                `Prazo de entrega`,
                `輸送時間`,
                `وقت الشحن`]
        }, {
            text: 'Get Rewards',
            list: [
                `Obtenir des récompenses`,
                `Erhalten Sie Belohnungen`,
                `Obtener recompensas`,
                `Получить награды`,
                `Obter recompensas`,
                `報酬を獲得する`,
                `احصل على مكافآت`]
        }, {
            text: 'Reviewing items can help others understand them better',
            list: [
                `L'examen des éléments peut aider les autres à mieux les comprendre`,
                `Die Überprüfung von Elementen kann anderen helfen, sie besser zu verstehen`,
                `Revisar elementos puede ayudar a otros a comprenderlos mejor`,
                `Просмотр элементов может помочь другим лучше понять их`,
                `Revisar itens pode ajudar outras pessoas a entendê-los melhor`,
                `アイテムをレビューすると、他の人がアイテムをより深く理解できるようになります`,
                `يمكن أن تساعد مراجعة العناصر الآخرين على فهمها بشكل أفضل`]
        }, {
            text: 'Review later',
            list: [
                `Réviser plus tard`,
                `Überprüfen Sie es später`,
                `Revisar más tarde`,
                `Проверить позже`,
                `Revise mais tarde`,
                `後でレビューする`,
                `راجع لاحقًا`]
        }, {
            text: 'Continue to review',
            list: [
                `Continuer à revoir`,
                `Lesen Sie weiter`,
                `continuar revisando`,
                `Продолжить просмотр`,
                `Continuar a revisar`,
                `レビューを続ける`,
                `استمر في المراجعة`]
        }, {
            text: 'Hide your profile photo and name as',
            list: [
                `Cachez votre photo de profil et votre nom comme`,
                `Verstecken Sie Ihr Profilfoto und Ihren Namen als`,
                `Oculta tu foto de perfil y nombre como`,
                `Скрыть фото и имя профиля как`,
                `Oculte sua foto de perfil e nome como`,
                `プロフィール写真と名前を非表示にします`,
                `إخفاء صورة ملفك الشخصي والاسم باسم`]
        }, {
            text: 't****o',
            list: [
                ``,
                ``,
                ``,
                ``,
                ``,
                ``,
                ``]
        }, {
            text: 'How about this jewelry? The item quality, wearing effect, customer service etc.',
            list: [
                `Que diriez-vous de ce bijou? La qualité de l'article, l'effet d'usure, le service client, etc.`,
                `Wie wäre es mit diesem Schmuck? Die Artikelqualität, der Trageeffekt, der Kundenservice usw.`,
                `¿Qué tal esta joyería? La calidad del artículo, el efecto de uso, el servicio al cliente, etc.`,
                `Как насчет этого украшения? Качество товара, эффект ношения, обслуживание клиентов и т. д.`,
                `Que tal essa joia? A qualidade do item, efeito de uso, atendimento ao cliente etc.`,
                `このジュエリーはどうでしょうか？商品の品質、着用効果、顧客サービスなど。`,
                `ماذا عن هذه المجوهرات؟ جودة المنتج ، وتأثير الارتداء ، وخدمة العملاء ، إلخ.`]
        }, {
            text: 'You will lose the 15%, 20% and 100% off coupons reward chance',
            list: [
                `Vous perdrez les chances de récompense des coupons de 15 %, 20 % et 100 %`,
                `Sie verlieren die Chance auf eine Rabattgutscheinbelohnung von 15 %, 20 % und 100 %`,
                `Perderá la oportunidad de recompensa de cupones de 15%, 20% y 100% de descuento`,
                `Вы потеряете шанс получить купон на скидку 15%, 20% и 100%`,
                `Você perderá a chance de recompensa de cupons de 15%, 20% e 100%`,
                `15%、20%、100% オフのクーポン特典のチャンスを失います。`,
                `ستخسر فرصة مكافأة القسائم بخصم 15٪ و 20٪ و 100٪`]
        }, {
            text: 'Share Review',
            list: [
                `Partager l'avis`,
                `Rezension teilen`,
                `Compartir revisión`,
                `Поделиться отзывом`,
                `Compartilhar comentário`,
                `レビューをシェアする`,
                `مراجعة المشاركة`]
        }, {
            text: 'we will notice it by email, please pay attention',
            list: [
                `nous le remarquerons par e-mail, veuillez faire attention`,
                `Wir werden es per E-Mail benachrichtigen, bitte achten Sie darauf`,
                `lo notaremos por correo electrónico, por favor preste atención`,
                `мы заметим это по электронной почте, пожалуйста, обратите внимание`,
                `vamos notificá-lo por e-mail, por favor, preste atenção`,
                `メールでお知らせしますので、ご注意ください`,
                `سنلاحظ ذلك عبر البريد الإلكتروني ، يرجى الانتباه`]
        }, {
            text: 'You will win 15%, 25% off coupons and 100% off coupon after our review',
            list: [
                `Vous gagnerez 15 %, 25 % de réduction sur les coupons et 100 % de réduction après notre examen`,
                `Nach unserer Bewertung gewinnen Sie 15 %, 25 % Rabatt auf Gutscheine und 100 % Rabatt auf Gutscheine`,
                `Ganará 15%, 25% de descuento en cupones y 100% de descuento en cupones después de nuestra revisión`,
                `Вы выиграете купоны на скидку 15%, 25% и купон на скидку 100% после нашего обзора`,
                `Você ganhará cupons de 15%, 25% de desconto e cupom de 100% de desconto após nossa análise`,
                `レビュー後、15%、25% オフ クーポン、100% オフ クーポンを獲得します。`,
                `ستربح كوبونات خصم 15٪ و 25٪ و 100٪ خصم بعد مراجعتنا`]
        }, {
            text: 'You will win 15%, 25% off coupons after our review',
            list: [
                `Vous gagnerez 15 %, 25 % de réduction sur les coupons après notre examen`,
                `Nach unserer Bewertung gewinnen Sie 15 % und 25 % Rabatt auf Gutscheine`,
                `Ganará 15%, 25% de descuento en cupones después de nuestra revisión`,
                `Вы выиграете купоны со скидкой 15%, 25% после нашего обзора`,
                `Você ganhará cupons de 15%, 25% de desconto após nossa análise`,
                `レビュー後、15%、25% オフのクーポンを獲得します`,
                `ستفوز بخصم 15٪ و 25٪ على القسائم بعد مراجعتنا`]
        }, {
            text: 'You will win 100% off coupon after our review',
            list: [
                `Vous gagnerez 100% de réduction après notre examen`,
                `Nach unserer Bewertung gewinnen Sie einen 100 % Rabatt-Gutschein`,
                `Ganará un cupón de descuento del 100% después de nuestra revisión`,
                `Вы выиграете купон на 100% скидку после нашего обзора`,
                `Você ganhará 100% de desconto no cupom após nossa análise`,
                `レビュー後、100％オフクーポンを獲得します`,
                `ستربح قسيمة خصم 100٪ بعد مراجعتنا`]
        }, {
            text: 'Share to win more',
            list: [
                `Partagez pour gagner plus`,
                `Teilen, um mehr zu gewinnen`,
                `Comparte para ganar más`,
                `Поделитесь, чтобы выиграть больше`,
                `Compartilhe para ganhar mais`,
                `シェアしてさらに勝ちましょう`,
                `شارك لتربح المزيد`]
        }, {
            text: `Notice: Once you share your reviews to social media and other people click the link and purchase from it, you will get 10% commission of the order price per order, and the reward will send to your "YFN account > wallet"`,
            list: [
                `Avis : une fois que vous avez partagé vos avis sur les réseaux sociaux et que d'autres personnes ont cliqué sur le lien et effectué un achat, vous recevrez une commission de 10 % sur le prix de la commande par commande, et la récompense sera envoyée à votre "compte YFN > portefeuille"`,
                `Hinweis: Sobald Sie Ihre Bewertungen in den sozialen Medien teilen und andere Personen auf den Link klicken und dort kaufen, erhalten Sie pro Bestellung eine Provision von 10 % des Bestellpreises und die Prämie wird an Ihr „YFN-Konto > Wallet“ gesendet`,
                `Aviso: una vez que comparta sus reseñas en las redes sociales y otras personas hagan clic en el enlace y compren en él, obtendrá una comisión del 10 % del precio del pedido por pedido, y la recompensa se enviará a su "cuenta YFN > monedero"`,
                `Примечание. После того, как вы поделитесь своими отзывами в социальных сетях, и другие люди перейдут по ссылке и совершат покупку по ней, вы получите комиссию в размере 10% от стоимости заказа за заказ, а вознаграждение будет отправлено на ваш «учетная запись YFN > кошелек»`,
                `Aviso: Depois de compartilhar suas avaliações nas mídias sociais e outras pessoas clicarem no link e comprarem, você receberá 10% de comissão do preço do pedido por pedido e a recompensa será enviada para sua "conta YFN > carteira"`,
                `注意: レビューをソーシャル メディアで共有し、他の人がリンクをクリックしてそこから購入すると、注文ごとに注文価格の 10% の手数料が発生し、報酬は「YFN アカウント > ウォレット」に送られます`,
                `ملاحظة: بمجرد مشاركة تعليقاتك على وسائل التواصل الاجتماعي والأشخاص الآخرين بالنقر على الرابط والشراء منه ، ستحصل على عمولة بنسبة 10٪ من سعر الطلب لكل طلب ، وستُرسل المكافأة إلى "حساب YFN> المحفظة"`]
        }, {
            text: 'I want to share the review with you! Click it out',
            list: [
                `Je veux partager la critique avec vous! Cliquez dessus`,
                `Ich möchte die Rezension mit Ihnen teilen! Klicken Sie darauf`,
                `¡Quiero compartir la reseña contigo! haz clic para sacarlo`,
                `Хочу поделиться отзывом с вами! Нажмите его`,
                `Quero compartilhar a resenha com você! clique nele`,
                `レビューを皆さんとシェアしたいと思います！クリックしてみてください`,
                `أريد مشاركة المراجعة معك! انقر فوقها`]
        }, {
            text: 'Share to win',
            list: [
                `Partagez pour gagner`,
                `Teilen, um zu gewinnen`,
                `Comparte para ganar`,
                `Поделись, чтобы выиграть`,
                `Compartilhe para ganhar`,
                `シェアして勝ちましょう`,
                `شارك لتربح`]
        }, {
            text: 'commission',
            list: [
                `commission`,
                `Kommission`,
                `comisión`,
                `комиссия`,
                `comissão`,
                `手数料`,
                `عمولة`]
        }, {
            text: 'Get Commission',
            list: [
                `Obtenez des commissions`,
                `Erhalten Sie eine Provision`,
                `Obtener comisión`,
                `Получить комиссию`,
                `Obter Comissão`,
                `コミッションを得る`,
                `احصل على العمولة`]
        }, {
            text: 'Review Center',
            list: [
                `Centre d'examen`,
                `Überprüfungszentrum`,
                `Centro de revisión`,
                `Центр отзывов`,
                `Centro de revisão`,
                `レビューセンター`,
                `مركز المراجعة`]
        }, {
            text: 'No Recent orders pending review',
            list: [
                `Aucune commande récente en attente d'examen`,
                `Keine aktuellen Bestellungen zur Überprüfung ausstehend`,
                `No hay pedidos recientes pendientes de revisión`,
                `Нет последних заказов, ожидающих проверки`,
                `Nenhum pedido recente com revisão pendente`,
                `レビュー保留中の最近の注文はありません`,
                `لا توجد أوامر حديثة معلقة للمراجعة`]
        }, {
            text: 'No Reviewed orders',
            list: [
                `Aucune commande vérifiée`,
                `Keine überprüften Bestellungen`,
                `Sin pedidos revisados`,
                `Нет проверенных заказов`,
                `Nenhum pedido revisado`,
                `レビュー済みの注文はありません`,
                `لا توجد أوامر تمت مراجعتها`]
        }, {
            text: 'highly recommends this item',
            list: [
                `recommande vivement cet article`,
                `kann diesen Artikel wärmstens empfehlen`,
                `recomienda encarecidamente este artículo`,
                `очень рекомендую этот товар`,
                `altamente recomendado este item`,
                `この商品を強くお勧めします`,
                `يوصي بشدة بهذا العنصر`]
        }, {
            text: 'Unavailable Coupons',
            list: [
                `Coupons non disponibles`,
                `Nicht verfügbare Gutscheine`,
                `Cupones no disponibles`,
                `Недоступные купоны`,
                `Cupons indisponíveis`,
                `利用できないクーポン`,
                `قسائم غير متوفرة`]
        }, {
            text: 'You have not got coupon yet',
            list: [
                `Vous n'avez pas encore de coupon`,
                `Sie haben noch keinen Gutschein erhalten`,
                `Aún no tienes cupón`,
                `У вас еще нет купона`,
                `Você ainda não tem cupom`,
                `まだクーポンを取得していません`,
                `لم تحصل على قسيمة بعد`]
        }, {
            text: 'Expected to Save',
            list: [
                `Devrait économiser`,
                `Wird voraussichtlich gespeichert`,
                `Se espera que ahorre`,
                `Ожидается сохранение`,
                `Esperado para economizar`,
                `節約が期待される`,
                `من المتوقع أن ينقذ`]
        }, {
            text: 'This is an estimated price, the actual price after discount will be calculated at checkout',
            list: [
                `Il s'agit d'un prix estimé, le prix réel après remise sera calculé au moment du paiement`,
                `Hierbei handelt es sich um einen Schätzpreis. Der tatsächliche Preis nach Abzug des Rabatts wird an der Kasse berechnet`,
                `Este es un precio estimado, el precio real después del descuento se calculará al finalizar la compra`,
                `Это ориентировочная цена, фактическая цена после скидки будет рассчитана при оформлении заказа.`,
                `Este é um preço estimado, o preço real após o desconto será calculado no checkout`,
                `これは推定価格です。割引後の実際の価格はチェックアウト時に計算されます`,
                `هذا سعر تقديري ، السعر الفعلي بعد الخصم سيتم احتسابه عند الخروج`]
        }, {
            text: 'Total savings with coupons',
            list: [
                `Économies totales avec les coupons`,
                `Gesamtersparnis mit Gutscheinen`,
                `Ahorro total con cupones`,
                `Общая экономия с купонами`,
                `Economia total com cupons`,
                `クーポンを利用した合計割引額`,
                `إجمالي المدخرات مع القسائم`]
        }, {
            text: 'The coupon can not to use',
            list: [
                `Le coupon ne peut pas être utilisé`,
                `Der Gutschein kann nicht verwendet werden`,
                `El cupón no se puede usar`,
                `Купон нельзя использовать`,
                `O cupom não pode usar`,
                `クーポンは使用できません`,
                `لا يمكن استخدام القسيمة`]
        }, {
            text: 'Select or input code',
            list: [
                `Sélectionner ou saisir le code`,
                `Code auswählen oder eingeben`,
                `Seleccione o ingrese el código`,
                `Выберите или введите код`,
                `Selecione ou insira o código`,
                `コードを選択または入力`,
                `حدد أو أدخل رمز`]
        }, {
            text: 'Please select the items you’d like to checkout width',
            list: [
                `Veuillez sélectionner les articles que vous souhaitez commander`,
                `Bitte wählen Sie die Artikel aus, deren Breite Sie zur Kasse gehen möchten`,
                `Por favor, seleccione los artículos que le gustaría pagar ancho`,
                `Пожалуйста, выберите товары, ширину которых вы хотите оформить`,
                `Por favor, selecione os itens que você gostaria de verificar a largura`,
                `チェックアウトしたいアイテムを選択してください 幅`,
                `يرجى تحديد العناصر التي ترغب في عرضها`]
        }, {
            text: 'Awaiting  Review',
            list: [
                `En attente d'examen`,
                `Warten auf Rezension`,
                `En espera de revisión`,
                `Ожидает проверки`,
                `Aguardando revisão`,
                `レビュー待ち`,
                `في انتظار المراجعة`]
        }, {
            text: 'Upload 3 photos to win 15% & 25% off coupons',
            list: [
                `Téléchargez 3 photos pour gagner 15 % et 25 % de réduction`,
                `Laden Sie 3 Fotos hoch, um 15 %- und 25 %-Rabattgutscheine zu gewinnen`,
                `Sube 3 fotos para ganar cupones de 15% y 25% de descuento`,
                `Загрузите 3 фотографии, чтобы выиграть купоны на скидку 15% и 25%`,
                `Carregue 3 fotos para ganhar cupons de 15% e 25% de desconto`,
                `写真を 3 枚アップロードして 15% および 25% オフのクーポンを獲得してください`,
                `قم بتحميل 3 صور لتحصل على كوبونات خصم 15٪ و 25٪`]
        }, {
            text: 'Upload 1 video to win a 100% off coupon',
            list: [
                `Téléchargez 1 vidéo pour gagner un coupon de réduction de 100 %`,
                `Laden Sie 1 Video hoch, um einen 100 % Rabatt-Gutschein zu gewinnen`,
                `Sube 1 video para ganar un cupón de 100% de descuento`,
                `Загрузите 1 видео, чтобы выиграть купон на 100% скидку`,
                `Carregue 1 vídeo para ganhar um cupom de 100% de desconto`,
                `動画を 1 つアップロードすると 100% オフ クーポンが獲得できます`,
                `قم بتحميل مقطع فيديو واحد للفوز بقسيمة خصم بنسبة 100٪`]
        }, {
            text: 'View All Orders',
            list: [
                `Afficher toutes les commandes`,
                `Alle Bestellungen anzeigen`,
                `Ver todos los pedidos`,
                `Просмотреть все заказы`,
                `Ver todos os pedidos`,
                `すべての注文を表示`,
                `عرض كل الطلبات`]
        }, {
            text: 'Logistic Service',
            list: [
                `Service logistique`,
                `Logistikdienstleistung`,
                `Servicio Logístico`,
                `Логистическая служба`,
                `Serviço Logístico`,
                `物流サービス`,
                `خدمة لوجستية`]
        }, {
            text: 'Reward Process',
            list: [
                `Processus de récompense`,
                `Belohnungsprozess`,
                `Proceso de recompensa`,
                `Процесс вознаграждения`,
                `Processo de Recompensa`,
                `報酬プロセス`,
                `عملية المكافأة`]
        }, {
            text: 'THE MORE YOU SHARE, THE MORE YOU GET!',
            list: [
                `PLUS VOUS PARTAGEZ, PLUS VOUS OBTENEZ !`,
                `Je mehr Sie teilen, desto mehr bekommen Sie!`,
                `¡CUANTO MÁS COMPARTES, MÁS RECIBES!`,
                `ЧЕМ БОЛЬШЕ ВЫ ДЕЛИТЕСЬ, ТЕМ БОЛЬШЕ ПОЛУЧАЕТЕ!`,
                `QUANTO MAIS VOCÊ COMPARTILHA, MAIS VOCÊ GANHA!`,
                `シェアすればするほど、より多くのものが得られます!`,
                `كلما شاركت أكثر ، كلما حصلت على المزيد!`]
        }, {
            text: 'Get Reward',
            list: [
                `Obtenir une récompense`,
                `Belohnung erhalten`,
                `Obtener recompensa`,
                `Получить вознаграждение`,
                `Obter recompensa`,
                `報酬を得る`,
                `الحصول على مكافأة`]
        }, {
            text: 'Click Link',
            list: [
                `Cliquez sur le lien`,
                `Klicken Sie auf „Link“.`,
                `Haga clic en enlace`,
                `Нажмите ссылку`,
                `Clique no link`,
                `リンクをクリックします`,
                `انقر فوق ارتباط`]
        }, {
            text: 'Coupon Center',
            list: [
                `Centre de coupons`,
                `Coupon-Center`,
                `Centro de cupones`,
                `Центр купонов`,
                `Central de Cupons`,
                `クーポンセンター`,
                `مركز القسيمة`]
        }, {
            text: 'YFN PROMO CODES & COUPONS',
            list: [
                `Codes promotionnels YFN`,
                `YFN -Promo -Codes & Coupons`,
                `Códigos de promoción y cupones de YFN`,
                `Промо -коды и купоны YFN`,
                `Códigos promocionais e cupons YFN`,
                `YFNプロモーションコードとクーポン`,
                `رموز الترويجي yfn وكوبونات`]
        }, {
            text: 'Explore our best offers and promo codes for discounted earrings, rings, necklaces, bracelets and more, all in one place. You’ll find exclusive deals on affordable jewelry, discounted engagement rings, special gifts on sale and more. We offer many ways to earn YFN coupons and rewards to save big on our collection of heirloom quality jewelry you’ll love for a lifetime.',
            list: [
                `Explorez nos meilleures offres et codes promotionnels pour les boucles d'oreilles à prix réduits, les bagues, les colliers, les bracelets et plus encore, tous au même endroit. Vous trouverez des offres exclusives sur des bijoux abordables, des bagues de fiançailles à prix réduit, des cadeaux spéciaux en vente et plus encore. Nous offrons de nombreuses façons de gagner des coupons et des récompenses YFN pour économiser grand sur notre collection de bijoux de qualité Heirloom que vous aimerez toute une vie.`,
                `Entdecken Sie unsere besten Angebote und Promo -Codes für ermäßigte Ohrringe, Ringe, Halsketten, Armbänder und mehr an einem Ort. Sie finden exklusive Angebote für erschwingliche Schmuck, ermäßigte Verlobungsringe, besondere Geschenke zum Verkauf und mehr. Wir bieten viele Möglichkeiten, YFN -Gutscheine und Belohnungen zu verdienen, um unsere Sammlung von Schmuck mit Erbstücke zu sparen, die Sie für ein Leben lieben werden.`,
                `Explore nuestras mejores ofertas y códigos de promoción para aretes, anillos, collares, pulseras y más, todo en un solo lugar. Encontrará ofertas exclusivas sobre joyas asequibles, anillos de compromiso con descuento, regalos especiales a la venta y más. Ofrecemos muchas formas de ganar cupones y recompensas de YFN para ahorrar en grande en nuestra colección de joyas de calidad de la herencia que le encantará durante toda la vida.`,
                `Исследуйте наши лучшие предложения и промо -коды для серьги скидки, кольца, ожерелья, браслеты и многое другое, все в одном месте. Вы найдете эксклюзивные предложения по доступным ювелирным украшениям, скидки на обручальные кольца, специальные подарки в продаже и многое другое. Мы предлагаем много способов заработать купоны и награды YFN, чтобы сэкономить на нашей коллекции ювелирных изделий для семейной реликвии, которые вы полюбите на всю жизнь.`,
                `Explore nossas melhores ofertas e códigos promocionais para brincos com desconto, anéis, colares, pulseiras e muito mais, tudo em um só lugar. Você encontrará ofertas exclusivas sobre jóias acessíveis, anéis de noivado com desconto, presentes especiais à venda e muito mais. Oferecemos muitas maneiras de ganhar cupons e recompensas da YFN para economizar muito em nossa coleção de jóias de qualidade da herança que você vai adorar por toda a vida.`,
                `割引されたイヤリング、リング、ネックレス、ブレスレットなどのための最高のオファーとプロモーションコードをすべて1か所で探索してください。手頃な価格のジュエリー、割引エンゲージメントリング、販売中の特別なギフトなどに関する独占的な取引があります。私たちは、YFNクーポンと報酬を獲得するための多くの方法を提供して、一生気に入ってくれる家宝品質のジュエリーのコレクションを大幅に節約しています。`,
                `استكشف أفضل العروض والرموز الترويجية للأقراط المخفضة والخواتم والقلائد والأساور وأكثر من ذلك في مكان واحد. ستجد صفقات حصرية على المجوهرات بأسعار معقولة ، وحلقات خطبة مخفضة ، وهدايا خاصة للبيع وأكثر من ذلك. نحن نقدم العديد من الطرق لكسب كوبونات YFN والمكافآت لتوفير Big على مجموعتنا من المجوهرات ذات جودة الإرث التي ستحبها مدى الحياة.`]
        }, {
            text: 'Subscribe for Email. Save 15% off instantly.',
            list: [
                `Abonnez-vous à un e-mail. Économisez 15% de réduction instantanément.`,
                `E -Mail abonnieren. Sparen Sie sofort 15% Rabatt.`,
                `Suscríbete para el correo electrónico. Ahorre un 15% de descuento al instante.`,
                `Подпишитесь на электронную почту. Сэкономьте 15% скидки мгновенно.`,
                `Inscreva -se por e -mail. Economize 15% de desconto instantaneamente.`,
                `電子メールを購読します。すぐに15％オフにします。`,
                `اشترك في البريد الإلكتروني. وفر 15 ٪ خصم على الفور.`]
        }, {
            text: 'Join our YFN email list for exclusive offers, and enjoy up to $50 off in savings on your first purchase. Get notified of YFN promos, special discount events, and our newest jewelry launches so you’ll never miss out on these limited time offers. You’ll receive tips, jewelry recommendations, gift ideas, and insider sale previews.',
            list: [
                `Rejoignez notre liste de courriels YFN pour des offres exclusives et profitez jusqu'à 50 $ de rabais en économies lors de votre premier achat. Soyez informé des promos YFN, des événements de réduction spéciaux et de nos nouveaux lancements de bijoux afin que vous ne manquiez jamais ces offres de temps limitées. Vous recevrez des conseils, des recommandations de bijoux, des idées de cadeaux et des aperçus de vente d'initiés.`,
                `Nehmen Sie an unserer YFN -E -Mail -Liste für exklusive Angebote teil und genießen Sie bei Ihrem ersten Einkauf bis zu 50 US -Dollar Rabatt. Beachten Sie YFN -Promos, Sonderrabatt -Events und unsere neuesten Schmuckstarts, damit Sie diese begrenzten Zeitangebote nie verpassen werden. Sie erhalten Tipps, Schmuck Empfehlungen, Geschenkideen und Insiderverkaufsvoransichten.`,
                `Únase a nuestra lista de correo electrónico de YFN para ofertas exclusivas y disfrute de hasta $ 50 de descuento en ahorros en su primera compra. Reciba notificaciones de las promociones de YFN, eventos especiales de descuento y nuestros lanzamientos de joyería más recientes para que nunca se pierda estas ofertas de tiempo limitado. Recibirá consejos, recomendaciones de joyería, ideas de regalos y vistas previas de venta interna.`,
                `Присоединяйтесь к нашему списку электронной почты YFN для эксклюзивных предложений и наслаждайтесь скидкой до 50 долларов при сбережениях при первой покупке. Получите уведомление о промо -промогах YFN, специальных мероприятиях скидок и наших новейших ювелирных изделиях, чтобы вы никогда не пропустили эти ограниченные временные предложения. Вы получите советы, рекомендации ювелирных изделий, идеи подарков и предварительные просмотра инсайдерской продажи.`,
                `Junte -se à nossa lista de e -mail da YFN para ofertas exclusivas e aproveite até US $ 50 em economia em sua primeira compra. Seja notificado sobre promoções da YFN, eventos especiais de desconto e nossos mais novos lançamentos de jóias para que você nunca perca essas ofertas de tempo limitado. Você receberá dicas, recomendações de jóias, idéias de presentes e pré -visualizações de vendas.`,
                `YFNメーリングリストに参加して、排他的なオファーを提供し、最初の購入時に最大50ドルの節約をお楽しみください。 YFNプロモーション、特別割引イベント、および最新の宝石の発売を通知して、これらの限られた時間のオファーを見逃すことはありません。ヒント、ジュエリーの推奨事項、ギフトのアイデア、インサイダーセールのプレビューを受け取ります。`,
                `انضم إلى قائمة البريد الإلكتروني YFN للحصول على العروض الحصرية ، واستمتع بمبلغ يصل إلى 50 دولارًا في التوفير في عملية الشراء الأولى. احصل على إعلام YFN Promos ، وأحداث الخصم الخاصة ، وأحدث عمليات إطلاق المجوهرات لدينا ، لذا لن تفوتك أبدًا هذه العروض الزمنية المحدودة. ستتلقى نصائح وتوصيات للمجوهرات وأفكار الهدايا ومعاينات بيع من الداخل.`]
        }, {
            text: 'By submitting your email address, you agree to receive news and exclusive email offers from YFN as described in our Privacy Policy.',
            list: [
                `En soumettant votre adresse e-mail, vous acceptez de recevoir des nouvelles et des offres e-mail exclusives de YFN comme décrit dans notre politique de confidentialité.`,
                `Durch die Einreichung Ihrer E -Mail -Adresse erklären Sie sich damit einverstanden, Nachrichten und exklusive E -Mail -Angebote von YFN zu erhalten, wie in unseren Datenschutzbestimmungen beschrieben.`,
                `Al enviar su dirección de correo electrónico, usted acepta recibir noticias y ofertas exclusivas por correo electrónico de YFN como se describe en nuestra Política de privacidad.`,
                `Отправляя свой адрес электронной почты, вы соглашаетесь получать новости и эксклюзивные предложения по электронной почте от YFN, как описано в нашей Политике конфиденциальности.`,
                `Ao enviar seu endereço de e -mail, você concorda em receber notícias e ofertas exclusivas de e -mail da YFN, conforme descrito em nossa política de privacidade.`,
                `メールアドレスを送信することにより、当社のプライバシーポリシーに記載されているように、YFNからニュースおよび排他的な電子メールオファーを受け取ることに同意します。`,
                `من خلال إرسال عنوان بريدك الإلكتروني ، فإنك توافق على تلقي الأخبار وعروض البريد الإلكتروني الحصرية من YFN كما هو موضح في سياسة الخصوصية الخاصة بنا.`]
        }, {
            text: "Today's YFN Promo Code",
            list: [
                `Code promotionnel YFN d'aujourd'hui`,
                `Der heutige YFN -Promo -Code`,
                `Código de promoción de YFN de hoy`,
                `Сегодняшний промо -код YFN`,
                `Código promocional do YFN de hoje`,
                `今日のYFNプロモーションコード`,
                `رمز الترويجي YFN اليوم`]
        }, {
            text: 'Who doesn’t love a great jewelry deal? Enjoy savings on select necklaces, earrings, rings, and treasured jewelry pieces you’ll cherish. Find today’s YFN Promo Code and use it in your cart during checkout for special savings.',
            list: [
                `Qui n'aime pas une bonne affaire de bijoux? Profitez d'économies sur certains colliers, boucles d'oreilles, anneaux et bijoux précieux que vous chérirez. Trouvez le code promotionnel YFN d'aujourd'hui et utilisez-le dans votre panier lors de la caisse pour des économies spéciales.`,
                `Wer liebt keinen großartigen Schmuckvertrag? Genießen Sie Einsparungen bei ausgewählten Halsketten, Ohrringen, Ringen und geschätzten Schmuckstücken, die Sie schätzen werden. Finden Sie den heutigen YFN -Promo -Code und verwenden Sie ihn während der Kasse in Ihrem Wagen für besondere Einsparungen.`,
                `¿A quién no le encanta un gran acuerdo de joyería? Disfrute de ahorros en collares seleccionados, aretes, anillos y piezas de joyería atesoradas que apreciará. Encuentre el código de promoción YFN de hoy y úselo en su carrito durante el pago para obtener ahorros especiales.`,
                `Кто не любит отличную ювелирную сделку? Наслаждайтесь экономией на избранных ожерельях, серьгах, кольцах и заветных украшениях, которые вы будете дорожить. Найдите сегодняшний промо -код YFN и используйте его в корзине во время заказа для специальной сбережений.`,
                `Quem não ama um ótimo negócio de jóias? Desfrute de economias em colares, brincos, anéis e peças de jóias preciosas que você apreciará. Encontre o código promocional do YFN de hoje e use -o no seu carrinho durante a compra para obter economias especiais.`,
                `素晴らしい宝石契約が好きではないのは誰ですか？選択したネックレス、イヤリング、リング、大切なジュエリーピースの節約をお楽しみください。今日のYFNプロモーションコードを見つけて、特別な節約のためにチェックアウト中にカートで使用します。`,
                `من لا يحب صفقة مجوهرات رائعة؟ استمتع بالتوفير على قلادات مختارة وأقراط وخواتم وقطع مجوهرات يعتز بها. ابحث عن رمز الترويجي YFN اليوم واستخدمه في عربة التسوق أثناء الخروج للحصول على توفير خاص.`]
        }, {
            text: 'OFF',
            list: [
                `DÉSACTIVÉ`,
                `AUS`,
                `APAGADO`,
                `ВЫКЛЮЧЕННЫЙ`,
                `DESLIGADO`,
                `オフ`,
                `عن`]
        }, {
            text: 'CODE',
            list: [
                `CODE`,
                `CODE`,
                `CÓDIGO`,
                `КОД`,
                `CÓDIGO`,
                `コード`,
                `شفرة`]
        }, {
            text: 'OFF',
            list: [
                `Rabais`,
                `Rabatt`,
                `Descuento`,
                `Скидка`,
                `Desconto`,
                `割引`,
                `عن`]
        }, {
            text: 'Promo codes & Coupons',
            list: [
                `Code de promo`,
                `Promo-Codes`,
                `Códigos promocionales`,
                `Промокоды`,
                `Códigos promocionais`,
                `プロモーションコード`,
                `الرموز الترويجية`]
        }, {
            text: 'Thanks for your subscription. Enjoy Shopping at YFN.',
            list: [
                `Merci pour votre abonnement. Profitez du shopping chez YFN.`,
                `Vielen Dank für Ihr Abonnement. Viel Spaß beim Einkaufen bei YFN.`,
                `Gracias por su suscripción. Disfruta de comprar en YFN.`,
                `Спасибо за вашу подписку. Наслаждайтесь покупками в YFN.`,
                `Obrigado pela sua assinatura. Desfrute de fazer compras na YFN.`,
                `サブスクリプションをありがとう。 YFNで買い物を楽しんでください。`,
                `شكرا للاشتراك الخاص بك. استمتع بالتسوق في YFN.`]
        }, {
            text: 'Please enter your email address',
            list: [
                `Veuillez entrer votre adresse e - mail`,
                `Bitte geben Sie Ihre E-Mail-Adresse ein`,
                `Introduzca su dirección de correo electrónico`,
                `Введите адрес электронной почты`,
                `Introduza o seu endereço de e-mail`,
                `電子メールアドレスを入力してください`,
                `الرجاء إدخال عنوان البريد الإلكتروني الخاص بك`]
        }, {
            text: 'Product ID',
            list: [
                `ID du produit`,
                `Produkt-ID`,
                `ID del producto`,
                `Идентификатор продукта`,
                `ID do produto`,
                `製品ID`,
                `معرف المنتج`]
        }, {
            text: 'YFN Jewelry Coupons, Discount Codes. Promos, Offers, & Deals',
            list: [
                `YFn bijoux coupons, Code de réduction. Promotions, offres et offres`,
                `YFN Schmuck Gutscheine, Rabattcodes. Aktionen, Angebote, & Angebote`,
                `Cupón de joyería yfn, Código de descuento. Promociones, ofertas y ofertas`,
                `Купоны на ювелирные изделия YFN, код скидки. Пропаганда, преференции и преференции`,
                `Cupons de jóias YFN, códigos de desconto. Promoções, Ofertas e Ofertas`,
                `YFNジュエリークーポン、割引コード。プロモーション、特典、特典`,
                `YFN مجوهرات كوبونات ، رموز الخصم . الترقيات والعروض والعروض`]
        }, {
            text: "YFN Jewelry coupons for new users, Coupons about YFN Jewelry new arrivals. You'll find exclusive deals on affordable jewelry, discounted engagement rings, unique promotional gifts, and more.",
            list: [
                `YFn Jewelry coupons pour les nouveaux utilisateurs, coupons pour les nouveaux utilisateurs de yFn Jewelry. Vous trouverez des offres exclusives telles que des bijoux abordables, des bagues de fiançailles à prix réduit, des cadeaux promotionnels uniques et bien plus encore.`,
                `YFN Schmuck Gutscheine für neue Benutzer, Gutscheine für YFN Schmuck Neuheiten. Hier finden Sie exklusive Angebote für erschwinglichen Schmuck, vergünstigte Verlobungsringe, einzigartige Werbegeschenke und vieles mehr.`,
                `Cupones de joyería yfn para nuevos usuarios, cupones para nuevos usuarios de joyería yfn. Encontrarás joyas asequibles, anillos de compromiso con descuento, regalos promocionales únicos y otras ofertas exclusivas.`,
                `Купоны на ювелирные изделия YFN для новых пользователей, купоны на ювелирные изделия YFN для новых пользователей. Вы найдете эксклюзивные предложения, такие как доступные ювелирные изделия, обручальные кольца со скидкой и уникальные рекламные подарки.`,
                `Cupons YFN Jóias para novos utilizadores, Cupons sobre YFN Jóias novidades. Você encontrará ofertas exclusivas em jóias acessíveis, anéis de noivado com desconto, presentes promocionais exclusivos e muito mais.`,
                `新規ユーザー向けのYFNジュエリークーポン、YFNジュエリー新規ユーザー向けのクーポン。お手頃価格のジュエリー、割引された婚約指輪、ユニークなプロモーションギフトなど、独自の特典が見つかります。`,
                `كوبونات للمجوهرات YFN للمستخدمين الجدد ، كوبونات لمستخدمين جدد للمجوهرات YFN . سوف تجد بأسعار معقولة والمجوهرات ، خواتم الخطوبة الخصم ، فريدة من نوعها الهدايا الترويجية وغيرها من العروض الحصرية .`]
        }, {
            text: 'All data will be encrypted',
            list: [
                `Toutes les données seront cryptées`,
                `Alle Daten werden verschlüsselt`,
                `Todos los datos serán encriptados`,
                `Все данные будут зашифрованы.`,
                `Todos os dados serão encriptados`,
                `すべてのデータが暗号化されます`,
                `جميع البيانات سوف تكون مشفرة`]
        }, {
            text: 'Payment Security',
            list: [
                `Paiement du dépôt de garantie`,
                `Zahlungssicherheit`,
                `Pago de la fianza`,
                `Выплата залога`,
                `Segurança dos pagamentos`,
                `保証金を支払う`,
                `دفع الهامش`]
        }, {
            text: 'YFN is committed to protecting your payment information and only shares your credit card information with our payment service providers who agreed to safeguard your information.',
            list: [
                `YFn s’engage à protéger vos informations de paiement et ne partage vos informations de carte de crédit qu’avec les fournisseurs de services de paiement avec lesquels nous avons accepté de protéger vos informations.`,
                `YFN verpflichtet sich, Ihre Zahlungsinformationen zu schützen und gibt Ihre Kreditkarteninformationen nur an unsere Zahlungsdienstleister weiter, die zugestimmt haben, Ihre Daten zu schützen.`,
                `Yfn se compromete a proteger su información de pago y solo comparte la información de su tarjeta de crédito con los proveedores de servicios de pago con los que acordamos proteger su información.`,
                `Компания YFN обязуется защищать вашу платежную информацию и делиться информацией о вашей кредитной карте только с поставщиками платежных услуг, с которыми мы согласны защищать вашу информацию.`,
                `A YFN está comprometida em proteger suas informações de pagamento e apenas compartilha suas informações de cartão de crédito com nossos provedores de serviços de pagamento que concordaram em proteger suas informações.`,
                `YFNはお客様の支払情報の保護に力を入れており、お客様の情報を保護する支払サービスプロバイダとのみクレジットカード情報を共有することに同意しています。`,
                `YFN ملتزمة بحماية معلومات الدفع الخاصة بك وتبادل معلومات بطاقة الائتمان الخاصة بك فقط مع مقدمي خدمات الدفع التي اتفقنا على حماية المعلومات الخاصة بك .`]
        }, {
            text: 'Security & Privacy',
            list: [
                `Sécurité et confidentialité`,
                `Sicherheit und Datenschutz`,
                `Seguridad y privacidad`,
                `Безопасность и конфиденциальность`,
                `Segurança e Privacidade`,
                `セキュリティとプライバシー`,
                `الأمن والخصوصية`]
        }, {
            text: 'YFN respects the privacy of users and visitors on our site, and we are committed to protecting it through maintaining industry-standard physical, technical, and administrative measures designed to guard your personal data from unauthorized processing, use or disclosure.',
            list: [
                `YFn respecte la vie privée des utilisateurs et des visiteurs de notre site Web et nous nous engageons à protéger vos données personnelles contre le traitement, l’utilisation ou la divulgation non autorisés en appliquant des mesures physiques, techniques et administratives conformes aux normes de l’industrie.`,
                `YFN respektiert die Privatsphäre der Benutzer und Besucher unserer Website und wir verpflichten uns, diese durch die Aufrechterhaltung von branchenüblichen physischen, technischen und administrativen Maßnahmen zu schützen, die dazu dienen, Ihre personenbezogenen Daten vor unbefugter Verarbeitung, Verwendung oder Offenlegung zu schützen.`,
                `Yfn respeta la privacidad de los usuarios y visitantes en nuestro sitio web, y nos comprometemos a proteger sus datos personales del procesamiento, uso o divulgación no autorizados manteniendo las medidas físicas, técnicas y de gestión de los estándares de la industria.`,
                `Компания YFN уважает конфиденциальность пользователей и посетителей нашего веб - сайта, и мы обязуемся защищать ваши персональные данные от несанкционированной обработки, использования или раскрытия с помощью физических, технических и управленческих мер, которые соответствуют отраслевым стандартам.`,
                `A YFN respeita a privacidade dos utilizadores e visitantes no nosso site e estamos empenhados em protegê-la através da manutenção de medidas físicas, técnicas e administrativas padrão da indústria concebidas para proteger os seus dados pessoais contra processamento, uso ou divulgação não autorizados.`,
                `YFNは、デルのWebサイト上のユーザーと訪問者のプライバシーを尊重し、業界標準の物理的、技術的、管理的な措置を維持することで、個人データを不正な処理、使用、または開示から保護することに努めています。`,
                `YFN تحترم خصوصية المستخدمين والزوار على موقعنا ، ونحن ملتزمون بحماية البيانات الشخصية الخاصة بك من غير معالجة أو استخدام أو الكشف عنها من خلال الحفاظ على معايير الصناعة المادية والتقنية والإدارية .`]
        }, {
            text: 'Invalid card number',
            list: [
                `Numéro de carte invalide`,
                `Ungültige Kartennummer`,
                `Número de tarjeta no válido`,
                `Неверный номер карты`,
                ` Número do cartão inválido`,
                `無効なカード番号`,
                `صحيح رقم البطاقة`]
        }, {
            text: 'Invalid security code',
            list: [
                `Code de sécurité invalide`,
                `Ungültiger Sicherheitscode`,
                `El Código de Seguridad no es válido`,
                `Код безопасности не работает.`,
                `Código de segurança inválido`,
                `無効なセキュリティコード`,
                `رمز الأمان غير صالح`]
        }, {
            text: 'Item availability, prices, shipping fees, and taxes may change(including items in your bag)',
            list: [
                `La disponibilité des articles, les prix, les frais d'expédition et les taxes sont sujets à changement (y compris les articles dans votre sac)`,
                `Artikelverfügbarkeit, Preise, Versandkosten und Steuern können sich ändern (einschließlich Artikel in Ihrer Tasche)`,
                `La disponibilidad de artículos, precios, fletes e impuestos pueden cambiar (incluidos los artículos en su bolso)`,
                `Поставка товаров, цены, фрахт и налоги могут измениться (включая товары в вашей сумке)`,
                `Disponibilidade de itens, preços, taxas de envio e impostos podem mudar (incluindo itens em sua bolsa)`,
                `物品の供給状況、価格、運送費、税金が変更される可能性があります（パッケージに含まれる物品）`,
                `توريد السلع والأسعار والشحن والضرائب قد تتغير ( بما في ذلك البنود في الحزمة الخاصة بك )`]
        }, {
            text: 'Removed from wishlist',
            list: [
                `Supprimé de la wishlist`,
                `Von Wunschliste entfernt`,
                `Ha sido eliminado de la lista de deseos`,
                `Удалено из списка желаний.`,
                `Removido da lista de desejos`,
                `請求書から削除されました`,
                `تم حذفها من قائمة ترغب`]
        }, {
            text: 'Added to wishlist',
            list: [
                `Ajouté à la wishlist`,
                `Zur Wunschliste hinzugefügt`,
                `Se ha añadido a la lista de deseos`,
                `Добавлен в список желаний.`,
                `Adicionado à lista de desejos`,
                `請求書に追加されました`,
                `إضافة إلى قائمة ترغب`]
        }, {
            text: 'Password incorrect! Forgot your password?',
            list: [
                `Le mot de passe est incorrect! Mot de passe oublié?`,
                `Passwort falsch! Passwort vergessen?`,
                `¡La contraseña no es correcta! ¿¿ has olvidado la contraseña?`,
                `Пароль неверный! Забыли пароль?`,
                `Senha incorrecta! Esqueceste-te da tua senha?`,
                `パスワードが正しくありません！パスワードを忘れましたか。`,
                `كلمة السر غير صحيحة ! نسيت كلمة السر ؟`]
        }, {
            text: 'Forgot my password',
            list: [
                `J'ai oublié mon mot de passe`,
                `Passwort vergessen`,
                `Olvida mi contraseña`,
                `Забыл мой пароль.`,
                `Esqueci-me da senha`,
                `私のパスワードを忘れました`,
                `نسيت كلمة السر`]
        }, {
            text: 'Try again',
            list: [
                `Veuillez réessayer`,
                `Versuchen Sie es erneut`,
                `Por favor, vuelva a intentarlo.`,
                `Пожалуйста, повторите попытку.`,
                `Tenta outra vez`,
                `再試行してください`,
                `يرجى المحاولة مرة أخرى`]
        }, {
            text: 'Get 10% Off',
            list: [
                `Profitez de 10% de réduction`,
                `GET 10% AUS`,
                `10% de descuento`,
                `10% de descuento`,
                `Ganhe 10% de desconto`,
                `DESCONTO DE 10%`,
                `تتمتع خصم 10 ٪`]
        }, {
            text: 'First Order',
            list: [
                `Le premier ordre`,
                `Erste Ordnung`,
                `Primer pedido`,
                `Первый заказ`,
                `Primeira Ordem`,
                `最初のオーダー`,
                `أول أمر`]
        }, {
            text: 'Free Custom',
            list: [
                `Personnalisation gratuite`,
                `Freie Benutzerdefinierte`,
                `Personalización gratuita`,
                `Бесплатные настройки`,
                `Personalizado Grátis`,
                `無料カスタマイズ`,
                `تخصيص مجانا`]
        }, {
            text: 'Your Unique Jewels',
            list: [
                `Vos bijoux uniques`,
                `Ihre einzigartigen Juwelen`,
                `Sus joyas únicas`,
                `Ваши уникальные драгоценности.`,
                `As Suas Jóias Únicas`,
                `あなただけのジュエリー`,
                `مجوهرات فريدة من نوعها`]
        }, {
            text: 'Free Returns',
            list: [
                `Retours gratuits`,
                `Kostenlose Rücksendungen`,
                `Devolución gratuita`,
                `Бесплатный возврат`,
                `Devoluções Gratuitas`,
                `無料返品`,
                `عودة مجانية`]
        }, {
            text: 'Within 60 Days',
            list: [
                `Dans les 60 jours`,
                `Innerhalb von 60 Tagen`,
                `En 60 días`,
                `В течение 60 дней`,
                `Dentro de 60 Dias`,
                `60日以内`,
                `في غضون 60 يوما`]
        }, {
            text: 'Create your account',
            list: [
                `Créer votre compte`,
                `Erstellen Sie Ihr Konto`,
                `Crear su cuenta`,
                `Создайте свой аккаунт`,
                `Criar a sua conta`,
                `アカウントの作成`,
                `إنشاء حساب`]
        }, {
            text: 'Welcome back!',
            list: [
                `Bienvenue à revenir!`,
                `Willkommen zurück!`,
                `¡Bienvenidos de vuelta!`,
                `С возвращением!`,
                `Bem-vindo de volta!`,
                `お帰りなさい！`,
                `مرحبا بكم مرة أخرى !`]
        }, {
            text: 'Quick and easy, just fill in the password',
            list: [
                `Rapide et facile, il suffit de remplir le mot de passe`,
                `Einfach und schnell, einfach das Passwort eingeben`,
                `Rápido y fácil, solo tiene que rellenar la contraseña`,
                `Быстро и просто, просто заполните пароль`,
                `Rápido e fácil, basta preencher a senha`,
                `パスワードを入力するだけの簡単な高速`,
                `سريعة وبسيطة ، مجرد ملء كلمة السر`]
        }, {
            text: 'Hi',
            list: [
                `Salut`,
                `Hallo`,
                `Hola`,
                `Привет`,
                `Olá`,
                `やあ`,
                `مرحبا`]
        }, {
            text: 'Language',
            list: [
                `Langue`,
                `Sprache`,
                `Idiomas`,
                `Язык`,
                `Língua`,
                `言語`,
                `اللغة`]
        }, {
            text: 'Address',
            list: [
                `Adresse de résidence`,
                `Adresse`,
                `Dirección`,
                `Адрес`,
                `Endereço`,
                `住所`,
                `العنوان`]
        }, {
            text: 'Street address',
            list: [
                `Adresse de la rue`,
                `Adresse der Straße`,
                `Dirección de la calle`,
                `Адрес улицы`,
                `Endereço de rua`,
                `ストリートアドレス`,
                `عنوان الشارع`]
        }, {
            text: 'Apt/Unit/Other(optional)',
            list: [
                `Appartement / unité / autre (facultatif)`,
                `Apt/Einheit/Sonstiges(optional)`,
                `Apartamentos / unidades / otros (opcional)`,
                `Квартира / Квартира / Другие (необязательно)`,
                `Apto/unidade/outro (opcional)`,
                `マンション/ユニット/その他（オプション）`,
                `شقة / وحدة / أخرى ( اختياري )`]
        }, {
            text: 'Zip Code',
            list: [
                `Code Postal`,
                `Postleitzahl`,
                `Código postal`,
                `Почтовый индекс`,
                `Código postal`,
                `郵便番号`,
                `الرمز البريدي`]
        }, {
            text: 'Postcode',
            list: [
                `Code Postal`,
                `Postleitzahl`,
                `Código postal`,
                `Почтовый индекс`,
                `Código postal`,
                `郵便番号`,
                `الرمز البريدي`]
        }, {
            text: 'City/Suburb',
            list: [
                `Ville / banlieue`,
                `Stadt/Vorort`,
                `Ciudad / suburbio`,
                `Городские / пригородные районы`,
                `Cidade/Subúrbio`,
                `都市/郊外`,
                `المدينة / الضواحي`]
        }, {
            text: 'State/Territory',
            list: [
                `État / région`,
                `Staat/Gebiet`,
                `Estado / región`,
                `Штат / территория`,
                `Estado/Território`,
                `州/地域`,
                `الدولة / المنطقة`]
        }, {
            text: 'Postal code',
            list: [
                `Code Postal`,
                `Postleitzahl`,
                `Código postal`,
                `Почтовый индекс`,
                `Código postal`,
                `郵便番号`,
                `الرمز البريدي`]
        }, {
            text: 'Province/Territory',
            list: [
                `Province / territoire`,
                `Provinz/Gebiet`,
                `Provincia / región`,
                `Провинция / территория`,
                `Província/Território`,
                `都道府県`,
                `مقاطعة / منطقة`]
        }, {
            text: 'House number',
            list: [
                `Numéro de maison`,
                `Hausnummer`,
                `Número de casa`,
                `Номер дома`,
                `Número da casa`,
                `家屋番号`,
                `رقم البيت`]
        }, {
            text: 'Zip code',
            list: [
                `Code Postal`,
                `Postleitzahl`,
                `Código postal`,
                `Почтовый индекс`,
                `Código postal`,
                `郵便番号`,
                `الرمز البريدي`]
        }, {
            text: 'Add Shipping Address',
            list: [
                `Ajouter une adresse de livraison`,
                `Lieferadresse hinzufügen`,
                `Añadir dirección de envío`,
                `Добавить адрес доставки`,
                `Adicionar o Endereço de Envio`,
                `出荷先住所の追加`,
                `إضافة عنوان الشحن`]
        }, {
            text: "Are you sure? If you leave now, your changes won't be save.",
            list: [
                `T'en es sûr? Si vous partez maintenant, vos modifications ne seront pas enregistrées.`,
                `Bist du sicher? Wenn Sie jetzt gehen, werden Ihre Änderungen nicht gespeichert.`,
                `¿¿ estás seguro? Si se va ahora, sus cambios no se guardarán.`,
                `Ты уверен? Если вы уйдете сейчас, ваши изменения не будут сохранены.`,
                `Tens a certeza? Se você sair agora, suas alterações não serão salvas.`,
                `本当ですか。今離れても、変更は保存されません。`,
                `هل أنت متأكد ؟ إذا كنت ترك الآن ، التغييرات الخاصة بك لن يتم حفظها .`]
        }, {
            text: 'Keep editing',
            list: [
                `Gardez l'édition`,
                `Weiter bearbeiten`,
                `Mantener la edición`,
                `Сохранить редактирование`,
                `Continuar a editar`,
                `編集の維持`,
                `الحفاظ على تحرير`]
        }, {
            text: 'Discard changes',
            list: [
                `Abandonner les changements`,
                `Änderungen verwerfen`,
                `Renunciar a los cambios`,
                `Отказ от изменений`,
                `Eliminar as alterações`,
                `変更を破棄`,
                `التخلي عن التغيير`]
        }, {
            text: 'Please fill the name consistent with the nameplate on your letterbox or doorbell.',
            list: [
                `S'il vous plaît remplir le nom qui correspond à la plaque signalétique sur la boîte aux lettres ou la sonnette.`,
                `Bitte füllen Sie den Namen aus, der mit dem Typenschild auf Ihrem Briefkasten oder Ihrer Türklingel übereinstimmt.`,
                `Rellene el nombre que coincida con la placa de identificación en el buzón o el timbre.`,
                `Пожалуйста, заполните имя, соответствующее табличке на почтовом ящике или дверном звонке.`,
                `Por favor, preencha o nome consistente com a placa de identificação em sua caixa postal ou campainha.`,
                `郵便受けやチャイムの銘板と一致する名前を記入してください。`,
                `يرجى ملء الاسم الذي يتوافق مع لوحة على صندوق البريد أو جرس الباب .`]
        }, {
            text: 'Please choose your satisfied reason',
            list: [
                `Veuillez choisir votre raison de satisfaction`,
                `Bitte wählen Sie Ihren zufriedenen Grund`,
                `Por favor elija su motivo de satisfacción`,
                `Пожалуйста, выберите причину удовлетворения`,
                `Por favor escolha seu motivo satisfeito`,
                `満足した理由を選択してください`,
                `يرجى اختيار السبب الراضي الخاص بك`]
        }, {
            text: 'Please choose your unsatisfied reason',
            list: [
                `Veuillez choisir votre raison d'insatisfaction`,
                `Bitte wählen Sie den Grund für Ihre Unzufriedenheit aus`,
                `Por favor elige tu motivo de insatisfacción`,
                `Пожалуйста, выберите причину неудовлетворения`,
                `Por favor, escolha o motivo de sua insatisfação`,
                `ご満足いただけない理由をお選びください`,
                `يرجى اختيار السبب غير المرضي`]
        }, {
            text: 'Add your favorite items or sign in to check your bag',
            list: [
                `Ajoutez vos articles préférés ou connectez - vous pour voir votre sac`,
                `Fügen Sie Ihre Lieblingsartikel hinzu oder melden Sie sich an, um Ihre Tasche zu überprüfen`,
                `Añadir sus artículos favoritos o iniciar sesión para ver su paquete`,
                `Добавьте свои любимые предметы или войдите, чтобы увидеть вашу сумку`,
                `Adicione seus itens favoritos ou faça login para verificar sua mala`,
                `お気に入りのアイテムを追加またはログインしてバッグを表示する`,
                `إضافة العناصر المفضلة لديك أو تسجيل الدخول لعرض الحزمة الخاصة بك`]
        }, {
            text: 'Add your favorite items to your bag',
            list: [
                `Ajoutez vos articles préférés au sac`,
                `Fügen Sie Ihre Lieblingsartikel in Ihre Tasche`,
                `Añadir sus artículos favoritos a la bolsa`,
                `Добавьте свои любимые вещи в пакет.`,
                `Adicione os seus itens favoritos à sua mala`,
                `お気に入りのアイテムをバッグに追加`,
                `إضافة العناصر المفضلة لديك إلى حزمة`]
        }, {
            text: 'SIGN IN/REGISTER',
            list: [
                `Connexion / enregistrement`,
                `ANMELDEN/REGISTRIEREN`,
                `Iniciar sesión / registrarse`,
                `Регистрация / регистрация`,
                `ASSINAR/REGISTAR`,
                `ログイン/登録`,
                `تسجيل الدخول`]
        }, {
            text: 'Your wishlist',
            list: [
                `Votre liste de souhaits`,
                `Ihre Wunschliste`,
                `Su lista de deseos`,
                `Ваше пожелание.`,
                `A sua lista de desejos`,
                `あなたの願い`,
                `قائمة رغباتك`]
        }, {
            text: 'Common Location',
            list: [
                `Emplacement public`,
                `Gemeinsame Lage`,
                `Ubicación pública`,
                `Публичные места`,
                `Localização Comum`,
                `共通の場所`,
                `موقع عام`]
        }, {
            text: 'Ship to',
            list: [
                `Le destinataire`,
                `Versand nach`,
                `Destinatario`,
                `Получатель`,
                `Enviar para`,
                `出荷先`,
                `المرسل إليه`]
        }, {
            text: 'My Profile',
            list: [
                `Mon profil`,
                `Mein Profil`,
                `Perfil`,
                `Мой профиль`,
                `O meu perfil`,
                `プロフィール`,
                `ملفي الشخصي`]
        }, {
            text: 'Confirm',
            list: [
                `Confirmé`,
                `Bestätigen`,
                `Confirmado`,
                `Подтверждение`,
                `Confirmar`,
                `かくにん`,
                `أكّد`]
        }, {
            text: 'Male',
            list: [
                `Mâle`,
                `Männlich`,
                `Masculino`,
                `Мужской`,
                `Sexo masculino`,
                `男性`,
                `ذكر`]
        }, {
            text: 'Female',
            list: [
                `Femelle`,
                `Weiblich`,
                `Femenino`,
                `Женский`,
                `Feminino`,
                `女性`,
                `أنثى`]
        }, {
            text: "Shop confidence with YFN Jewelry knowing that if something goes wrong, we'll always got your back.",
            list: [
                `Magasinez en toute confiance avec YFN Jewelry, sachant que nous vous soutiendrons toujours en cas de problème.`,
                `Kaufen Sie Vertrauen mit YFN Jewelry in dem Wissen, dass wir Ihnen immer den Rücken bieten, wenn etwas schief geht.`,
                `Compre con confianza con yfn Jewelry y sepa que siempre le apoyaremos si hay algún problema.`,
                `Уверенные покупки с YFN Jewelry, зная, что если возникнут проблемы, мы всегда будем поддерживать вас.`,
                `Compre confiança com a YFN Jewelry sabendo que, se algo der errado, sempre lhe protegeremos.`,
                `YFNジュエリーと自信を持って買い物をして、もし問題が発生したら、私たちは永遠にあなたを支持します。`,
                `التسوق بثقة مع مجوهرات YFN ، مع العلم أنه إذا كان هناك أي مشكلة ، وسوف ندعمكم دائما .`]
        }, {
            text: 'Phone Number(Optional)',
            list: [
                'Numéro de téléphone(Facultatif)',
                'Telenfonnummer(optional)',
                'Número de teléfono(Opcional)',
                'Номер телефона(опционально)',
                'Número de telefone(Opcional)',
                '電話番号（オプション）',
                `(اختياري)رقم التليفون`]
        }, {
            text: 'Please enter your email address',
            list: [
                `Veuillez entrer votre adresse e - mail`,
                `Bitte geben Sie Ihre E-Mail-Adresse ein`,
                `Introduzca su dirección de correo electrónico`,
                `Введите адрес электронной почты`,
                `Introduza o seu endereço de e-mail`,
                `電子メールアドレスを入力してください`,
                `الرجاء إدخال عنوان البريد الإلكتروني الخاص بك`]
        }, {
            text: 'Do you want to shop in ',
            list: [
                `Voulez - vous faire du shopping ici `,
                `Möchten Sie einkaufen in `,
                `¿¿ quieres comprar aquí? `,
                `Хотите купить здесь? `,
                `Queres fazer compras em `,
                `ここで買い物をしたいですか`,
                `هل تريد أن تسوق هنا `]
        }, {
            text: 'After switching, you may need to register or sign in again',
            list: [
                `Après le changement, vous devrez peut - être vous réinscrire ou vous connecter`,
                `Nach dem Wechsel müssen Sie sich möglicherweise erneut registrieren oder anmelden`,
                `Después de cambiar, es posible que necesite volver a registrarse o iniciar sesión`,
                `После переключения вам может потребоваться перерегистрация или регистрация`,
                `Depois de mudar, você pode precisar se registrar ou entrar novamente`,
                `切り替え後、再登録またはログインが必要になる場合があります`,
                `بعد التبديل ، قد تحتاج إلى إعادة تسجيل أو تسجيل الدخول`]
        }, {
            text: 'Item availability, prices, shipping fees, and taxes may change(including items in your bag)',
            list: [
                `La disponibilité des articles, les prix, les frais d'expédition et les taxes sont sujets à changement (y compris les articles dans votre sac)`,
                `Artikelverfügbarkeit, Preise, Versandkosten und Steuern können sich ändern (einschließlich Artikel in Ihrer Tasche)`,
                `La disponibilidad de artículos, precios, fletes e impuestos pueden cambiar (incluidos los artículos en su bolso)`,
                `Поставка товаров, цены, фрахт и налоги могут измениться (включая товары в вашей сумке)`,
                `Disponibilidade de itens, preços, taxas de envio e impostos podem mudar (incluindo itens em sua bolsa)`,
                `物品の供給状況、価格、運送費、税金が変更される可能性があります（パッケージに含まれる物品）`,
                `توريد السلع والأسعار والشحن والضرائب قد تتغير ( بما في ذلك البنود في الحزمة الخاصة بك )`]
        }, {
            text: 'Continue to ',
            list: [
                `Continuer `,
                `Weiter zu `,
                `Continúa. `,
                `Продолжение `,
                `Continuar para `,
                `続行 `,
                `تابع `]
        }, {
            text: `YFN's main sitemap page for the website. Featuring links to all major sections and pages within the site`,
            list: [
                `Page principale du plan du site de YFN pour le site Web. Présentant des liens vers toutes les principales sections et pages du site`,
                `YFNs Haupt-Sitemap-Seite für die Website. Mit Links zu allen wichtigen Abschnitten und Seiten innerhalb der Website`,
                `La página principal del mapa del sitio de YFN para el sitio web. Incluye enlaces a todas las secciones y páginas principales del sitio.`,
                `Основная страница карты сайта YFN для веб-сайта. Содержит ссылки на все основные разделы и страницы сайта.`,
                `Página principal do mapa do site da YFN para o site. Apresentando links para todas as principais seções e páginas do site`,
                `YFN の Web サイトのメイン サイトマップ ページ。サイト内のすべての主要なセクションとページへのリンクを掲載`,
                `صفحة خريطة الموقع الرئيسية لـ YFN للموقع. يضم روابط لجميع الأقسام والصفحات الرئيسية داخل الموقع`]
        }, {
            text: 'CONTINUE',
            list: [
                `Continue`,
                `Weiter`,
                `Continuar`,
                `Продолжение`,
                `Continuar`,
                `継続`,
                `استمرار`]
        }, {
            text: '6-18characters',
            list: [
                `6 à 18 caractères requis`,
                `6 bis 18 Zeichen erforderlich`,
                `Se necesitan entre 6 y 18 caracteres`,
                `Требуется от 6 до 18 символов.`,
                `6 a 18 caracteres necessários`,
                `6～18文字必要`,
                `تحتاج من 6 إلى 18 حرفا`]
        }, {
            text: 'YFN: Free Shipping Time Limited Only',
            list: [
                `YFN: délai de livraison gratuit seulement | Online Jewellery Platform`,
                `YFN: Kostenloser Versand Nur begrenzt; Online Schmuck Plattform`,
                `Yfn: solo tiempo de entrega gratuito | Plataforma de joyería en línea`,
                `YFN: Только время бесплатной доставки`,
                `YFN: Apenas Tempo de Envio Grátis | Online Jewelry Platform`,
                `YFN：送料無料期間限定｜オンラインジュエリープラットフォーム`,
                `YFN : الشحن مجانا الوقت فقط | المجوهرات على الانترنت منصة`]
        }, {
            text: 'Shop YFN jewelry for you including necklaces, rings, bracelets, anklets, charms, earrings, body jewelry, at our online jewelry platform. Discover our new luxury jewelry collection with Free shipping plus a 60-day return guarantee!',
            list: [
                `Achetez des bijoux YFN pour vous sur notre plate - forme de bijoux en ligne, y compris des colliers, des bagues, des bracelets, des chaînes de pied, des amulettes, des boucles d'oreilles, des bijoux corporels. Découvrez notre nouvelle collection de bijoux de luxe avec livraison gratuite et garantie de retour de 60 jours!`,
                `Kaufen Sie YFN Schmuck für Sie einschließlich Halsketten, Ringe, Armbänder, Fußkettchen, Charms, Ohrringe, Körperschmuck, auf unserer Online-Schmuckplattform. Entdecken Sie unsere neue Luxus-Schmuckkollektion mit kostenlosem Versand plus 60-tägiger Rückgabe Garantie!`,
                `Compre joyas yfn para usted en nuestra plataforma de joyas en línea, incluyendo collares, anillos, pulseras, tobilleras, amuletos, pendientes, joyas para el cuerpo. ¡¡ explora nuestra nueva colección de joyas de lujo, entrega gratuita y garantía de devolución de 60 días!`,
                `Купите ювелирные изделия YFN для вас на нашей онлайн - ювелирной платформе, включая ожерелье, кольцо, браслет, цепочку ног, амулет, серьги, ювелирные изделия для тела. Исследуйте нашу новую коллекцию роскошных ювелирных изделий, бесплатную доставку, 60 - дневную гарантию возврата!`,
                `Loja YFN jóias para você, incluindo colares, anéis, pulseiras, tornozeleiras, encantos, brincos, jóias do corpo, em nossa plataforma de jóias on-line. Descubra a nossa nova coleção de jóias de luxo com frete grátis e uma garantia de devolução de 60 dias!`,
                `ネックレス、指輪、ブレスレット、足輪、お守り、イヤリング、ボディジュエリーなど、オンラインジュエリープラットフォームでYFNジュエリーを購入してください。私たちの新しい贅沢ジュエリーシリーズを探して、送料無料、60日間返品保証！`,
                `على موقعنا على الانترنت منصة مجوهرات يمكنك شراء المجوهرات YFN ، بما في ذلك قلادة ، خاتم ، سوار ، خلخال ، تميمة ، والقرط ، والمجوهرات الجسم . استكشاف سلسلة جديدة من المجوهرات الفاخرة ، والشحن المجاني ، 60 يوما ضمان عودة !`]
        }, {
            text: 'Town/City',
            list: [
                `La ville`,
                `Ort/Stadt`,
                `Pueblos y ciudades`,
                `Города`,
                `Cidade/Cidade`,
                `タウン`,
                `البلدات`]
        }, {
            text: 'County',
            list: [
                `Comté`,
                `Landkreis`,
                `Condado`,
                `Округ`,
                `Condado`,
                `県`,
                `مقاطعة`]
        }, {
            text: `You've got a FREE STANDARD SHIPPING`,
            list: [
                `Vous bénéficiez d'une LIVRAISON STANDARD GRATUITE`,
                `Sie erhalten einen KOSTENLOSEN STANDARDVERSAND`,
                `Tienes ENVÍO ESTÁNDAR GRATIS`,
                `Вы получаете БЕСПЛАТНУЮ СТАНДАРТНУЮ ДОСТАВКУ.`,
                `Você tem um FRETE PADRÃO GRATUITO`,
                `標準配送料は無料です`,
                `لقد حصلت على شحن قياسي مجاني`]
        }, {
            text: `You've got a FREE EXPRESS SHIPPING`,
            list: [
                `Vous bénéficiez d'une LIVRAISON EXPRESS GRATUITE`,
                `Sie erhalten einen KOSTENLOSEN EXPRESS-VERSAND`,
                `Tienes ENVÍO EXPRESS GRATIS`,
                `Вы получили БЕСПЛАТНУЮ ЭКСПРЕСС-ДОСТАВКУ`,
                `Você tem um FRETE EXPRESSO GRATUITO`,
                `無料の速達配送をご利用いただけます`,
                `لقد حصلت على شحن سريع مجاني`]
        }, {
            text: `You've got a FREE SHIPPING`,
            list: [
                `Vous bénéficiez d'une LIVRAISON GRATUITE`,
                `Sie erhalten KOSTENLOSEN VERSAND`,
                `Tienes ENVÍO GRATIS`,
                `У вас есть БЕСПЛАТНАЯ ДОСТАВКА`,
                `Você tem FRETE GRÁTIS`,
                `送料無料です`,
                `لقد حصلت على شحن مجاني`]
        }, {
            text: 'Set your birthday',
            list: [
                `Définissez votre anniversaire`,
                `Legen Sie Ihren Geburtstag fest`,
                `Establece tu cumpleaños`,
                `Установите свой день рождения`,
                `Defina seu aniversário`,
                `誕生日を設定してください`,
                `حدد تاريخ ميلادك`]
        }, {
            text: 'Shipping Only For',
            list: [
                `Définissez votre anniversaire`,
                `Legen Sie Ihren Geburtstag fest`,
                `Establece tu cumpleaños`,
                `Установите свой день рождения`,
                `Defina seu aniversário`,
                `誕生日を設定してください`,
                `حدد تاريخ ميلادك`]
        }, {
            text: 'Continue with Google',
            list: [
                `Continuer avec Google`,
                `Weiter mit Google`,
                `Seguir usando Google`,
                `Продолжайте использовать Google`,
                `Continuar com o Google`,
                `グーグルを使い続ける`,
                `الاستمرار في استخدام جوجل`]
        }, {
            text: 'Continue with Facebook',
            list: [
                `Continuer avec Facebook`,
                `Weiter mit Facebook`,
                `Seguir usando Facebook`,
                `Продолжение использования Facebook`,
                `Continuar com o Facebook`,
                `Facebookを使い続ける`,
                `الاستمرار في استخدام الفيسبوك`]
        }, {
            text: 'Nickname',
            list: [
                `Surnom`,
                `Spitzname`,
                `Apodo`,
                `псевдоним`,
                `alcunha`,
                `ニックネーム`,
                `اللقب`]
        }, {
            text: 'Gender',
            list: [
                `Genre`,
                `Geschlecht`,
                `Género`,
                `Пол`,
                `Género`,
                `性別`,
                `جنس`]
        }, {
            text: 'My Birthday',
            list: [
                `Mon anniversaire`,
                `Mein Geburtstag`,
                `Mi cumpleaños`,
                `Мой день рождения.`,
                `O meu aniversário`,
                `私の誕生日`,
                `عيد ميلادي`]
        }, {
            text: 'Wedding Anniversary',
            list: [
                `Anniversaire de mariage`,
                `Hochzeitstag`,
                `Aniversario de la boda`,
                `Годовщина свадьбы`,
                `Aniversário do Casamento`,
                `結婚記念日`,
                `الذكرى السنوية`]
        }, {
            text: 'Bio',
            list: [
                `Biologique`,
                `Bio`,
                `Biología`,
                `Биология`,
                `Bio`,
                `せいぶつ`,
                `علم الأحياء`]
        }, {
            text: 'Return Policy',
            list: [
                `Politique de retour`,
                `Rückgaberecht`,
                `Política de devoluciones`,
                `Политика возврата`,
                `Política de devolução`,
                `返品規則`,
                `سياسة العائدات`]
        }, {
            text: 'Current Level',
            list: [
                `Niveau actuel`,
                `Aktuelles Level`,
                `Nivel actual`,
                `Текущий уровень`,
                `Nível atual`,
                `現在のレベル`,
                `المستوى الحالي`]
        }, {
            text: 'Check Points',
            list: [
                `Points de contrôle`,
                `Kontrollpunkte`,
                `Puntos de control`,
                `Контрольные точки`,
                `Pontos de verificação`,
                `チェックポイント`,
                `نقاط تفتيش`]
        }, {
            text: 'to reach',
            list: [
                `atteindre`,
                `erreichen`,
                `alcanzar`,
                `достигать`,
                `alcançar`,
                `到達すること`,
                `للوصول`]
        }, {
            text: 'points can be reach',
            list: [
                `les points peuvent être atteints`,
                `Punkte können erreicht werden`,
                `se pueden alcanzar puntos`,
                `точки могут быть достигнуты`,
                `pontos podem ser alcançados`,
                `ポイントが達成できる`,
                `يمكن الوصول إلى النقاط`]
        }, {
            text: 'Subscription Information',
            list: [
                `Information d'abonnement`,
                `Abonnementinformationen`,
                `Información de suscripción`,
                `Информация о подписке`,
                `Informações de assinatura`,
                `購読情報`,
                `معلومات الاشتراك`]
        }, {
            text: 'Double Growth Value',
            list: [
                `Valeur de croissance double`,
                `Doppelter Wachstumswert`,
                `Valor de doble crecimiento`,
                `Двойной рост стоимости`,
                `Valor de crescimento duplo`,
                `倍増価値`,
                `قيمة النمو المزدوج`]
        }, {
            text: 'per month',
            list: [
                `par mois`,
                `pro Monat`,
                `por mes`,
                `в месяц`,
                `por mês`,
                `月あたり`,
                `كل شهر`]
        }, {
            text: 'One-To-One Service',
            list: [
                `Service individuel`,
                `Eins-zu-Eins-Service`,
                `Servicio uno a uno`,
                `Индивидуальное обслуживание`,
                `Serviço individual`,
                `ワンツーワンサービス`,
                `خدمة فردية`]
        }, {
            text: 'For order over',
            list: [
                `Pour une commande terminée`,
                `Zur Bestellung vorbei`,
                `por orden terminada`,
                `Для заказа свыше`,
                `Para encomenda acima`,
                `オーダーオーバーの場合`,
                `للطلب انتهى`]
        }, {
            text: 'Arrange priority shipped for you and one-on-one customer service.',
            list: [
                `Organisez une expédition prioritaire pour vous et un service client individuel.`,
                `Vereinbaren Sie einen bevorzugten Versand für Sie und einen individuellen Kundenservice.`,
                `Organice el envío prioritario para usted y el servicio al cliente personalizado.`,
                `Организуйте приоритетную доставку для вас и индивидуальное обслуживание клиентов.`,
                `Organize prioridade de envio para você e atendimento ao cliente individual.`,
                `優先配送とマンツーマンのカスタマーサービスを手配します。`,
                `ترتيب الأولوية التي يتم شحنها لك وخدمة العملاء الفردية.`]
        }, {
            text: 'Expiring soon',
            list: [
                `Expire bientôt`,
                `Läuft bald ab`,
                `Expirará pronto`,
                `Срок действия скоро истекает`,
                `Expira em breve`,
                `もうすぐ期限切れになります`,
                `تنتهي قريبا`]
        }, {
            text: 'You have exceeded this level',
            list: [
                `Vous avez dépassé ce niveau`,
                `Sie haben dieses Niveau überschritten`,
                `Has superado este nivel`,
                `Вы превысили этот уровень`,
                `Você ultrapassou este nível`,
                `このレベルを超えています`,
                `لقد تجاوزت هذا المستوى`]
        }, {
            text: 'You are already at the highest level',
            list: [
                `Vous êtes déjà au plus haut niveau`,
                `Sie befinden sich bereits auf dem höchsten Niveau`,
                `Ya estás en el nivel más alto.`,
                `Вы уже на самом высоком уровне`,
                `Você já está no nível mais alto`,
                `あなたはすでに最高レベルにいます`,
                `أنت بالفعل على أعلى مستوى`]
        }, {
            text: 'Valid for the current month',
            list: [
                `Valable pour le mois en cours`,
                `Gültig für den aktuellen Monat`,
                `Válida para el mes actual`,
                `Действует в течение текущего месяца`,
                `Válido para o mês atual`,
                `当月有効`,
                `صالحة للشهر الحالي`]
        }, {
            text: 'Shipping Only For: FREE STANDARD SHIPPING',
            list: [
                `Livraison uniquement pour : Livraison standard gratuite`,
                `Nur Versand für: Kostenloser Standardversand`,
                `Envío solo para: Envío estándar gratuito`,
                `Только доставка: Бесплатная стандартная доставка`,
                `Frete apenas para: Frete padrão grátis`,
                `配送のみ: 通常配送無料`,
                `الشحن فقط من أجل: الشحن القياسي المجاني`]
        }, {
            text: 'Shipping Only For: FREE EXPRESS SHIPPING',
            list: [
                `Expédition uniquement pour : LIVRAISON EXPRESS GRATUITE`,
                `Nur Versand für: KOSTENLOSER EXPRESSVERSAND`,
                `Envío solo para: ENVÍO EXPRESS GRATIS`,
                `Только доставка: БЕСПЛАТНАЯ ЭКСПРЕСС-ДОСТАВКА`,
                `Frete apenas para: ENVIO EXPRESSO GRATUITO`,
                `配送のみ: 無料速達配送`,
                `الشحن فقط من أجل: الشحن السريع المجاني`]
        }, {
            text: 'More Service',
            list: [
                `Plus de services`,
                `Mehr Service`,
                `Más servicio`,
                `Больше услуг`,
                `Mais serviço`,
                `さらなるサービス`,
                `المزيد من الخدمة`]
        }, {
            text: 'Added To Bag Successfully',
            list: [
                `Ajouté au panier avec succès`,
                `Erfolgreich zur Tasche hinzugefügt`,
                `Agregado a la bolsa con éxito`,
                `Добавлено в корзину успешно`,
                `Adicionado à sacola com sucesso`,
                `バッグに正常に追加されました`,
                `تمت الإضافة إلى الحقيبة بنجاح`]
        }, {
            text: 'Trending Search',
            list: [
                `Recherche tendance`,
                `Trendsuche`,
                `Búsqueda de tendencias`,
                `Популярный поиск`,
                `Tendências de pesquisa`,
                `トレンド検索`,
                `بحث شائع`]
        }, {
            text: 'Repeated Order Reminder',
            list: [
                `Rappel de commande répété`,
                `Erinnerung an wiederholte Bestellungen`,
                `Recordatorio de pedido repetido`,
                `Напоминание о повторном заказе`,
                `Lembrete de pedido repetido`,
                `繰り返し注文リマインダー`,
                `تذكير الطلب المتكرر`]
        }, {
            text: 'You have placed an order',
            list: [
                `Vous avez passé une commande`,
                `Sie haben eine Bestellung aufgegeben`,
                `Has realizado un pedido`,
                `Вы разместили заказ`,
                `Você fez um pedido`,
                `注文しました`,
                `لقد قدمت طلبا`]
        }, {
            text: 'for the same product(s) on the YFN.com on',
            list: [
                `pour le(s) même(s) produit(s) sur YFN.com sur`,
                `für die gleichen Produkte auf YFN.com`,
                `para los mismos productos en YFN.com en`,
                `для тех же продуктов на YFN.com на`,
                `para o(s) mesmo(s) produto(s) no YFN.com em`,
                `YFN.com の同じ商品について`,
                `لنفس المنتج (المنتجات) على موقع YFN.com`]
        }, {
            text: 'Please confirm if you want to continue placing the order for purchase?',
            list: [
                `Veuillez confirmer si vous souhaitez continuer à passer la commande d'achat ?`,
                `Bitte bestätigen Sie, ob Sie mit der Kaufbestellung fortfahren möchten.`,
                `¿Por favor confirme si desea continuar realizando el pedido de compra?`,
                `Пожалуйста, подтвердите, хотите ли вы продолжить оформление заказа на покупку?`,
                `Por favor, confirme se deseja continuar fazendo o pedido de compra?`,
                `購入注文を続行するかどうかを確認してください。`,
                `يرجى التأكيد إذا كنت تريد الاستمرار في تقديم طلب الشراء؟`]
        }, {
            text: 'Continue to Order',
            list: [
                `Continuer à commander`,
                `Weiter zur Bestellung`,
                `Continuar con el pedido`,
                `Продолжить заказ`,
                `Continuar para fazer o pedido`,
                `注文を続ける`,
                `الاستمرار في الطلب`]
        }, {
            text: 'Check My Order',
            list: [
                `Vérifier ma commande`,
                `Überprüfen Sie meine Bestellung`,
                `Revisa mi pedido`,
                `Проверьте мой заказ`,
                `Verifique meu pedido`,
                `注文を確認してください`,
                `تحقق من طلبي`]
        }, {
            text: 'Coupons Items',
            list: [
                `Articles de coupons`,
                `Gutscheinartikel`,
                `Artículos de cupones`,
                `Купоны`,
                `Itens de cupons`,
                `クーポンアイテム`,
                `عناصر الكوبونات`]
        }, {
            text: 'Are you sure to buy it again',
            list: [
                `Êtes-vous sûr de l'acheter à nouveau`,
                `Werden Sie es sicher noch einmal kaufen`,
                `Estás segura de comprarlo de nuevo`,
                `Вы уверены, что купите его снова?`,
                `Você tem certeza de comprá-lo novamente`,
                `もう一度購入してもよろしいですか`,
                `هل أنت متأكد من شرائه مرة أخرى`]
        }, {
            text: 'for the same product(s) on the YFN.com at',
            list: [
                `pour le(s) même(s) produit(s) sur YFN.com à`,
                `für die gleichen Produkte auf YFN.com unter`,
                `para los mismos productos en YFN.com en`,
                `для тех же продуктов на YFN.com по адресу`,
                `para o(s) mesmo(s) produto(s) no YFN.com em`,
                `YFN.com の同じ製品については、`,
                `لنفس المنتج (المنتجات) على YFN.com على`]
        }, {
            text: 'You have no item saved now, heart everything you love',
            list: [
                `Vous n'avez aucun article enregistré pour le moment, aimez tout ce que vous aimez`,
                `Sie haben jetzt keinen Artikel gespeichert, sondern alles, was Sie lieben`,
                `No tienes ningún artículo guardado ahora, guarda en el corazón todo lo que amas`,
                `У вас сейчас не сохранено ни одного предмета, любите все, что любите.`,
                `Você não tem nenhum item salvo agora, coloque no coração tudo o que você ama`,
                `現在保存されているアイテムはありません。愛するものすべてにハートを付けてください`,
                `ليس لديك أي عنصر محفوظ الآن، قلب كل ما تحب`]
        }, {
            text: 'Free Worldwide Standard Shipping',
            list: [
                `Livraison standard gratuite dans le monde entier`,
                `Kostenloser weltweiter Standardversand`,
                `Envío estándar mundial gratuito`,
                `Бесплатная стандартная доставка по всему миру`,
                `Frete padrão gratuito para todo o mundo`,
                `世界中への標準配送料無料`,
                `شحن قياسي عالمي مجاني`]
        }, {
            text: 'THE CORE VALUE OF\nYFN JEWELRY\nIS SUSTAINABILITY',
            list: [
                `LA VALEUR FONDAMENTALE DE\nYFN JEWELRY\nEST LA DURABILITÉ`,
                `DER KERNWERT VON\nYFN JEWELRY\nIST NACHHALTIGKEIT`,
                `EL VALOR FUNDAMENTAL DE\nYFN JEWELRY\nES LA SOSTENIBILIDAD`,
                `ОСНОВНАЯ ЦЕННОСТЬ\nYFN JEWELRY\n— ЭКОЛОГИЧНОСТЬ`,
                `O VALOR CENTRAL DA\nYFN JEWELRY\nÉ A SUSTENTABILIDADE`,
                `YFN ジュエリーの核と\nなる価値は\n持続可能性です`,
                `القيمة\n الأساسية لمجوهرات \nYFN هي الاستدامة`]
        }, {
            text: "Through the Life Cycle Plan, we hope to achieve our goal of obtaining 100% recycled material by 2028. While solving climate change won't be easy, YFN Jewelry is committed to maximizing its impact and making the world a healthy, beautiful place.",
            list: [
                `Grâce au plan de cycle de vie, nous espérons atteindre notre objectif d'obtenir 100 % de matériaux recyclés d'ici 2028. Même s'il ne sera pas facile de résoudre le changement climatique, YFN Jewelry s'engage à maximiser son impact et à faire du monde un endroit sain et beau.`,
                `Mit dem Lebenszyklusplan hoffen wir, unser Ziel zu erreichen, bis 2028 100 % recyceltes Material zu erhalten. Auch wenn die Lösung des Klimawandels nicht einfach sein wird, setzt sich YFN Jewelry dafür ein, seine Auswirkungen zu maximieren und die Welt zu einem gesunden, schönen Ort zu machen.`,
                `A través del Plan de Ciclo de Vida, esperamos lograr nuestro objetivo de obtener material 100% reciclado para 2028. Si bien resolver el cambio climático no será fácil, YFN Jewelry se compromete a maximizar su impacto y hacer del mundo un lugar hermoso y saludable.`,
                `З дапамогай Плана жыццёвага цыкла мы спадзяемся дасягнуць нашай мэты па атрыманні 100% перапрацаванага матэрыялу да 2028 г. Нягледзячы на тое, што вырашыць праблему змены клімату будзе няпроста, YFN Jewelry імкнецца максымізаваць свой уплыў і зрабіць свет здаровым і прыгожым.`,
                `Através do Plano de Ciclo de Vida, esperamos atingir o nosso objetivo de obter 100% de material reciclado até 2028. Embora resolver as alterações climáticas não seja fácil, a YFN Jewelry está empenhada em maximizar o seu impacto e tornar o mundo um lugar saudável e bonito.`,
                `ライフサイクル プランを通じて、2028 年までに 100% リサイクル素材を入手するという目標を達成したいと考えています。気候変動を解決するのは簡単ではありませんが、YFN ジュエリーはその影響を最大化し、世界を健康で美しい場所にすることに取り組んでいます。`,
                `من خلال خطة دورة الحياة، نأمل أن نحقق هدفنا المتمثل في الحصول على مواد معاد تدويرها بنسبة 100% بحلول عام 2028. في حين أن حل تغير المناخ لن يكون سهلاً، فإن YFN Jewelry ملتزمة بزيادة تأثيره إلى أقصى حد وجعل العالم مكانًا صحيًا وجميلًا.`]
        }, {
            text: 'Looking forward to 2028',
            list: [
                `Dans l’attente de 2028`,
                `Ich freue mich auf 2028`,
                `Mirando hacia el 2028`,
                `З нецярпеннем чакаем 2028 года`,
                `Ansioso para 2028`,
                `2028年が楽しみ`,
                `نتطلع إلى عام 2028`]
        }, {
            text: 'We have introduced personalized cups, plates, and cutlery for employees to replace with 100% eco-friendly alternatives to reduce the waste of single-use household items; We have installed recycling bins across our headquarters to encourage proper waste disposal; In addition, we have introduced digital Signature systems eliminate the need for physical signatures and reduce paper waste.',
            list: [
                `Nous avons introduit des tasses, assiettes et couverts personnalisés pour les employés afin de les remplacer par des alternatives 100 % écologiques afin de réduire le gaspillage d'articles ménagers à usage unique ; Nous avons installé des bacs de recyclage dans tout notre siège social pour encourager une élimination appropriée des déchets ; De plus, nous avons introduit des systèmes de signature numérique pour éliminer le besoin de signatures physiques et réduire le gaspillage de papier.`,
                `Wir haben personalisierte Tassen, Teller und Besteck eingeführt, die unsere Mitarbeiter durch 100 % umweltfreundliche Alternativen ersetzen können, um die Verschwendung von Einweg-Haushaltsgegenständen zu reduzieren. Wir haben in unserem Hauptsitz Recyclingbehälter aufgestellt, um die ordnungsgemäße Abfallentsorgung zu fördern. Darüber hinaus haben wir digitale Signatursysteme eingeführt, um physische Unterschriften überflüssig zu machen und Papierverschwendung zu reduzieren.`,
                `Hemos introducido vasos, platos y cubiertos personalizados para que los empleados los reemplacen con alternativas 100% ecológicas para reducir el desperdicio de artículos del hogar de un solo uso; Hemos instalado contenedores de reciclaje en toda nuestra sede para fomentar la eliminación adecuada de residuos; Además, hemos introducido sistemas de firma digital para eliminar la necesidad de firmas físicas y reducir el desperdicio de papel.`,
                `Мы прадставілі персаналізаваныя кубкі, талеркі і сталовыя прыборы для супрацоўнікаў, каб замяніць іх на 100% экалагічна чыстыя альтэрнатывы, каб скараціць адходы аднаразовых прадметаў хатняга ўжытку; Мы ўсталявалі кантэйнеры для перапрацоўкі ў нашай штаб-кватэры, каб спрыяць правільнай утылізацыі адходаў; Акрамя таго, мы ўкаранілі сістэмы лічбавага подпісу, каб пазбавіцца ад неабходнасці фізічных подпісаў і паменшыць адходы паперы.`,
                `Introduzimos copos, pratos e talheres personalizados para os funcionários substituirem por alternativas 100% ecológicas para reduzir o desperdício de utensílios domésticos descartáveis; Instalamos lixeiras para reciclagem em nossa sede para incentivar o descarte adequado de resíduos; Além disso, introduzimos sistemas de assinatura digital para eliminar a necessidade de assinaturas físicas e reduzir o desperdício de papel.`,
                `使い捨て家庭用品の廃棄を削減するために、従業員向けにパーソナライズされたカップ、皿、カトラリーを 100% 環境に優しい代替品と交換できるように導入しました。 私たちは廃棄物の適切な処理を奨励するために、本社全体にリサイクル箱を設置しました。 さらに、デジタル署名システムを導入し、物理的な署名の必要性を排除し、紙の無駄を削減しました。`,
                `لقد قدمنا أكوابًا وأطباقًا وأدوات مائدة مخصصة للموظفين لاستبدالها ببدائل صديقة للبيئة بنسبة 100% لتقليل هدر الأدوات المنزلية ذات الاستخدام الواحد؛ لقد قمنا بتركيب صناديق إعادة التدوير في جميع أنحاء مقرنا الرئيسي لتشجيع التخلص السليم من النفايات؛ بالإضافة إلى ذلك، قمنا بإدخال أنظمة التوقيع الرقمي للتخلص من الحاجة إلى التوقيعات المادية وتقليل النفايات الورقية.`]
        }, {
            text: 'Daily life',
            list: [
                `La vie quotidienne`,
                `Alltag`,
                `Vida diaria`,
                `Штодзённае жыццё`,
                `Vida cotidiana`,
                `日常生活`,
                `الحياة اليومية`]
        }, {
            text: 'Production and recycling',
            list: [
                `Production et recyclage`,
                `Produktion und Recycling`,
                `Producción y reciclaje`,
                `Вытворчасць і перапрацоўка`,
                `Produção e reciclagem`,
                `生産とリサイクル`,
                `الإنتاج وإعادة التدوير`]
        }, {
            text: 'Our responsibility',
            list: [
                `Notre responsabilité`,
                `Unsere Verantwortung`,
                `Nuestra responsabilidad`,
                `Наша адказнасць`,
                `Nossa responsabilidade`,
                `私たちの責任`,
                `مسؤوليتنا`]
        }, {
            text: 'Sustainability should permeate every aspect of our lives and we want to integrate it into everything we do.By avoiding large inventories and reducing waste, YFN Jewelry hopes to minimize the negative impact on our beautiful planet. In fact, we only craft, cut, engrave, and coat your jewelry after we receive your order. Just to make sure nothing goes to waste.',
            list: [
                `La durabilité doit imprégner tous les aspects de nos vies et nous souhaitons l’intégrer dans tout ce que nous faisons.En évitant les gros stocks et en réduisant les déchets, YFN Jewelry espère minimiser l'impact négatif sur notre belle planète. Nous fabriquons, coupons, gravons et enduisons vos bijoux uniquement après réception de votre commande. Juste pour être sûr que rien ne soit gaspillé.`,
                `Nachhaltigkeit sollte jeden Aspekt unseres Lebens durchdringen und wir möchten sie in alles integrieren, was wir tun.Durch die Vermeidung großer Lagerbestände und die Reduzierung von Abfall hofft YFN Jewelry, die negativen Auswirkungen auf unseren schönen Planeten zu minimieren. Wir fertigen, schneiden, gravieren und beschichten Ihren Schmuck erst, nachdem wir Ihre Bestellung erhalten haben. Nur um sicherzustellen, dass nichts verschwendet wird.`,
                `La sostenibilidad debe impregnar todos los aspectos de nuestras vidas y queremos integrarla en todo lo que hacemos.Al evitar grandes inventarios y reducir el desperdicio, YFN Jewelry espera minimizar el impacto negativo en nuestro hermoso planeta. Solo elaboramos, cortamos, grabamos y cubrimos sus joyas después de recibir su pedido. Sólo para asegurarnos de que nada se desperdicie.`,
                `Устойлівае развіццё павінна пранікаць ва ўсе аспекты нашага жыцця, і мы хочам інтэграваць яго ва ўсё, што робім.Пазбягаючы вялікіх запасаў і памяншаючы адходы, YFN Jewelry спадзяецца звесці да мінімуму негатыўны ўплыў на нашу прыгожую планету. Мы ствараем, выразаем, гравіруем і пакрываем вашы ўпрыгажэнні толькі пасля таго, як атрымаем ваш заказ. Проста каб пераканацца, што нічога не прападае.`,
                `A sustentabilidade deve permear todos os aspectos das nossas vidas e queremos integrá-la em tudo o que fazemos.Ao evitar grandes estoques e reduzir o desperdício, a YFN Jewelry espera minimizar o impacto negativo em nosso lindo planeta. Só fabricamos, cortamos, gravamos e revestimos suas joias depois de recebermos seu pedido. Só para garantir que nada seja desperdiçado.`,
                `サステナビリティは私たちの生活のあらゆる側面に浸透する必要があり、私たちはそれを私たちの行動すべてに組み入れたいと考えています。YFN ジュエリーは、大量の在庫を避け、廃棄物を削減することで、美しい地球への悪影響を最小限に抑えたいと考えています。 ご注文をいただいてから、ジュエリーの製作、切断、彫刻、コーティングを行います。 何も無駄にならないようにするためです。`,
                `يجب أن تتخلل الاستدامة كل جانب من جوانب حياتنا، ونحن نريد دمجها في كل ما نقوم به.من خلال تجنب المخزون الكبير وتقليل النفايات، تأمل YFN Jewelry في تقليل التأثير السلبي على كوكبنا الجميل. نحن نقوم فقط بصناعة مجوهراتك وقطعها ونقشها وطلاءها بعد أن نتلقى طلبك. فقط للتأكد من أنه لن يضيع أي شيء.`]
        }, {
            text: 'Sustainability',
            list: [
                `Durabilité`,
                `Nachhaltigkeit`,
                `Sostenibilidad`,
                `Устойчивое развитие`,
                `Sustentabilidade`,
                `持続可能性`,
                `الاستدامة`]
        }, {
            text: 'was officially established. We use our production advantages to provide customers with personalized, customized services and can achieve delivery in a short time. We guarantee to repurchase the jewelry produced by YFN to ensure a healthy "life cycle."',
            list: [
                `Jewelry a été officiellement créé. Nous utilisons nos avantages de production pour fournir aux clients des services personnalisés et personnalisés et pouvons réaliser une livraison dans un court délai. Nous garantissons le rachat des bijoux produits par YFN pour garantir un « cycle de vie » sain.`,
                `offiziell gegründet. Wir nutzen unsere Produktionsvorteile, um unseren Kunden personalisierte, maßgeschneiderte Dienstleistungen zu bieten und eine Lieferung in kurzer Zeit zu erreichen. Wir garantieren den Rückkauf des von YFN hergestellten Schmucks, um einen gesunden „Lebenszyklus“ zu gewährleisten.`,
                `Utilizamos nuestras ventajas de producción para brindar a los clientes servicios personalizados y personalizados y podemos lograr la entrega en poco tiempo. Garantizamos recomprar las joyas producidas por YFN para garantizar un "ciclo de vida" saludable.`,
                `. Мы используем наши производственные преимущества, чтобы предоставлять клиентам персонализированные, индивидуальные услуги и обеспечивать доставку в короткие сроки. Мы гарантируем выкуп ювелирных изделий YFN, чтобы обеспечить здоровый «жизненный цикл».`,
                `foi oficialmente estabelecido. Utilizamos nossas vantagens de produção para oferecer aos clientes serviços personalizados e customizados e podemos conseguir a entrega em um curto espaço de tempo. Garantimos a recompra das joias produzidas pela YFN para garantir um “ciclo de vida” saudável.`,
                `が正式に設立されました。 当社は生産の利点を活用して、お客様にパーソナライズされたカスタマイズされたサービスを提供し、短納期を実現します。 健全な「ライフサイクル」を確保するために、YFN が製造したジュエリーを再購入することを保証します。`,
                `رسميًا. نحن نستخدم مزايا الإنتاج لدينا لتزويد العملاء بخدمات مخصصة ومخصصة ويمكننا تحقيق التسليم في وقت قصير. نحن نضمن إعادة شراء المجوهرات التي تنتجها YFN لضمان "دورة حياة" صحية.`]
        }, {
            text: 'From 1998 to 2014, the YFN jewelry factory provided ODM services for many European and American brands. Many brands favor its exquisite manufacturing technology. In 2015,',
            list: [
                `De 1998 à 2014, l'usine de bijoux YFN a fourni des services ODM pour de nombreuses marques européennes et américaines. De nombreuses marques privilégient sa technologie de fabrication exquise. En 2015, le `,
                `Von 1998 bis 2014 erbrachte die YFN-Schmuckfabrik ODM-Dienstleistungen für viele europäische und amerikanische Marken. Viele Marken bevorzugen die exquisite Fertigungstechnologie. Im Jahr 2015 wurde die `,
                `De 1998 a 2014, la fábrica de joyería YFN brindó servicios ODM para muchas marcas europeas y americanas. Muchas marcas prefieren su exquisita tecnología de fabricación. En 2015, se estableció oficialmente `,
                `С 1998 по 2014 год ювелирный завод YFN предоставлял ODM-услуги многим европейским и американским брендам. Многие бренды отдают предпочтение изысканным технологиям производства. В 2015 году был официально открыт `,
                `De 1998 a 2014, a fábrica de joias YFN forneceu serviços ODM para muitas marcas europeias e americanas. Muitas marcas favorecem sua tecnologia de fabricação requintada. Em 2015, o `,
                `998 年から 2014 年まで、YFN ジュエリー工場は多くのヨーロッパおよびアメリカのブランドに ODM サービスを提供しました。 その精緻な製造技術は多くのブランドから支持されています。 2015年に`,
                `من عام 1998 إلى عام 2014، قدم مصنع المجوهرات YFN خدمات ODM للعديد من العلامات التجارية الأوروبية والأمريكية. تفضل العديد من العلامات التجارية تكنولوجيا التصنيع الرائعة. في عام 2015، تم تأسيس `]
        }, {
            text: 'YFN Jewelry Online Mall',
            list: [
                `centre commercial en ligne YFN`,
                `YFN Jewelry Online Mall`,
                `YFN Jewelry Online Mall`,
                `интернет-торговый центр YFN Jewelry`,
                `YFN Jewelry Online Mall`,
                `YFNジュエリーオンラインモール`,
                `YFN Jewelry Online Mall`]
        }, {
            text: 'Click here',
            list: [
                `Cliquez ici`,
                `klicken Sie hier`,
                `haga clic aquí`,
                `Націсніце тут`,
                `Clique aqui`,
                `ここをクリックしてください。`,
                `انقر هنا`]
        }, {
            text: 'to learn more about what we’re doing to make a difference.',
            list: [
                `pour en savoir plus sur ce que nous faisons pour faire la différence.`,
                `Klicken Sie hier, um mehr darüber zu erfahren, was wir tun, um einen Unterschied zu machen.`,
                `para obtener más información sobre lo que estamos haciendo para marcar la diferencia.`,
                `,каб даведацца больш аб тым, што мы робім, каб змяніць сітуацыю.`,
                `para saber mais sobre o que estamos fazendo para fazer a diferença.`,
                `変化をもたらすために私たちが取り組んでいることの詳細については`,
                `لمعرفة المزيد حول ما نقوم به لإحداث فرق.`]
        }, {
            text: 'Sustainability should permeate every aspect of our lives and we want to integrate it into everything we do.',
            list: [
                `La durabilité doit imprégner tous les aspects de nos vies et nous souhaitons l’intégrer dans tout ce que nous faisons.`,
                `Nachhaltigkeit sollte jeden Aspekt unseres Lebens durchdringen und wir möchten sie in alles integrieren, was wir tun. `,
                `La sostenibilidad debe impregnar todos los aspectos de nuestras vidas y queremos integrarla en todo lo que hacemos.`,
                `Устойлівае развіццё павінна пранікаць ва ўсе аспекты нашага жыцця, і мы хочам інтэграваць яго ва ўсё, што робім.`,
                `A sustentabilidade deve permear todos os aspectos das nossas vidas e queremos integrá-la em tudo o que fazemos.`,
                `サステナビリティは私たちの生活のあらゆる側面に浸透する必要があり、私たちはそれを私たちの行動すべてに組み入れたいと考えています。`,
                `يجب أن تتخلل الاستدامة كل جانب من جوانب حياتنا، ونحن نريد دمجها في كل ما نقوم به.`]
        }, {
            text: 'The core value of YFN Jewelry is sustainability',
            list: [
                `La valeur fondamentale de YFN Jewelry est la durabilité`,
                `Der Kernwert von YFN Jewelry ist Nachhaltigkeit`,
                `El valor fundamental de YFN Jewelry es la sostenibilidad`,
                `Основная ценность YFN Jewelry — экологичность`,
                `O valor central da YFN Jewelry é a sustentabilidade`,
                `YFN ジュエリーの核となる価値は持続可能性です`,
                `القيمة الأساسية لمجوهرات YFN هي الاستدامة`]
        }, {
            text: 'Sustainability should permeate every aspect of our lives, and we craft, cut, engrave and coat your jewelry only after we receive your order, so there is no waste.',
            list: [
                `La durabilité doit imprégner tous les aspects de nos vies, et nous fabriquons, coupons, gravons et revêtons vos bijoux uniquement après réception de votre commande, afin qu'il n'y ait pas de gaspillage.`,
                `Nachhaltigkeit sollte jeden Aspekt unseres Lebens durchdringen. Wir fertigen, schneiden, gravieren und beschichten Ihren Schmuck erst, nachdem wir Ihre Bestellung erhalten haben, sodass keine Verschwendung entsteht.`,
                `La sostenibilidad debe impregnar todos los aspectos de nuestras vidas, y elaboramos, cortamos, grabamos y cubrimos sus joyas solo después de recibir su pedido, para que no haya desperdicio.`,
                `Экологичность должна пронизывать каждый аспект нашей жизни, и мы изготавливаем, режем, гравируем и покрываем ваши украшения только после того, как получим ваш заказ, чтобы не было отходов.`,
                `A sustentabilidade deve permear todos os aspectos de nossas vidas, e só fabricamos, cortamos, gravamos e revestimos suas joias depois de recebermos seu pedido, para que não haja desperdício.`,
                `持続可能性は私たちの生活のあらゆる側面に浸透する必要があり、ご注文をいただいてからジュエリーの製作、切断、彫刻、コーティングを行うため、無駄がありません。`,
                `يجب أن تتغلغل الاستدامة في كل جانب من جوانب حياتنا، ونحن نقوم بصناعة مجوهراتك وقطعها ونقشها وطلاءها فقط بعد أن نتلقى طلبك، لذلك لا يوجد أي هدر.`]
        }, {
            text: 'Your account has been created. You can log in with your Facebook account or via email and password. A random password has been sent to your email. Please update it ASAP.',
            list: [
                `Votre compte a été créé. Vous pouvez vous connecter avec votre compte Facebook ou par e - mail et mot de passe. Un mot de passe aléatoire a été envoyé à votre e - mail. Veuillez mettre à jour dès que possible.`,
                `Ihr Konto wurde erstellt. Sie können sich mit Ihrem Facebook-Konto oder per E-Mail und Passwort anmelden. Ein zufälliges Passwort wurde an Ihre E-Mail gesendet. Bitte aktualisieren Sie es so schnell wie möglich.`,
                `Su cuenta ha sido creada. Puede iniciar sesión con su cuenta de Facebook o por correo electrónico y contraseña. La contraseña aleatoria ha sido enviada a su correo electrónico. Por favor, actualice lo antes posible.`,
                `Ваш аккаунт создан. Вы можете войти в свою учетную запись Facebook или по электронной почте и паролю. Случайный пароль был отправлен в вашу электронную почту. Пожалуйста, обновите его как можно скорее.`,
                `A sua conta foi criada. Você pode fazer login com sua conta do Facebook ou via e-mail e senha. Uma senha aleatória foi enviada para o seu e-mail. Por favor, atualize o mais rápido possível.`,
                `アカウントが作成されました。Facebookアカウントを使用するか、電子メールとパスワードでログインできます。ランダムパスワードがEメールに送信されました。できるだけ早く更新してください。`,
                `تم إنشاء الحساب الخاص بك . يمكنك تسجيل الدخول باستخدام حساب الفيسبوك الخاص بك أو عن طريق البريد الإلكتروني وكلمة المرور . كلمة السر العشوائية تم إرسالها إلى البريد الإلكتروني الخاص بك . يرجى تحديث في أقرب وقت ممكن .`]
        }, {
            text: 'If you want to change email address, please contact us',
            list: [
                `Si vous souhaitez changer d'adresse e-mail, veuillez nous contacter`,
                `Wenn Sie Ihre E-Mail-Adresse ändern möchten, kontaktieren Sie uns bitte`,
                `Si desea cambiar la dirección de correo electrónico, por favor contáctenos`,
                `Если вы хотите изменить адрес электронной почты, пожалуйста, свяжитесь с нами`,
                `Se você deseja alterar o endereço de e-mail, entre em contato conosco`,
                `メールアドレスを変更したい場合はご連絡ください`,
                `إذا كنت تريد تغيير عنوان البريد الإلكتروني، يرجى الاتصال بنا`]
        }, {
            text: 'You have joined YFN for',
            list: [
                `Vous avez rejoint YFN pour`,
                `Du bist YFN beigetreten für`,
                `Te has unido a YFN por`,
                `Вы присоединились к YFN для`,
                `Você se juntou à YFN por`,
                `YFN に加入しました`,
                `لقد انضممت إلى YFN لـ`]
        }, {
            text: 'Thanks for your trust',
            list: [
                `Merci pour votre confiance`,
                `Vielen Dank für Ihr Vertrauen`,
                `Gracias por su confianza`,
                `Спасибо за ваше доверие`,
                `Obrigado por sua confiança`,
                `信頼していただきありがとうございます`,
                `شكرا لثقتكم`]
        }, {
            text: 'SIGN IN FOR BETTER EXPERIENCE',
            list: [
                `CONNECTEZ-VOUS POUR UNE MEILLEURE EXPÉRIENCE`,
                `MELDEN SIE SICH FÜR EINE BESSERE ERFAHRUNG AN`,
                `REGÍSTRESE PARA UNA MEJOR EXPERIENCIA`,
                `ВОЙДИТЕ ДЛЯ ЛУЧШЕГО ОПЫТА`,
                `ENTRAR PARA MELHOR EXPERIÊNCIA`,
                `より良い体験のためにサインインしてください`,
                `قم بتسجيل الدخول للحصول على تجربة أفضل`]
        }, {
            text: 'Registration successful',
            list: [
                `inscription réussi`,
                `Registrierung erfolgreich`,
                `Registro exitoso`,
                `Регистрация прошла успешно`,
                `Registro realizado com sucesso`,
                `登録に成功`,
                `تم التسجيل بنجاح`]
        }, {
            text: 'Welcome to YFN Jewelry',
            list: [
                `Bienvenue chez YFN Bijoux`,
                `Willkommen bei YFN Jewelry`,
                `Bienvenido a Joyería YFN`,
                `Добро пожаловать в ювелирные изделия YFN`,
                `Bem-vindo à YFN Joias`,
                `YFNジュエリーへようこそ`,
                `مرحبا بكم في مجوهرات YFN`]
        }, {
            text: 'You earned the following',
            list: [
                `Vous avez gagné ce qui suit`,
                `Sie haben Folgendes verdient`,
                `Obtuviste lo siguiente`,
                `Вы заработали следующее`,
                `Você ganhou o seguinte`,
                `以下を獲得しました`,
                `لقد كسبت ما يلي`]
        }, {
            text: 'Extra 10% OFF',
            list: [
                `10% de réduction supplémentaire`,
                `Zusätzliche 10% Rabatt`,
                `10% de descuento adicional`,
                `Дополнительная скидка 10%`,
                `10% de desconto extra`,
                `さらに18%オフ`,
                `خصم إضافي 10%`]
        }, {
            text: 'Applies to select items only',
            list: [
                `S'applique uniquement à certains éléments`,
                `Gilt nur für ausgewählte Artikel`,
                `Se aplica solo a artículos seleccionados`,
                `Применяется только к выбранным элементам`,
                `Aplica-se apenas a itens selecionados`,
                `選択したアイテムにのみ適用されます`,
                `ينطبق على عناصر مختارة فقط`]
        }, {
            text: 'Applies it when you checkout',
            list: [
                `S'applique lors de votre paiement`,
                `Wendet es beim Bezahlen an`,
                `Lo aplica al realizar el pago`,
                `Применяет его при оформлении заказа`,
                `Aplica quando você finaliza a compra`,
                `チェックアウト時に適用されます`,
                `يتم تطبيقه عند الخروج`]
        }, {
            text: '$2.00 Balance',
            list: [
                `Solde de 2,00 $`,
                `2,00 $ Restbetrag`,
                `Saldo de $2.00`,
                `Баланс в размере 2,00 долл. США`,
                `Saldo de US$ 2,00`,
                `$2.00 残高`,
                `رصيد 2.00 دولار`]
        }, {
            text: 'Expires in 30 days',
            list: [
                `Expire dans 30 jours`,
                `Läuft in 30 Tagen ab`,
                `Caduca en 30 días`,
                `Срок действия истекает через 30 дня`,
                `Expira em 30 dias`,
                `有効期限は 30 日です`,
                `تنتهي صلاحيته خلال 30 أيام`]
        }, {
            text: 'Check your inbox for your',
            list: [
                `Vérifiez votre boîte de réception pour votre`,
                `Überprüfen Sie Ihren Posteingang auf Ihre`,
                `Revisa tu bandeja de entrada para tu`,
                `Проверьте свой почтовый ящик на наличие`,
                `Verifique sua caixa de entrada para o seu`,
                `受信箱を確認してください`,
                `تحقق من البريد الوارد الخاص بك ل`]
        }, {
            text: 'Check Now',
            list: [
                `Vérifie maintenant`,
                `Jetzt prüfen`,
                `Revisalo ahora`,
                `Проверь сейчас`,
                `Verifique agora`,
                `今すぐチェック`,
                `تأكد الان`]
        }, {
            text: 'Same shipping address for billing info',
            list: [
                `Même adresse de livraison pour les informations de facturation`,
                `Gleiche Lieferadresse für Rechnungsinformationen`,
                `Misma dirección de envío para información de facturación`,
                `Тот же адрес доставки для платежной информации`,
                `Mesmo endereço de entrega para informações de faturamento`,
                `請求先情報の配送先住所が同じ`,
                `نفس عنوان الشحن لمعلومات الفواتير`]
        }, {
            text: 'Expire Soon',
            list: [
                `Expire bientôt`,
                `Läuft bald ab`,
                `Caducar pronto`,
                `Срок действия истекает скоро`,
                `Expira em breve`,
                `もうすぐ期限切れになります`,
                `تنتهي قريبا`]
        }, {
            text: 'Please select a payment method',
            list: [
                `Veuillez choisir un moyen de paiement`,
                `Bitte Zahlungsart wählen`,
                `Por favor seleccione un método de pago`,
                `Пожалуйста, выберите способ оплаты`,
                `Selecione uma forma de pagamento`,
                `お支払い方法を選択してください`,
                `الرجاء اختيار طريقة الدفع`]
        }, {
            text: 'coupons that will expire',
            list: [
                `coupons qui expireront`,
                `Gutscheine, die ablaufen`,
                `cupones que caducan`,
                `купоны, срок действия которых истекает`,
                `cupons que irão expirar`,
                `有効期限が切れるクーポン`,
                `الكوبونات التي ستنتهي صلاحيتها`]
        }, {
            text: 'You have',
            list: [
                `Tu as`,
                `Du hast`,
                `Tienes`,
                `У вас есть`,
                `Você tem`,
                `あなたが持っている`,
                `لديك`]
        }, {
            text: 'Please Enter Promo Code',
            list: [
                `Veuillez entrer le code promotionnel`,
                `Bitte geben Sie den Promo-Code ein`,
                `Por favor ingrese el código promocional`,
                `Пожалуйста, введите промокод`,
                `Insira o código promocional`,
                `プロモーション コードを入力してください`,
                `الرجاء إدخال الرمز الترويجي`]
        }, {
            text: 'Confirmation and shipment updates will be sent to',
            list: [
                `Les mises à jour de confirmation et d'expédition seront envoyées à`,
                `Bestätigungen und Versandaktualisierungen werden an gesendet`,
                `Las actualizaciones de confirmación y envío se enviarán a`,
                `Подтверждение и обновления об отправке будут отправлены на адрес`,
                `As atualizações de confirmação e envio serão enviadas para`,
                `確認と出荷の最新情報は次の宛先に送信されます。`,
                `سيتم إرسال تحديثات التأكيد والشحن إلى`]
        }, {
            text: 'REGISTER AND GET 10% OFF',
            list: [
                `INSCRIVEZ-VOUS ET OBTENEZ 10% DE RÉDUCTION`,
                `REGISTRIEREN UND 10% RABATT ERHALTEN`,
                `REGÍSTRESE Y OBTÉN 10% DE DESCUENTO`,
                `ЗАРЕГИСТРИРУЙТЕСЬ И ПОЛУЧИТЕ СКИДКУ 10%`,
                `INSCREVA-SE E GANHE 10% DE DESCONTO`,
                `登録して10%オフをゲット`,
                `سجل واحصل على خصم 10%`]
        }, {
            text: 'May be used to assist delivery',
            list: [
                `Peut être utilisé pour faciliter la livraison`,
                `Kann zur Unterstützung der Lieferung verwendet werden`,
                `Puede usarse para ayudar en el parto.`,
                `Может использоваться для облегчения родов.`,
                `Pode ser usado para auxiliar no parto`,
                `配達の補助に使用される場合があります`,
                `يمكن استخدامها للمساعدة في الولادة`]
        }, {
            text: 'Secured Payment',
            list: [
                `Paiement sécurisé`,
                `Gesicherte Zahlung`,
                `Pago seguro`,
                `Безопасный платеж`,
                `Pagamento Seguro`,
                `安全な支払い`,
                `الدفع المضمون`]
        }, {
            text: '60-Days Return',
            list: [
                `Retour sous 60 jours`,
                `60 Tage Rückgaberecht`,
                `Devolución de 60 días`,
                `Возврат в течение 60 дней`,
                `Retorno em 60 dias`,
                `60日間返品可能`,
                `إرجاع خلال 60 يومًا`]
        }, {
            text: '24H Service',
            list: [
                `Service 24 heures`,
                `24-Stunden-Service`,
                `Servicio las 24 horas`,
                `Круглосуточное обслуживание`,
                `Serviço 24 horas`,
                `24時間サービス`,
                `خدمة 24 ساعة`]
        }, {
            text: 'YOU FOREVER NOBLE',
            list: [
                `VOUS POUR TOUJOURS NOBLE`,
                `Du bist für immer edel`,
                `TÚ SIEMPRE NOBLE`,
                `ВЫ НАВСЕГДА БЛАГОРОДНЫЕ`,
                `VOCÊ SEMPRE NOBRE`,
                `あなたは永遠に高貴です`,
                `أنت نبيل إلى الأبد`]
        }, {
            text: 'FOREVER',
            list: [
                `POUR TOUJOURS`,
                `FÜR IMMER`,
                `PARA SIEMPRE`,
                `НАВСЕГДА`,
                `PARA SEMPRE`,
                `永遠に`,
                `للأبد`]
        }, {
            text: 'NOBLE',
            list: [
                `NOBLE`,
                `EDEL`,
                `NOBLE`,
                `БЛАГОРОДНЫЙ`,
                `NOBRE`,
                `ノーブル`,
                `النبيل`]
        }, {
            text: 'YFN is an international affordable luxury high-end jewelry brand founded in 1998 and represents',
            list: [
                `YFN est une marque internationale de bijoux de luxe haut de gamme abordable fondée en 1998 et représente`,
                `YFN ist eine internationale, erschwingliche Luxus-High-End-Schmuckmarke, die 1998 gegründet wurde und vertritt`,
                `YFN es una marca internacional de joyería de lujo asequible fundada en 1998 y representa`,
                `YFN — это международный доступный бренд элитных ювелирных изделий класса люкс, основанный в 1998 году и представляющий`,
                `YFN é uma marca internacional de joias de luxo acessível fundada em 1998 e representa`,
                `YFN は、1998 年に設立された、手頃な価格の国際的な高級ジュエリー ブランドです。`,
                `YFN هي علامة تجارية عالمية للمجوهرات الفاخرة بأسعار معقولة تأسست عام 1998 وتمثل`]
        }, {
            text: 'We uphold the three concepts of “Individuation, Inclusiveness and Empowerment, and Sustainable Fashion”.',
            list: [
                `Nous défendons les trois concepts « Individuation, Inclusivité et Autonomisation, et Mode Durable ».`,
                `Wir vertreten die drei Konzepte „Individuation, Inklusivität und Empowerment sowie nachhaltige Mode“.`,
                `Defendemos los tres conceptos de “Individuación, Inclusión y Empoderamiento, y Moda Sostenible”.`,
                `Мы поддерживаем три концепции: «Индивидуация, инклюзивность и расширение прав и возможностей, а также устойчивая мода».`,
                `Defendemos os três conceitos de “Individuação, Inclusão e Empoderamento e Moda Sustentável”.`,
                `「個性、包摂性とエンパワーメント、持続可能なファッション」の3つのコンセプトを掲げています。`,
                `نحن نتمسك بالمفاهيم الثلاثة وهي "التفرد، والشمولية والتمكين، والأزياء المستدامة".`]
        }, {
            text: 'You, Forever Noble',
            list: [
                `Toi, toujours noble`,
                `Du, ewig edler`,
                `Tú, siempre noble`,
                `Ты, вечно благородный`,
                `Você, para sempre nobre`,
                `あなた、永遠に高貴なあなた`,
                `أنت أيها النبيل إلى الأبد`]
        }, {
            text: 'We continue to provide consumers with better quality services.',
            list: [
                `Nous continuons à fournir aux consommateurs des services de meilleure qualité.`,
                `Wir bieten den Verbrauchern weiterhin Dienstleistungen von besserer Qualität.`,
                `Seguimos brindando a los consumidores servicios de mejor calidad.`,
                `Мы продолжаем предоставлять потребителям услуги более высокого качества.`,
                `Continuamos a fornecer aos consumidores serviços de melhor qualidade.`,
                `私たちは消費者により質の高いサービスを提供し続けます。`,
                `نحن نواصل تزويد المستهلكين بخدمات ذات جودة أفضل.`]
        }, {
            text: 'Individuation',
            list: [
                `Personnalisation`,
                `Individualisierung`,
                `Personalización`,
                `Индивидуация`,
                `Individuação`,
                `個性化`,
                `التفرد`]
        }, {
            text: `At YFN, we believe that all special moments deserve to be celebrated and that each product can be transformed with a little design from you into a unique piece of art worth cherishing forever.`,
            list: [
                `Chez yFn, nous croyons que tous les moments spéciaux méritent d’être célébrés et que chaque produit peut être transformé en une œuvre d’art unique à chérir pour toujours avec un peu de votre design.`,
                `Wir bei YFN glauben, dass alle besonderen Momente gefeiert werden sollten und dass jedes Produkt mit einem kleinen Design von Ihnen in ein einzigartiges Kunstwerk verwandelt werden kann, das es wert ist, für immer zu schätzen.`,
                `En yfn creemos que todos los momentos especiales son dignos de celebración y que cada producto se puede convertir en una obra de arte única que vale la pena apreciar para siempre a través de su pequeño diseño.`,
                ``,
                `Na YFN, acreditamos que todos os momentos especiais merecem ser comemorados e que cada produto pode ser transformado com um pouco de design de você em uma peça de arte única que vale a pena valorizar para sempre.`,
                ``,
                ``]
        }, {
            text: 'Exquisite Craftsmanship',
            list: [
                `Artisanat exquis`,
                `Exquisite Handwerkskunst`,
                `Exquisita artesanía`,
                `Изысканное Мастерство`,
                `Artesanato requintado`,
                `絶妙な職人技`,
                `براعة رائعة`]
        }, {
            text: 'We ensure that every detail of each product exceeds industry standards, and our factory has an advanced laboratory to provide strict quality assurance for each product. All YFN products are made of precious metals such as sterling silver, gold, and platinum, making high-end product customization more accessible and every day, rather than a luxury product limited to special occasions.',
            list: [
                `Nous veillons à ce que chaque détail de chaque produit dépasse les normes de l'industrie et notre usine dispose d'un laboratoire avancé pour fournir une assurance qualité stricte pour chaque produit. Tous les produits YFN sont fabriqués à partir de métaux précieux tels que l'argent sterling, l'or et le platine, ce qui rend la personnalisation de produits haut de gamme plus accessible et quotidienne, plutôt qu'un produit de luxe limité à des occasions spéciales.`,
                `Wir stellen sicher, dass jedes Detail jedes Produkts die Industriestandards übertrifft, und unsere Fabrik verfügt über ein modernes Labor, das eine strenge Qualitätssicherung für jedes Produkt gewährleistet. Alle YFN-Produkte werden aus Edelmetallen wie Sterlingsilber, Gold und Platin hergestellt, wodurch die Individualisierung von High-End-Produkten einfacher und alltagstauglicher wird als ein Luxusprodukt, das auf besondere Anlässe beschränkt ist.`,
                `Nos aseguramos de que cada detalle de cada producto supere los estándares de la industria y nuestra fábrica cuenta con un laboratorio avanzado para brindar una estricta garantía de calidad para cada producto. Todos los productos YFN están hechos de metales preciosos como plata esterlina, oro y platino, lo que hace que la personalización de productos de alta gama sea más accesible y cotidiana, en lugar de ser un producto de lujo limitado a ocasiones especiales.`,
                `Мы гарантируем, что каждая деталь каждого продукта превышает отраслевые стандарты, а на нашем заводе имеется современная лаборатория, обеспечивающая строгий контроль качества каждого продукта. Все продукты YFN изготовлены из драгоценных металлов, таких как серебро, золото и платина, что делает высококачественную продукцию более доступной и повседневной, а не роскошным продуктом, предназначенным только для особых случаев.`,
                `Garantimos que cada detalhe de cada produto exceda os padrões da indústria, e nossa fábrica possui um laboratório avançado para fornecer garantia de qualidade rigorosa para cada produto. Todos os produtos YFN são feitos de metais preciosos, como prata esterlina, ouro e platina, tornando a personalização de produtos de alta qualidade mais acessível e diária, em vez de um produto de luxo limitado a ocasiões especiais.`,
                `当社は各製品のあらゆる細部が業界基準を超えていることを確認しており、当社の工場には各製品に厳格な品質保証を提供する高度な実験室があります。すべての YFN 製品はスターリングシルバー、ゴールド、プラチナなどの貴金属で作られており、特別な機会に限定された贅沢な製品ではなく、ハイエンド製品のカスタマイズをより身近で日常的にしています。`,
                `نحن نضمن أن كل تفاصيل كل منتج تتجاوز معايير الصناعة، ومصنعنا لديه مختبر متقدم لتوفير ضمان صارم للجودة لكل منتج. جميع منتجات YFN مصنوعة من معادن ثمينة مثل الفضة الإسترليني والذهب والبلاتين، مما يجعل تخصيص المنتجات المتطورة أكثر سهولة وكل يوم، بدلاً من منتج فاخر يقتصر على المناسبات الخاصة.`]
        }, {
            text: `Sustainability should permeate every aspect of our lives and we want to integrate it into everything we do. We only craft, cut, engrave, and coat your jewelry upon receipt of your order, just to ensure nothing is wasted. We guarantee the repurchase of jewelry produced by YFN to ensure a healthy "life cycle".
            In order to better cater to the development of sustainable fashion, we launched Paper Biodegradable Packaging Materials, and every user who received our packaging has participated in the sustainable development.`,
            list: [
                `La durabilité doit imprégner tous les aspects de nos vies et nous souhaitons l’intégrer dans tout ce que nous faisons. Nous fabriquons, coupons, gravons et enduisons vos bijoux uniquement à la réception de votre commande, juste pour nous assurer que rien n'est gaspillé. Nous garantissons le rachat des bijoux produits par YFN pour garantir un « cycle de vie » sain.
            Afin de mieux répondre au développement d'une mode durable, nous avons lancé des matériaux d'emballage en papier biodégradables, et chaque utilisateur qui a reçu nos emballages a participé au développement durable.`,
                `Nachhaltigkeit sollte jeden Aspekt unseres Lebens durchdringen und wir möchten sie in alles integrieren, was wir tun. Wir fertigen, schneiden, gravieren und beschichten Ihren Schmuck erst nach Eingang Ihrer Bestellung, um sicherzustellen, dass nichts verschwendet wird. Wir garantieren den Rückkauf von von YFN hergestelltem Schmuck, um einen gesunden „Lebenszyklus“ zu gewährleisten.
            Um der Entwicklung nachhaltiger Mode besser gerecht zu werden, haben wir biologisch abbaubare Verpackungsmaterialien aus Papier eingeführt, und jeder Benutzer, der unsere Verpackungen erhalten hat, hat an der nachhaltigen Entwicklung teilgenommen.`,
                `La sostenibilidad debe impregnar todos los aspectos de nuestras vidas y queremos integrarla en todo lo que hacemos. Solo elaboramos, cortamos, grabamos y cubrimos sus joyas al recibir su pedido, solo para asegurarnos de que no se desperdicie nada. Garantizamos la recompra de joyas producidas por YFN para asegurar un "ciclo de vida" saludable.
            Para atender mejor el desarrollo de la moda sostenible, lanzamos materiales de embalaje biodegradables de papel, y cada usuario que recibió nuestro embalaje ha participado en el desarrollo sostenible.`,
                `Устойчивое развитие должно пронизывать каждый аспект нашей жизни, и мы хотим интегрировать его во все, что мы делаем. Мы изготавливаем, режем, гравируем и покрываем ваши украшения только после получения вашего заказа, чтобы ничего не было потрачено зря. Мы гарантируем выкуп ювелирных изделий, произведенных YFN, чтобы обеспечить здоровый «жизненный цикл».
            Чтобы лучше способствовать развитию устойчивой моды, мы выпустили бумажные биоразлагаемые упаковочные материалы, и каждый пользователь, получивший нашу упаковку, принял участие в устойчивом развитии.`,
                `A sustentabilidade deve permear todos os aspectos das nossas vidas e queremos integrá-la em tudo o que fazemos. Nós apenas fabricamos, cortamos, gravamos e revestimos suas joias após o recebimento do seu pedido, apenas para garantir que nada seja desperdiçado. Garantimos a recompra das joias produzidas pela YFN para garantir um “ciclo de vida” saudável.
            Para melhor atender ao desenvolvimento da moda sustentável, lançamos Materiais de Embalagem Biodegradáveis ​​de Papel, e cada usuário que recebeu nossas embalagens participou do desenvolvimento sustentável.`,
                `サステナビリティは私たちの生活のあらゆる側面に浸透する必要があり、私たちはそれを私たちの行動すべてに組み入れたいと考えています。無駄なものがないように、ご注文をいただいてからジュエリーの製作、切断、彫刻、コーティングを行います。健全な「ライフサイクル」を確保するために、YFN が製造したジュエリーの再購入を保証します。
            持続可能なファッションの発展によりよく対応するために、私たちは紙生分解性包装材料を発売し、私たちの包装を受け取ったすべてのユーザーが持続可能な発展に参加しました。`,
                `يجب أن تتخلل الاستدامة كل جانب من جوانب حياتنا ونريد دمجها في كل ما نقوم به. نحن نقوم فقط بصناعة مجوهراتك وقطعها ونقشها وتغليفها عند استلام طلبك، فقط لضمان عدم إهدار أي شيء. نحن نضمن إعادة شراء المجوهرات التي تنتجها YFN لضمان "دورة حياة" صحية.
            من أجل تلبية احتياجات تطوير الموضة المستدامة بشكل أفضل، أطلقنا مواد التعبئة والتغليف الورقية القابلة للتحلل الحيوي، وقد شارك كل مستخدم حصل على عبواتنا في التنمية المستدامة.`]
        }, {
            text: 'Development Path',
            list: [
                `Chemin de développement`,
                `Entwicklungspfad`,
                `Camino de desarrollo`,
                `Путь развития`,
                `Caminho de desenvolvimento`,
                `開発パス`,
                `مسار التنمية`]
        }, {
            text: 'Exquisite Craftsmanship',
            list: [
                `Un savoir-faire exquis`,
                `Exquisite Handwerkskunst`,
                `Artesanía exquisita`,
                `Изысканное Мастерство`,
                `Artesanato requintado`,
                `絶妙な職人技`,
                `براعة رائعة`]
        }, {
            text: 'Professional Service Team',
            list: [
                `Équipe de services professionnels`,
                `Professionelles Serviceteam`,
                `Equipo de servicio profesional`,
                `Профессиональная команда обслуживания`,
                `Equipe de serviço profissional`,
                `プロフェッショナルサービスチーム`,
                `فريق الخدمة المهنية`]
        }, {
            text: 'Our Brand Vision',
            list: [
                `Notre vision de marque`,
                `Unsere Markenvision`,
                `Nuestra visión de marca`,
                `Наше видение бренда`,
                `Nossa visão de marca`,
                `私たちのブランドビジョン`,
                `رؤية علامتنا التجارية`]
        }, {
            text: 'Online International Sites',
            list: [
                `Sites internationaux en ligne`,
                `Internationale Online-Sites`,
                `Sitios internacionales en línea`,
                `Международные интернет-сайты`,
                `Sites internacionais on-line`,
                `オンライン国際サイト`,
                `المواقع الدولية على الإنترنت`]
        }, {
            text: 'Development path',
            list: [
                `Parcours de développement`,
                `Entwicklungspfad`,
                `Camino de desarrollo`,
                `Путь развития`,
                `Caminho de desenvolvimento`,
                `開発パス`,
                `مسار التنمية`]
        }, {
            text: `From 1998 to 2024, YFN Jewelry Company provided ODM services to many high-end jewelry brands in Europe,United States and Australia. Through 26 years of processing and manufacturing technology precipitation, its advanced manufacturing technology has become favored by many brand owners. After 2014, we decided to develop the retail industry and created our own brand YFN. The factory faces consumers directly, eliminates middlemen, and becomes the most cost-effective high-end jewelry brand. We also use our own production advantages to fully support customers' personalized designs and achieve the fastest delivery time.`,
            list: [
                `De 1998 à 2024, YFN Jewelry Company a fourni des services ODM à de nombreuses marques de bijoux haut de gamme en Europe, aux États-Unis et en Australie. Grâce à 26 années de précipitation dans les technologies de transformation et de fabrication, sa technologie de fabrication avancée est devenue favorisée par de nombreux propriétaires de marques. Après 2014, nous avons décidé de développer le secteur de la vente au détail et avons créé notre propre marque YFN. L'usine fait face directement aux consommateurs, élimine les intermédiaires et devient la marque de bijoux haut de gamme la plus rentable. Nous utilisons également nos propres avantages de production pour prendre pleinement en charge les conceptions personnalisées des clients et obtenir le délai de livraison le plus rapide.`,
                `Von 1998 bis 2024 erbrachte die YFN Jewelry Company ODM-Dienstleistungen für viele High-End-Schmuckmarken in Europa, den Vereinigten Staaten und Australien. Durch 26 Jahre Erfahrung in der Verarbeitungs- und Fertigungstechnologie ist die fortschrittliche Fertigungstechnologie bei vielen Markeninhabern beliebt geworden. Nach 2014 beschlossen wir, den Einzelhandel weiterzuentwickeln und gründeten unsere eigene Marke YFN. Die Fabrik steht den Verbrauchern direkt gegenüber, eliminiert Zwischenhändler und wird zur kostengünstigsten High-End-Schmuckmarke. Wir nutzen auch unsere eigenen Produktionsvorteile, um die personalisierten Designs der Kunden vollständig zu unterstützen und die schnellste Lieferzeit zu erreichen.`,
                `De 1998 a 2024, YFN Jewelry Company brindó servicios ODM a muchas marcas de joyería de alta gama en Europa, Estados Unidos y Australia. A lo largo de 26 años de precipitación de tecnología de procesamiento y fabricación, su avanzada tecnología de fabricación ha sido favorecida por muchos propietarios de marcas. Después de 2014, decidimos desarrollar la industria minorista y creamos nuestra propia marca YFN. La fábrica se enfrenta directamente a los consumidores, elimina intermediarios y se convierte en la marca de joyería de alta gama más rentable. También utilizamos nuestras propias ventajas de producción para respaldar completamente los diseños personalizados de los clientes y lograr el tiempo de entrega más rápido.`,
                `С 1998 по 2024 год ювелирная компания YFN предоставляла услуги ODM многим элитным ювелирным брендам в Европе, США и Австралии. За 26 лет разработки и внедрения технологий производства передовые производственные технологии завоевали признание многих владельцев брендов. После 2014 года мы решили развивать сферу розничной торговли и создали собственный бренд YFN. Фабрика напрямую сталкивается с потребителями, устраняет посредников и становится наиболее экономически эффективным брендом ювелирных изделий высокого класса. Мы также используем наши собственные производственные преимущества, чтобы полностью поддерживать индивидуальный дизайн клиентов и достигать быстрейших сроков доставки.`,
                `De 1998 a 2024, a YFN Jewelry Company forneceu serviços ODM para muitas marcas de joias de alta qualidade na Europa, Estados Unidos e Austrália. Ao longo de 26 anos de precipitação de tecnologia de processamento e fabricação, sua avançada tecnologia de fabricação tornou-se preferida por muitos proprietários de marcas. A partir de 2014, decidimos desenvolver o setor varejista e criamos nossa própria marca YFN. A fábrica enfrenta diretamente os consumidores, elimina intermediários e se torna a marca de joias sofisticadas com melhor custo-benefício. Também usamos nossas próprias vantagens de produção para oferecer suporte total aos designs personalizados dos clientes e obter o prazo de entrega mais rápido.`,
                `1998 年から 2024 年まで、YFN Jewelry Company はヨーロッパ、米国、オーストラリアの多くの高級ジュエリー ブランドに ODM サービスを提供しました。 26年にわたる加工・製造技術の蓄積により、その高度な製造技術は多くのブランドオーナーに愛用されています。 2014年以降、小売業の発展を決意し、自社ブランドYFNを立ち上げました。消費者と直接向き合い、中間業者を排除し、最もコストパフォーマンスの高いハイエンドジュエリーブランドとなる工場です。また、独自の生産利点を活かして、お客様のパーソナライズされたデザインを完全にサポートし、最速の納期を実現します。`,
                `من عام 1998 إلى عام 2024، قدمت شركة YFN Jewelry Company خدمات ODM للعديد من ماركات المجوهرات الراقية في أوروبا والولايات المتحدة وأستراليا. من خلال 26 عامًا من تكنولوجيا المعالجة والتصنيع، أصبحت تكنولوجيا التصنيع المتقدمة مفضلة لدى العديد من أصحاب العلامات التجارية. بعد عام 2014، قررنا تطوير صناعة البيع بالتجزئة وأنشأنا علامتنا التجارية الخاصة YFN. يواجه المصنع المستهلكين مباشرة، ويزيل الوسطاء، ويصبح العلامة التجارية للمجوهرات الراقية الأكثر فعالية من حيث التكلفة. نحن نستخدم أيضًا مزايا الإنتاج الخاصة بنا لتقديم الدعم الكامل للتصميمات الشخصية للعملاء وتحقيق أسرع وقت للتسليم.`]
        }, {
            text: 'our markup',
            list: [
                `notre balisage`,
                `unser Markup`,
                `nuestro marcado`,
                `наша наценка`,
                `nossa marcação`,
                `私たちのマークアップ`,
                `ترميزنا`]
        }, {
            text: 'production costs',
            list: [
                `coûts de production`,
                `Produktionskosten`,
                `costos de producción`,
                `Produktionskosten`,
                `custos de produção`,
                `生産コスト`,
                `تكاليف الإنتاج`]
        }, {
            text: 'retail to customer',
            list: [
                `vente au détail au client`,
                `Einzelhandel an den Kunden`,
                `Venta al por menor al cliente`,
                `розничная торговля клиенту`,
                `varejo para o cliente`,
                `顧客への小売`,
                `البيع بالتجزئة للعميل`]
        }, {
            text: 'markup',
            list: [
                `balisage`,
                `Markup`,
                `margen`,
                `наценка`,
                `marcação`,
                `マークアップ`,
                `وضع علامة على`]
        }, {
            text: 'UP TO 10x',
            list: [
                `JUSQU'À 10x`,
                `BIS ZU 10x`,
                `HASTA 10x`,
                `ДО 10x`,
                `ATÉ 10x`,
                `最大10倍`,
                `ما يصل إلى 10x`]
        }, {
            text: 'wholesale markup',
            list: [
                `vente en gros balisage`,
                `Großhandelsaufschlag`,
                `marcado al por mayor`,
                `оптовая наценка`,
                `marcação de atacado`,
                `卸売値上げ`,
                `ترميز الجملة`]
        }, {
            text: 'production costs',
            list: [
                `coûts de production`,
                `Produktionskosten`,
                `costos de producción`,
                `производственные затраты`,
                `custos de produção`,
                `生産コスト`,
                `تكاليف الإنتاج`]
        }, {
            text: 'Other Retail Brands',
            list: [
                `Autres marques de vente au détail`,
                `Andere Einzelhandelsmarken`,
                `Otras marcas minoristas`,
                `Другие розничные бренды`,
                `Outras marcas de varejo`,
                `その他の小売ブランド`,
                `ماركات التجزئة الأخرى`]
        }, {
            text: 'Exquisite Craftsmanship',
            list: [
                `Un savoir-faire exquis`,
                `Exquisite Handwerkskunst`,
                `Artesanía exquisita`,
                `Изысканное Мастерство`,
                `Artesanato requintado`,
                `絶妙な職人技`,
                `براعة رائعة`]
        }, {
            text: 'Professional Service Team',
            list: [
                `Équipe de services professionnels`,
                `Professionelles Serviceteam`,
                `Equipo de servicio profesional`,
                `Профессиональная команда обслуживания`,
                `Equipe de serviço profissional`,
                `プロフェッショナルサービスチーム`,
                `فريق الخدمة المهنية`]
        }, {
            text: 'Our Brand Vision',
            list: [
                `Notre vision de marque`,
                `Unsere Markenvision`,
                `Nuestra visión de marca`,
                `Наше видение бренда`,
                `Nossa visão de marca`,
                `私たちのブランドビジョン`,
                `رؤية علامتنا التجارية`]
        }, {
            text: `YFN's mission is to help you express your stories and emotional memories by creating expressive products.
            The beating, warm heart of our jewelry. Every time you put it on, it will touch the depths of your soul. YFN will be committed to building a sustainable supply chain and actively affecting the ecological development of the earth. YFN’s ultimate vision is to create jewelry that is 100% traceable. We guarantee that the jewelry produced by YFN can be purchased repeatedly, ensuring a healthy "life cycle". We firmly believe that full-process transparent service is one of the keys to successfully building long-term relationships with customers, and hope that more jewelry enthusiasts will join our community to create the world's largest jewelry ecosystem.`,
            list: [
                `La mission de YFN est de vous aider à exprimer vos histoires et vos souvenirs émotionnels en créant des produits expressifs.
            Le cœur battant et chaleureux de nos bijoux. Chaque fois que vous l’enfilerez, il touchera les profondeurs de votre âme. YFN s'engagera à construire une chaîne d'approvisionnement durable et à influencer activement le développement écologique de la Terre. La vision ultime de YFN est de créer des bijoux 100 % traçables. Nous garantissons que les bijoux produits par YFN peuvent être achetés à plusieurs reprises, garantissant ainsi un « cycle de vie » sain. Nous croyons fermement qu'un service transparent complet est l'une des clés pour réussir à établir des relations à long terme avec les clients, et espérons que davantage de passionnés de bijoux rejoindront notre communauté pour créer le plus grand écosystème de bijoux au monde.`,
                `Die Mission von YFN ist es, Ihnen dabei zu helfen, Ihre Geschichten und emotionalen Erinnerungen durch die Schaffung ausdrucksstarker Produkte auszudrücken.
            Das schlagende, warme Herz unseres Schmucks. Jedes Mal, wenn Sie es auftragen, wird es die Tiefen Ihrer Seele berühren. YFN wird sich dafür einsetzen, eine nachhaltige Lieferkette aufzubauen und die ökologische Entwicklung der Erde aktiv zu beeinflussen. Die ultimative Vision von YFN ist es, Schmuck herzustellen, der zu 100 % rückverfolgbar ist. Wir garantieren, dass der von YFN hergestellte Schmuck wiederholt gekauft werden kann und somit ein gesunder „Lebenszyklus“ gewährleistet ist. Wir sind fest davon überzeugt, dass ein transparenter Service rund um den Prozess einer der Schlüssel zum erfolgreichen Aufbau langfristiger Kundenbeziehungen ist, und hoffen, dass sich mehr Schmuckbegeisterte unserer Community anschließen, um das weltweit größte Schmuck-Ökosystem zu schaffen.`,
                `La misión de YFN es ayudarte a expresar tus historias y recuerdos emocionales mediante la creación de productos expresivos.
            El corazón palpitante y cálido de nuestras joyas. Cada vez que te lo pongas, tocará lo más profundo de tu alma. YFN se comprometerá a construir una cadena de suministro sostenible y a afectar activamente el desarrollo ecológico de la tierra. La visión final de YFN es crear joyas que sean 100% rastreables. Garantizamos que las joyas producidas por YFN se pueden comprar repetidamente, asegurando un "ciclo de vida" saludable. Creemos firmemente que un servicio transparente en todo el proceso es una de las claves para construir con éxito relaciones a largo plazo con los clientes y esperamos que más entusiastas de la joyería se unan a nuestra comunidad para crear el ecosistema de joyería más grande del mundo.`,
                `Миссия YFN — помочь вам выразить свои истории и эмоциональные воспоминания, создавая выразительные продукты.
            Бьющееся, горячее сердце наших украшений. Каждый раз, когда вы его наденете, оно коснется глубины вашей души. YFN будет стремиться построить устойчивую цепочку поставок и активно влиять на экологическое развитие Земли. Конечная цель YFN — создавать украшения, которые можно на 100% проследить. Мы гарантируем, что ювелирные изделия YFN можно будет приобретать повторно, обеспечивая здоровый «жизненный цикл». Мы твердо верим, что полный процесс прозрачного обслуживания является одним из ключей к успешному построению долгосрочных отношений с клиентами, и надеемся, что все больше энтузиастов ювелирных изделий присоединятся к нашему сообществу, чтобы создать крупнейшую в мире ювелирную экосистему.`,
                `A missão da YFN é ajudá-lo a expressar suas histórias e memórias emocionais através da criação de produtos expressivos.
            O coração pulsante e quente de nossas joias. Cada vez que você colocá-lo, ele tocará as profundezas da sua alma. A YFN estará comprometida em construir uma cadeia de abastecimento sustentável e em afetar ativamente o desenvolvimento ecológico da Terra. A visão final da YFN é criar joias que sejam 100% rastreáveis. Garantimos que as joias produzidas pela YFN podem ser adquiridas repetidamente, garantindo um “ciclo de vida” saudável. Acreditamos firmemente que um serviço transparente em todo o processo é uma das chaves para construir relacionamentos de longo prazo com os clientes e esperamos que mais entusiastas de joias se juntem à nossa comunidade para criar o maior ecossistema de joias do mundo.`,
                `YFN の使命は、表現力豊かな製品を作成することで、あなたのストーリーや感情的な記憶を表現できるよう支援することです。
            ジュエリーの鼓動、温もり。身に着けるたびに、心の奥底に響く。 YFN は持続可能なサプライチェーンを構築し、地球の生態学的発展に積極的に影響を与えることに尽力します。 YFN の究極のビジョンは、100% 追跡可能なジュエリーを作成することです。 YFN が製造するジュエリーは繰り返し購入できることを保証し、健全な「ライフサイクル」を保証します。私たちは、全プロセスの透明性のあるサービスが顧客との長期的な関係をうまく構築するための鍵の 1 つであると強く信じており、より多くのジュエリー愛好家が私たちのコミュニティに参加して、世界最大のジュエリー エコシステムを構築することを願っています。`,
                `تتمثل مهمة YFN في مساعدتك في التعبير عن قصصك وذكرياتك العاطفية من خلال إنشاء منتجات معبرة.
            القلب النابض الدافئ لمجوهراتنا. في كل مرة ترتديه، سوف يمس أعماق روحك. سوف تلتزم YFN ببناء سلسلة توريد مستدامة والتأثير بشكل فعال على التنمية البيئية للأرض. تتمثل رؤية YFN النهائية في صنع مجوهرات يمكن تتبعها بنسبة 100%. نحن نضمن إمكانية شراء المجوهرات التي تنتجها YFN بشكل متكرر، مما يضمن "دورة حياة" صحية. نحن نؤمن إيمانًا راسخًا بأن الخدمة الشفافة الكاملة للعملية هي أحد مفاتيح بناء علاقات طويلة الأمد بنجاح مع العملاء، ونأمل أن ينضم المزيد من عشاق المجوهرات إلى مجتمعنا لإنشاء أكبر نظام بيئي للمجوهرات في العالم.`]
        }, {
            text: 'Online International Sites',
            list: [
                `Sites internationaux en ligne`,
                `Internationale Online-Sites`,
                `Sitios internacionales en línea`,
                `Международные интернет-сайты`,
                `Sites internacionais on-line`,
                `オンライン国際サイト`,
                `المواقع الدولية على الإنترنت`]
        }, {
            text: 'Currently, brand first-level domain name sites have been deployed in 14 regions around the world.',
            list: [
                `Actuellement, les sites de noms de domaine de premier niveau de marque sont déployés dans 14 régions du monde.`,
                `Derzeit sind Marken-First-Level-Domain-Namen-Websites in 14-Regionen auf der ganzen Welt implementiert.`,
                `En la actualidad, el sitio web de nombres de dominio de primer nivel de la marca se ha desplegado en 14 regiones de todo el mundo.`,
                `В настоящее время сайты брендовых доменных имен первого уровня развернуты в 14 регионах мира.`,
                `Atualmente, sites de nomes de domínio de primeiro nível da marca foram implantados em 14 regiões ao redor do mundo.`,
                `現在、ブランドのファーストレベル ドメイン名サイトは世界 14 地域に展開されています。`,
                `حاليًا، تم نشر مواقع أسماء نطاقات المستوى الأول للعلامة التجارية في 14 منطقة حول العالم.`]
        }, {
            text: `YFN is an international affordable luxury high-end jewelry brand founded in 1998 and represents "You,Forever Noble". We uphold the three concepts of “Individuation, Inclusiveness and Empowerment, and Sustainable Fashion”.`,
            list: [
                `YFn est une marque internationale de bijoux haut de gamme de luxe abordable fondée en 1998 et représente "You,Forever Noble". Nous adhérons aux trois idées « personnalisation, inclusion et autonomisation, mode durable».`,
                `YFN ist eine internationale erschwingliche Luxus-High-End-Schmuckmarke, die in 1998 gegründet wurde und "You,Forever Noble" repräsentiert. Wir halten die drei Konzepte "Individualisierung, Inklusivität und Empowerment sowie nachhaltige Mode" aufrecht.`,
                `Yfn es una marca internacional de joyas de lujo asequibles de alta gama fundada en 1998, que representa a "You,Forever Noble". Nos adherimos a los tres conceptos principales de "personalización, inclusión y empoderamiento, moda sostenible".`,
                ``,
                `YFN é uma marca internacional acessível de jóias high-end de luxo fundada em 1998 e representa "You,Forever Noble". Defendemos os três conceitos de "Individuação, Inclusão e Empoderamento e Moda Sustentável".`,
                ``,
                ``]
        }, {
            text: 'To this end, we have launched a professional customized series for you to choose, you can enter the following page to fill in the requirements.',
            list: [
                `Pour ce faire, nous avons lancé une gamme professionnelle personnalisée pour votre choix, vous pouvez aller à la page suivante pour remplir les exigences.`,
                `Zu diesem Zweck haben wir eine professionelle kundenspezifische Serie für Sie gestartet, die Sie wählen können, können Sie die folgende Seite eingeben, um die Anforderungen auszufüllen.`,
                `Para ello, hemos lanzado una serie personalizada profesional para que usted elija, y puede ingresar a la siguiente página para completar los requisitos.`,
                ``,
                `Para este fim, lançamos uma série personalizada profissional para você escolher, você pode entrar na seguinte página para preencher os requisitos.`,
                ``,
                ``]
        }, {
            text: 'Sustainable Fashion',
            list: [
                `Mode durable`,
                `Nachhaltige Mode`,
                `Moda sostenible`,
                ``,
                `Moda Sustentável`,
                ``,
                ``]
        }, {
            text: 'Sustainability should permeate every aspect of our lives and we want to integrate it into everything we do. We only craft, cut, engrave, and coat your jewelry upon receipt of your order, just to ensure nothing is wasted. We guarantee the repurchase of jewelry produced by YFN to ensure a healthy "life cycle".',
            list: [
                `La durabilité doit imprégner tous les aspects de nos vies et nous voulons l'intégrer dans tout ce que nous faisons. Nous traitons, Découpons, Gravons et enduisons vos bijoux uniquement après réception de votre commande pour nous assurer que rien n'est gaspillé. Nous garantissons le rachat des bijoux fabriqués par yFn pour assurer un « cycle de vie » sain.`,
                `Nachhaltigkeit sollte jeden Aspekt unseres Lebens durchdringen und wir wollen sie in alles integrieren, was wir tun. Wir fertigen, schneiden, gravieren und beschichten Ihren Schmuck nur nach Eingang Ihrer Bestellung, nur um sicherzustellen, dass nichts verschwendet wird. Wir garantieren den Rückkauf von Schmuck, der von YFN produziert wird, um einen gesunden "Lebenszyklus" zu gewährleisten.`,
                `La sostenibilidad debe penetrar en todos los aspectos de nuestras vidas y queremos integrarla en todo lo que hacemos. Solo procesamos, cortamos, tallamos y recubrimos sus joyas después de recibir su pedido para asegurarnos de que no se desperdicie nada. Garantizamos la recompra de joyas producidas por yfn para garantizar un "ciclo de vida" saludable.`,
                ``,
                `A sustentabilidade deve permear todos os aspectos de nossas vidas e queremos integrá-la em tudo o que fazemos. Nós só fabricamos, cortamos, gravamos e revestimos suas jóias após o recebimento de seu pedido, apenas para garantir que nada é desperdiçado. Garantimos a recompra de jóias produzidas pela YFN para garantir um "ciclo de vida" saudável.`,
                ``,
                ``]
        }, {
            text: 'In order to better cater to the development of sustainable fashion, we launched Paper Biodegradable Packaging Materials, and every user who received our packaging has participated in the sustainable development.',
            list: [
                `Afin de mieux répondre à l'évolution de la mode durable, nous avons lancé des matériaux d'emballage biodégradables en papier, où chaque utilisateur qui reçoit nos emballages participe au développement durable.`,
                `Um der Entwicklung nachhaltiger Mode besser gerecht zu werden, haben wir biologisch abbaubare Verpackungsmaterialien aus Papier eingeführt, und jeder Benutzer, der unsere Verpackungen erhalten hat, hat an der nachhaltigen Entwicklung teilgenommen.`,
                `Para atender mejor al desarrollo de la moda sostenible, hemos lanzado materiales de embalaje biodegradables para papel, y cada usuario que recibe nuestro embalaje participa en el desarrollo sostenible.`,
                ``,
                `Para melhor atender ao desenvolvimento da moda sustentável, lançamos Materiais de Embalagem Biodegradáveis de Papel, e todos os usuários que receberam nossas embalagens participaram do desenvolvimento sustentável.`,
                ``,
                ``]
        }, {
            text: `From 1998 to 2023, YFN Jewelry Company provided ODM services to many high-end jewelry brands in Europe,United States and Australia. Through 26 years of processing and manufacturing technology precipitation, its advanced manufacturing technology has become favored by many brand owners. After 2014, we decided to develop the retail industry and created our own brand YFN. The factory faces consumers directly, eliminates middlemen, and becomes the most cost-effective high-end jewelry brand. We also use our own production advantages to fully support customers' personalized designs and achieve the fastest delivery time.`,
            list: [
                `YFn Jewelry a fourni des services ODM à de nombreuses marques de bijoux haut de gamme en Europe, aux États - Unis et en Australie de 1998 à 2023. Après 26 ans de précipitation de la technologie de fabrication de traitement, sa technologie de fabrication avancée a été favorisée par de nombreux propriétaires de marques. Après 2014, nous avons décidé de développer le commerce de détail et avons créé notre propre marque, yFn. L'usine fait face aux consommateurs, élimine les intermédiaires et devient la marque de bijoux haut de gamme avec le meilleur rapport qualité - prix. Nous utilisons également nos propres avantages de production pour soutenir pleinement la conception personnalisée de nos clients et obtenir les délais de livraison les plus rapides.`,
                `Von 1998 bis 2023 erbrachte YFN Jewelry Company ODM-Dienstleistungen für viele High-End-Schmuckmarken in Europa, den Vereinigten Staaten und Australien. Durch 26-jährige Verarbeitungs- und Fertigungstechnologieniederschläge wurde seine fortschrittliche Fertigungstechnologie von vielen Markeninhabern bevorzugt. Nach 2014 beschlossen wir, den Einzelhandel zu entwickeln und gründeten unsere eigene Marke YFN. Die Fabrik steht den Verbrauchern direkt gegenüber, eliminiert Zwischenhändler und wird zur kosteneffektivsten High-End-Schmuckmarke. Wir nutzen auch unsere eigenen Produktionsvorteile, um die personalisierten Designs unserer Kunden vollständig zu unterstützen und die schnellste Lieferzeit zu erreichen.`,
                `Entre 1998 y 2023, yfn Jewelry proporcionó servicios ODM a muchas marcas de joyería de alta gama en europa, Estados Unidos y Australia. Después de 26 años de precipitación en la tecnología de procesamiento y fabricación, su tecnología de fabricación avanzada ha sido favorecida por muchos propietarios de marcas. Después de 2014, Decidimos desarrollar el comercio minorista y crear nuestra propia marca yfn. La fábrica se enfrenta directamente a los consumidores, ahorra intermediarios y se convierte en la marca de joyería de alta gama más rentable. También aprovechamos nuestras propias ventajas de producción para apoyar plenamente el diseño personalizado de nuestros clientes y lograr el tiempo de entrega más rápido.`,
                ``,
                `De 1998 a 2023, YFN Jewelry Company forneceu serviços ODM para muitas marcas de jóias high-end na Europa, Estados Unidos e Austrália. Através de 26 anos de processamento e precipitação de tecnologia de fabricação, sua tecnologia de fabricação avançada tornou-se favorecida por muitos proprietários de marcas. Depois de 2014, decidimos desenvolver o setor varejista e criamos nossa própria marca YFN. A fábrica enfrenta os consumidores diretamente, elimina intermediários e se torna a marca de jóias high-end mais econômica. Também usamos nossas próprias vantagens de produção para apoiar totalmente os projetos personalizados dos clientes e alcançar o tempo de entrega mais rápido.`,
                ``,
                ``]
        }, {
            text: 'We ensure that every detail of each product exceeds industry standards, and our factory has an advanced laboratory to provide strict quality assurance for each product. All YFN products are made of precious metals such as sterling silver, gold, and platinum, making high-end product customization more accessible and every day, rather than a luxury product limited to special occasions.',
            list: [
                `Nous nous assurons que chaque détail de chaque produit dépasse les normes de l'industrie et notre usine dispose d'un laboratoire de pointe pour fournir une garantie de qualité stricte pour chaque produit. Tous les produits yFn sont fabriqués à partir de métaux précieux tels que l'argent sterling, l'or et le platine, ce qui rend la personnalisation de produits haut de gamme plus facile à réaliser que le luxe limité aux occasions spéciales.`,
                `Wir stellen sicher, dass jedes Detail jedes Produkts Industriestandards übertrifft, und unsere Fabrik verfügt über ein fortschrittliches Labor, um strenge Qualitätssicherung für jedes Produkt bereitzustellen. Alle YFN-Produkte werden aus Edelmetallen wie Sterling Silber, Gold und Platin hergestellt, wodurch High-End-Produktanpassungen leichter zugänglich und jeden Tag, anstatt ein Luxusprodukt, das auf besondere Anlässe beschränkt ist.`,
                `Nos aseguramos de que cada detalle de cada producto supere los estándares de la industria, y nuestra fábrica cuenta con laboratorios avanzados que brindan una estricta garantía de calidad para cada producto. Todos los productos yfn están hechos de metales preciosos como plata esterlina, oro y platino, lo que facilita la personalización de productos de alta gama en lugar de artículos de lujo limitados a ocasiones especiales.`,
                ``,
                `Nós asseguramos que cada detalhe de cada produto excede os padrões da indústria, e nossa fábrica tem um laboratório avançado para fornecer garantia de qualidade estrita para cada produto. Todos os produtos YFN são feitos de metais preciosos, como prata esterlina, ouro e platina, tornando a personalização de produtos high-end mais acessível e todos os dias, em vez de um produto de luxo limitado a ocasiões especiais.`,
                ``,
                ``]
        }, {
            text: 'We have multiple service teams across the country such as Guangzhou, Hangzhou, Wuhan, Shenzhen, and Hong Kong, as well as service points in the United States, Spain, Indonesia, and other countries. Our staff come from all over the world and our website is multi-lingual and multi-currency. Currently supported in English, Italian, Arabic, Spanish, and Portuguese, we are proud to say that our business and services are even more global.',
            list: [
                `Nous avons plusieurs équipes de service dans tout le pays comme Guangzhou, Hangzhou, Wuhan, Shenzhen, Hong Kong, etc. et des points de service aux États - Unis, en Espagne, en Indonésie, etc. Nos employés viennent du monde entier et notre site Web est multilingue et multi - devises. Actuellement pris en charge en anglais, italien, arabe, espagnol et portugais, nous sommes fiers de dire que notre entreprise et nos services sont plus globaux.`,
                `Wir haben mehrere Serviceteams im ganzen Land wie Guangzhou, Hangzhou, Wuhan, Shenzhen und Hong Kong sowie Servicepunkte in den Vereinigten Staaten, Spanien, Indonesien und anderen Ländern. Unsere Mitarbeiter kommen aus der ganzen Welt und unsere Website ist mehrsprachig und mehrwährend. Aktuell unterstützt in Englisch, Italienisch, Arabisch, Spanisch und Portugiesisch, sind wir stolz darauf, sagen zu können, dass unser Geschäft und unsere Dienstleistungen noch globaler sind.`,
                `Tenemos varios equipos de servicio en guangzhou, hangzhou, wuhan, shenzhen, Hong Kong y otros países, y puntos de servicio en Estados unidos, españa, Indonesia y otros países. Nuestros empleados son de todo el mundo, y nuestro sitio web es multilingüe y monetario. Actualmente apoyamos inglés, italiano, árabe, español y portugués, y estamos orgullosos de decir que nuestros negocios y servicios son más globales.`,
                ``,
                `Temos várias equipes de serviço em todo o país, como Guangzhou, Hangzhou, Wuhan, Shenzhen e Hong Kong, bem como pontos de serviço nos Estados Unidos, Espanha, Indonésia e outros países. Nossa equipe vem de todo o mundo e nosso site é multilíngue e multi-moeda. Atualmente suportados em inglês, italiano, árabe, espanhol e português, estamos orgulhosos de dizer que nossos negócios e serviços são ainda mais globais.`,
                ``,
                ``]
        }, {
            text: `YFN's mission is to help you express your stories and emotional memories by creating expressive products.`,
            list: [
                `La Mission de yFn est de vous aider à exprimer vos histoires et vos souvenirs émotionnels en créant des produits expressifs.`,
                `YFN's Mission ist es, Ihnen zu helfen, Ihre Geschichten und emotionalen Erinnerungen durch die Schaffung ausdrucksstarker Produkte auszudrücken.`,
                `La Misión de yfn es ayudarte a expresar tus historias y recuerdos emocionales creando productos expresivos.`,
                ``,
                `A missão da YFN é ajudá-lo a expressar suas histórias e memórias emocionais criando produtos expressivos.`,
                ``,
                ``]
        }, {
            text: `The beating, warm heart of our jewelry. Every time you put it on, it will touch the depths of your soul. YFN will be committed to building a sustainable supply chain and actively affecting the ecological development of the earth. YFN’s ultimate vision is to create jewelry that is 100% traceable. We guarantee that the jewelry produced by YFN can be purchased repeatedly, ensuring a healthy "life cycle". We firmly believe that full-process transparent service is one of the keys to successfully building long-term relationships with customers, and hope that more jewelry enthusiasts will join our community to create the world's largest jewelry ecosystem.`,
            list: [
                `Nos bijoux battent des cœurs chauds. Chaque fois que vous le portez, il touche les profondeurs de votre âme. YFn travaillera à construire des chaînes d'approvisionnement durables et à influencer positivement le développement écologique de la planète. La vision ultime de yFn est de créer des bijoux 100% traçables. Nous garantissons que les bijoux fabriqués par yFn peuvent être achetés à plusieurs reprises, assurant un « cycle de vie» sain. Nous croyons fermement qu'un service transparent à l'échelle du processus est l'une des clés d'une relation à long terme réussie avec nos clients et nous espérons que davantage d'amateurs de bijoux se joindront à notre communauté pour créer le plus grand écosystème de bijoux au monde.`,
                `Das pulsierende, warme Herz unseres Schmucks. Jedes Mal, wenn du es anziehst, wird es die Tiefen deiner Seele berühren. YFN wird sich für den Aufbau einer nachhaltigen Lieferkette einsetzen und die ökologische Entwicklung der Erde aktiv beeinflussen. YFns ultimative Vision ist es, Schmuck herzustellen, der 100% nachvollziehbar ist. Wir garantieren, dass der von YFN hergestellte Schmuck wiederholt gekauft werden kann, was einen gesunden "Lebenszyklus" gewährleistet. Wir sind fest davon überzeugt, dass ein transparenter Full-Process-Service einer der Schlüssel zum erfolgreichen Aufbau langfristiger Kundenbeziehungen ist, und hoffen, dass sich weitere Schmuckbegeisterte unserer Community anschließen, um das weltweit größte Schmuck-Ökosystem zu schaffen.`,
                `Nuestras joyas latieron corazones cálidos. Cada vez que lo llevas, toca las profundidades de tu alma. Yfn trabajará para construir cadenas de suministro sostenibles e influir activamente en el desarrollo ecológico del planeta. La visión definitiva de yfn es crear joyas 100% trazables. Garantizamos que las joyas producidas por yfn se pueden comprar repetidamente, asegurando un "ciclo de vida" saludable. Creemos firmemente que el servicio transparente de todo el proceso es una de las claves para establecer con éxito relaciones a largo plazo con nuestros clientes y esperamos que más entusiastas de la joyería se unan a nuestra comunidad para crear el ecosistema de joyería más grande del mundo.`,
                ``,
                `O coração pulsante e quente das nossas jóias. Cada vez que o vestires, tocará as profundezas da tua alma. A YFN estará comprometida em construir uma cadeia de suprimentos sustentável e afetar ativamente o desenvolvimento ecológico da Terra. A visão final da YFN é criar jóias 100% rastreáveis. Garantimos que as jóias produzidas pela YFN podem ser compradas repetidamente, garantindo um "ciclo de vida" saudável. Acreditamos firmemente que o serviço transparente de processo completo é uma das chaves para construir com sucesso relacionamentos de longo prazo com os clientes, e esperamos que mais entusiastas de jóias se juntem à nossa comunidade para criar o maior ecossistema de jóias do mundo.`,
                ``,
                ``]
        }, {
            text: 'About Us | YFN Jewelry',
            list: [
                `À propos de nous | YFN Jewelry`,
                `Über uns | YFN Jewelry`,
                `Sobre nosotros | YFN Jewelry`,
                ``,
                `Sobre nós | YFN Jewelry`,
                ``,
                ``]
        }, {
            text: 'YFN mission is to offer our customers across the world a universe of contemporary jewelry personalized based on their feelings, thereby expressing their individuality.',
            list: [
                `La Mission de yFn est d’offrir aux clients du monde entier un univers de bijoux contemporains personnalisés en fonction de leurs sentiments, exprimant ainsi leur personnalité.`,
                `YFN Mission ist es, unseren Kunden auf der ganzen Welt ein Universum von zeitgenössischem Schmuck zu bieten, der auf ihre Gefühle basiert und dadurch ihre Individualität zum Ausdruck bringt.`,
                `La Misión de yfn es proporcionar a los clientes de todo el mundo un universo contemporáneo de joyas personalizado basado en sus sentimientos, expresando así su personalidad.`,
                ``,
                `A missão da YFN é oferecer aos nossos clientes em todo o mundo um universo de joias contemporâneas personalizadas com base em seus sentimentos, expressando assim sua individualidade.`,
                ``,
                ``]
        }, {
            text: `During the busy season, we can't guarantee delivery before January 1st for orders placed now. We apologize for any inconvenience. Enjoy a 15% discount for your patience if you don't mind the possibility of tardiness.`,
            list: [
                `Pendant la période chargée, nous ne pouvons pas garantir la livraison avant le 1er janvier pour les commandes passées maintenant. Nous nous excusons pour tout inconvénient causé. Profitez d'une réduction de 15% pour votre patience si vous n'êtes pas gêné(e) par la possibilité de retard.`,
                `Während der Hochsaison können wir keine Lieferung vor 1. Januar. Dezember für jetzt aufgegebene Bestellungen garantieren. Wir entschuldigen uns für eventuelle Unannehmlichkeiten. Genießen Sie einen 15%igen Rabatt für Ihre Geduld, wenn Sie mit der Möglichkeit einer verspäteten Lieferung einverstanden sind.`,
                `Durante la temporada ocupada, no podemos garantizar la entrega antes del 1 de Enero para las órdenes realizadas ahora. Nos disculpamos por cualquier inconveniente. Disfrute de un descuento del 15% por su paciencia si no le importa la posibilidad de tardanza.`,
                `В высокий сезон мы не можем гарантировать доставку заказов, размещенных сейчас, до 25 декабря. Мы извиняемся за любые неудобства. Получите скидку 15 % за терпение, если вас не пугает возможность опоздания.`,
                `Durante a temporada movimentada, não podemos garantir a entrega antes do dia 1 de Janeiro para os pedidos feitos agora. Pedimos desculpas por qualquer inconveniência. Aproveite um desconto de 15% pela sua paciência, caso não se importe com a possibilidade de atraso.`,
                `繁忙期には、現在ご注文いただいた場合、12 月 25 日より前にお届けすることは保証できません。ご不便をおかけして申し訳ございません。遅刻の可能性を気にしない場合は、15% 割引をご利用ください。`,
                `خلال الموسم المزدحم، لا يمكننا ضمان التسليم قبل 25 ديسمبر للطلبات المقدمة الآن. نحن نعتذر عن أي شيء غير مناسب. استمتع بخصم 15% على سعة صدرك إذا كنت لا تمانع في إمكانية التأخير.`]
        }, {
            text: `Keep Checking out`,
            list: [
                `Continuez à vérifier`,
                `Schauen Sie weiter vorbei`,
                `Sigue revisando`,
                `Продолжайте проверять`,
                `Continue verificando`,
                `チェックアウトを続ける`,
                `استمر في التحقق`]
        }, {
            text: `Return to Bag`,
            list: [
                `Retour au sac`,
                `Zurück zur Tasche`,
                `Volver a la bolsa`,
                `Вернуться в сумку`,
                `Voltar para a bolsa`,
                `バッグに戻る`,
                `العودة إلى الحقيبة`]
        }, {
            text: `You haven’t finished checking out yet.`,
            list: [
                `Vous n’avez pas encore fini de payer.`,
                `Sie sind noch nicht mit dem Auschecken fertig.`,
                `Aún no has terminado de pagar.`,
                `Вы еще не закончили выезд.`,
                `Você ainda não terminou de finalizar a compra.`,
                `まだチェックアウトが終わっていません。`,
                `لم تنته من التحقق بعد.`]
        }, {
            text: `Don’t miss out a`,
            list: [
                `Ne manquez pas un`,
                `Verpassen Sie nicht a`,
                `No te pierdas un`,
                `No te pierdas un`,
                `Não te percas`,
                `ノー・テ・ピアダス・ウン`,
                `لا بيرداس أون`]
        }, {
            text: `Log in to synchronize your shopping bag`,
            list: [
                `Connectez-vous pour synchroniser votre panier`,
                `Melden Sie sich an, um Ihren Warenkorb zu synchronisieren`,
                `Inicia sesión para sincronizar tu bolsa de compras`,
                `Войдите, чтобы синхронизировать корзину`,
                `Faça login para sincronizar sua sacola de compras`,
                `ログインしてショッピングバッグを同期してください`,
                `قم بتسجيل الدخول لمزامنة حقيبة التسوق الخاصة بك`]
        }, {
            text: `You haven’t finished checking out yet. Don’t miss out on`,
            list: [
                `Vous n'avez pas encore fini de payer. Ne manquez pas la`,
                `Sie sind noch nicht mit dem Auschecken fertig. Lassen Sie sich das nicht entgehen`,
                `Aún no has terminado de pagar. No te pierdas`,
                ``,
                `Você ainda não terminou o check-out. Não perca`,
                ``,
                ``]
        }, {
            text: `&`,
            list: [
                `et`,
                `&`,
                `y`,
                ``,
                `e`,
                ``,
                ``]
        }, {
            text: `a`,
            list: [
                `une`,
                `a`,
                `un`,
                ``,
                `um`,
                ``,
                ``]
        }, {
            text: `YFN Jewelry Exchange`,
            list: [
                `Échange de bijoux YFN`,
                `YFN-Schmuckbörse`,
                `Intercambio de joyas YFN`,
                ``,
                `Troca de joias YFN`,
                ``,
                ``]
        }, {
            text: `We want to embed sustainability into everything we do. We use our own production advantages to buy back jewelry produced by YFN to ensure a healthy "life cycle".`,
            list: [
                `Nous voulons intégrer la durabilité dans tout ce que nous faisons. Nous utilisons nos propres avantages de production pour racheter les bijoux produits par YFN afin de garantir un « cycle de vie » sain.`,
                `Wir wollen Nachhaltigkeit in alles integrieren, was wir tun. Wir nutzen unsere eigenen Produktionsvorteile, um von YFN produzierten Schmuck zurückzukaufen, um einen gesunden „Lebenszyklus“ zu gewährleisten.`,
                `Queremos incorporar la sostenibilidad en todo lo que hacemos. Utilizamos nuestras propias ventajas de producción para recomprar joyas producidas por YFN para garantizar un "ciclo de vida" saludable.`,
                ``,
                `Queremos incorporar a sustentabilidade em tudo o que fazemos. Usamos nossas próprias vantagens de produção para recomprar joias produzidas pela YFN para garantir um "ciclo de vida" saudável.`,
                ``,
                ``]
        }, {
            text: `YFN Jewelry aim to integrate sustainability into everything we do. By avoiding excessive inventory and reducing waste, we leverage our manufacturing expertise to provide personalized, customized services to our customers and ensure quick delivery. We guarantee repurchasing jewelry produced by YFN, ensuring a healthy "lifecycle."`,
            list: [
                `YFN Jewelry vise à intégrer la durabilité dans tout ce que nous faisons. En évitant les stocks excessifs et en réduisant les déchets, nous tirons parti de notre expertise en fabrication pour fournir des services personnalisés à nos clients et assurer une livraison rapide. Nous garantissons le rachat des bijoux produits par YFN, garantissant ainsi un « cycle de vie » sain.`,
                `YFN Jewelry ist bestrebt, Nachhaltigkeit in alles, was wir tun, zu integrieren. Indem wir übermäßige Lagerbestände vermeiden und Abfall reduzieren, nutzen wir unsere Fertigungskompetenz, um unseren Kunden personalisierte, maßgeschneiderte Dienstleistungen zu bieten und eine schnelle Lieferung sicherzustellen. Wir garantieren den Rückkauf von von YFN hergestelltem Schmuck und sorgen so für einen gesunden „Lebenszyklus“.`,
                `YFN Jewelry tiene como objetivo integrar la sostenibilidad en todo lo que hacemos. Al evitar un inventario excesivo y reducir el desperdicio, aprovechamos nuestra experiencia en fabricación para brindar servicios personalizados a nuestros clientes y garantizar una entrega rápida. Garantizamos la recompra de joyas producidas por YFN, asegurando un "ciclo de vida" saludable.`,
                ``,
                `A YFN Jewelry visa integrar a sustentabilidade em tudo o que fazemos. Ao evitar estoques excessivos e reduzir desperdícios, aproveitamos nossa experiência em fabricação para fornecer serviços personalizados e customizados aos nossos clientes e garantir entrega rápida. Garantimos a recompra de joias produzidas pela YFN, garantindo um “ciclo de vida” saudável.`,
                ``,
                ``]
        }, {
            text: `How YFN Jewelry Exchange Works?`,
            list: [
                `Comment fonctionne l'échange de bijoux YFN ?`,
                `Wie funktioniert der YFN-Schmuckaustausch?`,
                `¿Cómo funciona el intercambio de joyas YFN?`,
                ``,
                `Como funciona a troca de joias YFN?`,
                ``,
                ``]
        }, {
            text: `Exchange Process`,
            list: [
                `Processus Exchange`,
                `Austauschprozess`,
                `Proceso de intercambio`,
                ``,
                `Processo de Troca`,
                ``,
                ``]
        }, {
            text: `Application`,
            list: [
                `Application`,
                `Anwendung`,
                `Solicitud`,
                ``,
                `Aplicativo`,
                ``,
                ``]
        }, {
            text: `Valuate the Price`,
            list: [
                `Évaluer le prix`,
                `Bewerten Sie den Preis`,
                `Valorar el precio`,
                ``,
                `Avalie o preço`,
                ``,
                ``]
        }, {
            text: `Agree & Upload Tracking Number`,
            list: [
                `Accepter et télécharger le numéro de suivi`,
                `Zustimmen und Tracking-Nummer hochladen`,
                `Aceptar y cargar número de seguimiento`,
                ``,
                `Concordar e fazer upload do número de rastreamento`,
                ``,
                ``]
        }, {
            text: `Unbox & Confirm`,
            list: [
                `Déballer et confirmer`,
                `Auspacken und bestätigen`,
                `Desempaquetar y confirmar`,
                ``,
                `Desembale e confirme`,
                ``,
                ``]
        }, {
            text: `Weigh & Account Price`,
            list: [
                `Pesée et prix de compte`,
                `Wiegen und Kontopreis`,
                `Peso y precio de cuenta`,
                ``,
                `Peso e Preço da Conta`,
                ``,
                ``]
        }, {
            text: `Send Offer`,
            list: [
                `Envoyer une offre`,
                `Sende Angebot`,
                `Enviar oferta`,
                ``,
                `Enviar oferta`,
                ``,
                ``]
        }, {
            text: `Sign the Agreement`,
            list: [
                `Signer l'accord`,
                `Unterzeichnen Sie die Vereinbarung`,
                `Firma el acuerdo`,
                ``,
                `Assine o Acordo`,
                ``,
                ``]
        }, {
            text: `Get Paid`,
            list: [
                `Soyez payé`,
                `Bezahlt werden`,
                `Recibir el pago`,
                ``,
                `Ser pago`,
                ``,
                ``]
        }, {
            text: `Extra 10% off coupon`,
            list: [
                `Coupon de réduction supplémentaire de 10 %`,
                `Extra 10 % Rabatt auf den Gutschein`,
                `Cupón de descuento adicional del 10 %`,
                ``,
                `cupom extra de 10% de desconto`,
                ``,
                ``]
        }, {
            text: `①If you want to exchange the jewelry, please provide the order number and the item(s) id or title to us.`,
            list: [
                `①Si vous souhaitez échanger les bijoux, veuillez nous fournir le numéro de commande et l'identifiant ou le titre du ou des articles.`,
                `①Wenn Sie den Schmuck umtauschen möchten, geben Sie uns bitte die Bestellnummer und die Artikel-ID oder den Titel an.`,
                `①Si desea cambiar las joyas, proporcione el número de pedido y la identificación o título del artículo.`,
                ``,
                `①Se você deseja trocar as joias, forneça-nos o número do pedido e a identificação ou título do(s) item(s).`,
                ``,
                ``]
        }, {
            text: `②When YFN valuated the price, if you agree, you need to return the jewelry and provide the tracking number to us.`,
            list: [
                `②Lorsque YFN a évalué le prix, si vous êtes d'accord, vous devez retourner les bijoux et nous fournir le numéro de suivi.`,
                `②Wenn YFN den Preis bewertet hat und Sie damit einverstanden sind, müssen Sie den Schmuck zurücksenden und uns die Sendungsverfolgungsnummer mitteilen.`,
                `②Cuando YFN valore el precio, si está de acuerdo, deberá devolver las joyas y proporcionarnos el número de seguimiento.`,
                ``,
                `②Quando a YFN avaliar o preço, se você concordar, você precisará devolver as joias e nos fornecer o número de rastreamento.`,
                ``,
                ``]
        }, {
            text: `③After we signed the agreement, you will get paid to your PayPal account or YFN wallet, not only the exchange paid, but also 10% off coupon code.`,
            list: [
                `③Après avoir signé l'accord, vous serez payé sur votre compte PayPal ou votre portefeuille YFN, non seulement l'échange payé, mais également 10 % de réduction sur le code promo.`,
                `③Nachdem wir die Vereinbarung unterzeichnet haben, erhalten Sie die Zahlung auf Ihr PayPal-Konto oder YFN-Wallet, nicht nur die Umtauschzahlung, sondern auch einen 10 %-Rabatt-Gutscheincode.`,
                `③Después de firmar el acuerdo, se le pagará en su cuenta PayPal o billetera YFN, no solo el intercambio pagado, sino también un código de cupón de descuento del 10 %.`,
                ``,
                `③Depois de assinarmos o contrato, você receberá o pagamento em sua conta PayPal ou carteira YFN, não apenas a troca paga, mas também 10% de desconto no código do cupom.`,
                ``,
                ``]
        }, {
            text: `Disagree and Return`,
            list: [
                `Pas d'accord et retour`,
                `Nicht zustimmen und zurückkehren`,
                `No estoy de acuerdo y regreso`,
                ``,
                `Discordo e retorno`,
                ``,
                ``,
            ]
        }, {
            text: `①When YFN valuate the price, if you disagree, you need not return the jewelry.`,
            list: [
                `①Lorsque YFN évalue le prix, si vous n'êtes pas d'accord, vous n'avez pas besoin de retourner les bijoux.`,
                `①Wenn YFN den Preis bewertet und Sie nicht einverstanden sind, müssen Sie den Schmuck nicht zurückgeben.`,
                `①Cuando YFN valore el precio, si no está de acuerdo, no necesitará devolver las joyas.`,
                ``,
                `①Quando a YFN avaliar o preço, se você discordar, não será necessário devolver as joias.`,
                ``,
                ``,
            ]
        }, {
            text: `②When YFN send the offer, if you do not accept, we will return the jewelry and you need to pay for the return fee.`,
            list: [
                `②Lorsque YFN envoie l'offre, si vous n'acceptez pas, nous vous renverrons les bijoux et vous devrez payer les frais de retour.`,
                `②Wenn YFN das Angebot sendet und Sie es nicht annehmen, senden wir den Schmuck zurück und Sie müssen die Rücksendegebühr bezahlen.`,
                `②Cuando YFN envíe la oferta, si no la acepta, le devolveremos las joyas y deberá pagar la tarifa de devolución.`,
                ``,
                `②Quando a YFN enviar a oferta, caso você não aceite, devolveremos as joias e você deverá pagar a taxa de devolução.`,
                ``,
                ``,
            ]
        }, {
            text: `Recycling Applicable Products`,
            list: [
                `Recyclage des produits applicables`,
                `Recycling anwendbarer Produkte`,
                `Reciclaje de productos aplicables`,
                ``,
                `Reciclagem de produtos aplicáveis`,
                ``,
                ``,
            ]
        }, {
            text: `Customers can have gold, K gold and other precious jewelry which were purchased from YFN official website (`,
            list: [
                `Les clients peuvent échanger de l'or, de l'or K et d'autres bijoux précieux achetés sur le site officiel de YFN (`,
                `Kunden können Gold, K-Gold und anderen kostbaren Schmuck, der auf der offiziellen YFN-Website (`,
                `Los clientes pueden intercambiar oro, oro K y otras joyas preciosas que hayan comprado en el sitio web oficial de YFN (`,
                ``,
                `Os clientes podem trocar ouro, ouro K e outras joias preciosas que foram compradas no site oficial da YFN (`,
                ``,
                ``,
            ]
        }, {
            text: `) exchanged. Please consult our customer service for specific details`,
            list: [
                `). Veuillez consulter notre service client pour plus de détails`,
                `) gekauft wurde, umtauschen lassen. Für spezifische Details wenden Sie sich bitte an unseren Kundenservice`,
                `). Consulte nuestro servicio de atención al cliente para obtener detalles específicos.`,
                ``,
                `). Por favor consulte nosso serviço de atendimento ao cliente para detalhes específicos`,
                ``,
                ``,
            ]
        }, {
            text: `Exchange Safety`,
            list: [
                `Sécurité des échanges`,
                `Börsensicherheit`,
                `Seguridad en el intercambio`,
                ``,
                `Segurança de troca`,
                ``,
                ``,
            ]
        }, {
            text: `With over 30 years of expertise in precious metals, we have served 300,000 satisfied customers. Your items are always secure with us, from shipping to delivery and payment, your jewelry is thoroughly protected every step of the way.`,
            list: [
                `Avec plus de 30 ans d'expertise dans les métaux précieux, nous avons servi 300 000 clients satisfaits. Vos articles sont toujours en sécurité chez nous, de l'expédition à la livraison et au paiement, vos bijoux sont soigneusement protégés à chaque étape du processus.`,
                `Mit über 30 Jahren Erfahrung im Edelmetallbereich haben wir 300.000 zufriedene Kunden betreut. Ihre Artikel sind bei uns immer sicher, vom Versand bis zur Lieferung und Zahlung ist Ihr Schmuck bei jedem Schritt umfassend geschützt.`,
                `Con más de 30 años de experiencia en metales preciosos, hemos atendido a 300.000 clientes satisfechos. Sus artículos siempre estarán seguros con nosotros, desde el envío hasta la entrega y el pago, sus joyas estarán completamente protegidas en cada paso del camino.`,
                ``,
                `Com mais de 30 anos de experiência em metais preciosos, atendemos 300.000 clientes satisfeitos. Seus itens estão sempre seguros conosco, desde o envio até a entrega e pagamento, suas joias estão totalmente protegidas em cada etapa do processo.`,
                ``,
                ``,
            ]
        }, {
            text: `Three must-know things before exchanging`,
            list: [
                `Trois choses à savoir avant d'échanger`,
                `Drei Dinge, die Sie vor dem Umtausch unbedingt wissen müssen`,
                `Tres cosas que debes saber antes de realizar un intercambio`,
                ``,
                `Três coisas que você deve saber antes de trocar`,
                ``,
                ``,
            ]
        }, {
            text: `1.How much gold is there inside?`,
            list: [
                `1. Combien d’or y a-t-il à l’intérieur ?`,
                `1.Wie viel Gold ist drin?`,
                `1. ¿Cuánto oro hay dentro?`,
                ``,
                `1.Quanto ouro há dentro?`,
                ``,
                ``,
            ]
        }, {
            text: `Gold rings, earrings, bracelets, and necklaces are made by combining a certain amount of pure gold with other metals. The gold content in items can be defined as KARAT, for example, 10k, 14k, or 18k, or purity ratings such as 417, 585, 750. This also applies to all precious metals like silver, platinum, and palladium. Silver, platinum, and palladium are often marked with purities like 900, 925, or 950. Purity tells us the amount of pure gold in the item.`,
            list: [
                `Les bagues, boucles d'oreilles, bracelets et colliers en or sont fabriqués en combinant une certaine quantité d'or pur avec d'autres métaux. La teneur en or des objets peut être définie comme KARAT, par exemple 10k, 14k ou 18k, ou des indices de pureté tels que 417, 585, 750. Cela s'applique également à tous les métaux précieux comme l'argent, le platine et le palladium. L'argent, le platine et le palladium sont souvent marqués d'une pureté telle que 900, 925 ou 950. La pureté nous indique la quantité d'or pur contenu dans l'article.`,
                `Goldringe, Ohrringe, Armbänder und Halsketten werden durch die Kombination einer bestimmten Menge reinem Gold mit anderen Metallen hergestellt. Der Goldgehalt in Artikeln kann als KARAT definiert werden, zum Beispiel 10.000, 14.000 oder 18.000, oder Reinheitsgrade wie 417, 585, 750. Dies gilt auch für alle Edelmetalle wie Silber, Platin und Palladium. Silber, Platin und Palladium werden häufig mit Reinheitsgraden wie 900, 925 oder 950 gekennzeichnet. Der Reinheitsgrad gibt uns die Menge an reinem Gold im Artikel an.`,
                `Los anillos, aretes, pulseras y collares de oro se elaboran combinando una cierta cantidad de oro puro con otros metales. El contenido de oro en los artículos se puede definir como KARAT, por ejemplo, 10k, 14k o 18k, o grados de pureza como 417, 585, 750. Esto también se aplica a todos los metales preciosos como la plata, el platino y el paladio. La plata, el platino y el paladio suelen estar marcados con purezas como 900, 925 o 950. La pureza nos indica la cantidad de oro puro que hay en el artículo.`,
                ``,
                `Anéis, brincos, pulseiras e colares de ouro são feitos combinando uma certa quantidade de ouro puro com outros metais. O conteúdo de ouro nos itens pode ser definido como KARAT, por exemplo, 10k, 14k ou 18k, ou classificações de pureza como 417, 585, 750. Isso também se aplica a todos os metais preciosos como prata, platina e paládio. Prata, platina e paládio são frequentemente marcados com purezas como 900, 925 ou 950. A pureza nos diz a quantidade de ouro puro no item.`,
                ``,
                ``,
            ]
        }, {
            text: `2.How much does this item weigh?`,
            list: [
                `2. Combien pèse cet article ?`,
                `2.Wie viel wiegt dieser Artikel?`,
                `2.¿Cuánto pesa este artículo?`,
                ``,
                `2. Quanto pesa este item?`,
                ``,
                ``,
            ]
        }, {
            text: `The weight of an item, combined with its purity, can tell us how much pure gold it contains. For example, if a gold ring has a gold content of 50% and weighs 10 grams, the item contains 5 grams of pure gold. That 5 grams of pure gold is what we would be purchasing from you.`,
            list: [
                `Le poids d’un objet, combiné à sa pureté, peut nous indiquer la quantité d’or pur qu’il contient. Par exemple, si une bague en or contient 50 % d’or et pèse 10 grammes, l’article contient 5 grammes d’or pur. Ces 5 grammes d’or pur sont ce que nous achèterions chez vous.`,
                `Das Gewicht eines Artikels in Kombination mit seinem Reinheitsgrad kann uns Aufschluss darüber geben, wie viel reines Gold er enthält. Wenn ein Goldring beispielsweise einen Goldgehalt von 50 % hat und 10 Gramm wiegt, enthält der Artikel 5 Gramm reines Gold. Diese 5 Gramm reines Gold würden wir von Ihnen kaufen.`,
                `El peso de un artículo, combinado con su pureza, puede decirnos cuánto oro puro contiene. Por ejemplo, si un anillo de oro tiene un contenido de oro del 50% y pesa 10 gramos, el artículo contiene 5 gramos de oro puro. Esos 5 gramos de oro puro es lo que le compraríamos.`,
                ``,
                `O peso de um item, combinado com a sua pureza, pode nos dizer quanto ouro puro ele contém. Por exemplo, se um anel de ouro tem 50% de ouro e pesa 10 gramas, o item contém 5 gramas de ouro puro. Esses 5 gramas de ouro puro é o que estaríamos comprando de você.`,
                ``,
                ``,
            ]
        }, {
            text: `Notice: The weight specified here refers specifically to the weight of the metal, and any stones on the jewelry will not be included in the measurement.`,
            list: [
                `Remarque : Le poids spécifié ici se réfère spécifiquement au poids du métal, et les pierres présentes sur les bijoux ne seront pas incluses dans la mesure.`,
                `Hinweis: Das hier angegebene Gewicht bezieht sich speziell auf das Gewicht des Metalls, eventuelle Steine auf dem Schmuck werden nicht in die Messung einbezogen.`,
                `Aviso: El peso especificado aquí se refiere específicamente al peso del metal y las piedras de las joyas no se incluirán en la medida.`,
                ``,
                `Aviso: O peso especificado aqui refere-se especificamente ao peso do metal, e quaisquer pedras nas joias não serão incluídas na medição.`,
                ``,
                ``,
            ]
        }, {
            text: `3.What is the current price of gold?`,
            list: [
                `3. Quel est le prix actuel de l’or ?`,
                `3.Wie hoch ist der aktuelle Goldpreis?`,
                `3. ¿Cuál es el precio actual del oro?`,
                ``,
                `3.Qual é o preço atual do ouro?`,
                ``,
                ``,
            ]
        }, {
            text: `Now that we know the purity and weight of the item, we will pay you based on the daily market price of gold. The market price, also known as the spot price, is determined by the world market and fluctuates constantly. The market price is always changing. All the "prices we pay" listed on our website are real-time (please contact customer service for specific details).`,
            list: [
                `Maintenant que nous connaissons la pureté et le poids de l’article, nous vous paierons sur la base du prix quotidien de l’or sur le marché. Le prix du marché, également appelé prix spot, est déterminé par le marché mondial et fluctue constamment. Le prix du marché est en constante évolution. Tous les « prix que nous payons » indiqués sur notre site Web sont en temps réel (veuillez contacter le service client pour plus de détails).`,
                `Da wir nun den Reinheitsgrad und das Gewicht des Artikels kennen, zahlen wir Ihnen auf der Grundlage des täglichen Marktpreises für Gold. Der Marktpreis, auch Spotpreis genannt, wird vom Weltmarkt bestimmt und schwankt ständig. Der Marktpreis ändert sich ständig. Alle auf unserer Website aufgeführten „Preise, die wir zahlen“ sind Echtzeitpreise (für nähere Einzelheiten wenden Sie sich bitte an den Kundendienst).`,
                `Ahora que conocemos la pureza y el peso del artículo, le pagaremos según el precio del oro en el mercado diario. El precio de mercado, también conocido como precio spot, lo determina el mercado mundial y fluctúa constantemente. El precio de mercado siempre está cambiando. Todos los "precios que pagamos" que figuran en nuestro sitio web son en tiempo real (comuníquese con el servicio de atención al cliente para obtener detalles específicos).`,
                ``,
                `Agora que sabemos a pureza e o peso do item, pagaremos com base no preço diário de mercado do ouro. O preço de mercado, também conhecido como preço à vista, é determinado pelo mercado mundial e flutua constantemente. O preço de mercado está sempre mudando. Todos os "preços que pagamos" listados em nosso site são em tempo real (entre em contato com o atendimento ao cliente para obter detalhes específicos).`,
                ``,
                ``,
            ]
        }, {
            text: `Today's Recycling Price`,
            list: [
                `Le prix du recyclage aujourd'hui`,
                `Der heutige Recyclingpreis`,
                `El precio actual del reciclaje`,
                ``,
                `Preço de reciclagem de hoje`,
                ``,
                ``,
            ]
        }, {
            text: `Consult us for details`,
            list: [
                `Consultez-nous pour plus de détails`,
                `Für Einzelheiten wenden Sie sich bitte an uns`,
                `Consúltenos para más detalles`,
                ``,
                `Consulte-nos para detalhes`,
                ``,
                ``,
            ]
        }, {
            text: `Metal Material`,
            list: [
                `Matériau métallique`,
                `Metall Material`,
                `Material metálico`,
                ``,
                `Materiais Metálicos`,
                ``,
                ``,
            ]
        }, {
            text: `Recycling Price/g`,
            list: [
                `Prix de recyclage/g`,
                `Recyclingpreis/g`,
                `Precio de reciclaje/g`,
                ``,
                `Preço de reciclagem/g`,
                ``,
                ``,
            ]
        }, {
            text: `Consult Now`,
            list: [
                `Consulter maintenant`,
                `Jetzt beraten`,
                `Consultar ahora`,
                ``,
                `Consulte agora`,
                ``,
                ``,
            ]
        }, {
            text: `YFN Exchange`,
            list: [
                `Échange YFN`,
                `YFN-Austausch`,
                `Intercambio YFN`,
                `YFN биржа`,
                `Intercâmbio YFN`,
                `YFN取引所`,
                `تبادل YFN`,
            ]
        }, {
            text: `Sustainability & Exchange`,
            list: [
                `Développement durable et communication`,
                `Austausch für Nachhaltigkeit`,
                `Desarrollo sostenible e intercambio`,
                `Устойчивое развитие и обмен`,
                `Sustentabilidade e intercâmbio`,
                `持続可能性と交流`,
                `الاستدامة والتبادل`,
            ]
        }, {
            text: `to know more.`,
            list: [
                `en savoir plus.`,
                `mehr wissen.`,
                `para saber mas.`,
                `знать больше.`,
                `Para saber mais.`,
                `もっと知るために。`,
                `لمعرفة المزيد.`,
            ]
        }, {
            text: `YFN Jewelry aim to integrate sustainability into everything we do. We guarantee repurchasing jewelry produced by YFN, ensuring a healthy "lifecycle."`,
            list: [
                `YFN Jewelry vise à intégrer la durabilité dans tout ce que nous faisons. Nous garantissons le rachat des bijoux produits par YFN, garantissant ainsi un « cycle de vie » sain.`,
                `YFN Jewelry ist bestrebt, Nachhaltigkeit in alles, was wir tun, zu integrieren. Wir garantieren den Rückkauf von von YFN hergestelltem Schmuck und sorgen so für einen gesunden „Lebenszyklus“.`,
                `YFN Jewelry tiene como objetivo integrar la sostenibilidad en todo lo que hacemos. Garantizamos la recompra de joyas producidas por YFN, asegurando un "ciclo de vida" saludable.`,
                `YFN Jewelry стремится интегрировать устойчивое развитие во все, что мы делаем. Мы гарантируем выкуп ювелирных изделий YFN, обеспечивая здоровый «жизненный цикл».`,
                `A YFN Jewelry visa integrar a sustentabilidade em tudo o que fazemos. Garantimos a recompra de joias produzidas pela YFN, garantindo um “ciclo de vida” saudável.`,
                `YFN ジュエリーは、あらゆる活動に持続可能性を組み込むことを目指しています。 YFN が製造したジュエリーの再購入を保証し、健全な「ライフサイクル」を保証します。`,
                `تهدف مجوهرات YFN إلى دمج الاستدامة في كل ما نقوم به. نحن نضمن إعادة شراء المجوهرات التي تنتجها YFN، مما يضمن "دورة حياة" صحية.`,
            ]
        }, {
            text: `Gift Card`,
            list: [
                `Carte cadeau`,
                `Geschenkkarte`,
                `Tarjeta de regalo`,
                ``,
                `Cartão Presente`,
                ``,
                ``,
            ]
        }, {
            text: `E-Gift Card Balance `,
            list: [
                `Solde des cartes - cadeaux électroniques`,
                `Guthaben der E-Geschenkkarte`,
                `Saldo de la tarjeta de regalo electrónica`,
                ``,
                `Saldo do Cartão de Presente Electrónico`,
                ``,
                ``,
            ]
        }, {
            text: `Rule`,
            list: [
                `Règles`,
                `Regel`,
                `Reglas`,
                ``,
                `Regra`,
                ``,
                ``,
            ]
        }, {
            text: `Redeem`,
            list: [
                `Rédemption`,
                `Einlösen`,
                `Rescate`,
                ``,
                `Resgatar`,
                ``,
                ``,
            ]
        }, {
            text: `It’s empty here`,
            list: [
                `Ici c'est vide`,
                `Hier ist es leer`,
                `Está vacío`,
                ``,
                `Está vazio aqui`,
                ``,
                ``,
            ]
        }, {
            text: `Purchase an e-Gift Card`,
            list: [
                `Acheter une carte - cadeau électronique`,
                `Kaufen Sie eine E-Geschenkkarte`,
                `Compra una tarjeta de regalo electrónica`,
                ``,
                `Comprar um cartão-presente electrónico`,
                ``,
                ``,
            ]
        }, {
            text: `Claim-code`,
            list: [
                `Code de réclamation`,
                `Claim-Code`,
                `Código de reclamación`,
                ``,
                `Código do pedido`,
                ``,
                ``,
            ]
        }, {
            text: `Order Refund`,
            list: [
                `Remboursement de la commande`,
                `Rückerstattung der Bestellung`,
                `Reembolso del pedido`,
                ``,
                `Reembolso de pedido`,
                ``,
                ``,
            ]
        }, {
            text: `Order No`,
            list: [
                `N ° de commande`,
                `Best.-Nr`,
                `N º de pedido`,
                ``,
                `Número do pedido`,
                ``,
                ``,
            ]
        }, {
            text: `Redeem a YFN gift card`,
            list: [
                `Échanger une carte - cadeau yFn`,
                `Eine YFN Geschenkkarte einlösen`,
                `Cambiar la tarjeta de regalo yfn`,
                ``,
                `Resgatar um cartão-presente YFN`,
                ``,
                ``,
            ]
        }, {
            text: `Apply to your balance`,
            list: [
                `Appliquer à votre solde`,
                `Auf Ihr Guthaben anwenden`,
                `Aplicar a su saldo`,
                ``,
                `Aplicar ao seu saldo`,
                ``,
                ``,
            ]
        }, {
            text: `The entire gift card amount will be stored in your account, and is valid for ten years from the date of issuance.`,
            list: [
                `Le montant total de la carte - cadeau sera stocké dans votre compte et sera valide pendant dix ans à compter de la date d'émission.`,
                `Der gesamte Betrag der Geschenkkarte wird auf Ihrem Konto gespeichert und ist zehn Jahre ab Ausstellungsdatum gültig.`,
                `El importe total de la tarjeta de regalo se almacenará en su cuenta y será válido por diez años a partir de la fecha de emisión.`,
                ``,
                `Todo o valor do cartão presente será armazenado em sua conta e é válido por dez anos a partir da data de emissão.`,
                ``,
                ``,
            ]
        }, {
            text: `To`,
            list: [
                `Destinataire`,
                `An`,
                `Destinatario`,
                ``,
                `Para`,
                ``,
                ``,
            ]
        }, {
            text: `Recipient email`,
            list: [
                `Email du destinataire`,
                `E-Mail des Empfängers`,
                `Correo electrónico del destinatario`,
                ``,
                `E- mail do destinatário`,
                ``,
                ``,
            ]
        }, {
            text: `Please enter recipient email`,
            list: [
                `Veuillez entrer l'email du destinataire`,
                `Bitte geben Sie die E-Mail-Adresse des Empfängers ein`,
                `Introduzca el correo electrónico del destinatario`,
                ``,
                `Indique por favor o e- mail do destinatário`,
                ``,
                ``,
            ]
        }, {
            text: `Your name`,
            list: [
                `Votre nom`,
                `Ihr Name`,
                `Tu nombre`,
                ``,
                `O seu nome`,
                ``,
                ``,
            ]
        }, {
            text: `From`,
            list: [
                `Expéditeur`,
                `Von*`,
                `Del remitente`,
                ``,
                `De`,
                ``,
                ``,
            ]
        }, {
            text: `Add a message`,
            list: [
                `Ajouter un message`,
                `Nachricht hinzufügen`,
                `Añadir mensaje`,
                ``,
                `Adicionar uma mensagem`,
                ``,
                ``,
            ]
        }, {
            text: `Your message`,
            list: [
                `Votre message`,
                `Ihre Nachricht`,
                `Su mensaje`,
                ``,
                `A sua mensagem`,
                ``,
                ``,
            ]
        }, {
            text: `I have read, understand, and agree to`,
            list: [
                `J'ai lu, compris et accepté`,
                `Ich habe die Allgemeinen Geschäftsbedingungen von`,
                `He leído, entendido y estoy de acuerdo con`,
                ``,
                `Li, compreendi e concordo com a`,
                ``,
                ``,
            ]
        }, {
            text: `YFN's Gift Card terms and conditions`,
            list: [
                `YFn cartes - cadeaux termes et conditions`,
                `YFN für Geschenkkarten gelesen, verstanden und stimme ihnen zu`,
                `Términos y condiciones de la tarjeta de regalo yfn`,
                ``,
                `Termos e condições do Cartão Presente da YFN`,
                ``,
                ``,
            ]
        }, {
            text: `Preview`,
            list: [
                `Preview`,
                `Vorschau`,
                `Vista previa`,
                ``,
                `Antevisão`,
                ``,
                ``,
            ]
        }, {
            text: `Shop with your YFN Gift Card Acount balance.
            Use it to buy YFN products, accessories, games, apps, and more. Adding to your balance is simple — just redeem an YFN Gift Card or add money directly.
            Usually arrives within 5 minites.`,
            list: [
                `Achetez avec le solde de votre compte de carte-cadeau YFN.
                Utilisez-le pour acheter des produits, des accessoires, des jeux, des applications YFN et bien plus encore. L'ajout à votre solde est simple : il vous suffit d'utiliser une carte-cadeau YFN ou d'ajouter de l'argent directement.
                Arrive généralement dans les 5 minutes.`,
                `Kaufen Sie mit dem Guthaben Ihres YFN-Geschenkkartenkontos ein.
                Verwenden Sie es, um YFN-Produkte, Zubehör, Spiele, Apps und mehr zu kaufen. Das Aufstocken Ihres Guthabens ist ganz einfach: Lösen Sie einfach eine YFN-Geschenkkarte ein oder fügen Sie direkt Geld hinzu.
                Kommt normalerweise innerhalb von 5 Minuten an.`,
                `Compre con el saldo de su cuenta de tarjeta de regalo YFN.
                Úselo para comprar productos, accesorios, juegos, aplicaciones y más de YFN. Agregar a su saldo es simple: simplemente canjee una tarjeta de regalo YFN o agregue dinero directamente.
                Normalmente llega en 5 minutos.`,
                ``,
                `Compre com o saldo da sua conta do vale-presente YFN.
                Use-o para comprar produtos, acessórios, jogos, aplicativos YFN e muito mais. Adicionar ao seu saldo é simples – basta resgatar um vale-presente YFN ou adicionar dinheiro diretamente.
                Geralmente chega em 5 minutos.`,
                ``,
                ``,
            ]
        }, {
            text: `Hope you enjoy this YFN Jewelry Gift Card!`,
            list: [
                `J'espère que vous apprécierez cette carte - cadeau de bijoux yFn!`,
                `Ich hoffe, Sie genießen diese YFN Schmuck Geschenkkarte!`,
                `¡¡ espero que le guste esta tarjeta de regalo de joyas yfn!`,
                ``,
                `Espero que você goste deste YFN Jewelry Gift Card!`,
                ``,
                ``,
            ]
        }, {
            text: `YFN’s Gift Card Terms and Conditions`,
            list: [
                `YFn cartes - cadeaux termes et conditions`,
                `Allgemeine Geschäftsbedingungen für Geschenkkarten von YFN`,
                `Términos y condiciones de la tarjeta de regalo yfn`,
                ``,
                `Termos e Condições do Cartão Presente da YFN`,
                ``,
                ``,
            ]
        }, {
            text: `To buy e-gift card, you need to agree to`,
            list: [
                `Pour acheter une carte - cadeau électronique, vous devez accepter`,
                `Um eine E-Geschenkkarte zu kaufen, müssen Sie dem zustimmen`,
                `Para comprar una tarjeta de regalo electrónica, debe aceptar`,
                ``,
                `Para comprar um vale-presente eletrônico, você precisa concordar com`,
                ``,
                ``,
            ]
        }, {
            text: `NOT NOW`,
            list: [
                `Pas maintenant`,
                `NICHT JETZT`,
                `No ahora`,
                ``,
                `NÃO AGORA`,
                ``,
                ``,
            ]
        }, {
            text: `AGREE`,
            list: [
                `D'accord.`,
                `EINKOMMEN`,
                `De acuerdo`,
                ``,
                `CONCORDAM`,
                ``,
                ``,
            ]
        }, {
            text: `Preview`,
            list: [
                `Preview`,
                `Vorschau`,
                `Vista previa`,
                ``,
                `Antevisão`,
                ``,
                ``,
            ]
        }, {
            text: `e-Gift Card`,
            list: [
                `Carte cadeau électronique`,
                `E-Geschenkkarte`,
                `Tarjeta de regalo electrónica`,
                ``,
                `Cartão Presente Electrónico`,
                ``,
                ``,
            ]
        }, {
            text: `Claim Code`,
            list: [
                `Code de réclamation`,
                `Schadenskodex`,
                `Código de reclamación`,
                ``,
                `Código do pedido`,
                ``,
                ``,
            ]
        }, {
            text: `Confirm the email address`,
            list: [
                `Confirmer l'adresse e - mail`,
                `Bestätigen Sie die E-Mail-Adresse`,
                `Confirmar la dirección de correo electrónico`,
                ``,
                `Confirmar o endereço de e- mail`,
                ``,
                ``,
            ]
        }, {
            text: `is the recipient's correct email address and the gift card claim code will send to it.`,
            list: [
                `Est l'adresse e - mail correcte du destinataire et le Code de réclamation de la carte cadeau sera envoyé au destinataire.`,
                `Ist die korrekte E-Mail-Adresse des Empfängers und der Gutscheincode wird an ihn gesendet.`,
                `Es la dirección de correo electrónico correcta del destinatario y el Código de reclamación de la tarjeta de regalo se enviará al destinatario.`,
                ``,
                `é o endereço de e-mail correto do destinatário e o código de solicitação do cartão presente será enviado para ele.`,
                ``,
                ``,
            ]
        }, {
            text: `Please confirm the email address`,
            list: [
                `Veuillez confirmer votre adresse e - mail`,
                `Bitte bestätigen Sie die E-Mail-Adresse`,
                `Por favor, confirme la dirección de correo electrónico`,
                ``,
                `Por favor confirme o endereço de e-mail`,
                ``,
                ``,
            ]
        }, {
            text: `Delivers by email.`,
            list: [
                `Envoyé par e - mail.`,
                `Lieferung per E-Mail. `,
                `Enviado por correo electrónico.`,
                ``,
                `Entregas por e-mail. `,
                ``,
                ``,
            ]
        }, {
            text: `within 5 minutes`,
            list: [
                `dans les 5 minutes`,
                `innerhalb von 5 Minuten`,
                `en 5 minutos`,
                ``,
                `dentro de 5 minutos`,
                ``,
                ``,
            ]
        }, {
            text: `Order Confirmation and updates will send to your email`,
            list: [
                `La confirmation de commande et les mises à jour seront envoyées à votre adresse e-mail`,
                `Bestellbestätigungen und Aktualisierungen werden an Ihre E-Mail-Adresse gesendet`,
                `La confirmación del pedido y las actualizaciones se enviarán a su correo electrónico.`,
                ``,
                `A confirmação do pedido e atualizações serão enviadas para seu e-mail`,
                ``,
                ``,
            ]
        }, {
            text: `With 5 minutes sending YFN gift card to`,
            list: [
                `Envoyez une carte - cadeau yFn en 5 minutes à`,
                `Mit 5 Minuten Senden der YFN Geschenkkarte an`,
                `Enviar la tarjeta de regalo yfn a`,
                ``,
                `Com 5 minutos enviando o cartão presente YFN para`,
                ``,
                ``,
            ]
        }, {
            text: `Estimated delivery occasionally, delivery may be delayed.`,
            list: [
                `Livraison occasionnelle prévue, livraison peut être retardée.`,
                `Voraussichtliche Lieferung gelegentlich, Lieferung kann verzögert sein.`,
                `Ocasionalmente se espera la entrega, que puede retrasarse.`,
                ``,
                `Estimativa de entrega ocasionalmente, a entrega pode ser atrasada.`,
                ``,
                ``,
            ]
        }, {
            text: `Not Sent`,
            list: [
                `Non envoyé`,
                `Nicht gesendet`,
                `No enviado`,
                ``,
                `Não Enviada`,
                ``,
                ``,
            ]
        }, {
            text: `Sent`,
            list: [
                `Envoyer`,
                `Gesendet`,
                `Enviar`,
                ``,
                `Enviado`,
                ``,
                ``,
            ]
        }, {
            text: `Send to`,
            list: [
                `Envoyer à`,
                `Senden an`,
                `Enviado a`,
                ``,
                `Enviar para`,
                ``,
                ``,
            ]
        }, {
            text: `Apply Coupon Code`,
            list: [
                `Appliquer le Code coupon`,
                `Gutscheincode anwenden`,
                `Código preferencial`,
                ``,
                `Código do cupão`,
                ``,
                ``,
            ]
        }, {
            text: `Gift Card Balance`,
            list: [
                `Solde de la carte cadeau`,
                `Guthaben der Geschenkkarte`,
                `Saldo de la tarjeta de regalo`,
                ``,
                `Saldo do Cartão Presente`,
                ``,
                ``,
            ]
        }, {
            text: `Use Gift Card Balance`,
            list: [
                `Utiliser le solde de la carte - cadeau`,
                `Geschenkguthaben verwenden`,
                `Saldo con tarjeta de regalo`,
                ``,
                `Usar o Saldo do Cartão Presente`,
                ``,
                ``,
            ]
        }, {
            text: `Redeem Now`,
            list: [
                `Échangez maintenant`,
                `Jetzt einlösen`,
                `Cambiar de inmediato`,
                ``,
                `Resgatar Agora`,
                ``,
                ``,
            ]
        }, {
            text: `Have an e-gift card,`,
            list: [
                `Avoir une carte - cadeau électronique`,
                `Haben Sie eine E-Geschenkkarte`,
                `Con tarjeta de regalo electrónica`,
                ``,
                `Tenha um cartão-presente eletrônico`,
                ``,
                ``,
            ]
        }, {
            text: `Enter claim code`,
            list: [
                `Entrez le Code de réclamation`,
                `Forderungscode eingeben`,
                `Introduzca el Código de reclamación`,
                ``,
                `Introduzir o código do pedido`,
                ``,
                ``,
            ]
        }, {
            text: `Applied successfully`,
            list: [
                `Application réussie`,
                `Erfolgreich angewendet`,
                `Aplicación exitosa`,
                ``,
                `Aplicado com sucesso`,
                ``,
                ``,
            ]
        }, {
            text: `Log in`,
            list: [
                `Connectez-vous`,
                `Melden Sie sich an`,
                `Iniciar sesión`,
                ``,
                `Faça login`,
                ``,
                ``,
            ]
        }, {
            text: `Hello`,
            list: [
                `Bonjour`,
                `Hallo`,
                `Hola`,
                ``,
                `Olá`,
                ``,
                ``,
            ]
        }, {
            text: `Apply to your YFN Account`,
            list: [
                `Demander votre compte yFn`,
                `Bewerben Sie sich auf Ihr YFN-Konto`,
                `Solicite su cuenta yfn`,
                ``,
                `Aplicar à sua Conta YFN`,
                ``,
                ``,
            ]
        }, {
            text: `to Apply`,
            list: [
                `pour valider`,
                `um einzulösen`,
                `para canjear`,
                ``,
                `para resgatar`,
                ``,
                ``,
            ]
        }, {
            text: `Applied to your account successfully`,
            list: [
                `Appliqué avec succès à votre compte`,
                `Erfolgreich auf Ihr Konto angewendet`,
                `Se ha aplicado con éxito a su cuenta`,
                ``,
                `Aplicado à sua conta com sucesso`,
                ``,
                ``,
            ]
        }, {
            text: `Once applied to your yfn.com account, the entire amount will be added to your Gift Card balance. Your Gift Card balance cannot be transferred to other accounts, used to buy other gift cards, or except as required by law, redeemed for cash.`,
            list: [
                `Une fois appliqué sur votre compte yfn.com, le montant total sera ajouté au solde de votre carte - cadeau. Le solde de votre carte - cadeau ne peut pas être transféré sur un autre compte ou utilisé pour acheter d'autres cartes - cadeaux ou ne peut pas être échangé contre de l'argent comptant, sauf si la loi l'exige.`,
                `Sobald der Betrag auf Ihr yfn.com Konto angewendet wurde, wird der gesamte Betrag Ihrem Geschenkguthaben gutgeschrieben. Ihr Geschenkguthaben kann nicht auf andere Konten übertragen, verwendet werden, um andere Geschenkkarten zu kaufen oder, sofern nicht gesetzlich vorgeschrieben, gegen Bargeld eingelöst werden.`,
                `Una vez solicitado a su cuenta yfn.com, se añadirá el importe total al saldo de su tarjeta de regalo. El saldo de su tarjeta de regalo no se puede transferir a otras cuentas, ni se puede utilizar para comprar otras tarjetas de regalo, o no se puede convertir en efectivo a menos que la ley lo requiera.`,
                ``,
                `Uma vez aplicado à sua conta yfn.com, o valor total será adicionado ao saldo do seu Cartão Presente. O saldo do seu Cartão Presente não pode ser transferido para outras contas, usado para comprar outros cartões-presente ou, exceto conforme exigido por lei, resgatado por dinheiro.`,
                ``,
                ``,
            ]
        }, {
            text: `Your gift card balance will be applied automatically to eligible orders during the checkout process and when using 1-Click. If you don't want to use your gift card balance on your order, just do not apply it in checkout.`,
            list: [
                `Le solde de votre carte - cadeau sera automatiquement appliqué aux commandes admissibles pendant le processus de paiement et lorsque vous utilisez 1 - click. Si vous ne souhaitez pas utiliser le solde de votre carte - cadeau sur votre commande, ne l'utilisez pas à la Caisse.`,
                `Ihr Geschenkguthaben wird während des Bezahlvorgangs und bei Verwendung von 1-Click automatisch auf berechtigte Bestellungen angewendet. Wenn Sie Ihr Geschenkguthaben nicht für Ihre Bestellung verwenden möchten, verwenden Sie es einfach nicht in der Kasse.`,
                `Durante el proceso de facturación y al usar 1 - click, el saldo de su tarjeta de regalo se aplicará automáticamente a los pedidos elegibles. Si no desea usar el saldo de la tarjeta de regalo en el pedido, no lo use al pagar.`,
                ``,
                `O saldo do seu cartão-presente será aplicado automaticamente a pedidos elegíveis durante o processo de checkout e ao usar 1-Click. Se você não quiser usar o saldo do cartão presente em seu pedido, apenas não aplique no checkout.`,
                ``,
                ``,
            ]
        }, {
            text: `if you experience any issues using your gift card, you can reference your gift card by providing the following information to Customer Service:`,
            list: [
                `Si vous rencontrez des problèmes avec votre carte - cadeau, vous pouvez vous référer à votre carte - cadeau en fournissant au service clientèle les informations suivantes:`,
                `Wenn Sie Probleme mit der Verwendung Ihrer Geschenkkarte haben, können Sie auf Ihre Geschenkkarte verweisen, indem Sie dem Kundenservice die folgenden Informationen zur Verfügung stellen:`,
                `Si tiene algún problema al usar la tarjeta de regalo, puede referirse a su tarjeta de regalo proporcionando al servicio al cliente la siguiente información:`,
                ``,
                `Se você tiver algum problema ao usar seu cartão presente, você pode referenciar seu cartão presente fornecendo as seguintes informações ao Serviço de Atendimento ao Cliente:`,
                ``,
                ``,
            ]
        }, {
            text: `Order Number`,
            list: [
                `Numéro de commande`,
                `Bestellnummer`,
                `Número de pedido`,
                ``,
                `Número da Ordem`,
                ``,
                ``,
            ]
        }, {
            text: `e-Gift Card Orders`,
            list: [
                `Commande de cartes - cadeaux électroniques`,
                `Bestellungen von E-Geschenkkarten`,
                `Pedidos de tarjetas de regalo electrónicas`,
                ``,
                `Encomendas de cartões de presente eletrónicos`,
                ``,
                ``,
            ]
        }, {
            text: `Add Billing Address`,
            list: [
                `Ajouter une adresse de facturation`,
                `Rechnungsadresse hinzufügen`,
                `Agregar dirección de facturación`,
                ``,
                `Adicionar endereço de cobrança`,
                ``,
                ``,
            ]
        }, {
            text: `coupon(s) available`,
            list: [
                `coupon(s) disponible(s)`,
                `Gutschein(e) verfügbar`,
                `cupón(es) disponibles`,
                ``,
                `cupom(s) disponível(eis)`,
                ``,
                ``,
            ]
        }, {
            text: `Your Email(required)`,
            list: [
                `Votre Email (requis)`,
                `Ihre E-Mailadresse (erforderlich)`,
                `Tu Email (requerido)`,
                `Ваша электронная почта (требуется)`,
                `Seu email (necessário)`,
                `あなたのメール（必須）`,
                `بريدك الالكتروني (مطلوب)`,
            ]
        }, {
            text: `Subscribe Now`,
            list: [
                `S'abonner`,
                `Abonniere jetzt`,
                `Suscríbase ahora`,
                `Подпишись сейчас`,
                `Inscreva-se agora`,
                `今すぐ購読してください`,
                `إشترك الآن`,
            ]
        }, {
            text: `Copy Code`,
            list: [
                `COPIER LE CODE`,
                `CODE KOPIERT`,
                `COPIAR CÓDIGO`,
                ``,
                `COPIAR CÓDIGO`,
                ``,
                ``,
            ]
        }, {
            text: `Other Activities Recommended`,
            list: [
                `Autres activités proposées`,
                `Weitere empfohlene Tätigkeiten`,
                `Otras actividades recomendadas`,
                ``,
                `Outras actividades recomendadas`,
                ``,
                ``,
            ]
        }, {
            text: `Copied`,
            list: [
                `COPIÉ`,
                `KOPIERT`,
                `COPIADO`,
                ``,
                `COPIADO`,
                ``,
                ``,
            ]
        }, {
            text: `Under`,
            list: [
                `Moins de`,
                `Unter`,
                `Under`,
                ``,
                `Menos de`,
                ``,
                ``,
            ]
        }, {
            text: `YFN Coupon & Promo Codes`,
            list: [
                `YFN Coupons et codes promotionnels`,
                `YFN-Gutschein- und Promo-Codes`,
                `Cupones y códigos promocionales de YFN`,
                ``,
                `Cupom e códigos promocionais YFN`,
                ``,
                ``,
            ]
        }, {
            text: `Over`,
            list: [
                `Plus de`,
                `Über`,
                `Sobre`,
                ``,
                `Mais de`,
                ``,
                ``,
            ]
        }, {
            text: `Enter the code at checkout`,
            list: [
                `Entrez le code lors du paiement`,
                `Geben Sie den Code an der Kasse ein`,
                `Ingresa el código al finalizar la compra`,
                ``,
                `Digite o código no checkout`,
                ``,
                ``,
            ]
        }, {
            text: `Min`,
            list: [
                `Min`,
                `Min`,
                `Min`,
                ``,
                `Mínimo`,
                ``,
                ``,
            ]
        }, {
            text: `Thank you for your trust and support. YFN Jewelry Company provides coupons in different ways. We hope it can add an extra surprise and warmth to your shopping experience!`,
            list: [
                `Merci pour votre confiance et votre soutien. YFN Jewelry Company propose des coupons de différentes manières. Nous espérons que cela ajoutera une surprise et une chaleur supplémentaires à votre expérience de magasinage !`,
                `Vielen Dank für Ihr Vertrauen und Ihre Unterstützung. YFN Jewelry Company bietet Gutscheine auf unterschiedliche Weise an. Wir hoffen, dass es Ihrem Einkaufserlebnis eine zusätzliche Überraschung und Wärme verleiht!`,
                `Gracias por su confianza y apoyo. YFN Jewelry Company ofrece cupones de diferentes formas. ¡Esperamos que pueda agregar una sorpresa y calidez extra a su experiencia de compra!`,
                ``,
                `Obrigado pela sua confiança e apoio. A YFN Jewelry Company oferece cupons de diferentes maneiras. Esperamos que possa adicionar uma surpresa e calor extra à sua experiência de compra!`,
                ``,
                ``,
            ]
        }, {
            text: `Max`,
            list: [
                `Max`,
                `Max`,
                `Max`,
                ``,
                `Máximo`,
                ``,
                ``,
            ]
        }, {
            text: `Go`,
            list: [
                `Go`,
                `Go`,
                `Go`,
                ``,
                `Vai`,
                ``,
                ``,
            ]
        }, {
            text: `Price Range`,
            list: [
                `Échelle des prix`,
                `Preisklasse`,
                `Rango de precios`,
                ``,
                `Faixa de preço`,
                ``,
                ``,
            ]
        }, {
            text: `No item matched`,
            list: [
                `Aucun projet correspondant`,
                `Kein Artikel gefunden`,
                `No hay artículos que coincidan`,
                ``,
                `Nenhum item correspondia`,
                ``,
                ``,
            ]
        }, {
            text: `Please try with other options`,
            list: [
                `Veuillez essayer une autre option`,
                `Bitte versuchen Sie es mit anderen Optionen`,
                `Pruebe otras opciones`,
                ``,
                `Por favor, tente com outras opções`,
                ``,
                ``,
            ]
        }, {
            text: `Reselect`,
            list: [
                `Re - Sélection`,
                `Auswählen`,
                `Reelección`,
                ``,
                `Reselecionar`,
                ``,
                ``,
            ]
        }, {
            text: `Price`,
            list: [
                `Prix`,
                `Preis`,
                `Precio`,
                ``,
                `Preço`,
                ``,
                ``,
            ]
        }, {
            text: `WANNA BE A #YFNJewelrypartner?`,
            list: [
                `VOULEZ-VOUS ÊTRE UN #YFNJewelrypartner?`,
                `WOLLEN SIE EIN #YFNJewelrypartner werden?`,
                `¿QUIERES SER socio de #YFNJewelrypartner?`,
                ``,
                `QUER SER #YFNJewelrypartner?`,
                ``,
                ``,
            ]
        }, {
            text: `Then get ready to be influenced`,
            list: [
                `Alors préparez-vous à être influencé ?`,
                `Dann mach dich bereit, beeinflusst zu werden?`,
                `Entonces prepárate para ser influenciado?`,
                ``,
                `Então prepare-se para ser influenciado?`,
                ``,
                ``,
            ]
        }, {
            text: `JOIN NOW`,
            list: [
                `REJOIGNEZ-NOUS`,
                `JETZT ANMELDEN`,
                `ÚNETE AHORA`,
                ``,
                `ADERIR AGORA`,
                ``,
                ``,
            ]
        }, {
            text: `Why Join us`,
            list: [
                `Pourquoi nous rejoindre`,
                `Warum mitmachen`,
                `Por qué unirse a nosotros`,
                ``,
                `Porquê juntar-se a nós`,
                ``,
                ``,
            ]
        }, {
            text: `Free Samples`,
            list: [
                `Échantillons gratuits`,
                `Kostenlose Muster`,
                `Muestras gratuitas`,
                ``,
                `Amostras grátis`,
                ``,
                ``,
            ]
        }, {
            text: `Up to $500  in Monthly Free Gifting`,
            list: [
                `Jusqu'à 500 $ par mois en cadeaux gratuits`,
                `Monatlich bis zu $500 geschenkt`,
                `Hasta 500 $ en regalos gratis al mes`,
                ``,
                `Até $500 em brindes mensais`,
                ``,
                ``,
            ]
        }, {
            text: `Payment`,
            list: [
                `Paiement`,
                `Bezahlung`,
                `Pago `,
                ``,
                `Pagamento `,
                ``,
                ``,
            ]
        }, {
            text: `15%+ commission $25000+ Commission paid monthly`,
            list: [
                `15%+ de commission 25000$+ de commission payée mensuellement`,
                `15%+ Provision Monatlich gezahlte Provision von $25000+`,
                `15%+ de comisión Comisión de $25000+ pagada mensualmente`,
                ``,
                `15%+ comissão Mais de $25000 de comissão paga mensalmente
                `,
                ``,
                ``,
            ]
        }, {
            text: `Exclusive discount`,
            list: [
                `Remise exclusive`,
                `Exklusiver Rabatt`,
                `Descuento exclusivo`,
                ``,
                `Desconto exclusivo`,
                ``,
                ``,
            ]
        }, {
            text: `Your referrals can enjoy up to 15% off discount`,
            list: [
                `Vos filleuls peuvent bénéficier d'une remise allant jusqu'à 15%.`,
                `Ihre Empfehlungen können bis zu 15% Rabatt erhalten`,
                `Sus referidos pueden disfrutar de hasta un 15% de descuento`,
                ``,
                `As suas referências podem usufruir de até 15% de desconto`,
                ``,
                ``,
            ]
        }, {
            text: `Co-branding`,
            list: [
                `Co-branding`,
                `Co-Branding`,
                `Marca compartida`,
                ``,
                `Co-branding`,
                ``,
                ``,
            ]
        }, {
            text: `Exclusive Product Selection,Design and Shooting`,
            list: [
                `Sélection exclusive de produits, conception et prise de vue`,
                `Exklusive Produktauswahl, Design und Shooting`,
                `Selección exclusiva de productos, diseño y rodaje`,
                ``,
                `Seleção exclusiva de produtos, design e filmagem`,
                ``,
                ``,
            ]
        }, {
            text: `How it works`,
            list: [
                `Comment cela fonctionne-t-il ?`,
                `Wie es funktioniert`,
                `Cómo funciona`,
                ``,
                `Como é que funciona`,
                ``,
                ``,
            ]
        }, {
            text: `SIGN UP&Join Campaign`,
            list: [
                `S'inscrire et participer à la campagne   `,
                `ANMELDEN&Kampagne mitmachen   `,
                `INSCRÍBETE&Únete a la campaña   `,
                ``,
                `Inscrever-se e juntar-se à campanha   `,
                ``,
                ``,
            ]
        }, {
            text: `Choose jewelry`,
            list: [
                `Choisir un bijou`,
                `Schmuck auswählen`,
                `Elegir joyas`,
                ``,
                `Escolher jóias`,
                ``,
                ``,
            ]
        }, {
            text: `Post&Add products to showcase&Share Product in post&livestream`,
            list: [
                `Postez&Ajoutez des produits à présenter&Partagez le produit dans le post&livestream `,
                `Post&Produkte zum Schaufenster hinzufügen&Produkt in Post&Livestream teilen `,
                `Publicar&Añadir productos al escaparate&Compartir producto en post&livestream `,
                ``,
                `Publicar&Adicionar produtos à montra&Partilhar o produto no post&livestream `,
                ``,
                ``,
            ]
        }, {
            text: `Earn Cash&Balance`,
            list: [
                `Gagnez de l'argent&Balance`,
                `Geld verdienen&Guthaben`,
                `Gana dinero y saldo`,
                ``,
                `Ganhar dinheiro&Saldo`,
                ``,
                ``,
            ]
        }, {
            text: `Repeat for more free products`,
            list: [
                `Répéter pour plus de produits gratuits`,
                `Wiederholen Sie für weitere kostenlose Produkte`,
                `Repite Para más productos gratis`,
                ``,
                `Repita para obter mais produtos gratuitos`,
                ``,
                ``,
            ]
        }, {
            text: `Millions of Influencers Have Already Worked With Us`,
            list: [
                `Des millions d'influenceurs ont déjà travaillé avec nous  `,
                `Millionen von Influencern haben bereits mit uns gearbeitet  `,
                `Millones de influencers ya han trabajado con nosotros  `,
                ``,
                `Milhões de influenciadores já trabalharam connosco  `,
                ``,
                ``,
            ]
        }, {
            text: `Featured YFN Influencers`,
            list: [
                `Influenceurs YFN en vedette`,
                `Ausgewählte YFN-Influencer`,
                `Influenciadores YFN destacados`,
                ``,
                `Influenciadores da YFN em destaque`,
                ``,
                ``,
            ]
        }, {
            text: `FAQs about G Color Diamonds`,
            list: [
                `FAQ sur les diamants de couleur G`,
                `FAQs zu Diamanten der Farbe G`,
                `Preguntas frecuentes sobre los diamantes de color G`,
                `Часто задаваемые вопросы о бриллиантах цвета G`,
                `Perguntas frequentes sobre G Color Diamonds`,
                `G カラー ダイヤモンドに関するよくある質問`,
                `الأسئلة الشائعة حول G Color Diamonds`,
            ]
        }, {
            text: `Based on`,
            list: [
                `Basée sur`,
                `Basierend auf`,
                `Basado en más de`,
                ``,
                `Baseado em mais de`,
                ``,
                ``,
            ]
        }, {
            text: `reviews`,
            list: [
                `commentaires`,
                `Bewertungen`,
                `comentarios`,
                ``,
                `comentários`,
                ``,
                ``,
            ]
        }, {
            text: `This a verified purchase review`,
            list: [
                `This a Verified purchase Review`,
                `Dies ist eine verifizierte Kaufbewertung`,
                `Esta es una revisión de compras verificada.`,
                ``,
                `Esta é uma revisão de compra verificada`,
                ``,
                ``,
            ]
        }, {
            text: `Most recent`,
            list: [
                `Plus recent`,
                `Neueste`,
                `Recientemente`,
                ``,
                `Mais Recentes`,
                ``,
                ``,
            ]
        }, {
            text: `Most helpful`,
            list: [
                `Plus d'aide`,
                `Am hilfreichsten`,
                `El más útil`,
                ``,
                `Mais Útil`,
                ``,
                ``,
            ]
        }, {
            text: `Highest rating`,
            list: [
                `Highest rating`,
                `Höchste Bewertung`,
                `Calificación máxima`,
                ``,
                `Classificação mais Alta`,
                ``,
                ``,
            ]
        }, {
            text: `Lowest rating`,
            list: [
                `Le Lowest rating`,
                `Niedrigste Bewertung`,
                `Calificación mínima`,
                ``,
                `Classificação mais baixa`,
                ``,
                ``,
            ]
        }, {
            text: `Pictures first`,
            list: [
                `Images First`,
                `Bilder zuerst`,
                `Imagen primero`,
                ``,
                `Imagens Primeiro`,
                ``,
                ``,
            ]
        }, {
            text: `Video first`,
            list: [
                `Vidéo First`,
                `Video zuerst`,
                `Prioridad de vídeo`,
                ``,
                `Vídeo Primeiro`,
                ``,
                ``,
            ]
        }, {
            text: `Staat/Region`,
            list: [
                `Statut/Région`,
                `Staat/Region`,
                `Estado/Área`,
                ``,
                `Estado/Região`,
                ``,
                ``,
            ]
        }, {
            text: `Recommended for You`,
            list: [
                `Recommandé pour vous`,
                `Empfohlen für Sie`,
                `Recomendado para usted`,
                ``,
                `Recomendado para si`,
                ``,
                ``,
            ]
        }, {
            text: `Reply`,
            list: [
                `Répondre`,
                `Antwort`,
                `Respuesta`,
                ``,
                `Responder`,
                ``,
                ``,
            ]
        }, {
            text: `Overview`,
            list: [
                `Aperçu`,
                `Übersicht`,
                `Resumen`,
                ``,
                `Visão geral`,
                ``,
                ``,
            ]
        }, {
            text: `Material`,
            list: [
                `Matériaux`,
                `Material`,
                `Material`,
                ``,
                `Material`,
                ``,
                ``,
            ]
        }, {
            text: `Viewed`,
            list: [
                `Vu`,
                `von`,
                `Visto`,
                `Просмотрено`,
                `Visto`,
                ``,
                ``,
            ]
        }, {
            text: `of`,
            list: [
                `sur`,
                `angesehen`,
                `de`,
                `из`,
                `de`,
                ``,
                ``,
            ]
        }, {
            text: `YFN Jewelry`,
            list: [
                `Bijoux YFN`,
                `YFN Schmuck`,
                `Joyería YFN`,
                `ЮФН Ювелирные изделия`,
                `Joias YFN`,
                `YFNジュエリー`,
                `مجوهرات YFN`,
            ]
        }, {
            text: `My Bag`,
            list: [
                `Mon sac`,
                `Meine Tasche`,
                `Mi bolsa`,
                `Моя сумка`,
                `Minha bolsa`,
                ``,
                ``,
            ]
        }, {
            text: `Coupon Code`,
            list: [
                `Code promo`,
                `Gutscheincode`,
                `Código promocional`,
                `код купона`,
                `Código do cupom`,
                ``,
                ``,
            ]
        }, {
            text: `Order Summary`,
            list: [
                `Récapitulatif de la commande`,
                `Bestellübersicht`,
                `Resumen del pedido`,
                `итог заказа`,
                `resumo do pedido`,
                ``,
                ``,
            ]
        }, {
            text: `Order Total`,
            list: [
                `Total de la commande`,
                `Auftragssumme`,
                `Total del pedido`,
                `Весь заказ`,
                `total de pedidos`,
                ``,
                ``,
            ]
        }, {
            text: `Buy with`,
            list: [
                `Achetez avec`,
                `Kaufen mit`,
                `Comprar con`,
                `Купить с помощью`,
                `Compre com`,
                ``,
                ``,
            ]
        }, {
            text: `Continue Shopping`,
            list: [
                `Continuer vos achats`,
                `Mit dem Einkaufen fortfahren`,
                `Seguir comprando`,
                `Продолжить покупки`,
                `Continue comprando`,
                ``,
                ``,
            ]
        }, {
            text: `Please Choose`,
            list: [
                `Veuillez choisir`,
                `Veuillez choisir`,
                `Por favor elija`,
                `Пожалуйста, выберите`,
                `Por favor escolha`,
                ``,
                ``,
            ]
        }, {
            text: ` & Up`,
            list: [
                ` et plus`,
                ` & Up`,
                ` y superiores`,
                ` и вверх`,
                ` e acima`,
                ``,
                ``,
            ]
        }, {
            text: `Unisex(Women,Men)`,
            list: [
                `Unisexe (femmes, hommes)`,
                `Unisex (Frauen, Männer)`,
                `Unisex (mujeres, hombres)`,
                ``,
                `Unissex (mulheres, homens)`,
                ``,
                ``,
            ]
        }, {
            text: ``,
            list: [
                ``,
                ``,
                ``,
                ``,
                ``,
                ``,
                ``,
            ]
        }]
    }
}

export default Fn